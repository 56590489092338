import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import {
  LinkDataApiService,
  LinkDataType,
  OrderType,
  TransferDirectionFilter,
} from '../core/services/dpl-api-services';
import { OrderLoadsViewType } from '../shared';
import { DeepLinkService } from '../core/services/deep-link.service';
import { Observable, of } from 'rxjs';

type LinkData =
  | {
      type:
        | 'NewOffer'
        | 'TransportVoucherCreated'
        | 'BalanceTransferProcessConfirmationRequested'
        | 'BalanceTransferProcessConfirmed'
        | 'BalanceTransferProcessRejected';
    }
  | {
      type: 'NewOrderProposal';
      orderId: number;
      isSelfTransport?: boolean;
    }
  | {
      type: 'OrderCanceled';
      divisionId: number;
      orderType: OrderType;
      orderId: number;
      customerCoPilot: number;
    }
  | {
      type: 'FulfillmentPreRegistration';
      digitalCode: string;
      orderId: number;
      orderType: OrderType;
    }
  | {
      type: 'LoadCarrierSortingCreated';
      loadCarrierSortingId: number;
      loadCarrierReceiptId: number;
      customerCoPilot: number;
      documentNumber: string;
      divisionId: number;
    };

@Component({
  selector: 'dpl-link',
  template: `
    <p i18n="dpl-link|Sie werden umgeleitet!@@LinkTemplateText">
      Sie werden umgeleitet!
    </p>
  `,
  styles: [],
})
export class LinkComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deepLink: DeepLinkService,
    private linkData: LinkDataApiService
  ) {}

  ngOnInit(): void {
    const linkData$ = this.route.queryParams.pipe(
      map((params) => params['d']),
      map((base64Data) => {
        if (!base64Data) {
          return null;
        }

        const jsonString = atob(base64Data);
        if (!jsonString) {
          return null;
        }

        const data = JSON.parse(jsonString) as LinkData;
        return data;
      })
    );

    linkData$
      .pipe(
        first(),
        filter((data) => !!data),
        switchMap((data) => this.getNavigationCommands(data)),
        tap(([commands, navigationExtras]) => {
          const extras = {
            replaceUrl: true,
            ...navigationExtras,
          };

          return this.router.navigate(commands, extras);
        })
      )
      .subscribe();
  }

  getNavigationCommands(
    data: LinkData
  ): Observable<[string[], NavigationExtras]> {
    switch (data.type) {
      case 'NewOrderProposal': {
        const { orderId } = data;

        return this.linkData
          .get({
            type: LinkDataType.Offer,
            identifier: `${orderId}`,
          })
          .pipe(
            map((data) => {
              return this.deepLink.getNavigationCommand(data);
            })
          );
      }
      case 'NewOffer':
        return of([['/offers', 'general'], {}]);
      case 'TransportVoucherCreated':
        return of([['/offers'], {}]);
      case 'OrderCanceled': {
        const { orderId } = data;

        return this.linkData
          .get({
            type: LinkDataType.Order,
            identifier: `${orderId}`,
          })
          .pipe(
            map((data) => {
              return this.deepLink.getNavigationCommand(data);
            })
          );
      }

      case 'FulfillmentPreRegistration':
        return of([
          ['/order-loads'],
          {
            queryParams: {
              type: OrderLoadsViewType.Journal,
              orderType: data.orderType,
              code: data.digitalCode,
            },
          },
        ]);
      case 'BalanceTransferProcessConfirmationRequested':
        return of([
          ['/transfers', TransferDirectionFilter.ReceivedAndPending],
          {},
        ]);
      case 'BalanceTransferProcessConfirmed':
      case 'BalanceTransferProcessRejected': {
        const filter: TransferDirectionFilter =
          data.type === 'BalanceTransferProcessConfirmed'
            ? TransferDirectionFilter.SentAndConfirmed
            : TransferDirectionFilter.SentAndRejected;

        return of([['/transfers', filter], {}]);
      }
      case 'LoadCarrierSortingCreated': {
        const { loadCarrierReceiptId } = data;

        return this.linkData
          .get({
            type: LinkDataType.LoadCarrierReceipt,
            identifier: `${loadCarrierReceiptId}`,
          })
          .pipe(
            map((data) => {
              return this.deepLink.getNavigationCommand(data);
            })
          );
      }
      default:
        throw new Error(
          $localize`:@@ArgumentOutOfRange:Argument out of range` +
            `: ${(data as any)?.type}`
        );
    }
  }
}
