import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { APP_CONFIG, DplLiveConfiguration } from './config';
import { environment } from './environments/environment';
import { localizeUrl } from './utils';
import { persistState } from '@datorama/akita';

const storage = persistState({
  include: ['customers.active','customer-divisions.active', 'offer-requests', 'offers'],
});

fetch(localizeUrl('/assets/config.json'))
  .then((response) => response.json())
  .then((config: DplLiveConfiguration) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config },
      { provide: 'persistStorage', useValue: storage },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
