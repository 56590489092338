<button
  mat-icon-button
  *ngIf="fieldName"
  id="{{ 'poBtn' + fieldName }}"
  (mouseenter)="popOverVisible = true"
>
  <!-- (mouseleave)="popOverVisible = false" -->
  <mat-icon color="primary">info</mat-icon>
</button>
<dx-popover
  target="{{ '#poBtn' + fieldName }}"
  position="right"
  [(visible)]="popOverVisible"
  [showTitle]="true"
  titleTemplate="titleTemplate"
>
  <div *dxTemplate="let data of 'titleTemplate'">
    <h4>{{ headingText }}</h4>
  </div>
  <div *dxTemplate="let data = model; of: 'content'" dx-scroll-view="{}">
    <dx-scroll-view showScrollbar="always" style="max-height: 350px">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="quantity">
          <th
            mat-header-cell
            *matHeaderCellDef
            i18n="@@AvailabilityRowLoadCarrierQuantity"
          >
            Menge
          </th>
          <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
        </ng-container>

        <ng-container matColumnDef="stackHeight">
          <th
            mat-header-cell
            *matHeaderCellDef
            i18n="@@AvailabilityRowFormFielStackHeightLabelText"
          >
            Stapelhöhe
          </th>
          <td mat-cell *matCellDef="let element">{{ element.stackHeight }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </dx-scroll-view>
  </div>
</dx-popover>
