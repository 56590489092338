const localePath = (() => {
  const pathMatch = window.location.pathname.match(/^(\/.{2}\/)/);
  return pathMatch ? pathMatch[0] : null;
})();

export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function isMsalIframe() {
  return window.self !== window.top;
}

export function localizeUrl(urlString: string) {
  if (!localePath) {
    return urlString;
  }

  const base = urlString.startsWith('/') ? window.location.origin : undefined;

  const url = new URL(urlString, base);
  url.pathname = url.pathname.replace(/^\//, localePath);

  return url.toString();
}
