import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'newLine',
})
export class NewLinePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(textWithNewLine: string | null | undefined): any {
    return this.sanitizer.bypassSecurityTrustHtml(
      textWithNewLine?.replace(/\n/g, '<br/>')
    );
  }
}
