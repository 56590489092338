<ng-container *ngIf="viewData$ | async as data">
  <ng-container
    *ngIf="
      data.userRole === userRole.DplEmployee ||
      data.user?.permissions?.length > 0
    "
  >
    <ng-container>
      <button
        mat-icon-button
        [matMenuTriggerFor]="burger"
        fxShow="true"
        fxHide.gt-sm
      >
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #burger="matMenu">
        <button
          mat-menu-item
          [matMenuTriggerFor]="createMenu"
          i18n="
            topNavigationCreateMenuBtn|Top Navigation Menü
            Erstellen@@topNavigationCreateMenuBtn"
        >Erstellen</button>

        <button
          mat-menu-item
          [matMenuTriggerFor]="overviewMenu"
          i18n="
            topNavigationOverviewMenuBtn|Top Navigation Menü
            Übersicht@@topNavigationOverviewMenuBtn"
        >
          Übersicht
        </button>

        <a
          mat-menu-item
          [matMenuTriggerFor]="submissionMenu"
          i18n="
            topNavigationUploadMenuButton|Top Navigation Menü
            Upload@@topNavigationUploadMenuButton"
          *hasPermission="{
            actions: [
              resourceAction.UIProcessMultiUpload,
              resourceAction.UIVoucherSubmission
            ],
            resource: 'Division',
            referenceId: data.activeDivisionId
          }"
          >Upload</a
        >
        <a
          mat-menu-item
          [matMenuTriggerFor]="adminMenu"
          i18n="
            topNavigationSelfAdministrationMenuBtn|Top Navigation Menü
            Administration@@topNavigationSelfAdministrationMenuBtn"
          *hasPermission="{
            actions: [
              resourceAction.UISelfAdministrationUsers,
              resourceAction.UISelfAdministrationPartners,
              resourceAction.UISelfAdministrationAbsence
            ]
          }"
          >Administration</a
        >
      </mat-menu>

      <div fxShow="true" fxHide.lt-md fxLayout="row">
        <a mat-button class="siteLogo" [routerLink]="['']">
          <div fxlayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <img src="assets/dpl-pooling-logo-trans.png" /><span
              i18n="homePagetitle|Home Seitentitel@@homePagetitle"
              >Live Pooling</span
            >
          </div></a
        >
        <a
          mat-button
          [matMenuTriggerFor]="createMenu"
          i18n="
            topNavigationCreateMenuBtn|Top Navigation Menü
            Erstellen@@topNavigationCreateMenuBtn"
          >Erstellen</a
        >

        <a
          mat-button
          [matMenuTriggerFor]="overviewMenu"
          i18n="
            topNavigationOverviewMenuBtn|Top Navigation Menü
            Übersicht@@topNavigationOverviewMenuBtn"
          >Übersicht</a
        >
        <a
          mat-button
          [matMenuTriggerFor]="submissionMenu"
          i18n="
            topNavigationUploadMenuButton|Top Navigation Menü
            Upload@@topNavigationUploadMenuButton"
          *hasPermission="{
            actions: [
              resourceAction.UIProcessMultiUpload,
              resourceAction.UIVoucherSubmission
            ],
            resource: 'Division',
            referenceId: data.activeDivisionId
          }"
          >Upload</a
        >
        <a
          mat-button
          [matMenuTriggerFor]="adminMenu"
          i18n="
            topNavigationSelfAdministrationMenuBtn|Top Navigation Menü
            Administration@@topNavigationSelfAdministrationMenuBtn"
          *hasPermission="{
            actions: [
              resourceAction.UISelfAdministrationUsers,
              resourceAction.UISelfAdministrationPartners,
              resourceAction.UISelfAdministrationAbsence
            ]
          }"
          >Administration</a
        >
      </div>

      <mat-menu #createMenu="matMenu" class="wideMenu">
        <dpl-divider
          text="POOLING"
          [permissions]="[
            resourceAction.UICreateVoucher,
            resourceAction.UICreateNeedSelfTransport,
            resourceAction.UICreateAvailability,
            resourceAction.UICreateNeed,
            resourceAction.UICreateLivePoolingSearch,
            resourceAction.UICreateLoadCarrierReceiptDelivery,
            resourceAction.UICreateLoadCarrierReceiptPickup,
            resourceAction.UICreateExternalLoadCarrierReceiptIncomning,
            resourceAction.UICreateExternalLoadCarrierReceiptOutgoing,
            resourceAction.UICreateBalanceTransfer,
            resourceAction.UICreatePostLoadCarrierReceiptIncoming,
            resourceAction.UICreatePostLoadCarrierReceiptOutgoing,
            resourceAction.UIDepotOverview
          ]"
          [divisionId]="data.activeDivisionId"
          i18n-text="
            topNavigationDividerTextPooling|Text
            POOLING@@topNavigationDividerTextPooling"
        ></dpl-divider>

        <a
          mat-menu-item
          [routerLink]="['voucher']"
          *hasPermission="[
            resourceAction.UICreateVoucher,
            'Division',
            data.activeDivisionId
          ]"
          i18n="
            topNavigationPalletAcceptanceBtn|Top Navigation Schaltfläche
            Palettenannahme@@topNavigationPalletAcceptanceBtn"
          >Digitale Pooling Gutschriften</a
        >
        <a
          mat-menu-item
          [routerLink]="['offers/request']"
          [queryParams]="{ action: 'pickup' }"
          i18n="
            topNavigationOfferCreateBtn|Top Navigation Menü Bedarf melden - zur
            Selbstabholung@@topNavigationOfferCreateBtn"
          *hasPermission="[
            resourceAction.UICreateNeedSelfTransport,
            'Division',
            data.activeDivisionId
          ]"
        >
          Bedarf melden - zur Selbstabholung
        </a>
        <a
          mat-menu-item
          [routerLink]="['needs/start']"
          i18n="
            topNavigationNeedsStartMenuBtn|Top Navigation Menü Bedarf melden -
            zur Lieferung (DPL)@@topNavigationNeedsStartMenuBtn"
          *hasPermission="[
            resourceAction.UICreateNeed,
            'Division',
            data.activeDivisionId
          ]"
        >
          Bedarf melden - zur Lieferung (DPL)</a
        >

        <a
          mat-menu-item
          [routerLink]="['availabilities/start']"
          i18n="
            topNavigationAvailabilitiesStartBtn|Top Navigation Menü
            Verfügbarkeit melden - zur Abholung
            (DPL)@@topNavigationAvailabilitiesStartBtn"
          *hasPermission="[
            resourceAction.UICreateAvailability,
            'Division',
            data.activeDivisionId
          ]"
        >
          Verfügbarkeit melden - zur Abholung (DPL)</a
        >

        <a
          mat-menu-item
          [routerLink]="['search/start']"
          i18n="
            topNavigationLiveMenuBtn|Top Navigation Menü Abgabe / Abholung
            selbst planen (Live Karte)@@topNavigationLiveMenuBtn"
          *hasPermission="[
            resourceAction.UICreateLivePoolingSearch,
            'Division',
            data.activeDivisionId
          ]"
        >
          Abgabe / Abholung selbst planen (Live Karte)</a
        >

        <dpl-divider
          [permissions]="[
            resourceAction.UICreateLoadCarrierReceiptDelivery,
            resourceAction.UICreateLoadCarrierReceiptPickup
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['receipt/create']"
          [queryParams]="{ action: loadCarrierReceiptType.Delivery }"
          i18n="
            topNavigationLoadCarrierDeliveryReceiptBtn|Top Navigation Menü
            Annahme-Quittung erstellen - eigener
            Standort@@topNavigationLoadCarrierDeliveryReceiptBtn"
          *hasPermission="[
            resourceAction.UICreateLoadCarrierReceiptDelivery,
            'Division',
            data.activeDivisionId
          ]"
        >
          Annahme-Quittung erstellen - eigener Standort</a
        >
        <a
          mat-menu-item
          [routerLink]="['receipt/create']"
          [queryParams]="{ action: loadCarrierReceiptType.Pickup }"
          i18n="
            topNavigationLoadCarrierPickupReceiptBtn|Top Navigation Menü
            Ausgabe-Quittung erstellen - eigener
            Standort@@topNavigationLoadCarrierPickupReceiptBtn"
          *hasPermission="[
            resourceAction.UICreateLoadCarrierReceiptPickup,
            'Division',
            data.activeDivisionId
          ]"
        >
          Ausgabe-Quittung erstellen - eigener Standort</a
        >

        <!-- <a
        mat-menu-item
        [routerLink]="['receipt/create']"
        [queryParams]="{ action: loadCarrierReceiptType.Exchange }"
        i18n="
          Top Navigation Schaltfläche
          Tausch-Quittung@@topNavigationLoadCarrierExchangeReceiptBtn"
        *hasPermission="
          resourceAction.UICreateLoadCarrierReceiptExchange
        "
        >Tausch-Quittung</a
      > -->

        <dpl-divider
          [permissions]="[
            resourceAction.UICreateExternalLoadCarrierReceiptIncomning,
            resourceAction.UICreateExternalLoadCarrierReceiptOutgoing
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['ext-receipt/incoming']"
          *hasPermission="[
            resourceAction.UICreateExternalLoadCarrierReceiptIncomning,
            'Division',
            data.activeDivisionId
          ]"
          i18n="
            topNavigationExtReceiptIncomingLoadCarrierExternalLoadCarriers|Top
            Navigation Eigene Konten -
            Annahme@@topNavigationExtReceiptIncomingLoadCarrierExternalLoadCarriers"
          >Eigene Konten - Annahme</a
        >

        <a
          mat-menu-item
          [routerLink]="['ext-receipt/outgoing']"
          *hasPermission="[
            resourceAction.UICreateExternalLoadCarrierReceiptOutgoing,
            'Division',
            data.activeDivisionId
          ]"
          i18n="
            topNavigationExtReceiptOutgoingLoadCarrierExternalLoadCarriers|Top
            Navigation Eigene Konten -
            Ausgabe@@topNavigationExtReceiptOutgoingLoadCarrierExternalLoadCarriers"
          >Eigene Konten - Ausgabe</a
        >

        <dpl-divider
          [permissions]="[resourceAction.UICreateBalanceTransfer]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['transfer/create']"
          i18n="
            topNavigationTransferBtn|Top Navigation Menü Umbuchung
            erstellen@@topNavigationTransferBtn"
          *hasPermission="[
            resourceAction.UICreateBalanceTransfer,
            'Division',
            data.activeDivisionId
          ]"
          >Umbuchung erstellen</a
        >

        <!-- Nacherfassung -->
        <dpl-divider
          [permissions]="[
            resourceAction.UICreatePostLoadCarrierReceiptIncoming,
            resourceAction.UICreatePostLoadCarrierReceiptOutgoing
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['receipt/postincoming']"
          [queryParams]="{ action: loadCarrierReceiptType.Delivery }"
          *hasPermission="[
            resourceAction.UICreatePostLoadCarrierReceiptIncoming,
            'Division',
            data.activeDivisionId
          ]"
          style="font-style: italic"
          ><span
            i18n="
              LoadCarrierReceiptPostCreateActionLabel|Action Label
              Nacherfassung@@LoadCarrierReceiptPostCreateActionLabel"
            >Nacherfassung</span
          >&nbsp;<span
            i18n="
              Top Navigation Schaltfläche
              Annahme-Quittung@@topNavigationLoadCarrierDeliveryReceiptBtn"
            >Annahme-Quittung</span
          ></a
        >

        <a
          mat-menu-item
          [routerLink]="['receipt/postoutgoing']"
          [queryParams]="{ action: loadCarrierReceiptType.Pickup }"
          *hasPermission="[
            resourceAction.UICreatePostLoadCarrierReceiptOutgoing,
            'Division',
            data.activeDivisionId
          ]"
          style="font-style: italic"
        >
          <span
            i18n="
              LoadCarrierReceiptPostCreateActionLabel|Action Label
              Nacherfassung@@LoadCarrierReceiptPostCreateActionLabel"
            >Nacherfassung</span
          >&nbsp;<span
            i18n="
              Top Navigation Schaltfläche
              Ausgabe-Quittung@@topNavigationLoadCarrierPickupReceiptBtn"
            >Ausgabe-Quittung</span
          >
        </a>

        <dpl-divider
          text="DEPOT"
          [permissions]="[
            resourceAction.UICreateLoadCarrierReceiptDeliveryDepot,
            resourceAction.UICreatePostLoadCarrierReceiptIncomingDepot,
            UICreateLoadCarrierReceiptPickupDepot,
            resourceAction.UICreatePostLoadCarrierReceiptIncomingDepot
          ]"
          [divisionId]="data.activeDivisionId"
          i18n-text="
            topNavigationDividerTextDepot|Text
            DEPOT@@topNavigationDividerTextDepot"
        ></dpl-divider>

        <a
          mat-menu-item
          [routerLink]="['receipt/create']"
          [queryParams]="{
            action: loadCarrierReceiptType.Delivery,
            context: 'depot'
          }"
          i18n="
            topNavigationLoadCarrierDepoDeliveryReceiptBtn|Top Navigation Menü
            DEPOT: Annahme-Quittung erstellen - eigener
            Standort@@topNavigationLoadCarrierDepoDeliveryReceiptBtn"
          *hasPermission="[
            resourceAction.UICreateLoadCarrierReceiptDeliveryDepot,
            'Division',
            data.activeDivisionId
          ]"
          >DEPOT: Annahme-Quittung erstellen - eigener Standort</a
        >

        <a
          mat-menu-item
          [routerLink]="['receipt/create']"
          [queryParams]="{
            action: loadCarrierReceiptType.Pickup,
            context: 'depot'
          }"
          i18n="@@topNavigationLoadCarrierDepoPickupReceiptBtn"
          *hasPermission="[
            resourceAction.UICreateLoadCarrierReceiptPickupDepot,
            'Division',
            data.activeDivisionId
          ]"
          >DEPOT: Ausgabe-Quittung erstellen - eigener Standort</a
        >
        <!-- Nacherfassung -->
        <dpl-divider
          [permissions]="[
            resourceAction.UICreatePostLoadCarrierReceiptIncomingDepot
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['receipt/postincoming']"
          [queryParams]="{
            action: loadCarrierReceiptType.Delivery,
            context: 'depot'
          }"
          *hasPermission="[
            resourceAction.UICreatePostLoadCarrierReceiptIncomingDepot,
            'Division',
            data.activeDivisionId
          ]"
          style="font-style: italic"
          ><span
            i18n="
              LoadCarrierReceiptPostCreateActionLabel|Action Label
              Nacherfassung@@LoadCarrierReceiptPostCreateActionLabel"
            >Nacherfassung</span
          >&nbsp;<span
            i18n="
              Top Navigation Schaltfläche
              Annahme-Quittung@@topNavigationLoadCarrierDepoDeliveryReceiptBtn"
            >Depot-Annahme-Quittung</span
          >
        </a>
      </mat-menu>

      <mat-menu #overviewMenu="matMenu" class="wideMenu">
        <dpl-divider
          text="POOLING"
          i18n-text="
            topNavigationDividerTextPooling|Text
            POOLING@@topNavigationDividerTextPooling"
          [permissions]="[
            resourceAction.UIListVoucher,
            resourceAction.UIListAccountingRecords,
            resourceAction.UIListBalanceTransfers,
            resourceAction.UIListAvailability,
            resourceAction.UIListNeed,
            resourceAction.UIListNeedSelfTransport,
            resourceAction.UIListOffer,
            resourceAction.UIListLivePoolingOrders,
            resourceAction.UIListJournalDepot,
            resourceAction.UIListLoadCarrierReceipt
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>

        <a
          mat-menu-item
          [routerLink]="['offers/general']"
          i18n="
            topNavigationOffersGeneralListBtn|Top Navigation Angebote - zur
            Selbstabholung @@topNavigationOffersGeneralListBtn"
          *hasPermission="[
            resourceAction.UIListOffer,
            'Division',
            data.activeDivisionId
          ]"
          >Angebote - zur Selbstabholung</a
        >
        <a
          mat-menu-item
          [routerLink]="['offers']"
          i18n="
            topNavigationOffersListBtn|Top Navigation Gemeldete Bedarfe - zur
            Selbstabholung@@topNavigationOfferSelfCollect"
          *hasPermission="[
            resourceAction.UIListNeedSelfTransport,
            'Division',
            data.activeDivisionId
          ]"
          >Gemeldete Bedarfe - zur Selbstabholung</a
        >
        <a
          mat-menu-item
          [routerLink]="['orders']"
          [queryParams]="{ type: ordersViewType.Demand }"
          i18n="
            topNavigationNeedsListBtn|Top Navigation Gemeldete Bedarfe - zur
            Lieferung (DPL)@@topNavigationOfferDelivery"
          *hasPermission="[
            resourceAction.UIListNeed,
            'Division',
            data.activeDivisionId
          ]"
          >Gemeldete Bedarfe - zur Lieferung (DPL)</a
        >
        <a
          mat-menu-item
          [routerLink]="['orders']"
          [queryParams]="{ type: ordersViewType.Supply }"
          i18n="
            topNavigationAvailabilitiesListBtn|Top Navigation Gemeldete
            Verfügbarkeiten - zur Abholung
            (DPL)@@topNavigationAvailabilitiesListBtn"
          *hasPermission="[
            resourceAction.UIListAvailability,
            'Division',
            data.activeDivisionId
          ]"
        >
          Gemeldete Verfügbarkeiten - zur Abholung (DPL)</a
        >
        <a
          mat-menu-item
          [routerLink]="['orders']"
          [queryParams]="{ type: ordersViewType.LivePooling }"
          i18n="
            topNavigationLivePoolingListBtn|Top Navigation Selbst geplante
            Abgaben / Abholungen (Live Karte)@@topNavigationLivePoolingListBtn"
          *hasPermission="[
            resourceAction.UIListLivePoolingOrders,
            'Division',
            data.activeDivisionId
          ]"
        >
          Selbst geplante Abgaben / Abholungen (Live Karte)</a
        >
        <a
          mat-menu-item
          [routerLink]="['depot-overview']"
          i18n="
            topNavigationDepotOverviewMenuBtn|Top Navigation Menü Depot
            Übersicht@@topNavigationDepotOverviewMenuBtn"
          *hasPermission="[
            resourceAction.UIDepotOverview,
            'Division',
            data.activeDivisionId
          ]"
        >
          Depot Übersicht</a
        >
        <dpl-divider
          [permissions]="[
            resourceAction.UIListJournal,
            resourceAction.UIListLoadCarrierReceipt
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['order-loads']"
          [queryParams]="{ type: orderLoadsViewType.Journal }"
          i18n="
            topNavigationPoolingOrderLoadsJournalListBtn|Top Navigation Aktuelle
            Annahmen / Ausgaben - eigener
            Standort@@topNavigationPoolingOrderLoadsJournalListBtn"
          *hasPermission="[
            resourceAction.UIListJournal,
            'Division',
            data.activeDivisionId
          ]"
        >
          Aktuelle Annahmen / Ausgaben - eigener Standort
        </a>
        <a
          mat-menu-item
          [routerLink]="['load-carrier-receipts']"
          i18n="
            topNavigationReceiptsBtn|Top Navigation Quittungen (Annahmen /
            Ausgaben) - eigener Standort@@topNavigationReceiptsBtn"
          *hasPermission="[
            resourceAction.UIListLoadCarrierReceipt,
            'Division',
            data.activeDivisionId
          ]"
        >
          Quittungen (Annahmen / Ausgaben) - eigener Standort</a
        >
        <dpl-divider
          [permissions]="[resourceAction.UIListVoucher]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <a
          mat-menu-item
          [routerLink]="['voucher-register/start']"
          i18n="
            topNavigationVoucherRegisterBtn|Top Navigation Schaltfläche Digitale
            Pooling Gutschriften@@topNavigationVoucherRegisterBtn"
          *hasPermission="[
            resourceAction.UIListVoucher,
            'Division',
            data.activeDivisionId
          ]"
          >Digitale Pooling Gutschriften</a
        >
        <a
          mat-menu-item
          [routerLink]="['submission-overview/Voucher']"
          i18n="
            topNavigationDPGOnlineSubmissionOverviewMenuButton|Top Navigation
            Menü DPG Online Einreichung
            Upload@@topNavigationDPGOnlineSubmissionOverviewMenuButton"
          *hasPermission="[
            resourceAction.UIVoucherSubmission,
            'Division',
            data.activeDivisionId
          ]"
          >Upload Digital Pooling Gutschriften</a
        >
        <a
          mat-menu-item
          [routerLink]="['submission-overview/DeliveryLoadCarrierReceipt']"
          i18n="
            topNavigationDepotSubmissionOverviewMenuButton|Top Navigation Menü
            Depot Annahme Einreichung
            Upload@@topNavigationDepotSubmissionOverviewMenuButton"
          *hasPermission="[
            resourceAction.SubmitLoadCarrierReceipt,
            'Division',
            data.activeDivisionId
          ]"
          >Upload Depot Annahme-Belege</a
        >
        <a
          mat-menu-item
          [routerLink]="['submissions-deliverynotes']"
          i18n="
            topNavigationSubmissionsDeliverynotesOverviewMenuButton|Top
            Navigation Menü Upload
            DeliveryNotes@@topNavigationSubmissionsDeliverynotesOverviewMenuButton"
          *hasPermission="[
            resourceAction.UIProcessMultiUpload,
            'Division',
            data.activeDivisionId
          ]"
          >Upload quittierte Lieferscheine</a
        >
        <dpl-divider
          [permissions]="[
            resourceAction.UIListAccountingRecords,
            resourceAction.UIListBalanceTransfers
          ]"
          [divisionId]="data.activeDivisionId"
        ></dpl-divider>
        <!-- TODO: Add permission for reading accounting records -->
        <a
          mat-menu-item
          [routerLink]="['accounts/start']"
          *hasPermission="[
            resourceAction.UIListAccountingRecords,
            'Division',
            data.activeDivisionId
          ]"
          i18n="
            topNavigationAccountsStartBtn|Top Navigation Schaltfläche Pooling
            Konto@@topNavigationAccountsStartBtn"
          >Konten</a
        >
        <a
          mat-menu-item
          [routerLink]="['transfers']"
          *hasPermission="[
            resourceAction.UIListBalanceTransfers,
            'Division',
            data.activeDivisionId
          ]"
          i18n="
            topNavigationTransfersBtn|Top Navigation
            Konten@@topNavigationTransfersBtn"
        >
          Umbuchungen</a
        >

        <ng-container>
          <dpl-divider
            text="DEPOT"
            i18n-text="
              topNavigationDividerTextDepot|Text
              DEPOT@@topNavigationDividerTextDepot"
            [permissions]="[
              resourceAction.UIListJournalDepot,
              UIListLoadCarrierReceiptDepot
            ]"
            [divisionId]="data.activeDivisionId"
          ></dpl-divider>
          <a
            mat-menu-item
            [routerLink]="['order-loads']"
            [queryParams]="{
              type: orderLoadsViewType.Journal,
              context: 'depot'
            }"
            i18n="
              topNavigationShipperDepotOrderLoadsJournalListBtn|Top Navigation
              DEPOT: Aktuelle Annahmen / Ausgaben - eigener
              Standort@@topNavigationShipperDepotOrderLoadsJournalListBtn"
            *hasPermission="[
              resourceAction.UIListJournalDepot,
              'Division',
              data.activeDivisionId
            ]"
          >
            DEPOT: Aktuelle Annahmen / Ausgaben - eigener Standort
          </a>
          <a
            mat-menu-item
            [routerLink]="['load-carrier-receipts']"
            [queryParams]="{ context: 'depot' }"
            i18n="
              topNavigationShipperDepotLoadCarrierReceiptsListBtn|Top Navigation
              DEPOT: Quittungen (Annahmen / Ausgaben) - eigener
              Standort@@topNavigationShipperDepotLoadCarrierReceiptsListBtn"
            *hasPermission="[
              resourceAction.UIListLoadCarrierReceiptDepot,
              'Division',
              data.activeDivisionId
            ]"
          >
            DEPOT: Quittungen (Annahmen / Ausgaben) - eigener Standort
          </a>
        </ng-container>
      </mat-menu>

      <mat-menu #submissionMenu="matMenu" class="wideMenu">
        <a
          mat-menu-item
          [routerLink]="['process-multi']"
          i18n="
            topNavigationUploadMenuButton|Top Navigation Menü
            Upload@@topNavigationUploadMenuButton"
          *hasPermission="[
            resourceAction.UIProcessMultiUpload,
            'Division',
            data.activeDivisionId
          ]"
          >Lieferscheine</a
        >
        <a
          mat-menu-item
          [routerLink]="['voucher-submission']"
          i18n="
            topNavigationDPGOnlineSubmissionCreateMenuButton|Top Navigation Menü
            DPG Online Einreichung
            Upload@@topNavigationDPGOnlineSubmissionCreateMenuButton"
          *hasPermission="[
            resourceAction.UIVoucherSubmission,
            'Division',
            data.activeDivisionId
          ]"
          >DPL-DPG</a
        >
        <a
          mat-menu-item
          [routerLink]="['voucher-submission/depot']"
          i18n="
            topNavigationDepotSubmissionCreateMenuButton|Top Navigation Menü
            Depot Annahme Einreichung
            Upload@@topNavigationDepotSubmissionCreateMenuButton"
          *hasPermission="[
            resourceAction.SubmitLoadCarrierReceipt,
            'Division',
            data.activeDivisionId
          ]"
          >Depot-Annahme Belege</a
        >
      </mat-menu>

      <!-- self administration menu -->
      <mat-menu #adminMenu="matMenu" class="wideMenu">
        <a
          mat-menu-item
          [routerLink]="['self-administration']"
          i18n="
            topNavigationSelfadministrationUsersBtn|Top Navigation
            Userverwaltung @@topNavigationSelfadministrationUsersBtn"
          *hasPermission="[resourceAction.UISelfAdministrationUsers]"
          >Userverwaltung</a
        >
        <a
          mat-menu-item
          [routerLink]="['self-administration-partners']"
          i18n="
            topNavigationSelfAdministrationPartnersBtn|Top Navigation
            Partnerverwaltung @@topNavigationSelfAdministrationPartnersBtn"
          *hasPermission="[resourceAction.UISelfAdministrationPartners]"
          >Partnerverwaltung</a
        >
        <a
          mat-menu-item
          [routerLink]="['absence']"
          i18n="
            topNavigationSelfAdministrationAbsenceBtn|Top Navigation Abwesenheit
            @@topNavigationSelfAdministrationAbsenceBtn"
          *hasPermission="[resourceAction.UISelfAdministrationAbsence]"
          >Abwesenheit</a
        >
      </mat-menu>
    </ng-container>
  </ng-container>
</ng-container>
