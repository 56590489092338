import { Injectable } from '@angular/core';
import { CustomersQuery } from '../state/customers.query';
import { map, switchMap } from 'rxjs/operators';
import { CustomersStore } from '../state/customers.store';
import { filterNil } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(
    private customersQuery: CustomersQuery,
    private customersStore: CustomersStore
  ) {}

  getCustomers() {
    return this.customersQuery.customers$;
  }

  getActiveCustomer() {
    return this.customersQuery.activeCustomers$;
  }

  setActiveCustomer(id) {
    this.customersStore.setActive(id);
  }

  getCustomDocumentLabel(labelKey: string) {
    return this.getActiveCustomer().pipe(
      map((customer) => {
        if (customer?.customDocumentLabels?.length > 0) {
          for (const cdl of customer.customDocumentLabels) {
            if (cdl.uiLabel === labelKey) {
              return cdl.text;
            }
          }
        }
        switch (labelKey) {
          case 'CustomerReference':
            return $localize`:CustomerServiceDocumentCustomerReferenceLabel|Referenznummer (Aussteller)@@CustomerServiceDocumentCustomerReferenceLabel:Referenznummer (Aussteller)`;
          case 'VoProLog':
            return $localize`:CustomerServiceDocumentVoProLogLabel|Beschaffungslogistik@@CustomerServiceDocumentVoProLogLabel:Beschaffungslogistik`;
          case 'ExtLicensePlate':
            return $localize`:LicensePlate|Label Kennzeichen@@LicensePlate:Kennzeichen`;
          default:
            return labelKey;
        }
      })
    );
  }
}
