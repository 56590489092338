<ng-container *ngIf="isNotEmployee$ | async">
  <ng-container *ngIf="viewData$ | async as data">
    <div
      *ngIf="data.customers?.length > 0"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="4px"
      style="line-height: 36px; display: flex"
    >
      <!-- <div class="currentDivision">{{ data.currentDivision }}</div> -->
      <ng-container [ngSwitch]="data.customers.length">
        <mat-select
          *ngSwitchCase="0"
          [formControl]="form"
          class="customerdivisionselector"
        >
          <mat-option
            *ngFor="let division of data.customers[0].divisions"
            [value]="division.id"
          >
            {{ division.name + ' (' + data.customers[0].address.city + ') ' }}
          </mat-option>
        </mat-select>
        <mat-select
          *ngSwitchDefault
          [formControl]="form"
          class="customerdivisionselector"
        >
          <mat-optgroup
            *ngFor="let customer of data.customers"
            [label]="customer.name"
          >
            <mat-option
              *ngFor="let division of customer.divisions"
              [value]="division.id"
            >
              {{ division.name + ' (' + customer.address.city + ') ' }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </ng-container>
      <!-- <mat-select [formControl]="form">
      <ng-container *ngIf="data.customers && data.customers[0]">
        <ng-container *ngIf="data.customers.length <= 1">
          <mat-option
            *ngFor="let division of data.customers[0].divisions"
            [value]="division.id"
          >
            {{ division.name + ' ( ' + data.customers[0].address.city + ' ) ' }}
          </mat-option>
        </ng-container>
        <ng-container *ngIf="data.customers.length > 1">
          <mat-optgroup
            *ngFor="let customer of data.customers"
            [label]="customer.name"
          >
            <mat-option
              *ngFor="let division of customer.divisions"
              [value]="division.id"
            >
              {{ division.name + ' ( ' + customer.address.city + ' ) ' }}
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </ng-container>
    </mat-select> -->
    </div>
  </ng-container>
</ng-container>
