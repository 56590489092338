<ng-container *ngIf="viewData$ | async as data">
  <dpl-type-list-header *ngIf="data.isEmployee"></dpl-type-list-header>
  <dx-list
    #channelTypeList
    [dataSource]="data.store"
    [selectionMode]="'single'"
    [searchEnabled]="false"
    searchExpr="name"
    searchMode="contains"
    (onSelectionChanged)="onSelectionChanged($event)"
    [height]="data.isEmployee ? '542px': '755px'"
    showScrollbar="always"
    keyExpr="key"
    [selectedItemKeys]="initalActiveTypeKey$ | async"
  >
    <!-- <dxo-item-dragging [allowReordering]="true"> </dxo-item-dragging> -->
    <div *dxTemplate="let templateData of 'item'">
      <div class="channel-type-item">
        <div class="channel-type-info">
          <div class="channel-type-name">
            {{ templateData.title }}
          </div>
          <span *ngIf="getRequiredActionTag(templateData.key) | async">
            <i
              class="dx-icon-isnotblank dx-icon-custom-style"
              style="color: red"
            ></i>
          </span>

          <ng-container *ngIf="templateData.unreadCount ">
            <div>
              <span class="type-list-unread-count">
                {{ templateData.unreadCount }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </dx-list>
</ng-container>
