<ng-container *ngIf="viewData$ | async as data">
  <ng-container *ngIf="data.showButton">
    <ng-container *hasPermission="resourceAction.CancelVoucher">
      <button
        i18n-title="Buttontitel Stonieren@@CancelVoucherButtonCancelButtonTitle"
        mat-icon-button
        [dplOnClickDisableUntil]="onCancel()"
        [disabled]="data.disabled"
        title="Stornieren"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </ng-container>
  </ng-container>
</ng-container>
