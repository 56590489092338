import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LinkData, LinkDataType, OrderType } from './dpl-api-services';
import { ArgumentOutOfRangeError } from 'rxjs';
import { localizeUrl } from '../../../utils';
import { OrderLoadsContext, OrderLoadsViewType } from '../../shared';
import { OfferContext } from '../../offers/offers.types';

export type DeepLinkNavigationCommand = [string[], NavigationExtras];

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {
  constructor(private router: Router) { }

  getLinkToVoucher(data: {
    divisionId: number;
    loadCarrierTypeId: number;
    documentNumber: string;
    customerCoPilot: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, loadCarrierTypeId, documentNumber, customerCoPilot } =
      data;

    return [
      ['/voucher-register'],
      {
        queryParams: {
          divisionId,
          carrierType: loadCarrierTypeId,
          directId: documentNumber,
          customerCoPilot,
        },
      },
    ];
  }

  getLinkToOrder(data: {
    divisionId: number;
    type: OrderType;
    orderNumber: number;
    customerCoPilot: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, type, orderNumber, customerCoPilot } = data;
    return [
      ['/orders'],
      {
        queryParams: {
          divisionId,
          type,
          directId: orderNumber,
          highlightIds: [orderNumber],
          customerCoPilot,
        },
      },
    ];
  }

  getLinkToSelfTransport(data: {
    divisionId: number;
    type: OrderType;
    orderNumber: number;
    customerCoPilot: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, orderNumber, customerCoPilot } = data;
    return [
      ['/offers'],
      {
        queryParams: {
          divisionId,
          directId: orderNumber,
          highlightIds: [orderNumber],
          customerCoPilot,
        },
      },
    ];
  }

  getLinkToOffer(data: {
    divisionId: number;
    type: OrderType;
    orderNumber: number;
    customerCoPilot: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, orderNumber, customerCoPilot } = data;
    return [
      ['/offers', 'general'],
      {
        queryParams: {
          divisionId,
          directId: orderNumber,
          directIdContext: OfferContext.personal,
          highlightIds: [orderNumber],
          customerCoPilot,
        },
      },
    ];
  }

  getLinkToHotSpotOffer(data: {
    divisionId: number;
    orderProposalId: number;
    customerCoPilot?: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, orderProposalId, customerCoPilot } = data;
    return [
      ['/offers', 'general'],
      {
        queryParams: {
          divisionId,
          directId: orderProposalId,
          customerCoPilot,
          directIdContext: OfferContext.hotspot,
        },
      },
    ];
  }

  getLinkAccountBooking(data: {
    divisionId: number;
    loadCarrierTypeId: number;
    id: number;
    postingAccountId: number;
    customerCoPilot: number;
  }): DeepLinkNavigationCommand {
    const {
      divisionId,
      postingAccountId,
      loadCarrierTypeId,
      id,
      customerCoPilot,
    } = data;
    return [
      ['/accounts', 'start'],
      {
        queryParams: {
          divisionId,
          postingAccountId,
          loadCarrierTypeId,
          directId: id,
          highlightIds: [id],
          customerCoPilot,
        },
      },
    ];
  }

  getLinkToLoadCarrierReceipt(data: {
    divisionId: number;
    documentNumber: string;
    customerCoPilot?: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, documentNumber, customerCoPilot } = data;

    return [
      ['/load-carrier-receipts'],
      {
        queryParams: {
          divisionId,
          directId: documentNumber,
          customerCoPilot,
        },
      },
    ];
  }

  getLinkToOrderLoadSupply(data: {
    divisionId: number;
    digitalCode: number;
    context: OrderLoadsContext;
    customerCoPilot?: number;
  }): DeepLinkNavigationCommand {
    console.log(data)
    const { divisionId, digitalCode, context, customerCoPilot } = data;
    return [
      ['/order-loads'],
      {
        queryParams: {
          divisionId,
          type: OrderLoadsViewType.Journal,
          context,
          code: digitalCode,
          customerCoPilot,
          orderType: OrderType.Supply
        },
      },
    ];
  }

  getLinkToOrderLoadDemand(data: {
    divisionId: number;
    digitalCode: number;
    context: OrderLoadsContext;
    customerCoPilot?: number;
  }): DeepLinkNavigationCommand {
    console.log(data)
    const { divisionId, digitalCode, context, customerCoPilot } = data;
    return [
      ['/order-loads'],
      {
        queryParams: {
          divisionId,
          type: OrderLoadsViewType.Journal,
          code: digitalCode,
          customerCoPilot,
          orderType: OrderType.Demand
        },
      },
    ];
  }

  getLinkToOrderLoads(data: {
    divisionId: number;
    digitalCode: number;
    context: OrderLoadsContext;
    customerCoPilot?: number;
  }): DeepLinkNavigationCommand {
    const { divisionId, digitalCode, context, customerCoPilot } = data;
    return [
      ['/orders'],
      {
        queryParams: {
          divisionId,
          type: OrderLoadsViewType.Journal,
          context,
          directId: digitalCode,
          highlightIds: [digitalCode],
          customerCoPilot,
        },
      },
    ];
  }

  getNavigationCommand({ type, data }: LinkData) {
    switch (type) {
      case LinkDataType.Booking:
        return this.getLinkAccountBooking(data);
      case LinkDataType.LoadCarrierReceipt:
        return this.getLinkToLoadCarrierReceipt(data);
      case LinkDataType.VoucherIssuer:
      case LinkDataType.VoucherRecipient:
        return this.getLinkToVoucher(data);
      case LinkDataType.Order:
        return this.getLinkToOrder(data);
      case LinkDataType.OrderSelfTransport:
        return this.getLinkToSelfTransport(data);
      case LinkDataType.Offer:
        return this.getLinkToOffer(data);
      case LinkDataType.OrderLoadSupply:
        return this.getLinkToOrderLoadSupply(data)
      case LinkDataType.OrderLoadDemand:
        return this.getLinkToOrderLoadDemand(data)
        // return this.getLinkToOrderLoads(data);
      case LinkDataType.HotSpotOffer:
        return this.getLinkToHotSpotOffer(data);
      default:
        throw new ArgumentOutOfRangeError();
    }
  }

  generateUrl(command: DeepLinkNavigationCommand) {
    const [parts, extras] = command;
    const urlTree = this.router.createUrlTree(parts, extras);

    const url = this.router.serializeUrl(urlTree);
    const localizedUrl = localizeUrl(url);

    return localizedUrl;
  }
}
