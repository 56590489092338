import { Component, OnInit } from '@angular/core';
import { ICustomer } from '../../../customers/state/customer.model';
import { UntypedFormControl } from '@angular/forms';
import { of, Observable, combineLatest } from 'rxjs';
import {
  switchMap,
  startWith,
  map,
  first,
  filter,
  tap,
  publishReplay,
  refCount,
  distinctUntilChanged,
} from 'rxjs/operators';
import { DplApiService } from '../../../core/services';
import {
  DplEmployeeCustomer,
  DplEmployeeCustomerSelectionEntryType,
} from '@app/api/dpl';
import { UserService } from '../../../user/services/user.service';
import { AddressPipe } from '@app/shared/pipes';
import {
  getTypedParams,
  LoadingService,
} from '../../../../../../../libs/dpl-lib/src';
import { CustomersQuery } from '../../../customers/state/customers.query';
import { CustomersStore } from '../../../customers/state/customers.store';
import { ActivatedRoute } from '@angular/router';
import { GlobalSettingsQuery } from '../../state/global-settings/global-settings.query';
import { GlobalSettingsStore } from '../../state/global-settings/global-settings.store';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RouterQuery } from '@datorama/akita-ng-router-store';

type ViewData = {
  activeCustomer: ICustomer<number>;
  customerDirect: DplEmployeeCustomer;
};

interface CustomerSelectionRouteParams {}

interface CustomerSelectionQueryParams {
  customerCoPilot: number;
}

@Component({
  selector: 'dpl-customer-selection',
  templateUrl: './customer-selection.component.html',
  styleUrls: ['./customer-selection.component.scss'],
})
export class CustomerSelectionComponent implements OnInit {
  viewData$: Observable<ViewData>;
  options$: Observable<DplEmployeeCustomer[]>;
  activeCustomer$: Observable<ICustomer<number>>;
  customerControl = new UntypedFormControl();
  type = DplEmployeeCustomerSelectionEntryType;
  ignoreLeadTime$: Observable<boolean>;
  routeCanIgnoreLeadTime$: Observable<boolean>;
  ignoreLeadTimePaths: string[] = ['/availabilities/start', 'needs/start', '/offers/request'];

  constructor(
    private dpl: DplApiService,
    private userService: UserService,
    private address: AddressPipe,
    private loadingService: LoadingService,
    private customersQuery: CustomersQuery,
    private customersStore: CustomersStore,
    private route: ActivatedRoute,
    private globalSettingsQuery: GlobalSettingsQuery,
    private globalSettingsStore: GlobalSettingsStore,
    private routerQuery: RouterQuery
  ) {}

  ngOnInit() {
    //route query params

    const customerCoPilot$ = getTypedParams<
      CustomerSelectionRouteParams,
      CustomerSelectionQueryParams
    >(this.route).pipe(
      switchMap((params) => {
        if (params?.query?.customerCoPilot) {
          return this.dpl.employeeService.getCustomer(
            params.query.customerCoPilot
          );
        }
        return of(null as DplEmployeeCustomer);
      })
    );

    this.viewData$ = combineLatest([
      this.customersQuery
        .selectActive()
        .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)),
      customerCoPilot$,
    ]).pipe(
      map(([customer, customerCoPilot]) => {
        const viewData: ViewData = {
          activeCustomer: customer,
          customerDirect: customerCoPilot,
        };
        return viewData;
      }),
      tap((viewData) => {
        console.log('viewData', viewData);
        if (viewData.customerDirect) {
          this.onOptionSelected(viewData.customerDirect);
        }
      })
    );

    this.options$ = this.customerControl.valueChanges.pipe(
      startWith(''),
      filter((value: string) => value.length > 1),
      switchMap((value) => {
        return this.dpl.employeeService
          .searchCustomers({ searchTerm: value })
          .pipe(
            map((results) => {
              return results;
            })
          );
      })
    );

    this.ignoreLeadTime$ = this.globalSettingsQuery
      .select((state) => state.ignoreLeadTime)
      .pipe(distinctUntilChanged());

    this.routeCanIgnoreLeadTime$ = this.routerQuery
      .select((state) => state.state.url)
      .pipe(
        distinctUntilChanged(),
        map(
          (url) =>
            this.ignoreLeadTimePaths.find((path) => url.includes(path)) != null
        )
      );
  }

  onOptionSelected(customer: DplEmployeeCustomer) {
    //this.activeCustomer = customer;
    // TODO call refresh user with param
    this.userService
      .refreshUserData(customer)
      .pipe(first(), this.loadingService.showLoadingWhile())
      .subscribe();
  }

  // using a variable instead of a function prevents us from having to use bind(this)
  displayFn = (customer: DplEmployeeCustomer) => {
    return customer
      ? `customer.displayName | ${this.address.transform(
          customer.address,
          'long'
        )}`
      : '';
  };

  reset() {
    this.customersStore.set(null);
    this.customerControl.setValue(null);
    this.userService.refreshUserData().pipe(first()).subscribe();
  }

  setIgnoreLeadTime(ignoreLeadTime: MatSlideToggleChange) {
    this.globalSettingsStore.update({ ignoreLeadTime: ignoreLeadTime.checked });
  }
}

export enum CustomerSelectionEntryType {
  Customer = 'Customer',
  Division = 'Division',
  Account = 'Account',
}
