<ng-container>
    <mat-select (selectionChange)="selectLanugage($event)" class="languageselector" [ngModel]="selectedLanguage" style="width: 50px;">
      <mat-select-trigger>
        <img src={{selectedLanguage.ImageSrc}} width="20" height="15" >
      </mat-select-trigger>
        <mat-option
          *ngFor="let language of languages"
          [value]="language"
          >
          <img src={{language.ImageSrc}} width="20" height="15" >
        </mat-option>
    </mat-select>
  </ng-container>
