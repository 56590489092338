import { Injectable } from '@angular/core';
import { combineQueries, filterNil, QueryEntity } from '@datorama/akita';
import _ from 'lodash';
import {
  filter,
  map,
  publishReplay,
  refCount,
  switchMap,
} from 'rxjs/operators';
import { LoadCarrier } from '../../../core/services/dpl-api-services';
import {
  LoadCarrierPickerStore,
  LoadCarrierPickerState,
  TransferLoadCarrier,
} from './load-carrier-picker.store';

@Injectable({ providedIn: 'root' })
export class LoadCarrierPickerQuery extends QueryEntity<LoadCarrierPickerState> {
  active$ = this.selectActive();
  all$ = this.selectLoadCarriers();


  constructor(protected store: LoadCarrierPickerStore) {
    super(store);
  }

  getLoadCarrierById(loadCarrierId: number) {
    return this.selectEntity(loadCarrierId);
  }

  getSettingsLimitForLoadCarrier(loadCarrierId: number) {
    return this.selectEntity(loadCarrierId).pipe(
      map((loadCarrier) => {
        return (loadCarrier as TransferLoadCarrier)?.limit;
      })
    );
  }

  selectLoadCarriers() {
    return this.selectLoading().pipe(
      filter((loading) => !loading),
      switchMap(() => {
        return combineQueries([this.selectAll()]);
      }),
      map(([loadCarriers]) => {
        return (
          _(loadCarriers)
            .map((i) => {
              return <LoadCarrier>{
                ...i,
                // type: types[i.type.id],
                // quality: qualities[i.quality.id],
              };
            })
            // .orderBy([(i) => i.type.order, (i) => i.quality.order])
            .value()
        );
      }),
      publishReplay(1),
      refCount()
    );
  }

}
