import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { DevExtremeModule } from 'devextreme-angular';
import { LoadingLocationBusinessHoursExceptionsComponent } from './components/loading-location-business-hours-exceptions/loading-location-business-hours-exceptions.component';
import { LoadingLocationBusinessHoursComponent } from './components/loading-location-business-hours/loading-location-business-hours.component';
import { LoadingLocationBusinessHoursBulkAddDialogComponent } from './components/loading-location-business-hours-bulk-add-dialog/loading-location-business-hours-bulk-add-dialog.component';
import { LoadingLocationBusinessHoursSchedulerComponent } from './components/loading-location-business-hours-scheduler/loading-location-business-hours-scheduler.component';
import { LoadingLocationBusinessHoursSchedulerAddExceptionComponent } from './components/loading-location-business-hours-scheduler-add-exception/loading-location-business-hours-scheduler-add-exception.component';

@NgModule({
  declarations: [
    LoadingLocationBusinessHoursComponent,
    LoadingLocationBusinessHoursExceptionsComponent,
    LoadingLocationBusinessHoursBulkAddDialogComponent,
    LoadingLocationBusinessHoursSchedulerComponent,
    LoadingLocationBusinessHoursSchedulerAddExceptionComponent,
  ],
  imports: [CommonModule, SharedModule, DevExtremeModule],
  exports: [
    LoadingLocationBusinessHoursComponent,
    LoadingLocationBusinessHoursExceptionsComponent,
    LoadingLocationBusinessHoursSchedulerComponent,
  ],
})
export class LoadingLocationBusinessHoursModule {}
