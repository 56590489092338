<ng-container *ngIf="viewData$ | async as data"
  ><mat-toolbar class="messaging-header">
    <span
      i18n="
        MessagingSystem|Label Digitale Pooling Gutschriften@@MessagingSystem"
      >Messaging-System</span
    >
    <!-- <ng-container *ngIf="data.user?.role === 'DplEmployee'">
      <dx-check-box
        text="Mitarbeiterliste"
        hint="Mitarbeiterliste anzeigen"
        [(value)]="showEmployeeList"
      ></dx-check-box>
    </ng-container> -->
  </mat-toolbar>
  <div
    style="width: 100%; text-align: center; background-color: yellow"
    *ngIf="data.isEmployeeView as employee"
  >
    <span i18n="@@MessagingSystemViewForEmployees">SICHT FÜR MITARBEITER: </span
    >{{ employee.userName }}
  </div>
  <div
    [ngClass]="
      data.user?.role === 'DplEmployee'
        ? 'messaging-system-employee-content'
        : 'messaging-system-content'
    "
  >
    <!-- <ng-container *ngIf="showEmployeeList">
      <div class="messaging-system-employee-list">
        <dpl-messaging-system-user-list
          [users]="users"
        ></dpl-messaging-system-user-list>
      </div>
    </ng-container> -->
    <ng-container *ngIf="open; else hiddenTypeList">
      <div class="messaging-system-type-list">
        <div class="messaging-system-channel-header-and-icon">
          <div class="messaging-system-channel-header">
            <dpl-channel-list-header
              i18n-headerText="@@MessagingSystemChannelListHeaderText"
              headerText="Vorgangstypen"
            ></dpl-channel-list-header>
          </div>
          <div class="hide-icon">
            <dx-button
              icon="spinleft"
              class="spin-icon"
              (click)="open = false"
              style="top: -6px"
              hint="Vorgangstypenliste einklappen"
              i18n-hint="@@MessageSystemTypeListHintSpinLeftIcon"
            >
            </dx-button>
          </div>
        </div>
        <dpl-type-list></dpl-type-list>
      </div>
    </ng-container>
    <ng-template #hiddenTypeList>
      <div class="hidden-list">
        <div class="show-icon" (click)="open = true">
          <dx-button
            icon="spinright"
            class="spin-icon"
            style="top: 12px"
            hint="Vorgangstypenliste ausklappen"
            i18n-hint="@@MessageSystemTypeListHintSpinRightIcon"
          >
          </dx-button>
        </div>
        <span
          class="vertical-text"
          i18n="@@MessagingSystemChannelListVerticalText"
          >Vorgangstypen</span
        >
      </div>
    </ng-template>
    <ng-container *ngIf="openChannelList; else hiddenChannelList">
      <dx-resizable
        handles="right"
        [(width)]="resizableWidthChannelList"
        style="
          height: 864px;
          max-width: 80%;
          min-width: 350px;
          border-right: 1px solid black;
          border-left: 1px solid black;
          overflow: hidden;
        "
      >
        <div class="messaging-system-channel-list">
          <dpl-channel-list
            (hideList)="hideChannelList($event)"
          ></dpl-channel-list>
        </div>
      </dx-resizable>
    </ng-container>
    <ng-template #hiddenChannelList>
      <div class="hidden-list">
        <div class="show-icon" (click)="openChannelList = true">
          <dx-button
            icon="spinright"
            class="spin-icon"
            hint="Vorgangsliste ausklappen"
            i18n-hint="@@MessageSystemChannelListHintSpinRightIcon"
          >
          </dx-button>
          <span
            class="vertical-text"
            i18n="@@MessagingSystemChannelTypeListVerticalText"
            >Vorgangsliste</span
          >
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf="showChannel && data.user?.role; else noChannel">
      <div class="message-list-and-input">
        <dpl-channel [showChannelInfo]="true"> </dpl-channel>
      </div>
    </ng-container>
    <ng-template #noChannel>
      <div i18n="@@NoChannel">Es wurde noch kein Vorgang ausgewählt</div>
    </ng-template>
  </div>
</ng-container>
