<div class="container">
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
  >
    <div *ngIf="loadingText$ | async as text">
      <mat-chip-list>
        <mat-chip color="primary" selected>{{ text }}</mat-chip>
      </mat-chip-list>
    </div>
    <mat-spinner color="primary"></mat-spinner>
    <button
      i18n="
        LoadingSpinnerBackToPageButtonText|Loadingspinner Buttontext Zurück zur
        Seite@@LoadingSpinnerBackToPageButtonText"
      mat-raised-button
      color="primary"
      *ngIf="showCancel$ | async"
      (click)="hideSpinner()"
    >
      Zurück zur Seite
    </button>
  </div>
</div>
