import { FactoryProvider } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  Configuration,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { APP_CONFIG, DplLiveConfiguration } from '../../config';
import { environment } from '../../environments/environment';
import { isIE, localizeUrl } from '../../utils';

function MSALInstanceFactory(
  config: DplLiveConfiguration
): IPublicClientApplication {
  const clientConfig: Configuration = {
    auth: {
      ...config.msal.config.auth,
      redirectUri: localizeUrl(config.msal.config.auth.redirectUri),
      postLogoutRedirectUri: localizeUrl(
        config.msal.config.auth.postLogoutRedirectUri
      ),
    },
    cache: {
      ...config.msal.config.cache,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => {
          // if (!environment.production) {
          //   console.log(message);
          // }
        },
        logLevel: environment.production ? LogLevel.Info : LogLevel.Info,
        piiLoggingEnabled: !environment.production,
      },
    },
  };

  return new PublicClientApplication(clientConfig);
}

export const MSALInstanceProvider: FactoryProvider = {
  provide: MSAL_INSTANCE,
  useFactory: MSALInstanceFactory,
  deps: [APP_CONFIG],
};

function MSALGuardConfigFactory(
  config: DplLiveConfiguration
): MsalGuardConfiguration {
  const guardConfig: MsalGuardConfiguration = {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: config.msal.ngConfig.consentScopes,
    },
  };

  return guardConfig;
}

export const MSALGuardConfigProvider: FactoryProvider = {
  provide: MSAL_GUARD_CONFIG,
  useFactory: MSALGuardConfigFactory,
  deps: [APP_CONFIG],
};

function MSALInterceptorConfigFactory(
  config: DplLiveConfiguration
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();


  for (const resourceMap of config.msal.ngConfig.protectedResourceMap) {
    protectedResourceMap.set(`${resourceMap[0]}`, resourceMap[1]);
    protectedResourceMap.set(`${resourceMap[0]}/*`, resourceMap[1]);
  }

  const interceptorConfig: MsalInterceptorConfiguration = {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };

  return interceptorConfig;
}

export const MSALInterceptorConfigProvider: FactoryProvider = {
  provide: MSAL_INTERCEPTOR_CONFIG,
  useFactory: MSALInterceptorConfigFactory,
  deps: [APP_CONFIG],
};
