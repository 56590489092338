<ng-container *ngIf="viewData$ | async as data; else loading">
  <form [formGroup]="formGroup">
    <mat-dialog-content fxLayout="column">
      <mat-form-field>
        <mat-label
          ><span i18n="CompanyName|Label Name der Firma@@CompanyName"
            >Name der Firma</span
          >
        </mat-label>
        <input
          matInput
          trim
          [formControl]="formGroup.controls.companyName"
          [required]="hasRequiredField(formGroup.controls.companyName)"
        />
        <mat-error *ngIf="formGroup.controls.companyName.errors?.required">
          <span i18n="RequiredField|Label Pflichtfeld@@RequiredField"
            >Pflichfeld</span
          >
        </mat-error>
        <mat-error *ngIf="formGroup.controls.companyName.errors?.maxlength">
          <strong i18n="MaxLength|Label Pflichtfeld@@MaxLength"
            >Die Eingabe ist zu lang</strong
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          <span i18n="Directory|Label Verzeichniss@@Directory"
            >Verzeichnis</span
          >
        </mat-label>
        <mat-select
          [formControl]="formGroup.controls.partnerDirectory"
          [required]="hasRequiredField(formGroup.controls.partnerDirectory)"
        >
          <mat-option
            *ngFor="let partnerDirectory of data.partnerDirectories"
            [value]="partnerDirectory?.id"
          >
            {{ partnerDirectory.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.controls.partnerDirectory.errors?.required">
          <span i18n="RequiredField|Label Pflichtfeld@@RequiredField"
            >Pflichfeld</span
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label
          ><span i18n="Street|Label Straße@@Street">Straße</span></mat-label
        >
        <input matInput formControlName="street" />
        <mat-error *ngIf="formGroup.controls.street.errors?.maxlength">
          <strong i18n="MaxLength|Label Pflichtfeld@@MaxLength"
            >Die Eingabe ist zu lang</strong
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label
          ><span i18n="PostalCode|Label PLZ@@PostalCode">PLZ</span></mat-label
        >
        <input matInput formControlName="postalCode" />
        <mat-error *ngIf="formGroup.controls.postalCode.errors?.maxlength">
          <strong i18n="MaxLength|Label Pflichtfeld@@MaxLength"
            >Die Eingabe ist zu lang</strong
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label
          ><span i18n="Location|Label Ort@@Location">Ort</span></mat-label
        >
        <input matInput formControlName="city" />
        <mat-error *ngIf="formGroup.controls.postalCode.errors?.maxlength">
          <strong i18n="MaxLength|Label Pflichtfeld@@MaxLength"
            >Die Eingabe ist zu lang</strong
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label
          ><span i18n="Country|Label Land@@Country">Land</span></mat-label
        >
        <mat-select
          formControlName="country"
          placeholder="Land"
          i18n-placeholder="
            Select Placeholder Land@@PartnerCreateFormCountrySelectPlaceholder"
        >
          <mat-option
            *ngFor="let country of data.countries"
            [value]="country.id"
          >
            {{ country.id | country: countryFormat }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <app-country-picker
                  formControlName="country"
                  mode="full"
                  [prefill]="false"
                  width="100%"
                  required="true"
                >
                </app-country-picker> -->

      <!-- <app-address-form
        [subForm]="formGroup.controls.address"
      ></app-address-form> -->
    </mat-dialog-content>
  </form>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin: auto;"></mat-spinner>
</ng-template>
