<mat-toolbar color="primary">
  <span i18n="@@HeadingCreateLoadingLocation"
    >Be-/Entladestelle bearbeiten</span
  >
</mat-toolbar>
<dpl-loading-location-create-form
  [dataInput]="existingLocationCreate"
  [orderType]="data.orderType"
  (formValid)="formValidChange($event)"
  (onChange)="onChange($event)"
  [onlyInfo]="data.onlyInfo"
  [useCase]="locationCreateUseCase.Customer"
  [mode]="locationCreateMode.Edit"
>
</dpl-loading-location-create-form>

<mat-dialog-actions>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
    style="width: 100%"
  >
    <ng-container *ngIf="!data.onlyInfo; else closeBtn">
      <button mat-raised-button (click)="onCancel()" i18n="@@Cancel">
        Abbrechen
      </button>
      <button
        mat-raised-button
        (click)="onSave($event)"
        [disabled]="!formValid"
        cdkFocusInitial
        i18n="@@SaveChanges"
      >
        Änderungen speichern
      </button>
    </ng-container>
  </div>

  <ng-template #closeBtn>
    <button mat-raised-button (click)="onCancel()" i18n="@@Close">
      Schließen
    </button>
  </ng-template>
</mat-dialog-actions>
