<div fxLayout="column" fxLayoutGap="5px">
  <ng-container *ngIf="exceptions$ | async as exceptions">
    <ng-container *ngIf="exceptions?.length > 0; else noItems">
      <div
        *ngFor="let exception of exceptions"
        fxLayout="row"
        fxLayoutAlign=" stretch"
        fxLayoutGap="10px"
      >
        <span fxFlex="1 0 8em">{{
          exception.type | businessHourExceptionType
        }}</span>
        <div fxFlex="1 0 8em" fxLayout="column">
          {{ exception.fromDateTime | date: 'dd.MM.yy HH:mm':'+0000' }} -
          {{ exception.toDateTime | date: 'dd.MM.yy HH:mm':'+0000' }}
        </div>
      </div>
    </ng-container>
    <ng-template #noItems
      ><div i18n="@@ListNoItems">
        Keine Einträge vorhanden
      </div></ng-template
    >
  </ng-container>
</div>
