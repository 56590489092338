<ng-container *ngIf="viewData$ | async as data">
  <!-- <ng-container *ngIf=" | async as source"> -->
  <dx-data-grid
    id="gridContainer"
    [dataSource]="data.dataSource"
    [showBorders]="true"
    [showColumnLines]="true"
    [remoteOperations]="true"
  >
    <dxo-editing
      mode="row"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>
    <dxi-column
      caption="ID"
      dataField="id"
      [visible]="false"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
    ></dxi-column>
    <dxi-column
      i18n-caption="
        Spaltenname für
        Öffnungszustand@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsTypeColumnTitel"
      caption="Öffnungszustand"
      dataField="type"
      editCellTemplate="typeEditTemplate"
      [calculateDisplayValue]="getTypeName"
      alignment="left"
      ><dxi-validation-rule type="required"></dxi-validation-rule
    ></dxi-column>
    <dxi-column
      i18n-caption="
        Spaltenname für
        Von@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsFromDateTimeColumnTitel"
      caption="Von"
      dataField="fromDateTime"
      dataType="datetime"
      format="shortDateShortTime"
      ><dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule
        i18n-message="
          Fehlernachricht für Von Datum Das Datum (von) muss kleiner sein als
          das Datum
          (bis)@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsFromDateTimeCustomValidationMessage"
        type="custom"
        message="Das Datum (von) muss kleiner sein als das Datum (bis)"
        [validationCallback]="customValidationCallbackBusinessHourExceptions"
        [reevaluate]="true"
        [ignoreEmptyValue]="true"
      >
      </dxi-validation-rule>
    </dxi-column>
    <dxi-column
      i18n-caption="
        Spaltenname für
        Bis@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsToDateTimeColumnTitel"
      caption="Bis"
      dataField="toDateTime"
      dataType="datetime"
      format="shortDateShortTime"
      ><dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule
        i18n-message="
          Fehlernachricht für Bis Datum Das Datum (bis) muss größer sein als das
          Datum
          (von)@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsToDateTimeCustomValidationMessage"
        type="custom"
        message="Das Datum (bis) muss größer sein als das Datum (von)"
        [validationCallback]="customValidationCallbackBusinessHourExceptions"
        [reevaluate]="true"
        [ignoreEmptyValue]="true"
      >
      </dxi-validation-rule
    ></dxi-column>
    <!--Type Cell Template-->
    <!-- <div *dxTemplate="let dataType of 'typeCellTemplate'">
      <span> {{ getTypeName(data, dataType.value) }}</span>
    </div> -->
    <!-- Type Edit Template-->
    <div *dxTemplate="let dataType of 'typeEditTemplate'">
      <dx-select-box
        [dataSource]="data.dropdownExecptionType"
        displayExpr="display"
        valueExpr="value"
        [(value)]="dataType.value"
        (onValueChanged)="dataType.setValue($event.value)"
      ></dx-select-box>
    </div>
  </dx-data-grid>
</ng-container>
<!-- </ng-container> -->
