import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipeEnumBase } from '../../shared/pipes/translate-pipe.base';
import { LocalizationService } from '../../core';
import { ChatChannelState } from '../../generated/Dpl.B2b.Common.Enumerations';

@Pipe({
  name: 'chatChannelState'
})
export class ChatChannelStatePipe extends TranslatePipeEnumBase {
  name: string = 'ChatChannelState';

  constructor(private ls: LocalizationService) {
    super(ls);
  }

  transform(value: string) {
    const indexOf = Object.values(ChatChannelState).indexOf(value)
    const key = Object.keys(ChatChannelState)[indexOf]
    //TODO add a translated Error Message
    return key ? this.translate(key, this.name) : 'error translate channelState'
  }

}
