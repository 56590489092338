<div style="display: flex; align-items: center">
  <i
    class="material-icons"
    [style.color]="color"
    [title]="hideTextShowTitle ? title : ''"
  >
    brightness_1
  </i>
  <ng-conainer *ngIf="!hideTextShowTitle">
    <span style="margin-left: 5px">
      {{ title }}
    </span></ng-conainer
  >
</div>
