<div fxLayout="row" style="align-items: center; width: 100%;" >
  <ng-container *ngIf="!!icon">
    <mat-icon
      style="color: darkslategrey"
      [title]="title"
      [style.marginRight]="displayMode === 'long' && !!icon ? '10px' : '0px'"
    >
      {{ icon }}
    </mat-icon>
  </ng-container>
  <ng-container *ngIf="displayMode === 'long' || !icon">
    {{ title }}
  </ng-container>
</div>
