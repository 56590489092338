import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'dpl-grid-status-cell',
  templateUrl: './grid-status-cell.component.html',
  styleUrls: ['./grid-status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridStatusCellComponent implements OnInit {
  @Input() title: string;
  @Input() color: string;
  @Input() hideTextShowTitle?: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
