import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/services/authentication.service';
import {
  ResourceAction,
  UserRole,
} from '../../../core/services/dpl-api-services';
import { UserService } from '../../../user/services/user.service';
import { AadRole, IUser } from '../../../user/state/user.model';

interface IViewData {
  userAADRole: AadRole.Admin | AadRole.UserAdmin;
  isLoggedIn: boolean;
  userRole: UserRole;
  canManage:boolean;
  canPublish:boolean;
}

@Component({
  selector: 'dpl-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
  resourceAction = ResourceAction;
  viewData$: Observable<IViewData>;
  userRole = UserRole;
  AadRole = AadRole;

  constructor(private user: UserService, private auth: AuthenticationService) {}

  ngOnInit(): void {
    const user$ = this.user.getCurrentUser();
    const admin$ = this.user.selectDplAdmin();

    this.viewData$ = combineLatest([this.auth.isLoggedIn(), user$, admin$]).pipe(
      map(([isLoggedIn, user, aadRole]) => {

        const viewData: IViewData = {
          userAADRole: aadRole,
          userRole: user ? user.role : undefined,
          canManage: aadRole===AadRole.Admin||aadRole===AadRole.UserAdmin?true:false, //Todo should be done in route guard / hasPermission directive
          canPublish: user.aadRoles.indexOf(AadRole.Publisher) >= 0,
          isLoggedIn,
        };
        return viewData;
      })
    );
  }
}
