<mat-toolbar color="primary">
  <span>Neue Ausnahme</span>
</mat-toolbar>
<div
  fxLayout="column"
  fxLayoutGap="20px"
  [formGroup]="form"
  style="margin-top: 20px"
>
  <!-- <div fxLayout="row" fxLayoutGap="20px">
    <dx-check-box [formControlName]="'allDay'" text="ganztätgig"></dx-check-box>
  </div> -->
  <div fxLayout="row" fxLayoutGap="20px" mat-dialog-content>
    <dx-date-box
      type="time"
      label="Von"
      [min]="data.minTime"
      [max]="data.maxTime"
      [formControlName]="'fromTime'"
      [interval]="30"
      [acceptCustomValue]="false"
      [openOnFieldClick]="true"
    >
    </dx-date-box>
    <dx-date-box
      type="time"
      label="Bis"
      [min]="toTimeMin$ | async"
      [max]="data.maxTime"
      [formControlName]="'toTime'"
      [interval]="30"
      [acceptCustomValue]="false"
      [openOnFieldClick]="true"
    >
    </dx-date-box>
  </div>
</div>
<mat-dialog-actions>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
    style="width: 100%"
  >
    <button mat-raised-button (click)="onCancel()" i18n="@@Cancel">
      Abbrechen
    </button>
    <button
      mat-raised-button
      (click)="onSave()"
      [disabled]="!form.valid"
      cdkFocusInitial
    >
      Ausnahme erstellen
    </button>
  </div>
</mat-dialog-actions>
