import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { iif, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../../../../libs/dpl-lib/src/lib/services/loading.service';
import { delayCreation } from '../../../../../../../libs/dpl-lib/src/lib/utils';
import { EmployeeNoteType, Order, OrderStatus, ResourceAction } from '../../../core/services/dpl-api-services';
import { DplApiService } from '../../../core/services/dpl-api.service';
import { ConfirmActionDialogComponent, ConfirmActionDialogData, ConfirmActionDialogResult } from '../../../shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { OnBehalfOfService } from '../../../shared/services/on-behalf-of.service';

@Component({
  selector: 'dpl-cancel-order-button',
  templateUrl: './cancel-order-button.component.html',
  styleUrls: ['./cancel-order-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelOrderButtonComponent implements OnInit {
  @Input() orderId: number;
  @Input() status: OrderStatus;
  @Input() isEmployee = false;
  @Output() canceled = new EventEmitter<boolean>();
  resourceAction = ResourceAction;

  constructor(
    private dialog: MatDialog,
    private dpl: DplApiService,
    private onBehalfOfService: OnBehalfOfService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {}

  isDisabled() {
    switch (this.status) {
      case OrderStatus.Pending:
      case OrderStatus.InCoordination:
        return false;
        break;
      case OrderStatus.TransportPlanned:
      case OrderStatus.Fulfilled:
      case OrderStatus.Cancelled:
        return true;
        break;
      case OrderStatus.CancellationRequested:
        return this.isEmployee ? false : true;
        break;
    }
  }

  onCancel() {
    const employeeCancel$ = this.onBehalfOfService
      .openOnBehalfofDialog(EmployeeNoteType.Cancellation)
      .pipe(
        switchMap((executionResult) => {
          if (!executionResult) {
            return of(null as Order);
          }
          return this.dpl.orders
            .cancel(this.orderId, {
              dplNote: executionResult,
            })
            .pipe(this.loadingService.showLoadingWhile());
        })
      );
    const userCancel$ = delayCreation(() =>
      this.dialog
        .open<
          ConfirmActionDialogComponent,
          ConfirmActionDialogData,
          ConfirmActionDialogResult
        >(ConfirmActionDialogComponent, {
          data: {
            title: $localize`:OrderServiceCancelDialogTitle|Dialog Title for Cancel Storno@@OrderServiceCancelDialogTitle:Stornieren`, //ToDo adjust tile "Stornieren"|"Stornierung anfragen"
            context: 'cancel',
          },
          disableClose: true,
          autoFocus: false,
        })
        .afterClosed()
        .pipe(
          switchMap((result) => {
            if (!result || !result.confirmed) {
              return of(null as Order);
            }
            return this.dpl.orders
              .cancel(this.orderId, {
                note: result.note,
              })
              .pipe(this.loadingService.showLoadingWhile());
          })
        )
    );
    return iif(() => this.isEmployee, employeeCancel$, userCancel$).pipe(
      delay(200),
      tap(() => this.canceled.emit(true))
    );
  }
}

