import { Inject, Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthError,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { applyTransaction, PersistState } from '@datorama/akita';
import { from, NEVER, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { isMsalIframe } from '../../../utils';
import { UserStore } from '../../user/state/user.store';
import { SessionQuery } from '../session/state/session.query';
import { SessionState, SessionStore } from '../session/state/session.store';
import { API_BASE_URL } from './dpl-api-services';
import { NotificationService } from './notification.service';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout
const ignoredErrors = new Set(['monitor_window_timeout']);

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private authStore: SessionStore,
    private userStore: UserStore,
    private authQuery: SessionQuery,
    private msal: MsalService,
    private interceptor: MsalInterceptor,
    private msalBroadcastService: MsalBroadcastService,
    private notification: NotificationService,
    @Inject('persistStorage') private persistStorage: PersistState,
    @Inject(API_BASE_URL) private apiBaseUrl: string
  ) {
    if (isMsalIframe()) {
      return;
    }

    this.msalBroadcastService.msalSubject$.subscribe((data) => {
      if (!data.error) {
        return;
      }

      switch (data.eventType) {
        case EventType.SSO_SILENT_FAILURE:
          return;

        default:
          break;
      }

      // https://learn.microsoft.com/en-us/entra/identity-platform/msal-error-handling-js#error-types
      if (data.error instanceof InteractionRequiredAuthError) {
        return;
      }

      if (data.error instanceof AuthError) {
        if (ignoredErrors.has(data.error.errorCode)) {
          return;
        }

        const messageAuthError = $localize`:MsalAuthError@@MsalAuthError:Es gab einen Fehler bei der Anmeldung. Fehlercode: `;
        const messageAuthErrorWithErrorCode = `${messageAuthError} ${data.error.errorCode}`;
        this.notification.showError(messageAuthErrorWithErrorCode);

        return;
      }

      const messageAuthError = $localize`:MsalGeneralError@@MsalGeneralError:Es gab einen Fehler bei der Anmeldung. Fehlercode: `;
      const messageAuthErrorWithErrorCode = `${messageAuthError} ${data.eventType}`;
      this.notification.showError(messageAuthErrorWithErrorCode);
    });
  }

  public isReady() {
    this.msal.instance.enableAccountStorageEvents();

    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap((status) => this.setCrediantials())
    );
  }

  public isLoggedIn() {
    return this.authQuery.isLoggedInAndNotExpired$;
  }

  // public getCurrentSession() {
  //   return this.authQuery.currentSession$;
  // }

  private setCrediantials() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msal.instance.getActiveAccount();

    if (!activeAccount && this.msal.instance.getAllAccounts().length > 0) {
      const accounts = this.msal.instance.getAllAccounts();
      this.msal.instance.setActiveAccount(accounts[0]);
    }

    return of(this.msal.instance.getActiveAccount()).pipe(
      switchMap((account) => {
        if (!account) {
          this.authStore.logout();
          return of(false);
        }

        return this.getTokenForRequestUrl(this.apiBaseUrl).pipe(
          // if token cant be retrieved user session is expired
          map((token) => !!token),
          catchError((error) => {
            return of(false);
          }),
          tap((isLoggedInAndNotExpired) => {
            if (!isLoggedInAndNotExpired) {
              return this.authStore.logout();
            }

            const session: SessionState = {
              isLoggedInAndNotExpired,
              name: account.name,
              email: account.username,
            };

            applyTransaction(() => {
              this.userStore.update({
                ...this.userStore.getValue(),
                ...{
                  email: session.email,
                  name: session.name,
                },
              });
              this.authStore.login(session);
            });
          })
        );
      })
    );
  }

  login() {
    this.persistStorage.clearStore();
    return from(this.msal.loginPopup()).pipe(
      switchMap((data) => {
        return this.setCrediantials();
      }),
      map(() => true),
      catchError((error, obs) => {
        // if the page was opened on a protected route use redirect login
        // modern browsers prevent pages to trigger a popup without user interaction
        if (
          typeof error === 'string' &&
          error.indexOf('popup_window_error') >= 0
        ) {
          this.msal.loginRedirect();
          return NEVER;
        }
        return of(false);
      })
    );
  }

  logout() {
    this.msal.logout();
    this.authStore.logout();
    this.persistStorage.clearStore();
  }

  getTokenForRequestUrl(url: string) {
    // HACK: use private method on interceptor to get scopes for endpoint
    const scopes = (this.interceptor as any).getScopesForEndpoint(url);
    return from(this.msal.acquireTokenSilent({ scopes })).pipe(
      map((resp) => resp.accessToken)
    );
  }

  addAuthHeaderForDevExpress(custom?: (method, ajaxOptions) => void) {
    const customHandling = custom;
    return async (method, ajaxOptions) => {
      return await this.getTokenForRequestUrl(this.apiBaseUrl)
        .pipe(
          map((token) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            ajaxOptions.headers = { Authorization: 'Bearer ' + token };
            if (customHandling) {
              customHandling(method, ajaxOptions);
            }
          })
        )
        .toPromise();
    };
  }
}
