import { Pipe } from '@angular/core';
import { LocalizationService } from '@app/core';

import { TranslatePipeEnumBase } from './translate-pipe.base';

@Pipe({
  name: 'orderAccessScope',
})
export class OrderAccessScopePipe extends TranslatePipeEnumBase {
  name: string = 'OrderAccessScope';

  constructor(private ls: LocalizationService) {
    super(ls);
  }
}
