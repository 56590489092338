<button
  mat-icon-button
  id="poBtnLoadingDirection"
  (mouseenter)="popOverVisible = true"
  (mouseleave)="popOverVisible = false"
>
  <mat-icon color="primary">info</mat-icon>
</button>
<dx-popover
  target="#poBtnLoadingDirection"
  position="bottom"
  [(visible)]="popOverVisible"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
      <!-- Todo: check Problems with the shown icon: Missing Picture -->
      <img
        src="../../../../assets/images/infoLoadingDirection.png"
        width="200px"
      />
      <div i18n="@@InfoLoadingDirection">
        Wir verladen Paletten grundsätzlich in Längsrichtung (lange Seite =
        Fahrtrichtung).
      </div>
    </div>
  </div>
</dx-popover>
