<div class="jumbotron" fxLayout="column" fxLayoutAlign="center center">
  <div class="pagetitle">
    <h1 i18n="homePagetitle|Home Seitentitel@@homePagetitle">Live Pooling</h1>
  </div>
  <div class="subtitle">
    <span i18n="homeSubtitle|Home Subtitle@@homeSubtitle"
      >Willkommen bei Live Pooling - dem DPL
      Online-Paletten-Management-System</span
    >
  </div>
</div>
<dpl-notice-pending-tranfers></dpl-notice-pending-tranfers>
