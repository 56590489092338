<ng-container *ngIf="text">
  <div class="container" *hasPermission="{actions: permissions,  resource: 'Division', referenceId: divisionId}">
    <!-- <div class="line"><mat-divider></mat-divider></div> -->
    <div class="text mat-typography">{{ text }}</div>
    <div class="line"></div>
  </div>
</ng-container>
<ng-container *ngIf="!text">
  <!-- <div class="line"> -->
    <mat-divider  *hasPermission="{actions: permissions, resource: 'Division', referenceId: divisionId}"></mat-divider>
  <!-- </div> -->
</ng-container>
