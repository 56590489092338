import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { DxHtmlEditorComponent } from 'devextreme-angular/ui/html-editor';
import { MatDialog } from '@angular/material/dialog';
import {
  FileUploadDialogComponent,
  FileUploadDialogData,
} from '../file-upload-dialog/file-upload-dialog.component';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import {
  ChannelDto,
  MentionDto,
} from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { UserService } from '../../../user/services/user.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UploadedFilesStore } from '../../../process-confirmation/state/uploaded-files.store';
import { UploadedFilesQuery } from '../../../process-confirmation/state/uploaded-files.query';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ChatChannelState } from '../../../generated/Dpl.B2b.Common.Enumerations';
import Quill from 'devextreme-quill';
import { Clipboard } from '@angular/cdk/clipboard';

type ViewData = {
  mentionDataSource: CustomStore | DataSource;
  isEmployee: boolean;
};

@Component({
  selector: 'dpl-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UploadedFilesStore, UploadedFilesQuery],
})
export class MessageInputComponent implements OnChanges, OnInit {
  @Input() isDialog?: boolean = false;
  @Input() selectedChannel?: ChannelDto;
  @Input() copiedText?;
  @Input() disableHeightChange? = false;
  @Input() disableSendButton?: boolean = false;
  @Output() changeListHight?: EventEmitter<number> = new EventEmitter<number>();
  @Output() reloadParent?: EventEmitter<boolean> = new EventEmitter<boolean>();

  mentions$: Observable<MentionDto[]>;
  isDplEmployee$: Observable<boolean>;
  expandButtonOptions = {
    location: 'after',
    icon: 'expand',
    hint: $localize`:@@MessageInputExpandIconHint:Eingabefenster größe umschalten`,
    onClick: () => {
      this.toggleFullScreen();
    },
  };

  @ViewChild('htmlEditor') htmlEditor: DxHtmlEditorComponent;
  @ViewChild('editorContainer') editorContainer: ElementRef;
  isFullScreen = false;
  editorValue;
  hubConnection: boolean;
  uploadedFilesLength;
  reloadSub = new BehaviorSubject<boolean>(true);
  viewData$: Observable<ViewData>;
  reload$ = this.reloadSub.asObservable();
  private htmlEditorHasFocus = false
  static ExtendedClipboardClass = null;
  static ExtendedUploaderClass = null;
  constructor(
    private uploadedFilesStore: UploadedFilesStore,
    private uploadedFilesQuery: UploadedFilesQuery,
    private dialog: MatDialog,
    private messagingDataService: MessagingDataService,
    private userService: UserService,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    const connection$ =
      this.messagingDataService.checkSignalRConnectionObservable();
    const reload$ = this.reloadSub.asObservable();
    this.viewData$ = combineLatest([
      connection$,
      this.userService.getIsDplEmployee(),
      reload$,
    ]).pipe(
      map(([connection, isEmployee]) => {
        this.htmlEditorHasFocus = false
        const dataSource = new DataSource({
          store: new CustomStore({
            key: 'userId',
            loadMode: 'raw',
            load: async () => {
              if (isEmployee && connection) {
                return this.messagingDataService
                  .getMentions(this.selectedChannel.channelId)
                  .toPromise();
              } else {
                return of([] as MentionDto[]).toPromise();
              }
            },
          }),
        });
        this.hubConnection = connection;

        const viewData: ViewData = {
          mentionDataSource: dataSource,
          isEmployee,
        };
        return viewData;
      })
    );
  }

  openFileUploadDialog() {
    const data: FileUploadDialogData = {
      channel: this.selectedChannel,
      uploadedFiles: this.uploadedFilesQuery.getAll(),
    };

    this.dialog
      .open<FileUploadDialogComponent>(FileUploadDialogComponent, {
        minWidth: '450px',
        maxWidth: '650px',
        width: '35%',
        minHeight: '200px',
        maxHeight: '1000px',
        panelClass: 'messaging-system-dialog-panel',
        disableClose: true,
        data: data,
        viewContainerRef: this.viewContainerRef,
      })
      .afterClosed()
      .subscribe(() => {
        const uploadedFiles = this.uploadedFilesQuery.getAll();
        this.uploadedFilesLength = uploadedFiles.length;
        this.reloadSub.next(true);
      });
  }

  ngOnChanges(changes) {
    if (
      changes.selectedChannel?.currentValue &&
      changes.selectedChannel?.previousValue &&
      changes.selectedChannel?.currentValue?.channelId !==
      changes.selectedChannel?.previousValue?.channelId
    ) {
      //Clear HtmlEditor Value
      if (this.hubConnection) {
        this.editorValue = '';
        this.clearUploadStore();
      }
      this.onFocusHtmlEditor()
      if (this.isDialog) {
        this.htmlEditor.instance.option('height', '243px');
      } else {
        this.htmlEditor.instance.option('height', '250px');
      }
      this.isFullScreen = false;
      if (!this.disableHeightChange) {
        this.changeListHight.emit(517);
      }

      this.reloadSub.next(true);
    }
    if (this.copiedText?.length > 0) {
      this.editorValue = this.copiedText;
      this.copiedText = '';
    }
  }

  clearUploadStore() {
    this.uploadedFilesStore.set({});
    const uploadedFiles = this.uploadedFilesQuery.getAll();
    this.uploadedFilesLength = uploadedFiles.length;
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      if (this.isDialog) {
        if (this.selectedChannel.channelState === ChatChannelState.Enclosed) {
          this.htmlEditor.instance.option('height', '741px');
        } else {
          this.htmlEditor.instance.option('height', '760px');
        }
      } else {
        if (this.selectedChannel.channelState === ChatChannelState.Enclosed) {
          this.htmlEditor.instance.option('height', '712px');
        } else {
          this.htmlEditor.instance.option('height', '731px');
        }
      }

      this.editorContainer.nativeElement.style.zIndex = '1000';
      this.editorContainer.nativeElement.style.position = 'unset';
      if (!this.disableHeightChange) {
        this.changeListHight.emit(0);
      }
    } else {
      if (this.isDialog) {
        this.htmlEditor.instance.option('height', '243px');
      } else {
        this.htmlEditor.instance.option('height', '250px');
      }
      // Setzen Sie hier die ursprüngliche Höhe
      this.editorContainer.nativeElement.style.zIndex = 'auto';
      this.editorContainer.nativeElement.style.position = 'relative';
      if (!this.disableHeightChange) {
        this.changeListHight.emit(517);
      }
    }
  }

  getIdsFromMentionText(text: any): number[] {
    const matches = text.matchAll(
      /<span class="dx-mention".*data-id="([0-9]+)".*/g
    );
    const ids = Array.from(matches, (match) => match[1]);
    const idsAsNumbers = Array.from(ids, Number);
    if (idsAsNumbers.length > 0) {
      return idsAsNumbers;
    }
    return [] as number[];
  }

  sendMessage() {
    const fileIds = this.uploadedFilesQuery
      .getAll()
      .map((value) => value.fileId);
    const mentions = this.getIdsFromMentionText(this.editorValue);

    this.messagingDataService
      .writeMessage(
        this.selectedChannel.channelId,
        this.editorValue,
        mentions,
        fileIds
      )
      .subscribe((success) => {
        console.log(success);
        if (success) {
          //reset HTML Editor Value
          this.editorValue = '';
          //reset Upload Store
          if (fileIds.length > 0) {
            //reset Upload Store
            this.clearUploadStore();
          }
          // setTimeout(() => {
          this.htmlEditor.instance.focus();
          // }, 1)

          if (this.disableHeightChange) {
            console.log('parent need to reload DataSource');
            this.reloadParent.emit(true);
          }
        } else {
          this.onFocusHtmlEditor()
          throw new Error(
            $localize`:@@MessageInputHubConnectionError:Hub Connection is not connected`
          );
        }
      });
  }

  getUploadedFileText() {
    return this.uploadedFilesLength;
  }

  getMentionDisplayExpr = (item: MentionDto) => {
    // TODO: Check what we do if the userName is the same
    return item.userName;
  };

  onContentReady(event) {
    //Todo Maybe only focus one time
    this.onFocusHtmlEditor()
  }

  onFocusHtmlEditor() {
    if (!this.htmlEditorHasFocus) {
      setTimeout(() => {
        if (this.editorValue && this.editorValue.length > 0) {
          this.htmlEditor.instance.setSelection(this.editorValue.length - 1, 0)
        } else {
          this.htmlEditor.instance.focus();
        }
        this.htmlEditorHasFocus = true
      }, 500);
    } else {
      console.log('htmlEditorHasFocus')
    }

  }

  onFocusOut(event) {
    this.htmlEditorHasFocus = false
    console.log('focusout', event)
  }

  //Maybe for later Use if we want to disable Images in HTMLEditor
  onInitialized(e) {
    let htmlEditor = e.component;
    if (!MessageInputComponent.ExtendedClipboardClass) {
      const Clipboard = Quill.import('modules/clipboard');
      class ExtendedClipboard extends Clipboard {
        constructor(quill, options) {
          quill.root.addEventListener('paste', (args) => {
            const div = document.createElement('div');
            div.innerHTML = args.clipboardData.getData('text/html');
            const elements = div.querySelectorAll('img, table');
            elements.forEach((elem) => elem.parentNode.removeChild(elem));
            if (elements.length === 0 && div.innerHTML.length > 0) {
              //Do Nothing allow to paste content
            } else {
              args.preventDefault();
              // cancel on demand;
              throw new Error(
                $localize`:@@MessageInputPasteError:Einfügen nicht möglich. Bitte beachten Sie, dass Bilder nur über den Anhang-Button hinzugefügt werden können.`
              );
            }
          });
          super(quill, options);
        }
      }
      Quill.register(
        {
          'modules/clipboard': ExtendedClipboard,
        },
        true
      );
      MessageInputComponent.ExtendedClipboardClass = ExtendedClipboard;
    }
    if (!MessageInputComponent.ExtendedUploaderClass) {
      const Uploader = Quill.import('modules/uploader');
      class ExtendedUploader extends Uploader {
        constructor(quill, options) {
          quill.root.addEventListener('drop', (args) => {
            args.stopImmediatePropagation();
            args.preventDefault();
            throw new Error(
              $localize`:@@MessageInputUploaderError:Es ist nicht erlaubt Dateien in das Nachrichtenfeld zu ziehen!`
            );
          });
          super(quill, options);
        }
      }
      Quill.register(
        {
          'modules/uploader': ExtendedUploader,
        },
        true
      );
      MessageInputComponent.ExtendedClipboardClass = ExtendedUploader;
    }
  }
}
