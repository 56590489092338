<div fxLayout="column" fxLayoutGap="20px" style="margin: 20px">
  <style>
    <!--
     /* Font Definitions */
     @font-face
    	{font-family:Wingdings;
    	panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
    	{font-family:"Cambria Math";
    	panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
    	{font-family:Calibri;
    	panose-1:2 15 5 2 2 2 4 3 2 4;}
    @font-face
    	{font-family:"Lucida Sans Unicode";
    	panose-1:2 11 6 2 3 5 4 2 2 4;}
    @font-face
    	{font-family:"Segoe UI";
    	panose-1:2 11 5 2 4 2 4 2 2 3;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
    	{margin-top:0cm;
    	margin-right:0cm;
    	margin-bottom:8.0pt;
    	margin-left:0cm;
    	line-height:107%;
    	font-size:11.0pt;
    	font-family:"Calibri",sans-serif;}
    p.MsoHeader, li.MsoHeader, div.MsoHeader
    	{mso-style-link:"Kopfzeile Zchn";
    	margin:0cm;
    	font-size:11.0pt;
    	font-family:"Calibri",sans-serif;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
    	{mso-style-link:"Fußzeile Zchn";
    	margin:0cm;
    	font-size:11.0pt;
    	font-family:"Calibri",sans-serif;}
    p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
    	{margin:0cm;
    	font-size:11.0pt;
    	font-family:"Calibri",sans-serif;}
    span.KopfzeileZchn
    	{mso-style-name:"Kopfzeile Zchn";
    	mso-style-link:Kopfzeile;}
    span.FuzeileZchn
    	{mso-style-name:"Fußzeile Zchn";
    	mso-style-link:Fußzeile;}
    .MsoChpDefault
    	{font-family:"Calibri",sans-serif;}
    .MsoPapDefault
    	{margin-bottom:8.0pt;
    	line-height:107%;}
     /* Page Definitions */
     @page WordSection1
    	{size:595.3pt 841.9pt;
    	margin:78.0pt 70.85pt 49.65pt 70.85pt;}
    div.WordSection1
    	{page:WordSection1;}
     /* List Definitions */
     ol
    	{margin-bottom:0cm;}
    ul
    	{margin-bottom:0cm;}
    -->
  </style>

  <div
    lang="DE"
    link="blue"
    vlink="#954F72"
    style="tab-interval: 35.4pt; word-wrap: break-word"
  >
    <div class="WordSection1">
      <p class="MsoNoSpacing">
        <b style="mso-bidi-font-weight: normal">
          <u>
            <span
              style="
                font-size: 15pt;
                font-family: 'Arial', sans-serif;
                mso-fareast-language: DE;
              "
              >Datenschutzerklärung für die Online-Plattform Live Pooling
            </span>
          </u>
        </b>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >I. Name und Anschrift des Verantwortlichen
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
          anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
          sonstiger datenschutzrechtlicher Bestimmungen ist:
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >DPL Deutsche Paletten Logistik GmbH<br />
          <span class="SpellE">Overweg</span> 12<br />
          D-59494 Soest<br />
          Tel: +49 2921 7899 - 0<br />
          Fax: +49 2921 7899 - 178<br />
          E-Mail:&nbsp;</span
        >
        <a href="mailto:info@dpl-pooling.com">
          <span
            style="
              font-family: 'Arial', sans-serif;
              color: windowtext;
              mso-fareast-language: DE;
            "
            >info@dpl-pooling.com
          </span>
        </a>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Website:&nbsp;</span
        >
        <a href="http://www.dpl-pooling.com/" target="_blank">
          <span
            style="
              font-family: 'Arial', sans-serif;
              color: windowtext;
              mso-fareast-language: DE;
            "
            >www.dpl-pooling.com</span
          >
        </a>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          <b style="mso-bidi-font-weight: normal"
            >II. Name und Anschrift des Datenschutzbeauftragten
          </b>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Der Datenschutzbeauftragte des Verantwortlichen ist:
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Dominik <span class="SpellE">Fünkner</span>
          <br />
          datenschutzexperte.de / PROLIANCE GmbH<br />
          Leopoldstr. 21
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >80802 München
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Deutschland
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Tel.: 089-217685176<br />
          E-Mail:&nbsp;</span
        >
        <span
          style="
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            mso-fareast-language: DE;
          "
          >datenschutzbeauftragter@datenschutzexperte.de</span
        >
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Website:&nbsp;</span
        >
        <span
          style="
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: 'Times New Roman';
            mso-fareast-language: DE;
          "
          >www.datenschutzexperte.de
        </span>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >&nbsp;
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >III. Allgemeines zur Datenverarbeitung
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >1. Umfang der Verarbeitung personenbezogener Daten</span
          >
        </u>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          ><br />
          Wir verarbeiten Ihre personenbezogenen Daten, soweit dies zur
          Bereitstellung einer funktionsfähigen Plattform sowie unserer Inhalte
          und Leistungen erforderlich ist. Die Verarbeitung Ihrer
          personenbezogenen Daten erfolgt regelmäßig zur Erfüllung des
          Nutzungsvertrages für die Plattform oder auf Basis Ihrer Einwilligung.
          <span style="mso-spacerun: yes"></span>Eine Ausnahme gilt in solchen
          Fällen, in denen die Verarbeitung der Daten durch gesetzliche
          Vorschriften gestattet ist.
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          <u>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</u>
          <br />
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
          Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
          <span class="SpellE">lit</span>. a EU-Datenschutzgrundverordnung
          (DSGVO) als Rechtsgrundlage.
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
          eines Vertrages, dessen Vertragspartei die betroffene Person ist,
          erforderlich ist, dient Art. 6 Abs. 1 <span class="SpellE">lit</span>.
          b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge,
          die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
          rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
          unterliegt, dient Art. 6 Abs. 1 <span class="SpellE">lit</span>. c
          DSGVO als Rechtsgrundlage.
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Für den Fall, dass lebenswichtige Interessen der betroffenen Person
          oder einer anderen natürlichen Person eine Verarbeitung
          personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
          <span class="SpellE">lit</span>. d DSGVO als Rechtsgrundlage.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
          unseres Unternehmens oder eines Dritten erforderlich und überwiegen
          die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
          erstgenannte Interesse nicht, so dient Art. 6 Abs. 1
          <span class="SpellE">lit</span>. f DSGVO als Rechtsgrundlage für die
          Verarbeitung.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          <u>3. Datenlöschung und Speicherdauer</u><br />
          Die personenbezogenen Daten der betroffenen Person werden gelöscht
          oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
          Speicherung kann darüber hinaus erfolgen, wenn dies durch den
          europäischen oder nationalen Gesetzgeber in unionsrechtlichen
          Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
          Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
          Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
          Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen
          Vertragsabschluss oder eine Vertragserfüllung besteht. Ihre Daten
          werden also solange aufbewahrt wie Sie ein Nutzerkonto für die
          Plattform haben. Informationen, die für Rechnungslegung und
          Buchhaltung relevant sind, etwa Informationen zu Aufträgen,
          Stornierungen, Annahmeerklärungen oder Zahlungen, werden entsprechend
          er gesetzlichen Aufbewahrungsfristen nach 6 bzw. 10 Jahren
          gelöscht.<span style="mso-spacerun: yes"> </span>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >&nbsp;
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >IV. Bereitstellung der Plattform
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >1. Beschreibung und Umfang der Datenverarbeitung</span
          >
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Bei jedem Aufruf der Plattform erfasst unser System automatisiert
          Daten und Informationen vom Computersystem des aufrufenden Rechners.
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Folgende Daten werden hierbei erhoben:
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l3 level1 lfo4"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore">
            §<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Informationen über den Browsertyp und die verwendete Version
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l3 level1 lfo4"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Das Betriebssystem des Nutzers
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l3 level1 lfo4"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Den Internet-Service-Provider des Nutzers
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l3 level1 lfo4"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Die IP-Adresse des Nutzers
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l3 level1 lfo4"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Datum und Uhrzeit des Zugriffs<br
            style="mso-special-character: line-break"
          />
          <br style="mso-special-character: line-break" />
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >2. Rechtsgrundlage für die Datenverarbeitung</span
          >
        </u>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          ><br />
          Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art.
          6 Abs. 1 <span class="SpellE">lit</span>. f DSGVO.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >3. Zweck der Datenverarbeitung</span
          >
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Die vorübergehende Speicherung der IP-Adresse durch das System ist
          notwendig, um eine Auslieferung der browserbasierten Plattform an den
          Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des
          Nutzers für die Dauer der Anfrage gespeichert bleiben.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >4. Dauer der Speicherung</span
          >
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
          der Daten zur Bereitstellung der Plattform ist dies der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >5. Widerspruchs- und Beseitigungsmöglichkeit</span
          >
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Die Erfassung der Daten zur Bereitstellung der Plattform und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internetseite zwingend erforderlich. Es besteht folglich seitens des
          Nutzers keine Widerspruchsmöglichkeit.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >&nbsp;
        </span>
      </p>

      <b style="mso-bidi-font-weight: normal">
        <span
          style="
            font-size: 11pt;
            line-height: 107%;
            font-family: 'Arial', sans-serif;
            mso-fareast-font-family: Calibri;
            mso-fareast-theme-font: minor-latin;
            mso-ansi-language: DE;
            mso-fareast-language: DE;
            mso-bidi-language: AR-SA;
          "
        >
          <br
            clear="all"
            style="mso-special-character: line-break; page-break-before: always"
          />
        </span>
      </b>

      <p class="MsoNormal">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            ><p>&nbsp;</p>
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >V. Verwendung von Cookies
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >1) Beschreibung und Umfang der Datenverarbeitung
          </span>
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Unsere Internetseite verwendet sogenannte "Cookies". Cookies sind
          kleine Textdateien, die entweder vorübergehend für die Dauer einer
          Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf dem
          Endgerät gespeichert werden. Session-Cookies werden nach Ende Ihres
          Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
          Endgerät gespeichert bis Sie diese selbst löschen&#8239;oder eine
          automatische Lösung durch Ihren Webbrowser erfolgt.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht
          funktionieren würden (z.B. die Warenkorbfunktion oder
          Spracheinstellungen). Andere Cookies dienen dazu, das Nutzerverhalten
          auszuwerten&#8239;oder Werbung anzuzeigen.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          ><p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >2) Rechtsgrundlage für die Datenverarbeitung</span
          >
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Technisch notwendige Cookies werden auf Grundlage von Art. 6 Abs. 1
          <span class="SpellE">lit</span>. f DSGVO gespeichert. Wir haben ein
          berechtigtes Interesse an der Speicherung von Cookies zur technisch
          fehlerfreien und optimierten Bereitstellung unserer Dienste. Andere
          Cookies werden nur mit Ihrer Einwilligung auf Grundlage von Art. 6
          Abs. 1 <span class="SpellE">lit</span>. a DSGVO gespeichert. Die
          Einwilligung ist jederzeit für die Zukunft widerrufbar. Die
          Rechtsgrundlage kann sich auch aus Art. 6 Abs. 1
          <span class="SpellE">lit</span>. b DSGVO ergeben, falls die
          Verarbeitung für die Erfüllung eines Vertrags, dessen Vertragspartei
          die betroffene Person ist, oder zur Durchführung vorvertraglicher
          Maßnahmen erforderlich ist, die auf Anfrage der betroffenen Person
          erfolgen.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Soweit Cookies zu Analysezwecken eingesetzt werden, werden wir Sie
          hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren
          und eine Einwilligung einholen.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >3) Zweck der Datenverarbeitung
          </span>
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Der Zweck der Verwendung technisch notwendiger Cookies ist, die
          Nutzung der Plattform für die Nutzer zu vereinfachen. Einige
          Funktionen unserer Internetseite können ohne den Einsatz von Cookies
          nicht angeboten werden. Für diese ist es erforderlich, dass der
          Browser auch nach einem Seitenwechsel wiedererkannt wird.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >4) Dauer der Speicherung, Widerspruchs- und
            Beseitigungsmöglichkeit</span
          >
        </u>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem
          an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die
          volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung
          der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung
          von Cookies deaktivieren oder einschränken. Bereits gespeicherte
          Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert
          erfolgen. Werden Cookies für unsere Plattform deaktiviert, können
          möglicherweise nicht mehr alle Funktionen der Plattform vollumfänglich
          genutzt werden.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >VI. Dienste Dritter
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Für die Funktionsfähigkeit der Plattform sind verschiedene Dienste
          von Dritten eingebunden. Diese Dienste sind notwendig um die Anbindung
          an unsere Systeme zur Auftragserteilung, Annahmequittierung und die
          Einsicht in das Kundenkonto zu ermöglichen. <br />
          <br />
          <u>1) Stream</u>
          <br />
          <span class="SpellE">Stream</span> von Stream.io
          <span class="SpellE">Inc</span>, 2005 Broadway, Boulder, CO 80302, USA
          ist unser Anbieter zur Bereitstellung der Chatfunktion. Dabei erlangt
          Stream Kenntnis von folgenden Daten:<span style="mso-spacerun: yes">
          </span>
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Email- Adresse
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >IP-Adresse
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Alle Kommunikationsinhalte (inkl. Dateien, Fotos, etc.)
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Zeitpunkt der letzten Anmeldung
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Welche Nachrichten wurden gelesen (angezeigt)
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Welche Nachrichten wurden noch nicht gelesen (angezeigt)
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Zeitpunkt des Lesens einer Nachricht
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Vorgänge, zu denen eine Chat-Kommunikation existiert
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="margin-left: 36pt; text-indent: -18pt; mso-list: l8 level1 lfo2"
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Vorgangsnummer
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-left: 36pt">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Durch die Nutzung des Dienstes erfolgt keine Übertragung
          personenbezogener Daten in die USA. Der Chatdienst ist komplett in der
          EU gehostet. Rechtsgrundlage für die Verarbeitung der
          personenbezogenen Daten ist Art. 6 Abs. 1 S. 1
          <span class="SpellE">lit</span>. b DSGVO und dient zur
          Vertragserfüllung um Ihnen innerhalb unseres Angebots einen
          Live-Kundensupport anbieten zu können.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Ihre Daten werden nach Aufgabe des Kundenzugangs gelöscht.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >2) Microsoft Azure Dienste
          </span>
        </u>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          ><br />
          Es werden die in der Übersicht dargestellten Dienste von Microsoft
          Azure, Microsoft <span class="SpellE">Ireland</span>
          <span class="SpellE">Operations</span> Ltd.,
          <span class="SpellE">Carmenhall</span> Road,
          <span class="SpellE">Sandyford</span>, Dublin 18,
          <span class="SpellE">Ireland</span> eingesetzt. Der Einsatz der
          Dienste erfolgt zur Bereitstellung der Plattform und zur Erfüllung des
          Nutzungsvertrages. Rechtsgrundlage für den Einsatz der Dienste und
          damit verbundene Datenübermittlung an den Anbieter ist daher Art. 6
          Abs. 1 b) DSGVO.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <table
        class="MsoTableGrid"
        border="1"
        cellspacing="0"
        cellpadding="0"
        style="
          border-collapse: collapse;
          border: none;
          mso-border-alt: solid windowtext 0.5pt;
          mso-yfti-tbllook: 1184;
          mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
        "
      >
        <thead>
          <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
            <td
              width="160"
              valign="top"
              style="
                width: 120.25pt;
                border: solid windowtext 1pt;
                mso-border-alt: solid windowtext 0.5pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNoSpacing">
                <b style="mso-bidi-font-weight: normal">
                  <span style="font-family: 'Arial', sans-serif"
                    >Verwendeter Dienst</span
                  >
                </b>
                <b style="mso-bidi-font-weight: normal">
                  <span
                    style="
                      font-family: 'Arial', sans-serif;
                      mso-fareast-language: DE;
                    "
                  >
                  </span>
                </b>
              </p>
            </td>
            <td
              width="151"
              valign="top"
              style="
                width: 4cm;
                border: solid windowtext 1pt;
                border-left: none;
                mso-border-left-alt: solid windowtext 0.5pt;
                mso-border-alt: solid windowtext 0.5pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNoSpacing">
                <b style="mso-bidi-font-weight: normal">
                  <span style="font-family: 'Arial', sans-serif"
                    >Kategorie
                  </span>
                </b>
                <b style="mso-bidi-font-weight: normal">
                  <span
                    style="
                      font-family: 'Arial', sans-serif;
                      mso-fareast-language: DE;
                    "
                  >
                  </span>
                </b>
              </p>
            </td>
            <td
              width="293"
              valign="top"
              style="
                width: 219.45pt;
                border: solid windowtext 1pt;
                border-left: none;
                mso-border-left-alt: solid windowtext 0.5pt;
                mso-border-alt: solid windowtext 0.5pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNoSpacing">
                <b style="mso-bidi-font-weight: normal">
                  <span style="font-family: 'Arial', sans-serif"
                    >Gespeicherte Daten</span
                  >
                </b>
                <b style="mso-bidi-font-weight: normal">
                  <span
                    style="
                      font-family: 'Arial', sans-serif;
                      mso-fareast-language: DE;
                    "
                  >
                  </span>
                </b>
              </p>
            </td>
          </tr>
        </thead>
        <tr style="mso-yfti-irow: 1">
          <td
            width="160"
            valign="top"
            style="
              width: 120.25pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif"
                >Azure Active Directory</span
              >
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 4cm;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif"
                >Authentifizierung
              </span>
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
          <td
            width="293"
            valign="top"
            style="
              width: 219.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp;</span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif">IP-Adresse </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif">Browser </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Betriebssystem
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Email-Adresse
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Vorname / Nachname
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Telefonnummer (falls Multi-Faktor Authentifizierung aktiviert
                ist)
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Zeitpunkt der letzten Anmeldung
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Anzahl der genutzten Geräte
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Ort der Anmeldung
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                  mso-fareast-language: DE;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Anzahl der fehlerhaften Anmeldungen</span
              >
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 2">
          <td
            width="160"
            valign="top"
            style="
              width: 120.25pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif">SQL Azure</span>
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 4cm;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif">Datenbank</span>
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
          <td
            width="293"
            valign="top"
            style="
              width: 219.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >alle zum Betrieb der Plattform notwendigen Kundendaten
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif">IP-Adresse </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Email-Adresse
              </span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 3; page-break-inside: avoid">
          <td
            width="160"
            valign="top"
            style="
              width: 120.25pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif"
                >Azure Hosting, <span class="SpellE">Application</span>
                <span class="SpellE">Insights</span>
              </span>
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 4cm;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif">Azure</span>
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
          <td
            width="293"
            valign="top"
            style="
              width: 219.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif">IP-Adresse </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif">Browser </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Betriebssystem
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Ort der Anmeldung
              </span>
            </p>
            <p
              class="MsoNoSpacing"
              style="
                margin-left: 11.45pt;
                text-indent: -11.45pt;
                mso-list: l12 level1 lfo6;
              "
            >
              <span
                style="
                  font-family: Wingdings;
                  mso-fareast-font-family: Wingdings;
                  mso-bidi-font-family: Wingdings;
                  mso-fareast-language: DE;
                "
              >
                <span style="mso-list: Ignore"
                  >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
                </span>
              </span>
              <span style="font-family: 'Arial', sans-serif"
                >Sitzungsdauer</span
              >
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
              >
              </span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 4; mso-yfti-lastrow: yes">
          <td
            width="160"
            valign="top"
            style="
              width: 120.25pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif"
                >Microsoft Translation Service
              </span>
            </p>
          </td>
          <td
            width="151"
            valign="top"
            style="
              width: 4cm;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span style="font-family: 'Arial', sans-serif">Übersetzung </span>
            </p>
          </td>
          <td
            width="293"
            valign="top"
            style="
              width: 219.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="MsoNoSpacing">
              <span
                style="
                  font-family: 'Arial', sans-serif;
                  mso-fareast-language: DE;
                "
                >Es werden keine Informationen gespeichert.
              </span>
            </p>
          </td>
        </tr>
      </table>

      <p
        class="MsoNoSpacing"
        style="
          margin-bottom: 6pt;
          margin-top: 0cm;
          mso-margin-bottom-alt: 0cm;
          mso-margin-top-alt: 0cm;
          mso-add-space: auto;
        "
      >
        <span
          style="
            font-family: 'Arial', sans-serif;
            color: white;
            mso-color-alt: windowtext;
            mso-fareast-language: DE;
          "
        >
          <br />
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Die Speicherung und Verarbeitung der Daten durch die Dienste Azure
          Active Directory, SQL Azure und Azure Hosting,
          <span class="SpellE">Application</span>
          <span class="SpellE">Insights</span> erfolgt in Deutschland oder
          innerhalb der europäischen Union.<br />
          Nähere Informationen zum Umgang mit personenbezogenen Daten durch
          Microsoft im Rahmen der genannten Dienste finden Sie unter
        </span>
        <span style="font-family: 'Arial', sans-serif"
          >https://privacy.microsoft.com/en-us/PrivacyStatement.
        </span>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <u>
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >3) Google Maps
          </span>
        </u>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Die Plattform nutzt über eine Schnittstelle den
          Online-Kartendienstanbieter Google Maps. Dadurch können wir Ihnen
          interaktive Karten direkt in der Webseite anzeigen und ermöglichen
          Ihnen die komfortable Nutzung der Karten-Funktion. Anbieter des
          Kartendienstes ist Google <span class="SpellE">Ireland</span> Limited,
          Gordon House, Barrow Street, Dublin 4,
          <span class="SpellE">Ireland</span>. Zur Nutzung der Funktionalitäten
          von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Rechtgrundlage für die Verarbeitung Ihrer personenbezogenen Daten ist
          Ihre hierzu erteilte Einwilligung nach Art. 6 Abs. 1 S. 1
          <span class="SpellE">lit</span>. a DSGVO.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Da eine Übertragung personenbezogener Daten in die USA erfolgt, sind
          weitere Schutzmechanismen erforderlich, die das Datenschutzniveau der
          DSGVO sicherstellen. Um dies zu gewährleisten, setzt Google
          Standarddatenschutzklauseln gemäß Art. 46 Abs. 2
          <span class="SpellE">lit</span>. c DSGVO ein. Diese verpflichten den
          Empfänger der Daten in den USA, die Daten entsprechend dem
          Schutzniveau in Europa zu verarbeiten. In Fällen, in denen dies auch
          durch diese vertragliche Erweiterung nicht sichergestellt werden kann,
          bemühen wir uns um darüberhinausgehende Regelungen und Zusagen des
          Empfängers in den USA.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von Google:
          https://www.google.de/intl/de/policies/privacy/
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span class="SpellE">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >Opt</span
          >
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >-out: https://www.google.com/settings/ads/
        </span>
      </p>

      <p class="MsoNoSpacing">
        <b style="mso-bidi-font-weight: normal">
          <span
            lang="EN-US"
            style="
              font-family: 'Arial', sans-serif;
              mso-ansi-language: EN-US;
              mso-fareast-language: DE;
            "
          >
            <p>&nbsp;</p>
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing">
        <b style="mso-bidi-font-weight: normal">
          <span
            lang="EN-US"
            style="
              font-family: 'Arial', sans-serif;
              mso-ansi-language: EN-US;
              mso-fareast-language: DE;
            "
          >
            <p>&nbsp;</p>
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >VII. Betroffenenrechte
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Sie haben das Recht:
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'"> &nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
          über die Verarbeitungszwecke, die Kategorie der personenbezogenen
          Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
          offengelegt wurden oder werden, die geplante Speicherdauer, das
          Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
          Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
          die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden,
          sowie über das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich <span class="SpellE">Profiling</span> und ggf.
          aussagekräftigen Informationen zu deren Einzelheiten verlangen;
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
          zu verlangen;
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
          personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
          zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch
          gegen die Verarbeitung eingelegt haben;
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen;
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft nicht mehr fortführen dürfen und
        </span>
      </p>

      <p
        class="MsoNoSpacing"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 4pt;
          margin-left: 17.85pt;
          text-indent: -17.85pt;
          mso-list: l9 level1 lfo14;
        "
      >
        <span
          style="
            font-family: Wingdings;
            mso-fareast-font-family: Wingdings;
            mso-bidi-font-family: Wingdings;
            mso-fareast-language: DE;
          "
        >
          <span style="mso-list: Ignore"
            >§<span style="font: 7pt 'Times New Roman'">&nbsp; </span>
          </span>
        </span>
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In
          der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres
          üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
          Kanzleisitzes wenden.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <p>&nbsp;</p>
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >
            <p>&nbsp;</p>
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <b style="mso-bidi-font-weight: normal">
          <span
            style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
            >VIII. Widerspruchsrecht
          </span>
        </b>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
          Interessen gemäß Art. 6 Abs. 1 S. 1 <span class="SpellE">lit</span>. f
          DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO
          Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
          einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
          besonderen Situation ergeben oder sich der Widerspruch gegen
          Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
          Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns
          umgesetzt wird.<b style="mso-bidi-font-weight: normal"> </b>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, genügt eine E-Mail an&nbsp;</span
        >
        <a href="mailto:info@dpl-pooling.com">
          <span
            style="
              font-family: 'Arial', sans-serif;
              color: windowtext;
              mso-fareast-language: DE;
            "
            >info@dpl-pooling.com.</span
          >
        </a>
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
        </span>
      </p>

      <p class="MsoNoSpacing" style="margin-bottom: 6pt">
        <span
          style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
        >
          <br />
          <br />
          <b style="mso-bidi-font-weight: normal">IX. Datensicherheit </b>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Wir verwenden innerhalb des Plattform-Besuchs das verbreitete
          SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
          wird. In der Regel handelt es sich dabei um eine 256 Bit
          Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung
          unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
          zurück. Ob eine einzelne Seite unseres Internetauftrittes
          verschlüsselt übertragen wird, erkennen Sie an der geschlossenen
          Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der
          unteren Statusleiste Ihres Browsers.<b
            style="mso-bidi-font-weight: normal"
          >
          </b>
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige
          oder vorsätzliche Manipulationen, teilweisen oder vollständigen
          Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
          schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.
        </span>
      </p>

      <p class="MsoNoSpacing">
        <span style="font-family: 'Arial', sans-serif; mso-fareast-language: DE"
          >&nbsp;
        </span>
      </p>
    </div>
  </div>
</div>
