import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import {
  ExternalLoadCarrierTypesStore,
  ExternalLoadCarrierTypesState,
} from './external-load-carrier-types.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'order',
  sortByOrder: Order.ASC,
})
export class ExternalLoadCarrierTypesQuery extends QueryEntity<ExternalLoadCarrierTypesState> {
  all$ = this.selectAll();
  constructor(protected store: ExternalLoadCarrierTypesStore) {
    super(store);
  }
}
