import { Component, OnInit, Input } from '@angular/core';
import {
  OrderTransportType,
  OrderLoad,
  OrderType,
  LoadCarrierReceiptType,
  OrderStatus,
  UserRole,
  LoadingLocationOwnershipType,
} from '@app/api/dpl';
import { Router } from '@angular/router';
import { DocumentsService } from '@app/shared/services';

@Component({
  selector: 'dpl-load-carrier-receipt-button',
  templateUrl: './load-carrier-receipt-button.component.html',
  styleUrls: ['./load-carrier-receipt-button.component.scss'],
})
export class LoadCarrierReceiptButtonComponent implements OnInit {
  transportType = OrderTransportType;
  orderStatus = OrderStatus;
  loadingLocationOwnershipType = LoadingLocationOwnershipType;

  @Input() refLmsAvail2DeliId: number;
  @Input() digitalCode: string;
  @Input() status: OrderStatus;
  @Input() type: OrderType;
  @Input() isSelfTransport: boolean;
  @Input() loadingLocationOwnership: LoadingLocationOwnershipType;
  @Input() depotContext = false;

  constructor(private router: Router, private document: DocumentsService) {}

  ngOnInit(): void {}

  onCreateReceipt() {
    const getAction: (orderType: OrderType) => LoadCarrierReceiptType = (
      orderType: OrderType
    ) => {
      switch (this.type) {
        case OrderType.Supply:
          return LoadCarrierReceiptType.Pickup;
        case OrderType.Demand:
          return LoadCarrierReceiptType.Delivery;
        default:
          throw new Error(
            $localize`:@@UnkownOrderType:Unkown orderType` + `: ${this.type}`
          );
      }
    };

    if (this.depotContext) {
      this.router.navigate(['/receipt/create', this.digitalCode], {
        queryParams: {
          action: getAction(this.type),
          context: 'depot',
        },
      });
    } else {
      this.router.navigate(['/receipt/create', this.digitalCode], {
        queryParams: {
          action: getAction(this.type),
        },
      });
    }
  }

  openDocument(orderLoad: OrderLoad) {
    //printByAvailToDeliId

    this.document
      .printByAvailToDeliId(this.refLmsAvail2DeliId, this.type, false)
      .subscribe();
  }
}
