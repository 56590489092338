import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';
import { IExternalLoadCarrier } from './external-load-carrier.model';

export interface ExternalLoadCarriersState
  extends EntityState<IExternalLoadCarrier<number, number>, number>,
    ActiveState<number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'external-load-carriers' })
export class ExternalLoadCarriersStore extends EntityStore<ExternalLoadCarriersState> {
  constructor() {
    super();
  }
}
