<div class="messaging-system-button">
  <ng-container *ngIf="isIssuer">
    <mat-icon class="messaging-system-icon-back"
      >arrow_back</mat-icon
    >
  </ng-container>
  <ng-container *ngIf="isRecipient">
    <mat-icon class="messaging-system-icon-forward"
      >arrow_forward</mat-icon
    >
  </ng-container>
  <ng-container
    *ngIf="
      messagingDataService.ticketExists(referenceNumber, type)
        | async as ticketId;
      else noTicket
    "
  >
    <button
      mat-icon-button
      color="primary"
      (click)="openTicketDialog(ticketId)"
      title="Messagingsystem öffnen"
      i18n-title="@@TitleForButtonToOpenATicket"
      [matBadge]="
        messagingDataService.ticketHasUnreadMessages(ticketId) | async
      "
      [matBadgeHidden]="
        !(messagingDataService.ticketHasUnreadMessages(ticketId) | async)
      "
      matBadgeColor="accent"
    >
      <mat-icon>mail</mat-icon>
    </button>
  </ng-container>
  <ng-template #noTicket>
    <button
      mat-icon-button
      (click)="createTicket()"
      i18n-title="@@TitleForButtonToCreateATicket"
      title="Messagingsystem öffnen"
    >
      <mat-icon>mail</mat-icon>
    </button>
  </ng-template>
</div>
