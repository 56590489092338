import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { IExternalLoadCarrierQuality } from './external-load-carrier-quality.model';

export interface ExternalLoadCarrierQualitiesState extends EntityState<IExternalLoadCarrierQuality, number>, ActiveState<number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'external-load-carrier-qualities' })
export class ExternalLoadCarrierQualitiesStore extends EntityStore<ExternalLoadCarrierQualitiesState> {

  constructor() {
    super();
  }

}
