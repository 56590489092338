import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {  of, timer } from 'rxjs';
import {  catchError, switchMap, tap } from 'rxjs/operators';
import { APP_CONFIG, DplLiveConfiguration } from '../../../config';

import { IGlobalNotification } from '../state/global-notifications/global-notifications.model';
import { createInitialState, GlobalNotificationsStore } from '../state/global-notifications/global-notifications.store';

@Injectable({
  providedIn: 'root',
})
export class GlobalNotificationsService {
  constructor(
    @Inject(APP_CONFIG) private config: DplLiveConfiguration,
    private http: HttpClient,
    private store: GlobalNotificationsStore
  ) {
    
  }

  getGlobalNotifications() {
    let notifications$ = this.http.get<IGlobalNotification>(
      this.config.app.ui.globalNotifications.url
    );

    return timer(0, this.config.app.ui.globalNotifications.debounceTime || 3600000).pipe(switchMap (timer => {
        return notifications$.pipe(catchError(error => {
          return of(createInitialState());
        }), tap (response => {
          

          let notification : IGlobalNotification = {
            startDate: new Date(response.startDate),
            endDate: new Date(response.endDate),
            textEN: response.textEN,
            textDE: response.textDE,
            level: response.level
          }
          
          this.store.update(notification);
        }))
    }))
  }
}
