import { InjectionToken } from '@angular/core';
import { AppInsightsConfig } from '@markpieszak/ng-application-insights';

import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Configuration } from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';

export const APP_CONFIG = new InjectionToken<DplLiveConfiguration>(
  'APP_CONFIG'
);

export interface DplLiveConfiguration {
  app: {
    apiBaseUrl: string;
    info: {
      environment: string;
      build: string;
      buildTime: string;
      release: string;
      releaseTime: string;
    };
    ui: {
      autoComplete: {
        minLength: number;
        debounceTime: number;
      };
      loading: {
        durationUntilUserCanHide: number;
        durationMax: number;
      };
      globalNotifications: {
        url: string;
        debounceTime: number;
      };
    };
    scheduledOrderRemoval: {
      supplyDaysOverdue: number;
      demandDaysOverdue: number;
    };
  };
  msal: {
    config: {
      auth: {
        clientId: string;
        authority: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
        navigateToLoginRequestUrl: boolean;
      };
      cache: {
        cacheLocation: string;
      };
    };
    ngConfig: {
      consentScopes: string[];
      protectedResourceMap: [string, string[]][];
    };
  };
  applicationInsights: AppInsightsConfig;
  googleMaps: LazyMapsAPILoaderConfigLiteral;
  stream: {
    key: string;
  };
  chat: {
    functions: {
      createChannelOrAddMeAsMember: string;
      getChannelsWithMessages: string;
    };
  };
}
