import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UploadedFilesStore, UploadedFilesState } from './uploaded-files.store';

@Injectable()
export class UploadedFilesQuery extends QueryEntity<UploadedFilesState> {

  constructor(protected store: UploadedFilesStore) {
    super(store);
  }

}
