import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { first } from "rxjs/operators";
import { TransferLimitationState, TransferLimitationStore } from "./transfer-limitations.store";

@Injectable({ providedIn: 'root' })
export class TransferLimitationQuery extends QueryEntity<TransferLimitationState> {
  active$ = this.selectActive();
  all$ = this.selectAll();


  constructor(protected store: TransferLimitationStore) {
    super(store);
  }

  getTransferLimitationBySourceAccountId(sourceAccountId: number) {
    return this.selectAll({filterBy: limit => limit.refLtmsAccountId === sourceAccountId});
  }
}
