import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'palletmanagement-date-from-to-dialog',
  templateUrl: './date-from-to-dialog.component.html',
  styleUrls: ['./date-from-to-dialog.component.css'],
})
export class DateFromToDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
