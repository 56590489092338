import { Injectable } from '@angular/core';

import { CustomerDivisionsQuery } from '../state/customer-divisions.query';
import { pluck, switchMap } from 'rxjs/operators';
import { CustomerDivisionsStore } from '../state/customer-divisions.store';
import { filterNil } from '@datorama/akita';

@Injectable({
  providedIn: 'root', 
})
export class CustomerDivisionsService {
  constructor(
    private divisionQuery: CustomerDivisionsQuery,
    private customerDivisionsStore: CustomerDivisionsStore
  ) {}

  getDivisions() {
    return this.divisionQuery.divisions$;
  }

  setActive(id: number) {
    this.customerDivisionsStore.setActive(id);    
  }

  getActiveDivision() {
    return this.divisionQuery.activeDivision$;
  }

  getActiveDivisionId(){
    return this.divisionQuery.activeDivision$.pipe(filterNil,pluck('id'));
  }
}
