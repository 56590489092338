import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipeEnumBase } from '../../shared/pipes/translate-pipe.base';
import { LocalizationService } from '../../core';
import {  ChatChannelType } from '../../generated/Dpl.B2b.Common.Enumerations';

@Pipe({
  name: 'chatChannelType'
})
export class ChatChannelTypePipe extends TranslatePipeEnumBase {
  name: string = 'ChatChannelType';

  constructor(private ls: LocalizationService) {
    super(ls);
  }

  transform(value: string) {    
    return super.transform(value);
  }

}
