import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../../core';
import { TranslatePipeEnumBase } from './translate-pipe.base';

@Pipe({
  name: 'storageType'
})
export class StorageTypePipe extends TranslatePipeEnumBase {
  name:string= 'StorageType';

  constructor(private ls: LocalizationService) {
    super(ls);
  }

}
