import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { LanguageSelectType } from '../channel/channel.component';
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@ngneat/reactive-forms';
import { UserService } from '../../../user/services/user.service';
import { filterNilValue } from '@datorama/akita';
import { distinctUntilChanged, distinctUntilKeyChanged, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { User } from '../../../core/services/dpl-api-services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DxListComponent } from 'devextreme-angular';

type ViewData = {
  currentUser: User;
  ticket: ChannelDto;
};

@Component({
  selector: 'dpl-show-on-login-announcements-dialog',
  templateUrl: './show-on-login-announcements-dialog.component.html',
  styleUrls: ['./show-on-login-announcements-dialog.component.scss'],
})
export class ShowOnLoginAnnouncementsDialogComponent implements OnInit, OnDestroy {
  //Todo: get Languages for channel or this component from one place
  languages: LanguageSelectType[] = [
    {
      id: 0,
      name: $localize`:@@LanguageCodeOriginal:Original`,
      value: '',
    },
    {
      id: 1,
      name: $localize`:@@LanguageCodeDE:Deutsch`,
      value: 'de',
    },
    {
      id: 2,
      name: $localize`:@@LanguageCodeEN:Englisch`,
      value: 'en',
    },
    {
      id: 3,
      name: $localize`:@@LanguageCodeFR:Französisch`,
      value: 'fr',
    },
    {
      id: 4,
      name: $localize`:@@LanguageCodeIT:Italienisch`,
      value: 'it',
    },
    {
      id: 5,
      name: $localize`:@@LanguageCodeES:Spanisch`,
      value: 'es',
    },
    {
      id: 5,
      name: $localize`:@@LanguageCodePL:Polnisch`,
      value: 'pl',
    },
  ];

  languageDict = this.languages.reduce((prev, curr) => {
    prev[curr.value] = curr;
    return prev;
  }, {});
  selectedLanguageControl: FormControl<string>;
  viewData$: Observable<ViewData>;
  activeTicket: ChannelDto;
  reloadSub = new BehaviorSubject<boolean>(false);
  messageListHight = 517
  private dxList: DxListComponent;
  @ViewChild(DxListComponent, { static: false }) set scrollViewRef(
    ref: DxListComponent
  ) {
    this.dxList = ref;
  }

  constructor(
    private messagingDataService: MessagingDataService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ShowOnLoginAnnouncementsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public announcement: ChannelDto
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { console.log('destroyed') }

  closeAnnoncementForNextLogin(announcement: ChannelDto) {
    this.messagingDataService.setLastReading(announcement.channelId)
    this.dialogRef.close();
  }

  closeAnnoncement() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const reload$ = this.reloadSub.asObservable();
    this.selectedLanguageControl = new FormControl();

    const ticket$ = reload$.pipe(switchMap(() => from(this.messagingDataService._hubProxy.channel(this.announcement.channelId))))
    const language$ = this.selectedLanguageControl.value$.pipe(
      filterNilValue(),
      untilDestroyed(this),
      distinctUntilChanged()
    );

    combineLatest([ticket$, language$]).subscribe();

    const currentUser$ = this.userService
      .getCurrentUser()
      .pipe(filterNilValue());


    const selectedLanguageControlValue$ = combineLatest([
      currentUser$.pipe(distinctUntilKeyChanged('id')),
      ticket$.pipe(filterNilValue(), distinctUntilKeyChanged('channelId')),
    ]).pipe(untilDestroyed(this), mapTo(this.languages[0].value));





    this.selectedLanguageControl.setValue(selectedLanguageControlValue$);

    this.selectedLanguageControl.value$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap((selectedLanguage) => {
          if (selectedLanguage) {
            this.messagingDataService.translateChannel(
              this.announcement.channelId,
              selectedLanguage
            );
            console.log('tryToReload For new Language')
            this.reloadSub.next(true)
          }
        })
      )
      .subscribe();

    this.viewData$ = combineLatest([currentUser$, ticket$]).pipe(map(([currentUser, ticket]) => {
      const viewData: ViewData = {
        currentUser: {
          ...currentUser,
          externalPostingAccounts: null,
          postingAccounts: null,
          customers: null,
        },
        ticket: ticket
      }
      return viewData
    }))

  }




}
