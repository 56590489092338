
  <dx-data-grid
    #gridContainer
    [dataSource]="dataSource"
    [showBorders]="true"
    [showColumnLines]="true"
    [remoteOperations]="true"
  >
    <dxo-editing
      mode="row"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>
    <dxi-column
      caption="ID"
      dataField="id"
      [visible]="false"
      [allowEditing]="false"
      [formItem]="{ visible: false }"
    >
    </dxi-column>
    <dxi-column
      i18n-caption="
        Spaltenname für
        Wochentage@@CustomerAdministrationLoadingLocationBusinessHoursDayOfWeekColumnTitel"
      caption="Wochentag"
      dataField="dayOfWeek"
      cellTemplate="cellTemplateDayOfWeek"
      [calculateSortValue]="getDayOfWeekSortValues"
      editCellTemplate="editCellTemplateDayOfWeek"
      sortOrder="asc"
      [sortIndex]="0"
      alignment="left"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column
      i18n-caption="
        Spaltenname für Oeffnungszeiten
        (von)@@CustomerAdministrationLoadingLocationBusinessHoursFromTimeColumnTitel"
      caption="Öffungszeiten (von)"
      dataField="fromTime"
      dataType="datetime"
      format="shortTime"
      pickerType="rollers"
      [editorOptions]="{ type: 'time' }"
      [sortIndex]="1"
      sortOrder="asc"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule
        i18n-message="
          Fehlernachricht für Oeffnungszeiten (von) Oeffnungszeit (von) muss
          kleiner sein als die Oeffnungszeit
          (bis)@@CustomerAdministrationLoadingLocationBusinessHoursFromTimeCustomValidationMessage"
        type="custom"
        message="Öffnungszeit (von) muss kleiner sein als die Öffnungszeit (bis)"
        [validationCallback]="customValidationCallbackBusinessHours"
        [reevaluate]="true"
        [ignoreEmptyValue]="true"
      >
      </dxi-validation-rule>
      
    </dxi-column>
    <dxi-column
      i18n-caption="
        Spaltenname für Oeffnungszeiten
        (bis)@@CustomerAdministrationLoadingLocationBusinessHoursToTimeColumnTitel"
      caption="Öffungszeiten (bis)"
      dataField="toTime"
      dataType="datetime"
      format="shortTime"
      pickerType="rollers"
      [editorOptions]="{ type: 'time' }"
      sortOrder="desc"
      [sortIndex]="2"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule
        i18n-message="
          Fehlernachricht für Oeffnungszeiten (bis) Oeffnungszeit (bis) muss
          größer sein als die Oeffnungszeit
          (von)@@CustomerAdministrationLoadingLocationBusinessHoursToTimeCustomValidationMessage"
        type="custom"
        message="Öffnungszeit (bis) muss größer sein als die Öffnungszeit (von)"
        [validationCallback]="customValidationCallbackBusinessHours"
        [reevaluate]="true"
        [ignoreEmptyValue]="true"
      >
      </dxi-validation-rule>
      
    </dxi-column>
    <!-- grid toolbar -->
    <dxo-toolbar>
      <dxi-item location="after">
        <div *dxTemplate>
          <button mat-stroked-button color="primary" (click)="onBulkAdd()">
            Erstellen
          </button>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <!-- Buttons for Grid-->
    <dxi-column type="buttons" name="gridButtons" class="grid-button-icons">
      <!-- edit button -->
      <dxi-button name="edit"></dxi-button>
      <!-- delete button -->
      <dxi-button name="delete"></dxi-button>
      
    </dxi-column>
    <!-- Cell template for Day of Week -->
    <div *dxTemplate="let templateDataDayOfWeek of 'cellTemplateDayOfWeek'">
      {{ templateDataDayOfWeek.value | dayOfWeek }}
    </div>
    <!-- Edit template for Day of Week -->
    <div *dxTemplate="let templateDataDayOfWeek of 'editCellTemplateDayOfWeek'">
      <!-- Day of Week Select-Box for Grid -->
      <dx-select-box
        id="duplicate-row-business-hours-day-of-week"
        [dataSource]="dayOfWeeksTypeData"
        displayExpr="display"
        valueExpr="value"
        [(value)]="templateDataDayOfWeek.value"
        (onValueChanged)="templateDataDayOfWeek.setValue($event.value)"
      >
      </dx-select-box>
    </div>
  </dx-data-grid>
  

