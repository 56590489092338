import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { filterNil } from '@datorama/akita';
import { Observable } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import {
  BalanceTransfersApiService,
  TransferDirectionFilter,
} from '../../../core/services/dpl-api-services';
import { CustomerDivisionsQuery } from '../../../customers/state/customer-divisions.query';

@Component({
  selector: 'dpl-notice-pending-tranfers',
  templateUrl: './notice-pending-tranfers.component.html',
  styleUrls: ['./notice-pending-tranfers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoticePendingTranfersComponent implements OnInit {
  pendingCount$: Observable<number>;

  constructor(
    private balanceTransferService: BalanceTransfersApiService,
    private customerDivisionsQuery: CustomerDivisionsQuery
  ) {}

  ngOnInit(): void {
    this.pendingCount$ = this.customerDivisionsQuery.selectActive().pipe(
      filterNil,
      pluck('customerId'),
      switchMap((customerId) => {
        return this.balanceTransferService.countReceivedPendingTransfers({
          customerId,
          transferDirectionFilter: TransferDirectionFilter.ReceivedAndPending,
        });
      })
    );
  }
}
