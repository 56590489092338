import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import {
  Address,
  API_BASE_URL,
  CustomerPartner,
  CustomerPartnerLookup,
  PartnerDirectoryType,
  PartnerType,
} from '@app/api/dpl';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import {
  Controls,
  NgxSubFormRemapComponent,
  subformComponentProviders,
  SubFormGroup,
} from 'ngx-sub-form';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  startWith,
  tap,
} from 'rxjs/operators';

import { APP_CONFIG, DplLiveConfiguration } from '../../../../config';
import { AppErrorHandlerService, AuthenticationService } from '../../../core';
import { CustomValidators } from '../../../validators';
import { PartnerTypePipe } from '../../pipes/partner-type.pipe';
import {
  PartnerCreateDialogComponent,
  PartnerPickerContext,
} from '../partner-create-dialog/partner-create-dialog.component';
import { CustomersService } from '../../../customers/services/customers.service';
import { filterNil } from '@datorama/akita';

export type PartnerLookupForm = {
  presetPartner: CustomerPartner;
  partner: CustomerPartnerLookup;
  address: Address;
  searchValue: string;
  type: string;
};

export type FormSync = {
  partner: CustomerPartnerLookup;
  presetPartner: CustomerPartner;
};

@Component({
  selector: 'partner-lookup',
  templateUrl: './partner-lookup.component.html',
  styleUrls: ['./partner-lookup.component.scss'],
  providers: subformComponentProviders(PartnerLookupComponent),
})
export class PartnerLookupComponent
  extends NgxSubFormRemapComponent<
    CustomerPartnerLookup | CustomerPartner,
    PartnerLookupForm
  >
  implements OnChanges, OnInit
{
  @Input() context: PartnerPickerContext;
  @Input() title: string;
  @Input() placeholder: '';
  @Input() required = false;
  dropDownBoxValue: number | any = undefined;
  @ViewChild('autocompleteInput')
  autocompleteInput: ElementRef;
  @ViewChild('autocompleteInput', {
    read: MatAutocompleteTrigger,
  })
  autocompleteTrigger: MatAutocompleteTrigger;

  protected emitInitialValueOnInit = false;

  partnersDs: DataSource;
  baseUrl: string;
  disableBtnSub = new BehaviorSubject<boolean>(true);
  disableBtn$: Observable<boolean>;
  searchValue$: Observable<string>;
  searchValue: string;

  dropDownOpened = false;
  dropDownValue: number[];

  dropDownOptions: any;

  formSync$: Observable<FormSync>;

  presetSet = false;
  presetDisplayText: string;

  directoryType = PartnerDirectoryType;
  activeCustomerId: number;

  constructor(
    @Inject(APP_CONFIG) public config: DplLiveConfiguration,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private partnerTypePipe: PartnerTypePipe,
    private appErrorHandlerService: AppErrorHandlerService,
    private customersService: CustomersService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super();
    this.baseUrl = baseUrl;
    this.customersService
      .getActiveCustomer()
      .pipe(filterNil)
      .subscribe((customer) => {
        this.activeCustomerId = customer.id;
      });

    // use this hack to add custom class to overlay to make DropDown look in Mirko's way
    this.dropDownOptions = {
      onShown: (args) => {
        args.component
          .content()
          .parentElement.parentElement.classList.add('partnerlookup-overlay');
      },
    };
  }
  ngOnInit(): void {
    this.presetSet = false;
    this.disableBtn$ = this.disableBtnSub.asObservable();

    const partnerChange$ = this.formGroup.controls.partner.valueChanges.pipe(
      startWith(this.formGroup.controls.partner.value),
      distinctUntilChanged(),
      tap((partner) => {
        this.formGroup.controls.address.reset();
        this.formGroup.controls.type.reset();
        if (partner) {
          if (partner?.address) {
            this.formGroup.controls.address.patchValue(partner.address);
          }
          if (partner?.type) {
            this.formGroup.controls.type.patchValue(
              this.partnerTypePipe.transform(partner.type)
            );
          }
          this.presetSet = false;
          this.cd.detectChanges();
        }
      })
    );

    const presetChange$ =
      this.formGroup.controls.presetPartner.valueChanges.pipe(
        startWith(this.formGroup.controls.presetPartner.value),
        distinctUntilChanged(),
        tap((presetPartner) => {
          if (presetPartner) {
            this.presetSet = true;
            this.presetDisplayText = presetPartner.companyName;
          } else {
            this.presetSet = false;
          }
        })
      );

    this.formSync$ = combineLatest([partnerChange$, presetChange$]).pipe(
      map(([partner, presetPartner]) => {
        const data: FormSync = {
          partner,
          presetPartner,
        };
        return data;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    this.setRequiredState(this.required);
    // the reason we need to disable the controls manually is
    // because a form group cannot be created in disabled state
    // this is a reactive forms limitation and also applies to sub form groups
    // hack not sure why a timeout is needed

    setTimeout(() => {
      this.formGroup.controls.address.disable();
      this.formGroup.controls.type.disable();
    }, 200);

    this.partnersDs = this.getDs();
    this.searchValue$ = this.formGroup.controls.searchValue.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((value) => {
        this.searchValue = value;
        return value;
      }),
      tap((value) => {
        if (value?.length >= this.config.app.ui.autoComplete.minLength) {
          this.partnersDs = new DataSource({
            store: AspNetData.createStore({
              key: ['id', 'directoryId'],
              loadUrl: this.baseUrl + '/partners/search',
              loadParams: {
                ...this.getDsLoadParams(),
              },
              onAjaxError: (args) => {
                this.appErrorHandlerService.onAjaxError(args);
              },
              onBeforeSend:
                this.authenticationService.addAuthHeaderForDevExpress(),
            }),
            filter: [
              ['CompanyName', 'contains', value],
              // 'or',
              // ['AddressString', 'contains', value],
            ],
          });
          this.disableBtnSub.next(false);
        }
      })
    );
  }

  getDs() {
    //inital dummy ds for dx-dropdown
    return new DataSource({
      store: AspNetData.createStore({
        key: ['id', 'directoryId'],
        loadUrl: this.baseUrl + '/partners/search',
        loadParams: {
          ...this.getDsLoadParams(),
        },
        onAjaxError: (args) => {
          this.appErrorHandlerService.onAjaxError(args);
        },
        onBeforeSend: this.authenticationService.addAuthHeaderForDevExpress(),
      }),
    });
  }

  getDsLoadParams() {
    switch (this.context) {
      case 'supplier':
        return {
          type: [PartnerType.Supplier, PartnerType.Default],
          partnerDirectoryTypes: [
            PartnerDirectoryType.Default,
            PartnerDirectoryType.Pooling,
            PartnerDirectoryType.ProcurementLogistic,
          ],
          customerId: this.activeCustomerId,
        };
      case 'shipper':
        return {
          type: [PartnerType.Shipper, PartnerType.Default],
          partnerDirectoryTypes: [
            PartnerDirectoryType.Default,
            PartnerDirectoryType.Pooling,
            PartnerDirectoryType.ProcurementLogistic,
          ],
          customerId: this.activeCustomerId,
        };
      default:
        throw $localize`:@@InvalidPickerContext:invalid picker context`;
    }
  }

  onDropDownOptionChanged(e) {
    if (e.name === 'value') {
      this.dropDownOpened = false;
      this.cd.detectChanges();
    }
  }

  onEditorPreparing(e) {
    if (e?.parentType === 'filterRow') {
      e.editorOptions.showClearButton = true;
    }
  }

  // use this hack to add custom class to overlay to make DropDown look in Mirko's way
  onRowPrepared(e) {
    if (e && e.rowType === 'group' && e.data) {
      //Maybe change to check group key (e.key) - maybe need a enum for it ('DPL Pooling' 'Standard')
      if (e.data.items && e.data.items[0]?.partnerDirectoryType) {
        switch (e.data.items[0]?.partnerDirectoryType) {
          case PartnerDirectoryType.Pooling:
            (e.rowElement as HTMLElement)?.classList.add(
              'partner-header-pooling'
            );
            break;
          case PartnerDirectoryType.ProcurementLogistic:
            (e.rowElement as HTMLElement)?.classList.add(
              'partner-header-procurement'
            );
            break;
          default:
            break;
        }
      }
      if (
        e.data.collapsedItems &&
        e.data.collapsedItems[0]?.partnerDirectoryType
      ) {
        switch (e.data.collapsedItems[0]?.partnerDirectoryType) {
          case PartnerDirectoryType.Pooling:
            (e.rowElement as HTMLElement)?.classList.add(
              'partner-header-pooling'
            );
            break;
          case PartnerDirectoryType.ProcurementLogistic:
            (e.rowElement as HTMLElement)?.classList.add(
              'partner-header-procurement'
            );
            break;
          default:
            break;
        }
      }
    }
  }

  onCreateNewPartner() {
    this.dropDownOpened = false;
    type DataType = { context: PartnerPickerContext; partner: CustomerPartner };
    const data: DataType = { context: this.context, partner: null };

    const dialog = this.dialog.open<
      PartnerCreateDialogComponent,
      DataType,
      CustomerPartner
    >(PartnerCreateDialogComponent, { data: data, width: '400px' });

    dialog
      .afterClosed()
      .pipe(
        first(),
        tap((response) => {
          if (!response) {
            return;
          }
          this.formGroup.controls.partner.patchValue(response);
        })
      )
      .subscribe();
  }

  protected getFormControls(): Controls<PartnerLookupForm> {
    return {
      partner: new UntypedFormControl({ value: null, disabled: false }),
      presetPartner: new UntypedFormControl({ value: null, disabled: false }),
      address: new SubFormGroup(null),
      type: new UntypedFormControl({ value: null, disabled: false }),
      searchValue: new UntypedFormControl({ value: null, disabled: false }),
    };
  }

  public setRequiredState(isRequired: boolean) {
    const partner = this.formGroup.controls.partner;
    partner.setValidators(
      isRequired
        ? [Validators.required, CustomValidators.isObject]
        : [CustomValidators.isObject]
    );
    partner.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  protected transformFromFormGroup(
    value: PartnerLookupForm
  ): CustomerPartnerLookup | CustomerPartner {
    if (!value) {
      return null;
    }

    if (!value.partner && !value.presetPartner) {
      return null;
    }

    if (value.presetPartner) {
      return value.presetPartner;
    }

    return value.partner;
  }

  protected transformToFormGroup(
    value: CustomerPartner,
    defaultValues: Partial<PartnerLookupForm>
  ): PartnerLookupForm {
    if (!value) {
      return defaultValues as PartnerLookupForm;
    }

    return {
      partner: null,
      presetPartner: value,
      address: value.address,
      searchValue: '',
      type: value?.type,
    };
  }

  onSelectionPartnerDataGridChanged(event) {
    this.dropDownValue =
      event?.selectedRowsData?.length > 0 ? event?.selectedRowsData[0] : null;
    this.dropDownOpened = false;
    this.cd.detectChanges();
  }

  clearSearchValue() {
    this.formGroup.controls.searchValue.reset();
  }

  isPoolingHeader(item) {
    if (!!item.data?.items) {
      return (
        item.data.items[0]?.partnerDirectoryType ===
        PartnerDirectoryType.Pooling
      );
    }
    return false;
  }

  isProcurementHeader(item) {
    if (!!item.data?.items) {
      return (
        item.data.items[0]?.partnerDirectoryType ===
        PartnerDirectoryType.ProcurementLogistic
      );
    }
    return false;
  }
}
