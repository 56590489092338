import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { MessagingChannelsQuery } from '../../../messaging-data/state/messaging-channels.query';
import { ImpersonateChatUserDto } from '../../../messaging-data/state/messaging-channels.store';

@Component({
  selector: 'dpl-responsive-member-change-dialog',
  templateUrl: './responsive-member-change-dialog.component.html',
  styleUrls: ['./responsive-member-change-dialog.component.scss'],
})
export class ResponsiveMemberChangeDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ResponsiveMemberChangeDialogComponent>,
    private messagingDataService: MessagingDataService,
    private messagingChannelsQuery: MessagingChannelsQuery,
    @Inject(MAT_DIALOG_DATA) public channel: ChannelDto
  ) {}
  possibleResponsibleUsers$?: Observable<ImpersonateChatUserDto[]>;
  userId: number;
  channelId: string;

  ngOnInit(): void {
    this.possibleResponsibleUsers$ =
      this.messagingChannelsQuery.selectPossibleEditorUsers$.pipe(
        map((users) => {
          return _.orderBy(users, (x) => x.userName, 'asc');
        })
      );
  }

  setResponsiveMember() {
    console.log(this.channelId, this.userId);
    if (this.channelId?.length > 0 && this.userId) {
      this.messagingDataService.setResponsibleUser(this.channelId, this.userId);
    } else {
      console.log('select a user');
    }
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  setRepsonsibleUser(channelId, userId: number) {
    this.channelId = channelId;
    this.userId = userId;
  }
}
