import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgxSingleFieldSubFormComponent } from '@dpl/dpl-lib';
import { subformComponentProviders } from 'ngx-sub-form';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { AccountsService } from '../../../accounts/services/accounts.service';
import { IAccount } from '../../../accounts/state/account.model';
import { LoadCarrier } from '../../../core/services/dpl-api-services';
import { ExternalLoadCarriersQuery } from '../../../master-data/external-load-carriers/state/external-load-carriers.query';
import { LoadCarriersService } from '../../../master-data/load-carriers/services/load-carriers.service';
import { ILoadCarrier } from '../../../master-data/load-carriers/state/load-carrier.model';
import { LoadCarrierPickerQuery } from './load-carrier-picker.query';

export type LoadCarrierPickerContext =
  | 'demand'
  | 'supply'
  | 'voucher'
  | 'pickup'
  | 'delivery'
  | 'deliveryReceipt'
  | 'pickupReceipt'
  | 'exchangeReceipt'
  | 'transfer'
  | 'demandSelfTransport'
  | 'supplySelfTransport'
  | 'extDemand'
  | 'extSupply'
  | 'extDemandSelfTransport'
  | 'extSupplySelfTransport'
  | 'custom';

export type LoadCarrierPickerMode = 'id' | 'loadCarrier';

export type CustomFilterFn = (
  loadCarriers: LoadCarrier[]
) => Observable<LoadCarrier[]>;

export type Conditions = keyof Pick<
  IAccount,
  | 'dropoffConditions'
  | 'pickupConditions'
  | 'transferConditions'
  | 'supplyConditions'
  | 'demandConditions'
  | 'voucherConditions'
>;

@Component({
  selector: 'load-carrier-picker',
  template: `
    <mat-form-field fxFill [formGroup]="formGroup">
      <mat-select
        placeholder="Ladungsträger"
        [formControl]="formGroup.controls.innerControl"
        i18n-placeholder="LoadCarrier|Label Ladungsträger@@LoadCarrier"
        #loadCarrier="ngForm"
        [dplHighlight]="loadCarrier"
      >
        <mat-option
          *ngFor="let loadCarrier of loadCarriers$ | async"
          [value]="mode === 'id' ? loadCarrier.id : loadCarrier"
        >
          <dpl-load-carrier-display-title
            [context]="context"
            [loadCarrier]="loadCarrier"
            *ngIf="!external"
          ></dpl-load-carrier-display-title>
          <ng-container *ngIf="external">
            <!-- external load carrier -->
            {{ loadCarrier.name }}
          </ng-container>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="formGroup.controls.innerControl.errors?.required">
        <span
          i18n="Pflichtfeld|Label Pflichtfeld@@LoadCarrierFormErrorRequiredText"
          >Pflichtfeld</span
        ></mat-error
      >
      <!-- <mat-error *ngIf="form.hasError('required')"></mat-error> -->
    </mat-form-field>
  `,
  styles: [],
  providers: subformComponentProviders(LoadCarrierPickerComponent),
})
export class LoadCarrierPickerComponent
  extends NgxSingleFieldSubFormComponent<number>
  implements OnInit, OnChanges {
  @Input() selectFirst? = false;
  @Input() allowLoadCarrierSelection? = true;
  @Input() context: LoadCarrierPickerContext;
  @Input() mode: LoadCarrierPickerMode = 'id';
  @Input() customFilter?: CustomFilterFn;
  protected emitInitialValueOnInit = false;
  loadCarriers$: Observable<any>; //ILoadCarrier[] | IExternalLoadCarrier[]
  context$ = new ReplaySubject<LoadCarrierPickerContext>();

  external: boolean;

  constructor(
    private loadCarrierService: LoadCarriersService,
    private accounts: AccountsService,
    private externalLoadCarriersQuery: ExternalLoadCarriersQuery,
    private loadCarrierPickerQuery: LoadCarrierPickerQuery
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes['context']) {
      this.context$.next(this.context);
    }

    if (this.allowLoadCarrierSelection) {
      this.formGroup.controls.innerControl.enable();
    } else {
      this.formGroup.controls.innerControl.disable();
    }
  }

  ngOnInit() {
    this.loadCarriers$ = this.context$.pipe(
      switchMap((context) => {
        if (
          this.context === 'extDemand' ||
          this.context === 'extDemandSelfTransport' ||
          this.context === 'extSupply' ||
          this.context === 'extSupplySelfTransport'
        ) {
          this.external = true;
          return this.externalLoadCarriersQuery.selectAll();
        } else {
          // ILoadCarriers
          // if (this.context === 'transfer') {
          //   const transferLoadCarriers =  this.loadCarrierPickerQuery.selectLoadCarriers();
          //   return transferLoadCarriers
          // } else {
          this.external = false;
          return this.loadCarrierService.getLoadCarriers().pipe(
            switchMap((loadCarriers) => {
              switch (context) {
                // needs, load carrier receipt
                case 'demand': {
                  return this.filterByCondition(
                    loadCarriers,
                    'demandConditions'
                  );
                }
                // availabilities, load carrier receipt
                case 'supply': {
                  return this.filterByCondition(
                    loadCarriers,
                    'supplyConditions'
                  );
                }
                // load carrier receipt
                case 'pickupReceipt': {
                  return this.filterByCondition(
                    loadCarriers,
                    'supplyConditions'
                  );
                }
                // load carrier receipt
                case 'deliveryReceipt': {
                  return this.filterByCondition(
                    loadCarriers,
                    'demandConditions'
                  );
                }
                // load carrier receipt
                case 'exchangeReceipt': {
                  return this.filterByCondition(
                    loadCarriers,
                    'demandConditions'
                  );
                }
                // offer request, map-search
                case 'pickup': {
                  return this.filterByCondition(
                    loadCarriers,
                    'pickupConditions'
                  );
                }
                // offer request, map-search
                case 'delivery': {
                  return this.filterByCondition(
                    loadCarriers,
                    'dropoffConditions'
                  );
                }
                // TODO: check if these can be removed as these are not used anywhere
                // case 'demandSelfTransport': {
                //   return this.filterByCondition(
                //     loadCarriers,
                //     'pickupConditions'
                //   );
                // }
                // case 'supplySelfTransport': {
                //   return this.filterByCondition(
                //     loadCarriers,
                //     'dropoffConditions'
                //   );
                // }
                case 'voucher': {
                  return this.filterByCondition(
                    loadCarriers,
                    'voucherConditions'
                  );
                }
                //Mapping
                case 'transfer': {
                  const transferLoadCarriers$ = this.loadCarrierPickerQuery.selectLoadCarriers();
                  return this.filterByLoadCarrierPickerStore(
                    loadCarriers,
                    transferLoadCarriers$
                  );
                }
                default:
                  return of(loadCarriers);
              }
            })
          );
          // }
        }
      }),
      tap((loadCarriers) => {
        if (!this.selectFirst) {
          return;
        }

        const loadCarrier = loadCarriers?.length > 0 ? loadCarriers[0] : null;
        if (!loadCarrier) {
          return;
        }

        this.formGroup.controls.innerControl.setValue(
          this.mode === 'id' ? loadCarrier.id : (loadCarrier as any)
        );
      })
    );
  }

  filterByCondition(loadCarriers: ILoadCarrier[], conditionsType: Conditions) {
    return this.accounts.getActiveAccount().pipe(
      filter((account) => !!account),
      map((account) => {
        const conditions = account[conditionsType];
        return loadCarriers.filter((i) =>
          conditions.some((c) => c.loadCarrierId === i.id)
        );
      })
    );
  }
  //Test
  filterByLoadCarrierPickerStore(
    loadCarriers: ILoadCarrier[],
    transferLoadCarriers$: Observable<LoadCarrier[]>
  ) {
    return transferLoadCarriers$.pipe(
      map((data) => {
        return loadCarriers.filter((i) => data.some((c) => c.id === i.id));
      })
    );
  }

  protected getFormControl() {
    return new UntypedFormControl(null, Validators.required);
  }
}
