import { Component, Input } from '@angular/core';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { ChatChannelType } from '../../../generated/Dpl.B2b.Common.Enumerations';
import { MatDialog } from '@angular/material/dialog';
import { first, switchMap, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../../../../libs/dpl-lib/src/lib/services';
import { MessagingSystemDialogComponent } from '../messaging-system-dialog/messaging-system-dialog.component';

@Component({
  selector: 'dpl-messaging-system-button',
  templateUrl: './messaging-system-button.component.html',
  styleUrls: ['./messaging-system-button.component.scss'],
})
export class MessagingSystemButtonComponent {
  @Input() referenceNumber: string;
  @Input() type: ChatChannelType;
  @Input() isIssuer?: boolean = false;
  @Input() isRecipient?: boolean = false;
  constructor(
    public messagingDataService: MessagingDataService,
    private dialog: MatDialog,
    private loadingService: LoadingService
  ) { }

  createTicket() {
    event.preventDefault();
    const connection = this.messagingDataService.checkSignalRConnection()
    if(connection){
      this.messagingDataService
      .createTicket(`${this.referenceNumber}`, this.type)
      .pipe(
        first(),
        this.loadingService.showLoadingWhile(),
        tap((ticket) => {
          this.openTicketDialog(ticket.channelId);
        })
      )
      .subscribe();
    }else{
      //TODO show an error or inform the user maybe try to reconnect
      console.log('SignalR is not in connected state')
    }
    
  }

  openTicketDialog(channelId: string) {

    this.messagingDataService.setTicketActive(channelId);
    this.dialog
      .open<MessagingSystemDialogComponent>(MessagingSystemDialogComponent, {
        minWidth: '600px',
        maxWidth: '1200px',
        width: '40%',
        minHeight: '856px',
        maxHeight: '1000px',
        panelClass: 'messaging-system-dialog-panel',
        disableClose: true,
        // data: this.selectedChannel,
      })
      .afterClosed()
      .pipe(first())
      .subscribe();
  }
}
