<ng-container *ngIf="viewData$ | async as data">
  <div fxLayoutAlign="space-between center" fxLayout="row">
    <div class="single-file-upload-button">
      <!--OnUploadError need to checked if it works-->
      <dx-file-uploader
        [maxFileSize]="maxFileSize"
        [uploadMode]="uploadMode"
        [allowedFileExtensions]="allowedFileExtensions"
        [multiple]="allowMultipleFileUpload"
        [maxFileCount]="MAX_UPLOAD_COUNT"
        [chunkSize]="chunkSize"
        [uploadChunk]="uploadChunk"
        [allowCanceling]="allowCanceling"
        [abortUpload]="onAbortUpload"
        (onValueChanged)="onValueChanged($event)"
        (onUploaded)="onUploaded($event)"
        (onUploadStarted)="onUploadStarted($event)"
        (onFilesUploaded)="filesUploaded($event)"
        [selectButtonText]="selectButtonText"
        labelText=""
        invalidFileExtensionMessage="Der Dateitype ist nicht erlaubt!"
        invalidMaxFileSizeMessage="Die Datei ist zu groß!"
        uploadFailedMessage="Das Hochladen ist Fehlgeschlagen!"
        i18n-invalidFileExtensionMessage="
          Fehleranzeige Der Dateitype ist nicht erlaubt!
          @@SingleUploadFormFileUploaderInvalidFileExtensionMessage"
        i18n-invalidMaxFileSizeMessage="
          Fehleranzeige Die Datei ist zu groß!
          @@SingleUploadFormFileUploaderInvalidMaxFileSizeMessage"
        i18n-uploadFailedMessage="
          Fehleranzeige Das Hochladen ist Fehlgeschlagen!
          @@SingleUploadFormFileUploaderUploadFailedMessage"
        [showFileList]="showFileList"
      ></dx-file-uploader>
    </div>
  </div>
</ng-container>
