import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
  isLoggedInAndNotExpired: boolean | undefined;
  name: string;
  email: string;
}

export function createInitialSessionState(): SessionState {
  return <SessionState>{
    name: null,
    email: null,
    isLoggedInAndNotExpired: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialSessionState());
  }

  login(session: SessionState) {
    this.update(session);
  }

  logout() {
    this.update({ isLoggedInAndNotExpired: false, name: null, email: null });
  }
}
