import { Injectable } from "@angular/core";
import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { BalanceTransferLimitation } from "../../core/services/dpl-api-services";

export interface TransferLimitationState
  extends EntityState<BalanceTransferLimitation, number>,
    ActiveState<number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'transfer-limitations', idKey: "refLtmsAccountId"  })
export class TransferLimitationStore extends EntityStore<TransferLimitationState> {
  constructor() {
    super();
  }
}
