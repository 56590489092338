import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import {
  MessagingChannelListState,
  MessagingChannelListStore,
} from './messaging-channel-list.store';

@Injectable()
export class MessagingChannelListQuery extends QueryEntity<MessagingChannelListState> {
  constructor(protected store: MessagingChannelListStore) {
    super(store);
  }
}
