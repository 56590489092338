export /**
        * The idxIgnoreCase function returns the value of a property in an object,
        * ignoring case.
        *
        * @example var d= idxIgnoreCase(LoadCarrierReceiptContextType, "dEPOt")
        * @param obj: T Ensure that the function can only be called with an object
        * @param k: K extends (
           Uppercase&lt;K&gt; extends Uppercase&lt;Extract&lt;keyof T Ensure that the key passed in is a valid key for the object
        * @param string&gt;&gt; ? unknown : never
           ) ? K : Extract&lt;keyof T Make sure that the key is a string
        * @param string&gt; Narrow the type of k
        *
        * @return The value of the property
        *
        * @docauthor AS
        */
       function idxIgnoreCase<K extends string, T extends object>(
  obj: T,
  k: K extends (
    Uppercase<K> extends Uppercase<Extract<keyof T, string>> ? unknown : never
    ) ? K : Extract<keyof T, string>
): {
  [P in Extract<keyof T, string>]-?:
  Uppercase<P> extends Uppercase<K> ? T[P] : never
}[Extract<keyof T, string>] {
  return Object.entries(obj).find(
    ([p, v]) => k.toUpperCase() === p.toUpperCase())?.[1] as any;
}
