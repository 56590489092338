import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { AuthGuard } from './core/auth.guard';
import {
  PermissionResourceType,
  ResourceAction,
} from './core/services/dpl-api-services';
import { LinkComponent } from './link/link.component';
import { PrivacyComponent, SupportComponent } from './shared';
import { SingleUploadFormComponent } from './shared/components/single-upload-form/single-upload-form.component';
import { EmployeeGuard } from './shared/guards/employee.guard';
import { PermissionGuard } from './shared/guards/permission.guard';
import { DefaultContentComponent } from './shared/layout/components/default-content/default-content.component';
import { HomeComponent } from './shared/layout/components/home/home.component';
import { ImprintComponent } from './shared/layout/components/imprint/imprint.component';
import { MessagingSystemComponent } from './messaging-system/messaging-system.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'imprint',
    pathMatch: 'full',
    component: ImprintComponent,
  },
  {
    path: 'privacy',
    pathMatch: 'full',
    component: PrivacyComponent,
  },
  {
    path: 'support',
    pathMatch: 'full',
    component: SupportComponent,
  },
  {
    path: 'service-health',
    loadChildren: () =>
      import('./service-health/service-health.module').then(
        (m) => m.ServiceHealthModule
      ),
  },
  {
    path: 'link',
    component: LinkComponent,
  },
  {
    path: '',
    canActivate: [MsalGuard, AuthGuard],
    children: [
      {
        path: 'messaging-system',
        children: [
          {
            path: '',
            component: MessagingSystemComponent,
          },
        ],
      },
      {
        path: '',
        component: DefaultContentComponent,
        children: [
          {
            path: 'accounts',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadAccountingRecords,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./accounts/accounts.module').then(
                (m) => m.AccountsModule
              ),
          },
          {
            path: 'transfers',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadBalanceTransfer,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./balance-transfers/balance-transfers.module').then(
                (m) => m.BalanceTransfersModule
              ),
          },
          {
            path: '',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.CreateOrder,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./availabilities/availabilities.module').then(
                (m) => m.AvailabilitiesModule
              ),
          },
          {
            path: 'orders',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadOrder,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./orders/orders.module').then((m) => m.OrdersModule),
          },
          {
            path: 'order-loads',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadOrderLoad,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./order-loads/order-loads.module').then(
                (m) => m.OrderLoadsModule
              ),
          },
          {
            path: 'receipt',

            loadChildren: () =>
              import('./load-carrier-receipt/load-carrier-receipt.module').then(
                (m) => m.LoadCarrierReceiptModule
              ),
          },
          {
            path: 'ext-receipt',
            loadChildren: () =>
              import(
                './ext-load-carrier-receipt/ext-load-carrier-receipt.module'
              ).then((m) => m.ExtLoadCarrierReceiptModule),
          },
          {
            path: 'transfer',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.CreateBalanceTransfer,
              resource: PermissionResourceType.Division,
            },

            loadChildren: () =>
              import('./transfer/transfer.module').then(
                (m) => m.TransferModule
              ),
          },
          {
            path: 'transports',
            // TODO add permission guard as soon as we have permission actions for this
            loadChildren: () =>
              import('./transports/transports.module').then(
                (m) => m.TransportsModule
              ),
          },
          {
            path: 'voucher',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.CreateVoucher,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./voucher/voucher.module').then((m) => m.VoucherModule),
          },
          {
            path: 'voucher-register',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadVoucher,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./voucher-register/voucher-register.module').then(
                (m) => m.VoucherRegisterModule
              ),
          },
          {
            path: 'voucher/export',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ExportVoucher,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./voucher-export/voucher-export.module').then(
                (m) => m.VoucherExportModule
              ),
          },
          {
            path: 'search',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.CreateLivePoolingSearch,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./search/search.module').then((m) => m.SearchModule),
          },
          {
            path: 'load-carrier-receipts',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadLoadCarrierReceipt,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import(
                './load-carrier-receipts/load-carrier-receipts.module'
              ).then((m) => m.LoadCarrierReceiptsModule),
          },
          {
            path: 'receipts-export',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ExportLoadCarrierReceipt,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import(
                './load-carrier-recepits-export/load-carrier-receipts-export.module'
              ).then((m) => m.LoadCarrierReceiptsExportModule),
          },
          {
            path: 'sorting',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.ReadLoadCarrierReceipt,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./sorting/sorting.module').then((m) => m.SortingModule),
          },
          {
            path: 'offers',
            canActivate: [PermissionGuard],
            data: {
              action: [
                ResourceAction.UICreateNeedSelfTransport,
                ResourceAction.UIListOffer,
              ],
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./offers/offers.module').then((m) => m.OffersModule),
          },

          {
            path: 'process-multi',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UIProcessMultiUpload,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./process-multi/process-multi.module').then(
                (m) => m.ProcessMultiModule
              ),
          },
          {
            path: 'self-administration-partners',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UISelfAdministrationPartners,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import(
                './self-administration-partners/self-administration-partners.module'
              ).then((m) => m.SelfAdministrationPartnersModule),
          },
          {
            path: 'self-administration',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UISelfAdministrationUsers,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./self-administration/self-administration.module').then(
                (m) => m.SelfAdministrationModule
              ),
          },
          {
            path: 'voucher-submission',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UIVoucherSubmission,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./voucher-submission/voucher-submission.module').then(
                (m) => m.VoucherSubmissionModule
              ),
          },
          {
            path: 'submission-overview',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UIVoucherSubmission,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import('./submission-overview/submission-overview.module').then(
                (m) => m.SubmissionOverviewModule
              ),
          },
          {
            path: 'submissions-deliverynotes',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UIVoucherSubmission,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import(
                './submissions-deliverynotes/submissions-deliverynotes.module'
              ).then((m) => m.SubmissionsDeliveryNotesModule),
          },
          {
            path: 'absence',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UISelfAdministrationAbsence,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import(
                './loading-location-absence/loading-location-absence.module'
              ).then((m) => m.LoadingLocationAbsenceModule),
          },
          {
            path: 'depot-overview',
            canActivate: [PermissionGuard],
            data: {
              action: ResourceAction.UIDepotOverview,
              resource: PermissionResourceType.Division,
            },
            loadChildren: () =>
              import(
                './loading-location-absence-overview/loading-location-absence-overview.module'
              ).then((m) => m.LoadingLocationAbsenceOverviewModule),
          },
        ],
      },
    ],
  },
  {
    path: '',
    canActivate: [MsalGuard, AuthGuard, EmployeeGuard],
    children: [
      {
        path: 'customer-administration',
        loadChildren: () =>
          import(
            './customer-administration/customer-administration.module'
          ).then((m) => m.CustomerAdministrationModule),
      },
      {
        path: 'address-administration',
        loadChildren: () =>
          import('./address-administration/address-administration.module').then(
            (m) => m.AddressAdministrationModule
          ),
      },
      {
        path: 'announcements',
        loadChildren: () =>
          import('./announcements/announcements.module').then(
            (m) => m.AnnouncementsModule
          ),
      },
      {
        path: 'partner-administration',
        loadChildren: () =>
          import('./partner-administration/partner-administration.module').then(
            (m) => m.PartnerAdministrationModule
          ),
      },
      {
        path: 'expresscode-administration',
        loadChildren: () =>
          import(
            './expresscode-administration/expresscode-administration.module'
          ).then((m) => m.ExpressCodeAdministrationModule),
      },
      {
        path: 'scenario-administration',
        loadChildren: () =>
          import(
            './scenario-administration/scenario-administration.module'
          ).then((m) => m.ScenarioAdministrationModule),
      },
      {
        path: 'document-upload',
        component: SingleUploadFormComponent,
      },
      {
        path: 'reporting',
        loadChildren: () =>
          import('./reporting/reporting.module').then(
            (mod) => mod.ReportingModule
          ),
      },
      {
        path: 'upload-process-create',
        loadChildren: () =>
          import('./upload-process-create/upload-process-create.module').then(
            (m) => m.UploadProcessCreateModule
          ),
      },
      {
        path: 'order-access-group-administration',
        loadChildren: () =>
          import(
            './order-access-group-administration/order-access-group-administration.module'
          ).then((m) => m.OrderAccessGroupAdministrationModule),
      },
      {
        path: 'loading-location-administration',
        loadChildren: () =>
          import(
            './loading-location-administration/loading-location-administration.module'
          ).then((m) => m.LoadingLocationAdministrationModule),
      },
    ],
  },

  {
    path: 'process-confirmation/:token',
    loadChildren: () =>
      import('./process-confirmation/process-confirmation.module').then(
        (m) => m.ProcessConfirmationModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
