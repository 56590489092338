import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { User } from '../../../core/services/dpl-api-services';
import { DxListComponent } from 'devextreme-angular';

@Component({
  selector: 'dpl-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
})
export class MessageListComponent {
  @Input() messages: MessageDto[];
  @Input() height: number;
  @Input() currentUser: User;
  @Input() selectedLanguage: string;
  @Output() copiedMessageText = new EventEmitter<any>();
  @ViewChild(DxListComponent, { static: false }) set scrollViewRef(
    ref: DxListComponent
  ) {
    this.oldDxList = this.dxList
    this.dxList = ref;
  }
  private oldDxList: DxListComponent;
  private dxList: DxListComponent;



  scrollToEnd() {
    if (
      this.dxList &&
      this.dxList.instance &&
      this.dxList.instance.option('items')
    ) {
      setTimeout(() => {
        const scrollHeight = this.dxList.instance.scrollHeight();
        this.dxList.instance.scrollBy(scrollHeight);
      }, 100);
    } else {
      console.log("tryAgainScrollToEnd")
      //If dxList is undefined
      setTimeout(() => {
        this.scrollToEnd();
      }, 100);
    }
  }

  onCopyMessage(e) {
    this.copiedMessageText.emit(e);
  }

  onMessageListContentReady(event) {
    //Maybe this fix the scrolling but it needs a test
    // console.log('contentReady Message-List now scroll to End', event)
    this.scrollToEnd()
  }
}
