import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, pluck, switchMap, tap } from 'rxjs/operators';
import {
  ChatChannelState,
  ChatChannelType,
} from '../../../generated/Dpl.B2b.Common.Enumerations';
import { ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { LanguagesService } from '../../../master-data/languages/services/languages.service';
import { ILanguage } from '../../../master-data/languages/state/language.model';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { UserService } from '../../../user/services/user.service';
import { ChangeChannelStateDialogComponent } from '../change-channel-state-dialog/change-channel-state-dialog.component';
import { ResponsiveMemberChangeDialogComponent } from '../responsive-member-change-dialog/responsive-member-change-dialog.component';
import { MessagingChannelListService } from '../../services/messaging-channel-list.service';
import { filterNilValue } from '@datorama/akita';
import ArrayStore from 'devextreme/data/array_store';
import _ from 'lodash';
import { FormControl } from '@ngneat/reactive-forms';

type ViewData = {

  ticketsStore: ArrayStore;
  isEmployee: boolean;
  activeLanguage: ILanguage;
  currentUserId: number;
};

@Component({
  selector: 'dpl-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelListComponent implements OnInit {
  @Output() hideList: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('selectBox') selectBox: DxSelectBoxComponent;
  viewData$: Observable<ViewData>;
  sortedItems: ChannelDto[];
  sortableFields = [
    {
      id: 'lastMessage.timestemp',
      text: $localize`:@@LastMessageTimeStempTicketSort:Letzte Nachricht`,
    },
    {
      id: 'unreadCount',
      text: $localize`:@@UnreadCountTicketSort:Ungelesene Nachrichten`,
    },
    {
      id: 'creator.timestemp',
      text: $localize`:@@CreateTicketTimeStempTicketSort:Erstelldatum`,
    },
  ];
  currentSortOption;
  channelState = ChatChannelState;

  popupVisible = false;
  popupVisibleResponsiveMember = false;
  popupVisibleDeleteChannel = false;
  reloadSub = new BehaviorSubject(false);
  activeChannelId$: Observable<string[]>;
  sortOrder: 'asc' | 'desc' = 'desc';

  selectedSortingControl: FormControl<string>;


  sortingDict = this.sortableFields.reduce((prev, curr) => {
    prev[curr.id] = curr;
    return prev;
  }, {});


  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private languageService: LanguagesService,
    private messagingDataService: MessagingDataService,
    private messagingChannelListService: MessagingChannelListService
  ) { }
  ngOnInit(): void {
    this.activeChannelId$ = this.messagingDataService
      .getTicketActiveNullable()
      .pipe(map((ticket) => (ticket ? [ticket.channelId] : [])));
    const reload$ = this.reloadSub.asObservable();
    const isEmployee$ = this.userService.getIsDplEmployee();
    const userId$ = this.userService
      .getCurrentUser()
      .pipe(filterNilValue(), pluck('id'));
    const activeChannelListKey$ = this.messagingChannelListService
      .getActiveChannelKey()
    const tickets$ = activeChannelListKey$
      .pipe(
        switchMap((activeChannelKey) => {
          console.log('activeChannelKey', activeChannelKey);

          return this.messagingDataService.ticketsAll(
            activeChannelKey === this.messagingChannelListService.allChannelKey
              ? undefined
              : ChatChannelType[activeChannelKey]
          );
        })
      );

    this.selectedSortingControl = new FormControl();

    const currentLanguage$ = this.languageService
      .getActiveLanguage()
      .pipe(first());

    this.viewData$ = combineLatest([
      isEmployee$,
      tickets$,
      activeChannelListKey$,
      currentLanguage$,
      userId$,
      reload$,
    ]).pipe(
      map(([isEmployee, tickets, activeChannelListKey, activeLanguage, userId, reload]) => {
        const viewData: ViewData = {
          isEmployee,

          ticketsStore: new ArrayStore({
            data: this.sortItems(activeChannelListKey !== 'Announcement' ? tickets.filter(value => value.channelType !== ChatChannelType.Announcement) : tickets),
            key: 'channelId',
          }),
          activeLanguage,
          currentUserId: userId,
        };
        return viewData;
      })
    );

    this.currentSortOption = this.sortableFields[0].id;
  }

  onSortOptionChanged(e) {
    const selectedSortOption = e.value;
    this.currentSortOption = selectedSortOption;
    this.reloadSub.next(true);
  }

  changeSortOrder() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.reloadSub.next(true);
  }

  sortItems(channels: ChannelDto[]): ChannelDto[] {
    if (
      !this.currentSortOption ||
      this.currentSortOption === 'lastMessage.timestemp'
    ) {
      return _.orderBy(
        channels,
        (x) => x.messages[x.messageCount - 1]?.creator?.timestamp,
        this.sortOrder
      );
    } else if (this.currentSortOption === 'creator.timestemp') {
      return _.orderBy(channels, (x) => x.creator?.timestamp, this.sortOrder);
    } else if (this.currentSortOption === 'unreadCount') {
      return _.orderBy(channels, (x) => x.unreadCount, this.sortOrder);
    } else if (this.currentSortOption === 'messageCount') {
      return _.orderBy(channels, (x) => x.messageCount, this.sortOrder);
    }
  }

  onSelectionChanged(e) {
    console.log('onSelectionChanged', e);

    if (e.addedItems?.length > 0) {
      this.messagingDataService.setTicketActive(e.addedItems[0].channelId);
    }
  }

  selectChannel(channelId: string) {
    this.messagingDataService.setTicketActive(channelId);
  }

  deleteChannel() {
    console.log('delete channel clicked, not implemented');
  }

  openChannelStateDialog(ticket: ChannelDto, event) {
    event.stopPropagation();
    this.dialog.open<ChangeChannelStateDialogComponent>(
      ChangeChannelStateDialogComponent,
      {
        minWidth: '250px',
        maxWidth: '800px',
        width: '25%',
        panelClass: 'messaging-system-dialog-panel',
        data: ticket,
        disableClose: true,
      }
    );
  }

  getCompanyInfoText(dataSource) {
    if (dataSource.channelType !== 1000) {
      const splittedInfo: string[] = dataSource.channelCompanyInfoText.split(' / ');
      return splittedInfo[1] + ' / ' + splittedInfo[2];
    } else {
      return ''
    }
  }

  openResposiveMemberDialog(ticket: ChannelDto, event) {
    event.stopPropagation();
    this.dialog.open<ResponsiveMemberChangeDialogComponent>(
      ResponsiveMemberChangeDialogComponent,
      {
        minWidth: '250px',
        maxWidth: '800px',
        width: '25%',
        panelClass: 'messaging-system-dialog-panel',
        data: ticket,
        disableClose: true,
      }
    );
  }

  onSpinLeft() {
    this.hideList.emit(true);
  }

  getSelectedTypeList() {
    return this.messagingChannelListService.getActiveChannelTypeText();
  }

  getMessageRequiredActionTag(channelId: string) {
    return this.messagingDataService.getEmployeeActionRequiredTicket(channelId);
  }

  getSortOrderHint(sortOrder: 'asc' | 'desc'): string {
    return sortOrder === 'desc' ?
      $localize`:@@SortOrderDESC:Absteigend sortieren` :
      $localize`:@@SortOrderASC:Aufsteigend sortieren`
  }
}
