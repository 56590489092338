import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import _ from 'lodash';

import { DayOfWeek } from '../../../core/services/dpl-api-services';
import { LoadingLocationDialogData } from '../../../loading-locations/components/loading-location-create-dialog/loading-location-create-dialog.component';
import { DayOfWeekPipe } from '../../../shared';
import { getWeekdaySort } from '../../../shared/utils';

export interface LoadingLocationBusinessHoursBulkAddDialogData {}

export interface LoadingLocationBusinessHoursBulkAddDialogResult {
  daysOfWeek: DayOfWeek[];
  fromTime: Date;
  toTime: Date;
}

@Component({
  selector: 'dpl-loading-location-business-hours-bulk-add-dialog',
  templateUrl:
    './loading-location-business-hours-bulk-add-dialog.component.html',
  styleUrls: [
    './loading-location-business-hours-bulk-add-dialog.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLocationBusinessHoursBulkAddDialogComponent
  implements OnInit
{
  form: FormGroup<LoadingLocationBusinessHoursBulkAddDialogResult>;

  constructor(
    public dialogRef: MatDialogRef<
      LoadingLocationBusinessHoursBulkAddDialogComponent,
      LoadingLocationBusinessHoursBulkAddDialogResult
    >,
    private fb: FormBuilder,
    private dayOfWeekPipe: DayOfWeekPipe,
    @Inject(MAT_DIALOG_DATA) data: LoadingLocationDialogData
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      //ToDo add validation
      daysOfWeek: new UntypedFormControl(this.getDefaultDaysOfWeek(), Validators.required),
      fromTime: new UntypedFormControl(null, Validators.required),
      toTime: new UntypedFormControl(null, Validators.required),
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.form.value);
  }

  getDayOfWeekData() {
    return _.orderBy(
      Object.keys(DayOfWeek).map((value, id) => {
        value = DayOfWeek[value as any];

        const display = this.dayOfWeekPipe.transform(value, 'long');
        return { id, value, display, sort: getWeekdaySort(DayOfWeek[value]) };
      }),
      (x) => x.sort
    );
  }

  getDefaultDaysOfWeek() {
    return [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
    ];
  }
}
