import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first, switchMap, tap } from 'rxjs/operators';

import { LoadingService } from '../../../../../../../libs/dpl-lib/src';
import { DplApiService } from '../../../core';
import {
  OrderStatus,
  OrderTransportType,
  ResourceAction,
} from '../../../core/services/dpl-api-services';
import { CustomerDivisionsService } from '../../../customers/services/customer-divisions.service';
import { PrintContext, PrintService } from '../../services';
import { SendEmailDialogComponent } from '../send-email-dialog/send-email-dialog.component';

@Component({
  selector: 'dpl-transport-voucher-buttons',
  templateUrl: './transport-voucher-buttons.component.html',
  styleUrls: ['./transport-voucher-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportVoucherButtonsComponent implements OnInit {
  @Input() orderId: number;
  @Input() digitalCode: string;
  @Input() status: OrderStatus;
  @Input() transportType: OrderTransportType;
  orderTransportType = OrderTransportType;
  orderStatus = OrderStatus;
  resourceAction = ResourceAction;

  constructor(
    private dialog: MatDialog,
    private dpl: DplApiService,
    private loadingService: LoadingService,
    private division: CustomerDivisionsService,
    private printService: PrintService,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  ngOnInit(): void {}

  onPrintDocument() {
    this.division
      .getActiveDivision()
      .pipe(
        first(),
        switchMap((division) => {
          if (!division) {
            throw $localize`:@@ActiveDivisionNeedTobeSetCreatingVoucher:Active division needs to be set when creating voucher`;
          }
          return this.dpl.transportVouchersService
            .print(division.id, this.digitalCode, {
              printDateTimeOffset: new Date().getTimezoneOffset(),
              localeId: this.localeId,
            })
            .pipe(
              this.loadingService.showLoadingWhile(),
              switchMap((transportVoucher) => {
                return this.printService.printUrl(
                  transportVoucher.downloadLink,
                  false,
                  PrintContext.TransportVoucher,
                  transportVoucher
                );
              })
            );
        })
      )
      .pipe(first())
      .subscribe();
  }

  onSendEmailDocument() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      orderId: this.orderId,
      digitalCode: this.digitalCode,
    };

    const dialog = this.dialog.open(SendEmailDialogComponent, dialogConfig);

    dialog
      .afterClosed()
      .pipe(
        first(),
        tap((response) => {
          console.log('Dialog Rückgabe', response);
        })
      )
      .subscribe();
  }
}
