<button
  mat-icon-button
  *ngIf="infoText && fieldName"
  id="{{ 'poBtn' + fieldName }}"
  (mouseenter)="popOverVisible = true"
  (mouseleave)="popOverVisible = false"
>
  <mat-icon color="primary">info</mat-icon>
</button>
<dx-popover
  target="{{ '#poBtn' + fieldName }}"
  position="bottom"
  [(visible)]="popOverVisible"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <span>{{ infoText }}</span>
  </div>
</dx-popover>
