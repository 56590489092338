import {
  Address,
  BusinessHours,
  DayOfWeek, Demand,
  LoadCarrier,
  LoadCarrierOffering,
  LoadCarrierOfferingDetail,
  LoadingLocation,
  OfferingsApiService,
  Order,
  OrderGroupsCreateRequest,
  OrderProposal,
  OrderQuantityType
} from '@app/api/dpl';

import { ILoadCarrier } from '../master-data/load-carriers/state/load-carrier.model';
import { IGeoPoint } from '../shared';
import { IOrderProposal, ProposalState } from './state/proposals.store';

export interface OfferRequest extends Order {
  requestStatus?: OfferRequestStatus;
  locations: OfferRequestLocation[];
  options?: OfferOption[];
  timing: {
    type: 'single' | 'series';
    dates: Date[];
    daysOfWeek?: DayOfWeek[];
    startWeek?: number;
    numberOfWeeks?: number;
  };
}

export interface OfferRequestCreateRequest extends OrderGroupsCreateRequest {
  locations: OfferRequestLocation[];
  options?: OfferOption[];
  timing: {
    type: 'single' | 'series';
    dates: Date[];
    daysOfWeek?: DayOfWeek[];
    startWeek?: number;
    numberOfWeeks?: number;
  };
}

export interface OfferRequestLocation {
  postalCode?: string; // plz bereich
  addressText?: string; // text zum anzeigen über google box ausgewählt
  geoPoint?: [number, number]; // lat, lng oder umgekehrt
  radius?: number; // meters // nur bei ortseingabe
}

export interface OfferGeneralOption extends Omit<OfferOption, 'hasDifference'> {
  personal: boolean;
}

export interface OfferOption extends LoadCarrierOfferingDetail {
  loadCarrierId: number;
  loadCarrierQuantity: number;
  //from LoadCarrierOffering
  address?: Address | null;
  lat?: number;
  lng?: number;
  businessHours?: BusinessHours[] | null;
  // from "offer"
  expiresAt: Date;
  optionStatus: OfferOptionStatus;
  hasDifference: boolean;
}

// nur für angebote und hotspot angebote
export interface Offer {
  id: number;
  createdAt: Date;
  isDeclined: boolean;
  type: 'request' | 'hotspot';
  customerId?: number; // isu used when type = request
  recipients?: number[]; // user ids, are used when type = 'hotspot'
  expiresAt: Date;
  options: LoadCarrierOffering[];
}

type SearchLoadCarrierOfferingsRequest = Parameters<
  OfferingsApiService['searchLoadCarrierOfferings']
>[0];

// lösen wir im client auf basirend auf offer request
export interface AutoOfferRequest extends SearchLoadCarrierOfferingsRequest {
  dateFrom: Date; // entweder date range oder date array ist gefüllt
  dateTo: Date; // entweder date range oder date array ist gefüllt
}

export enum OfferOptionStatus {
  open = 'open',
  accepted = 'accepted',
  declined = 'declined',
}
export enum OfferRequestStatus {
  open = 'open',
  accepted = 'accepted',
  declined = 'declined',
}

export enum CheckStatus {
  PartialAccepted = 'PartialAccepted',
  NoOpen = 'NoOpen',
  PartialDeclined = 'PartialDeclined',
  AllDeclined = 'AllDeclined',
  AllOpen = 'AllOpen',
}

export enum StatusCheckAction {
  Close = 'Close',
  Retry = 'Retry',
  DeclineOpen = 'DeclineOpen',
  CancelRequest = 'CancelRequest',
}

// dialog types
export interface OptionsDialogData {
  order: Demand;
}

export enum OfferContext {
  proposal = 'proposal',
  personal = 'personal',
  hotspot = 'hotspot',
}

export interface OptionsDialogResult {
  proposals: IOrderProposal[];
  cancelOrder?:boolean;  
}

export interface OfferRequestFormHeader {
  locations: {
    type: 'postalCode' | 'address';
    postalCode?: Pick<OfferRequestLocation, 'radius'> & {
      postalCode: string;
    }
    address?: Pick<OfferRequestLocation, 'radius'> & {
      lookup: {
        searchText: string;
        geoPoint: IGeoPoint<any>;
      };
    };
  }[];
  loadCarrierInfo: Partial<{
    loadCarrier: ILoadCarrier;
    quantityType: OrderQuantityType;
    numberOfStacks: number;
    supportsPartialMatching: boolean;
    stackHeightMin: number;
    loadCarrierQuantity: number;
    loadCarrierQuantityPerLoad: number;
    quantityPerEur: number;
    baseLoadCarrier: LoadCarrier;
    baseLoadCarrierQuantity: number;
  }>;
  loadingInfo: {
    supportsRearLoading?: boolean;
    supportsSideLoading?: boolean;
    supportsJumboVehicles?: boolean;
  };
}
export interface OfferRequestForm {
  header: OfferRequestFormHeader;
  positions: {
    type: 'single' | 'series';
    baseInfo: OfferRequestFormHeader;
    numberOfLoads: number;
    timing: {
      single?: {
        calendarWeekStart: Date;
        dates: Date[] | null;
      };
      series?: {
        daysOfWeek: DayOfWeek[] | null;
        startWeek: number;
        numberOfWeeks: number;
      };
    };
    customerReference?:string;
    orderInformation?:string;
    note?:string;
  }[];
}

export type OfferRequestAction = 'pickup' | 'delivery';

export interface OfferDayPickerDialogData {
  from: Date;
  to: Date;
  loadingLocation?: LoadingLocation;
  context: OfferContext;
  holidays:Date[];
}

export interface OfferDayPickerDialogResult {
  date: Date;
}

export enum ProposalCheckResponse {
  Reject = 'Reject',
  Reserve = 'Reserve',
  Back = 'Back',
  Cancel = 'Cancel',
  NoAction = 'NoAction',
}

export enum ProposalActionType {
  Accept='Accept',
  Decline = "Decline",
  Reserve = 'Reserve'
}

export interface OfferAdditonalInformationDialogData{
  date: Date;
}

export interface OfferAdditonalInformationDialogResponse{
  customerReference:string;
  orderInformation:string;
}
