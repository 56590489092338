import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';
import { IExternalPostingAccount } from './external-posting-account.model';

export interface ExternalPostingAccountsState
  extends EntityState<IExternalPostingAccount, number>,
    ActiveState<number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'external-posting-accounts' })
export class ExternalPostingAccountsStore extends EntityStore<ExternalPostingAccountsState> {
  constructor() {
    super();
  }
}
