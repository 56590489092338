<ng-container *ngIf="viewData$ | async as data">
  <div
    *ngIf="data.basket?.items?.length > 0; else basketEmpty"
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <!-- <mat-toolbar color="primary">
    <span class="fill-remaining-space">Basket</span>
    <button mat-icon-button (click)="onClearAll()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-toolbar> -->
    <div>
      <mat-list>
        <mat-list-item
          *ngFor="let item of data.basket.items; let i = index"
          [ngClass]="selected === item.id ? 'selected' : ''"
          (click)="onSelected(item)"
          style="height: 100px; "
        >
          <div fxLayout="row" matListItemLine fxLayoutAlign="space-between center" >
            <div fxLayout="column" style="color:black;">
              <h3 matLine *ngIf="item.title">
                {{ item.title }}
              </h3>

              <!-- hack to show guid -->
              <div style="font-size: 10px" matLine>
                <span i18n="@@BasketListShowLmsOrderId">ID </span
                >{{ item.data?.lmsOrderId }}
              </div>

              <div matLine *ngFor="let subTitle of item.subTitles">
                {{ subTitle }}
              </div>
            </div>
            <div>
              <!--
        Please note its not possible to wrap elemnts in ng-container or ng-template
        as otherwise material list styles will not work
        thats why we have to put an ngIf in every block below
       -->
              <ng-container *ngIf="!item.response">
                <button
                  mat-icon-button
                  (click)="onRemoveFromBasket(item); $event.stopPropagation()"
                >
                  <mat-icon matListIcon>clear</mat-icon>
                </button>
              </ng-container>

              <p matLine *ngIf="item.response">
                <mat-error
                  matLine
                  class="break-word"
                  *ngFor="let message of item.response.errorMessages"
                  >{{ message }}</mat-error
                >
              </p>
              <p matLine *ngIf="item.response">
                <mat-error
                  matLine
                  class="warning break-word"
                  *ngFor="let message of item.response.warningMessages"
                  >{{ message }}</mat-error
                >
              </p>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
    <div>
      <button        
        mat-raised-button
        color="primary"
        (click)="onCheckout(data.basket)"
        style="height:55px;margin:5px;"
      >
        <span i18n="CreateOrder|Label Auftrag erstellen@@CreateOrder"
          >Auswahl abwickeln (weiter zum Checkout)</span
        >
      </button>
    </div>
  </div>
  <ng-template #basketEmpty>
    <mat-list>
      <mat-list-item>
        <h3
          matLine
          i18n="basketListEmptyHint|Warenkorb Hinweis Leer@@basketListEmptyHint"
        >
          Der Einkaufskorb ist leer.
        </h3>
      </mat-list-item>
    </mat-list>
  </ng-template>
</ng-container>
