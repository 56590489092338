import { Component, Inject } from '@angular/core';
import { ChatChannelState } from '../../../generated/Dpl.B2b.Common.Enumerations';
import { ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';

@Component({
  selector: 'dpl-change-channel-state-dialog',
  templateUrl: './change-channel-state-dialog.component.html',
  styleUrls: ['./change-channel-state-dialog.component.scss'],
})
export class ChangeChannelStateDialogComponent {
  channelStates: ChatChannelState[]
  channelState: ChatChannelState

  constructor(
    public dialogRef: MatDialogRef<ChangeChannelStateDialogComponent>,
    private messagingDataService: MessagingDataService,
    @Inject(MAT_DIALOG_DATA) public channel: ChannelDto
  ) {

  }

  closeTicket(){
    this.messagingDataService.closeTicket(this.channel.channelId);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

}
