<mat-toolbar
  style="margin-top: 10px"
  *ngIf="pendingCount$ | async as pendingCount"
  fxLayout="row" fxLayoutAlign="center center"
>
  <button mat-icon-button [routerLink]="['transfers/ReceivedAndPending']">
    <mat-icon>visibility</mat-icon>
  </button>
  <span style="margin-left: 10px; margin-right: 5px">{{ pendingCount }}</span>
  <span
    style="margin-right: 5px"
    *ngIf="pendingCount === 1"
    i18n="@@NoticePendingTranfersCountEQOne"
    >Umbuchung muss noch bestätigt werden</span
  >
  <span
    style="margin-right: 5px"
    *ngIf="pendingCount > 1"
    i18n="@@NoticePendingTranfersCountGTOne"
    >Umbuchungen müssen noch bestätigt werden</span
  >
</mat-toolbar>
