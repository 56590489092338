import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipeEnumBase } from './translate-pipe.base';
import { LocalizationService } from '@app/core';
import { OrderStatus } from '@app/api/dpl';

@Pipe({
  name: 'LoadingLocationAdminType',
})
export class LoadingLocationAdminTypePipe extends TranslatePipeEnumBase {
  name: string = 'LoadingLocationAdminType';

  constructor(private ls: LocalizationService) {
    super(ls);
  }

}
