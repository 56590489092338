<ng-container *hasPermission="resourceAction.CancelOrder">
    <button
      i18n-title="
        Buttontitel Stornierung anfragen@@CancelButtonStornoCancelButtonTitle"
      mat-icon-button
      [dplOnClickDisableUntil]="onCancel()"
      [disabled]="isDisabled()"
      title="Stornierung anfragen"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </ng-container>
  