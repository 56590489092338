<ng-container matColumnDef="hasDplNote">
  <th
    mat-header-cell
    *matHeaderCellDef
    i18n="DPL|Table Header Has DPL Note DPL @@ColumnEmployeeNoteDPLTableHeader"
  >
    DPL
  </th>
  <td mat-cell *matCellDef="let element">
    <button
      mat-icon-button
      *ngIf="element.hasDplNote"
      title="von DPL bearbeitet"
      i18n-title="ColumnEmployeeNoteModifyByDpl|Label Von Dpl bearbeitet@@ColumnEmployeeNoteModifyByDpl"
    >
      <mat-icon>
        warning
      </mat-icon>
    </button>
  </td>
</ng-container>
