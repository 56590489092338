import { IAccount } from 'apps/dpl-live/src/app/accounts/state/account.model';
import {
  Person,
  User,
  UserRole,
} from 'apps/dpl-live/src/app/core/services/dpl-api-services';
import { ICustomer } from 'apps/dpl-live/src/app/customers/state/customer.model';

export interface IUser<
  TCustomer extends number | ICustomer = ICustomer,
  TPostingAccount extends number | IAccount = IAccount
> {
  id: number;
  email: string;
  name: string;
  role: UserRole;
  organizationName: string;
  customers: TCustomer[];
  postingAccounts: TPostingAccount[];
  permissions: User['permissions'];
  aadRoles: AadRole[];
  person: Person
}

export enum AadRole {
  Manager = 'Manager',
  Admin = 'Admin',
  UserAdmin = 'UserAdmin',
  Publisher = 'Publisher',
}

/**
 * A factory function that creates User
 */
export function createUser(params: Partial<IUser>) {
  return {} as IUser;
}
