<mat-toolbar color="primary">
  <span i18n="@@HeadingCreateLoadingLocation"
    >Neue Be-/Entladestelle erstellen</span
  >
</mat-toolbar>
<!-- <dpl-loading-location-create-form #form  [orderType]="data.orderType"> -->
<dpl-loading-location-create-form
  [dataInput]="null"
  [orderType]="data.orderType"
  (formValid)="formValidChange($event)"
  (onChange)="onChange($event)"
  [useCase]="locationCreateUseCase.Customer"
  [mode]="locationCreateMode.Create"
>
</dpl-loading-location-create-form>

<mat-dialog-actions>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
    style="width: 100%"
  >
    <button mat-raised-button (click)="onCancel()" i18n="@@Cancel">
      Abbrechen
    </button>
    <button
      mat-raised-button
      (click)="onCreateLoadingLocation($event)"
      [disabled]="!formValid"
      cdkFocusInitial
      i18n="@@CreateLoadingLocation"
    >
      Be-/Entladestelle erstellen
    </button>
  </div>
</mat-dialog-actions>
