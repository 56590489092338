import { Injectable } from '@angular/core';
import { ResourceAction, PermissionResourceType, UserRole } from '@app/api/dpl';
import { UserService } from '../../user/services/user.service';
import { first, map, pluck, switchMap } from 'rxjs/operators';
import { generatePermissionKey } from './permission.utils';
import { CustomerDivisionsService } from '../../customers/services/customer-divisions.service';
import { combineLatest, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private userService: UserService,
    private divisionsService: CustomerDivisionsService
  ) {}

  hasPermission(
    action: ResourceAction,
    resource: PermissionResourceType,
    referenceId: number
  ) {
    const permissionKey = generatePermissionKey(action, resource, referenceId);

    return this.userService.getPermissions().pipe(
      map((permissions) => {
        return permissions[permissionKey];
      })
    );
  }

  hasPermissionOnDivision(action: ResourceAction) {
    return this.userService.getIsDplEmployee().pipe(
      switchMap((isEmnployee) => {
        return isEmnployee
          ? of(true)
          : this.divisionsService.getActiveDivision().pipe(
              first(),
              pluck('id'),
              switchMap((divisionId) => {
                return this.hasPermission(
                  action,
                  PermissionResourceType.Division,
                  divisionId
                );
              })
            );
      })
    );
  }

  hasAtLeastOnePermission(
    actions: ResourceAction[],
    resource?: PermissionResourceType,
    referenceId?: number
  ) {
    return this.userService.getIsDplEmployee().pipe(
      switchMap((isEmnployee) => {
        return isEmnployee
          ? of(true)
          : this.userService.getPermissions().pipe(
              map((permissions) => {
                for (const action of actions) {
                  const permissionKey = generatePermissionKey(
                    action,
                    resource,
                    referenceId
                  );
                  if (permissions[permissionKey]) {
                    return true;
                  }
                }
                return false;
              })
            );
      })
    );
  }

  getResourceTypesInHierarchy(resource: PermissionResourceType) {
    switch (resource) {
      case PermissionResourceType.Organization: {
        return [PermissionResourceType.Organization];
      }
      case PermissionResourceType.Customer: {
        return [
          PermissionResourceType.Organization,
          PermissionResourceType.Customer,
        ];
      }
      case PermissionResourceType.Division: {
        return [
          PermissionResourceType.Organization,
          PermissionResourceType.Customer,
          PermissionResourceType.Division,
        ];
      }
      case PermissionResourceType.PostingAccount: {
        return [
          PermissionResourceType.Organization,
          PermissionResourceType.Customer,
          PermissionResourceType.PostingAccount,
        ];
      }
      default:
        throw new Error(
          $localize`:@@ArgumentOutOfRange:Argument out of range` +
            `: ${resource}`
        );
    }
  }

  getHasRoleAccessToFeature(feature: AppFeature, userRole: UserRole): boolean {
    switch (feature) {
      case 'create-voucher-form':
        return (
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.DplEmployee
        );
      case 'create-availability-form':
        return (
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.Shipper ||
          userRole === UserRole.DplEmployee
        );
      case 'create-delivery-form':
        return (
          userRole === UserRole.Shipper || userRole === UserRole.DplEmployee
        );
      case 'create-load-carrier-receipt-delivery-form':
        return (
          userRole === UserRole.Shipper || userRole === UserRole.DplEmployee
        );
      case 'create-load-carrier-receipt-delivery-sorting-form':
        return (
          userRole === UserRole.Warehouse || userRole === UserRole.DplEmployee
        );
      case 'create-load-carrier-receipt-pickup-form':
        return (
          userRole === UserRole.Shipper ||
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.Warehouse ||
          userRole === UserRole.DplEmployee
        );
      case 'create-load-carrier-receipt-exchange-form':
        return false;
      // return (
      //   userRole === UserRole.Shipper || userRole === UserRole.DplEmployee
      // );
      case 'create-transfer-form':
        // Todo: check what userRoles are allowed to use transfer-form
        return (
          userRole === UserRole.Shipper ||
          userRole === UserRole.DplEmployee ||
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.Warehouse
        );
      case 'voucher-list':
        return (
          userRole === UserRole.Shipper ||
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.DplEmployee
        );
      case 'voucher-list-expired-vouchers':
        return (
          userRole === UserRole.Shipper ||
          userRole === UserRole.Retailer ||
          userRole === UserRole.Warehouse ||
          userRole === UserRole.DplEmployee
        );
      case 'accounting-record-list':
        return true;
      case 'availability-list':
        return (
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.Shipper ||
          userRole === UserRole.DplEmployee
        );
      case 'delivery-list':
        return (
          userRole === UserRole.Shipper || userRole === UserRole.DplEmployee
        );
      case 'journal':
        return (
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.Shipper ||
          userRole === UserRole.Warehouse ||
          userRole === UserRole.DplEmployee
        );
      case 'transports':
        return false;
      case 'load-carrier-receipt-list':
        return (
          userRole === UserRole.Retailer ||
          userRole === UserRole.PointOfSale ||
          userRole === UserRole.Shipper ||
          userRole === UserRole.Warehouse ||
          userRole === UserRole.DplEmployee
        );
      case 'live-pooling':
        return (
          userRole === UserRole.Shipper || userRole === UserRole.DplEmployee
        );
      case 'offer':
        return (
          userRole === UserRole.Shipper ||
          userRole === UserRole.DplEmployee ||
          userRole === UserRole.ShipperDepot
        );
      case 'administration':
        return userRole === UserRole.DplEmployee;
      case 'external-load-carriers':
        return true;
      case 'create-post-load-carrier-receipt-delivery-form':
        return userRole !== UserRole.Warehouse;
      case 'create-post-load-carrier-receipt-pickup-form':
        return true;
      case 'create-post-load-carrier-receipt-delivery-sorting-form':
        return (
          userRole === UserRole.Warehouse ||
          userRole === UserRole.DplEmployee ||
          userRole === UserRole.ShipperDepot
        );
      default:
        return false;
    }
  }
}

export type AppFeature =
  | 'create-voucher-form'
  | 'create-availability-form'
  | 'create-delivery-form'
  | 'create-load-carrier-receipt-delivery-form'
  | 'create-post-load-carrier-receipt-delivery-form'
  | 'create-load-carrier-receipt-pickup-form'
  | 'create-post-load-carrier-receipt-pickup-form'
  | 'create-load-carrier-receipt-exchange-form'
  | 'create-load-carrier-receipt-delivery-sorting-form'
  | 'create-post-load-carrier-receipt-delivery-sorting-form'
  | 'create-transfer-form'
  | 'voucher-list'
  | 'voucher-list-expired-vouchers'
  | 'accounting-record-list'
  | 'availability-list'
  | 'delivery-list'
  | 'journal'
  | 'transports'
  | 'load-carrier-receipt-list'
  | 'live-pooling'
  | 'offer'
  | 'administration'
  | 'external-load-carriers';
