import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ILoadCarrier } from 'apps/dpl-live/src/app/master-data/load-carriers/state/load-carrier.model';
import { DocumentTypesService } from '@app/shared/services/document-types.service';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  SubFormGroup,
} from 'ngx-sub-form';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import {
  distinctUntilChanged,
  filter,
  map,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  combineLatest,
  EMPTY,
  Observable,
  of,
  Subject,
  merge,
  ReplaySubject,
} from 'rxjs';
import { AccountsService } from '../../../accounts/services/accounts.service';
import {
  Balance,
  CustomerDocumentSettings,
  LoadCarrierReceiptDepotPreset,
} from '../../../core/services/dpl-api-services';
import { CustomersService } from '../../../customers/services/customers.service';
import { LoadCarriersService } from '../../../master-data/load-carriers/services/load-carriers.service';
import { LoadCarrierPickerContext } from '../load-carrier-picker/load-carrier-picker.component';
import { ExternalLoadCarriersQuery } from '../../../master-data/external-load-carriers/state/external-load-carriers.query';
import { IAccount } from '../../../accounts/state/account.model';
import { LoadCarrierPickerQuery } from '../load-carrier-picker/load-carrier-picker.query';
import { TransferLoadCarrier } from '../load-carrier-picker/load-carrier-picker.store';

export interface LoadCarrierQuantity {
  id: number;
  quantity: number;
}

type ViewData = {};

@Component({
  selector: 'app-load-carrier-form',
  template: `
    <ng-container *ngIf="viewData$ | async as data" [formGroup]="formGroup">
      <load-carrier-picker
        [subForm]="formGroup.controls.id"
        [allowLoadCarrierSelection]="allowLoadCarrierSelection"
        [context]="context"
        fxFlex
      ></load-carrier-picker>
      <mat-form-field fxFlex>
        <input
          matInput
          [formControl]="formGroup.controls.quantity"
          type="number"
          digit-only
          placeholder="Menge"
          i18n-placeholder="Amount|Label Menge@@Amount"
          #loadCarrierQuantity="ngForm"
          [dplHighlight]="loadCarrierQuantity"
        />
        <mat-error
          *ngIf="
            formGroup.controls.quantity.touched &&
            formGroup.controls.quantity.errors?.required
          "
          ><span
            i18n="
              Pflichtfeld|Label Pflichtfeld@@LoadCarrierFormErrorRequiredText"
            >Pflichtfeld</span
          ></mat-error
        >
        <mat-error
          i18n="
            ErrorMustbeGreaterZero|Fehler Wert muss größer 0
            sein@@ErrorMustBeGreaterZero"
          *ngIf="
            formGroup.controls.quantity.touched &&
            formGroup.controls.quantity.errors?.min
          "
          >Der Wert muss größer 0 sein.</mat-error
        >
        <mat-error
          i18n="
            LoadCarrierErrorMaxBalance|Saldo nicht
            ausreichend@@LoadCarrierErrorMaxBalance"
          *ngIf="
            formGroup.controls.quantity.touched &&
            formGroup.controls.quantity.errors?.maxBalance
          "
          >Saldo nicht ausreichend</mat-error
        >

        <mat-error
          i18n="
            LoadCarrierErrorMaxTransferLoadCarrierSettingLimit|Sie haben die
            erlaubte Menge für diesen Ladungsträger
            überschritten.@@LoadCarrierErrorMaxTransferLoadCarrierSettingLimit"
          *ngIf="
            formGroup.controls.quantity.touched &&
            formGroup.controls.quantity.errors
              ?.maxTransferLoadCarrierSettingsLimit
          "
          >Sie haben die erlaubte Menge für diesen Ladungsträger
          überschritten.</mat-error
        >
        <mat-error
          i18n="
            LoadCarrierErrorMaxDepoPreset|Sie haben eine Menge über 199 Paletten
            eingetragen@@LoadCarrierErrorMaxDepoPreset"
          *ngIf="
            formGroup.controls.quantity.touched &&
            formGroup.controls.quantity.errors?.maxDepoPreset
          "
          >Sie haben eine Menge über 199 Paletten eingetragen. Wenn Sie keine
          direkte Sortierung der Paletten vornehmen, verwenden Sie bitte das
          entsprechende Szenario ab 200 Paletten.</mat-error
        >
        <mat-error
          i18n="
            LoadCarrierErrorMaxQuantity|Konfigurierte Maximale Anzahl
            überschritten@@LoadCarrierErrorMaxQuantity"
          *ngIf="
            formGroup.controls.quantity.touched &&
            formGroup.controls.quantity.errors?.max
          "
          >Maximale Anzahl überschritten</mat-error
        >
        <mat-hint
          class="app-mat-hint-warning"
          i18n="
            LoadCarrierErrorThresholdForWarningQuantity|Der Wert scheint sehr
            Hoch@@LoadCarrierErrorThresholdForWarningQuantity"
          *ngIf="
            !formGroup.controls.quantity.errors &&
            formGroup.controls.quantity.touched &&
            (showQuantityWarning$ | async)
          "
          >Der Wert scheint sehr hoch!</mat-hint
        >
      </mat-form-field>
    </ng-container>
  `,
  styles: [],
  providers: subformComponentProviders(LoadCarrierFormComponent),
})
export class LoadCarrierFormComponent
  extends NgxSubFormComponent<LoadCarrierQuantity>
  implements OnInit, OnChanges {
  @Input() allowLoadCarrierSelection = true;
  @Input() context: LoadCarrierPickerContext;
  @Input() contextData: any;

  private allowLoadCarrierSelection$ = new ReplaySubject<boolean>();
  private contextData$ = new ReplaySubject<any>();
  protected emitInitialValueOnInit = false;
  viewData$: Observable<ViewData>;
  showQuantityWarning$: Observable<boolean>;

  constructor(
    private accountsService: AccountsService,
    private customerService: CustomersService,
    private loadCarrierPickerQuery: LoadCarrierPickerQuery,
    private loadCarriers: LoadCarriersService,
    private externalLoadCarriers: ExternalLoadCarriersQuery,
    private doucmentTypes: DocumentTypesService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    this.allowLoadCarrierSelection$.next(this.allowLoadCarrierSelection);
    this.contextData$.next(this.contextData);
  }

  ngOnInit() {
    const loadCarrierId$ = this.formGroup.controls.id.valueChanges.pipe(
      startWith(this.formGroup.controls.id.value),
      distinctUntilChanged(),
      tap((id) => {
        if (id === null || id === undefined) {
          return this.formGroup.controls.quantity.disable();
        }

        this.formGroup.controls.quantity.enable();
      })
    );

    const loadCarrierType$ = loadCarrierId$.pipe(
      filter((i) => !!i),
      switchMap((loadCarrierId) => {
        if (
          this.context === 'extDemand' ||
          this.context === 'extDemandSelfTransport' ||
          this.context === 'extSupply' ||
          this.context === 'extSupplySelfTransport'
        ) {
          return this.externalLoadCarriers.getLoadCarrierById(loadCarrierId);
        } else {
          return this.loadCarriers.getLoadCarrierById(loadCarrierId);
        }
      }),
      publishReplay(1),
      refCount()
    );

    const customer$ = this.customerService
      .getActiveCustomer()
      .pipe(publishReplay(1), refCount());

    const documentSettings$ = combineLatest([customer$, loadCarrierType$]).pipe(
      map(([customer, loadCarrier]) => {
        const documentTypeId = this.doucmentTypes.getDocumentTypeIdForLoadCarrierPickerContext(
          this.context
        );

        if (!documentTypeId) {
          return null;
        }

        return customer?.documentSettings?.find(
          (i) =>
            i.documentTypeId === documentTypeId &&
            i.loadCarrierTypeId == loadCarrier.type
        );
      }),
      publishReplay(1),
      refCount()
    );

    const depoPreset$ = combineLatest([customer$, this.contextData$]).pipe(
      map(([customer, contextData]) => {
        if (!contextData) {
          return null;
        }

        return customer.loadCarrierReceiptDepotPresets?.find(
          (i) => i.id === contextData
        );
      }),
      publishReplay(1),
      refCount()
    );

    const account$ = this.accountsService.getActiveAccount();
    this.showQuantityWarning$ = combineLatest([
      documentSettings$,
      this.formGroup.controls.quantity.valueChanges,
    ]).pipe(
      map(([documentSettings, quantity]) => {
        return (
          documentSettings?.thresholdForWarningQuantity &&
          quantity > documentSettings.thresholdForWarningQuantity
        );
      }),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );

    const quantityValidator$ = combineLatest([
      documentSettings$,
      depoPreset$,
      account$,
    ]).pipe(
      switchMap(([documentSettings, depoPreset, account]) =>
        this.getQuantityValidator(documentSettings, depoPreset, account)
      ),
      tap((validators) => {
        if (!validators) {
          return;
        }
        this.formGroup.controls.quantity.setValidators(validators);
        if (this.context === 'transfer') {
          //Todo check balances
          this.formGroup.controls.quantity.markAsTouched();
        }
        this.formGroup.controls.quantity.updateValueAndValidity();
      })
    );

    const formSync$ = combineLatest([
      loadCarrierId$,
      quantityValidator$,
      this.showQuantityWarning$,
    ]).pipe(
      switchMap(() => EMPTY),
      startWith([null])
    );

    this.viewData$ = combineLatest([formSync$]).pipe(
      map(() => {
        const viewData: ViewData = {};
        return viewData;
      })
    );

    this.formGroup.markAsUntouched();
  }

  private getQuantityValidator(
    documentSettings: CustomerDocumentSettings,
    depoPreset?: LoadCarrierReceiptDepotPreset,
    account?: IAccount
  ): Observable<ValidatorFn[]> {
    switch (this.context) {
      case 'transfer':
        return of(this.formGroup.controls.id.getRawValue()).pipe(
          switchMap((loadCarrierId) => {
            return loadCarrierId
              ? this.loadCarrierPickerQuery
                  .getSettingsLimitForLoadCarrier(loadCarrierId)
                  .pipe(
                    map((transferSettingLoadCarrierLimit) => {
                      return transferSettingLoadCarrierLimit >= 0
                        ? transferSettingLoadCarrierLimit
                        : null; // Maybe default Limit
                    })
                  )
              : of(0);
          }),
          map((limit) => {
            return [
              Validators.required,
              Validators.min(1),
              maxCustom(limit, 'TransferLoadCarrierSettingsLimit'),
            ];
          })
        );

      case 'voucher': {
        return of(documentSettings).pipe(
          map((settings) => {
            const validators = [Validators.min(1)];

            if (documentSettings?.maxQuantity) {
              validators.push(Validators.max(settings.maxQuantity));
            }

            return validators;
          })
        );
      }
      case 'delivery':
      case 'deliveryReceipt':
      case 'pickup':
      case 'pickupReceipt': {
        return this.allowLoadCarrierSelection$.pipe(
          map((allowLoadCarrierSelection) => {
            // if this is true this is not a sorting case
            // HACK add additional contexts to be able to distiguish between sorting / non sorting
            if (allowLoadCarrierSelection) {
              return [Validators.required, Validators.min(1)];
            }

            if (depoPreset && depoPreset.isSmallQuantity) {
              return [Validators.min(0), maxCustom(199, 'DepoPreset')];
            }

            return [Validators.min(0)];
          })
        );
      }

      default:
        return of([Validators.required, Validators.min(1)]);
    }
  }

  protected getFormControls(): Controls<LoadCarrierQuantity> {
    return {
      id: new SubFormGroup(null, Validators.required),
      quantity: new UntypedFormControl(null),
    };
  }

  getDefaultValue() {
    const defaultValue: LoadCarrierQuantity = {
      id: null,
      quantity: null,
    };

    return defaultValue;
  }
}

function maxCustom(max: number, postfix: string) {
  const maxValidator = Validators.max(max);

  return (control) => {
    const result = maxValidator(control);
    if (result) {
      const error = {} as any;
      error[`max${postfix}`] = true;
      return error;
    }

    return null;
  };
}
