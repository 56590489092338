import { Inject, Injectable } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import * as signalR from '@microsoft/signalr';
import {
  ArgumentOutOfRangeError,
  BehaviorSubject,
  combineLatest,
  EMPTY,
  fromEvent,
  merge,
  NEVER,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  pluck,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { arrayUpsert } from '@datorama/akita';

import { AuthenticationService } from '../../core/services';
import {
  API_BASE_URL,
  LinkDataApiService,
  LinkDataType,
} from '../../core/services/dpl-api-services';
import {
  ChatChannelState,
  ChatChannelType,
  UserRole,
} from '../../generated/Dpl.B2b.Common.Enumerations';
import {
  ReceiveAssignChannelMember,
  ReceiveChannelStateChanged,
  ReceiveDeleteChannel,
  ReceiveReadChannel,
  ReceiveRemoveChannelMember,
  ReceiveTranslateChannel,
  ReceiveWriteMessage,
} from '../../generated/Dpl.B2b.Contracts.Chat.Messaging';
import {
  AttachmentDto,
  ChannelDto,
  MemberUserDto,
  MessageDto,
  ReceiveChannel,
} from '../../generated/Dpl.B2b.Contracts.Chat.Shared';
import {
  getHubProxyFactory,
  getReceiverRegister,
} from '../../generated/TypedSignalR.Client';
import {
  IMessagingHub,
  IMessagingReceiver,
} from '../../generated/TypedSignalR.Client/Dpl.B2b.Chat.Hubs.Messaging';
import { UserQuery } from '../../user/state/user.query';
import { MessagingChannelsQuery } from '../state/messaging-channels.query';
import {
  ChannelStatusFilter,
  ChannelTimeFilter,
  ImpersonateChatUserDto,
  MessagingChannelsStore,
} from '../state/messaging-channels.store';
import { UserService } from '../../user/services/user.service';
import { LoadingService } from '../../../../../../libs/dpl-lib/src';
import _, { orderBy } from 'lodash';
import { CustomerDivisionsService } from '../../customers/services/customer-divisions.service';
import { DeepLinkService } from '../../core/services/deep-link.service';

import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MessagingDataService {
  _hubProxy: IMessagingHub;
  _connection: signalR.HubConnection;

  connectionSub = new BehaviorSubject<boolean>(false);

  constructor(
    private userQuery: UserQuery,
    private auth: AuthenticationService,
    private store: MessagingChannelsStore,
    private loadingService: LoadingService,
    private query: MessagingChannelsQuery,
    private userService: UserService,
    private customerDivisionsService: CustomerDivisionsService,
    private linkData: LinkDataApiService,
    private deepLink: DeepLinkService,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {}

  initialize() {
    this.store.update({ impersonateUserId: null });

    return this.auth.isLoggedIn().pipe(
      filter((isLoggedIn) => isLoggedIn),
      switchMap(() => {
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(`${this.baseUrl}/hubs/chat/messaging`, {
            accessTokenFactory: () => {
              return this.auth
                .getTokenForRequestUrl(this.baseUrl)
                .pipe(first())
                .toPromise();
            },
            transport: signalR.HttpTransportType.WebSockets,
            skipNegotiation: true,
          })
          // inspired by https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-7.0&tabs=visual-studio#automatically-reconnect
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              const interval =
                // when trying to connect for less than 2 min
                retryContext.elapsedMilliseconds < 2 * 60 * 1000
                  ? 10 * 1000
                  : 30 * 1000;

              return Math.random() * interval;
            },
          })
          .build();

        const receiver: IMessagingReceiver = {
          // async onUserConnected(connectionId) {
          //   console.log('connected', connectionId);
          // },
          // async onUserDisconnected(connectionId, message) {
          //   console.log('disconnected', connectionId, message);
          // },
          // async onCreateTicket(ticket) {
          //   console.log("create ticket",ticket);
          // },
          // async onWriteMessage(message) {
          //   console.log("write message", message);
          // },
        } as IMessagingReceiver;

        //Todo bind receiver events
        // receiver.onCreateTicket = async (ticket) => {
        //   this.onTicketCreated(ticket);
        // };
        receiver.onCreateChannel = async (receiveChannel) => {
          this.onCreateChannel(receiveChannel);
        };

        receiver.onUserDisconnected = async (message) => {
          console.log('onUserDisconnected');
          this.connectionSub.next(false);
          console.log(message);
          //TODO: what must we do if a user is disconnected
        };

        receiver.onDeleteChannel = async (receiveChannel) => {
          this.onDeleteChannel(receiveChannel);
        };

        receiver.onWriteMessage = async (message) => {
          this.onMessageWritten(message);
        };

        receiver.onTranslateChannel = async (channel) => {
          this.onMesageTranslated(channel);
        };

        receiver.onChannelRead = async (receiveReadMsg) => {
          this.onChannelRead(receiveReadMsg);
        };

        receiver.onAssignResponsibleUser = async (
          channelId,
          channelName,
          user
        ) => {
          this.onResponsableUserUpdate(channelId, channelName, user);
        };

        receiver.onChannelStateChanged = async (
          receiveMsg: ReceiveChannelStateChanged
        ) => {
          this.onChannelStateChanged(receiveMsg.channelId, receiveMsg.state);
        };

        receiver.onAssignChannelMember = async (
          receiveMsg: ReceiveAssignChannelMember
        ) => {
          this.onMemberAssign(receiveMsg.channelId, receiveMsg.memberUser);
        };

        receiver.onRemoveChannelMember = async (
          receiveMsg: ReceiveRemoveChannelMember
        ) => {
          this.onMemberRemove(receiveMsg.channelId, receiveMsg.memberUser);
        };

        connection.onreconnecting(() => {
          this.connectionSub.next(false);
          console.log('connection reconnecting ...');
        });

        connection.onreconnected(() => {
          this.connectionSub.next(true);
          console.log('connection onreconnected .');
        });

        connection.onclose(() => {
          this.connectionSub.next(false);
          console.log('connection closed .');
        });

        const subscription = getReceiverRegister('IMessagingReceiver').register(
          connection,
          receiver
        );

        const chatHubProxy =
          getHubProxyFactory('IMessagingHub').createHubProxy(connection);

        return connection
          .start()
          .then(() => {
            // console.log('tap', connection, chatHubProxy, subscription);

            this._connection = connection;
            this._hubProxy = chatHubProxy;
            this.store.update({ initialized: true });
            if (connection.state === signalR.HubConnectionState.Connected) {
              this.connectionSub.next(true);
            }
            this.refreshChannels();
            this.refreshPossibleResponsibleUsers();
            this.refreshPossibleEditorUsers();
          })
          .catch((error) => {
            this.connectionSub.next(false);
            this.store.update({ initialized: false });
            this._connection = null;
            this._hubProxy = null;
            throw error;
          });
      })
    );
  }

  private _callHubProxySafe<T>(
    delegate: (hubProxy: typeof this._hubProxy) => Promise<T>
  ) {
    return this.query.initialized$.pipe(
      first(),
      switchMap((initialized) => {
        if (!initialized) {
          this.connectionSub.next(false);
          return EMPTY;
        }

        return of(this._hubProxy);
      }),
      switchMap((hubProxy) => {
        return delegate(hubProxy);
      })
    );
  }

  private _getConnectionSafe() {
    const initialized = this.query.getValue().initialized;

    if (initialized === undefined) {
      this.connectionSub.next(false);
      throw new Error(
        $localize`:@@MessageSystemInitalizedError:Messaging not initialized`
      );
    }
    if (!initialized) {
      this.connectionSub.next(false);
      return null;
    }
    if (this._connection.state === signalR.HubConnectionState.Connected) {
      this.connectionSub.next(true);
    }
    return this._connection;
  }

  checkSignalRConnectionObservable(): Observable<boolean> {
    //Check NetworkState
    const networkStatus$ = merge(
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(startWith({ type: navigator.onLine ? 'online' : 'offline' }));
    const connection$ = networkStatus$.pipe(
      switchMap((networkState) => {
        if (networkState?.type === 'online') {
          const connection = this._getConnectionSafe(); //Check ConnectionState after checking Networkstate
          if (!connection) {
            this.connectionSub.next(false);
          }
        } else {
          this.connectionSub.next(false);
        }
        return this.connectionSub.asObservable();
      })
    );

    return connection$; //return connectionSub as Observable
  }

  // TODO: should be observable instead
  checkSignalRConnection(): boolean {
    const connection = this._getConnectionSafe();
    if (!connection) {
      return false;
    }
    if (connection.state === signalR.HubConnectionState.Connected) {
      this.connectionSub.next(true);
    } else {
      this.connectionSub.next(false);
    }
    return connection.state === signalR.HubConnectionState.Connected;
  }

  refreshChannels() {
    this._callHubProxySafe((hubProxy) => hubProxy.channels({})).subscribe(
      (channels) => {
        const currentUserId = this.userQuery.getValue()?.id;
        const channelsWithUnreadCount = channels.map((channel) => {
          const unreadCount = calculateUnreadCountForMessages(
            currentUserId,
            channel
          );

          return {
            ...{
              ...channel,
              channelTypeString: ChatChannelType[channel.channelType],
            },
            unreadCount,
          };
        });
        this.store.set(channelsWithUnreadCount);
        this.store.update({ channelsInitialized: true });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  createTicket(referenceNumber: string, type: ChatChannelType) {
    // console.log(referenceNumber, type);
    const currentUserId = this.userQuery.getValue()?.id;

    return this.customerDivisionsService.getActiveDivision().pipe(
      first(),
      switchMap((division) => {
        return this._callHubProxySafe((hubProxy) => {
          return hubProxy.createTicket(referenceNumber, type, division.id);
        });
      }),
      tap((channel) => {
        const unreadCount = calculateUnreadCountForMessages(
          currentUserId,
          channel
        );

        this.store.add({
          ...{
            ...channel,
            channelTypeString: ChatChannelType[channel.channelType],
          },
          unreadCount,
        });
        // console.log('create ticket subscribe', x);
      })
    );
  }

  getMentions(channelId: string) {
    return this._callHubProxySafe((hubProxy) => hubProxy.mentions(channelId));
  }

  writeMessage(
    channelId: string,
    message: string,
    mentions?: number[],
    fileIds?: number[]
  ) {
    return this._callHubProxySafe((hubProxy) => {
      return hubProxy.writeMessage(channelId, message, fileIds, mentions);
    }).pipe(
      map(() => true),
      catchError((e) => {
        console.log(Error, e);
        return of(false);
      }),
      this.loadingService.showLoadingWhile()
    );
    // .subscribe((x) => console.log('write message subscribe', x));
  }

  translateChannel(channelId: string, language: string) {
    this._callHubProxySafe((hubProxy) =>
      hubProxy.translateChannel(channelId, language)
    ).subscribe((x) => console.log('translate channel subscribe', x));
  }

  setLastReading(channelId: string) {
    this._callHubProxySafe((hubProxy) =>
      hubProxy.readChannel(channelId)
    ).subscribe();
  }

  setTicketActive(channelId: string) {
    this.store.setActive(channelId);
  }

  refreshPossibleResponsibleUsers() {
    this.userService
      .getIsDplEmployee()
      .pipe(
        first(),
        switchMap((isEmployee) => {
          if (!isEmployee) {
            return of([]);
          }

          return this.userService.getCurrentUser().pipe(
            first(),
            switchMap((user) => {
              return this._callHubProxySafe((hubProxy) =>
                hubProxy.divisionContributors(null)
              ).pipe(
                map((possibleUsers) => {
                  return possibleUsers.map((possibleUser) => {
                    const item: ImpersonateChatUserDto = {
                      ...possibleUser,
                      self: possibleUser.userId === user.id,
                    };
                    return item;
                  });
                })
              ); //currentUser call and mark current users;
            })
          );
        }),
        tap((users) => {
          this.store.update({ posibleResponsibleUsers: users });
        })
      )
      .subscribe();
  }

  refreshPossibleEditorUsers() {
    this.userService
      .getIsDplEmployee()
      .pipe(
        first(),
        switchMap((isEmployee) => {
          if (!isEmployee) {
            return of([]);
          }

          return this.userService.getCurrentUser().pipe(
            first(),
            switchMap((user) => {
              return this._callHubProxySafe((hubProxy) =>
                hubProxy.customerContributors(null)
              ).pipe(
                map((possibleUsers) => {
                  return possibleUsers.map((possibleUser) => {
                    const item: ImpersonateChatUserDto = {
                      ...possibleUser,
                      self: possibleUser.userId === user.id,
                    };
                    return item;
                  });
                })
              ); //currentUser call and mark current users;
            })
          );
        }),
        tap((users) => {
          this.store.update({ possibleEditorUsers: users });
        })
      )
      .subscribe();
  }

  //todo get possible impersonate users --> this.hubProxy.customerContributors(null)

  setResponsibleUser(channelId: string, userId: number) {
    // console.log('setResponsibleUser', channelId, userId);

    this.setTicketActive(null);

    this._callHubProxySafe((hubProxy) => {
      return hubProxy.assignResponsibleUser(channelId, userId);
    }).subscribe();
  }

  closeTicket(channelId: string) {
    this._callHubProxySafe((hubProxy) => {
      return hubProxy.closeChannel(channelId);
    }).subscribe();
  }

  //receiver methods

  onCreateChannel(receivbeChannel: ReceiveChannel) {
    const channel = receivbeChannel.channel;
    const currentUserId = this.userQuery.getValue()?.id;
    const unreadCount = calculateUnreadCountForMessages(currentUserId, channel);

    this.store.upsert(channel.channelId, { ...channel, unreadCount });
  }

  onDeleteChannel(receivedChannel: ReceiveDeleteChannel) {
    this.store.remove(receivedChannel.channelId);
  }

  onChannelRead(event: ReceiveReadChannel) {
    const { readingId, channelId, userId, timestamp } = event;

    const currentUserId = this.userQuery.getValue()?.id;

    this.store.update(channelId, (channel) => {
      const readings = arrayUpsert(
        channel.readings || [],
        readingId,
        { id: readingId, channelId, userId, timestamp },
        'id'
      );

      const unreadCount = calculateUnreadCountForMessages(currentUserId, {
        ...channel,
        readings,
      });

      return {
        readings,
        unreadCount,
      };
    });
  }

  onMessageWritten(message: ReceiveWriteMessage) {
    //Server ToDo return Object with channelId + messageId + readable user information
    const messageDto: MessageDto = {
      messageId: message.messageId,
      message: message.message,
      creator: message.creator,
      attachments: message.attachments,
      revision: 0,
    };

    const currentUserId = this.userQuery.getValue()?.id;

    // Message hinzufügen, falls noch nciht vorhanden und MessageCount und UnreadCount hochzählen!
    this.store.update(message.channelId, (channel) => {
      const messagesExisting = channel.messages || [];

      // check message message exists in store
      if (messagesExisting.find((x) => x.messageId === messageDto.messageId)) {
        //message exists
        // console.log('message exists', messageDto.messageId);
        return {};
      }

      const messages = [...messagesExisting, messageDto];
      const unreadCount = calculateUnreadCountForMessages(currentUserId, {
        ...channel,
        messages,
      });

      return {
        messages,
        messageCount: channel.messageCount + 1,
        unreadCount,
      };
    });
  }

  onMesageTranslated(receiveTranslateMessage: ReceiveTranslateChannel) {
    this.store.update(receiveTranslateMessage.channelId, (channel) => {
      //replace message with translated message
      return {
        messages: [
          ...channel.messages.filter(
            (x) => x.messageId != receiveTranslateMessage.message.messageId
          ),
          receiveTranslateMessage.message,
        ],
      };
    });
  }

  onResponsableUserUpdate(
    channelId: string,
    channelName: string,
    user: MemberUserDto
  ) {
    // console.log('onResponsableUserUpdate', channelId, channelName, user);
    this.store.update(channelId, (channel) => {
      return {
        responsibleUser: user,
        // unreadCount: 0, //reset unread count, because responsible user is updated, currentUser is not member anymore
      };
    });
  }

  onMemberAssign(channelId: string, user: MemberUserDto) {
    // console.log('onMemberAssign', channelId, user);
    this.userService
      .getCurrentUser()
      .pipe(filterNilValue(), first(), pluck('id'))
      .subscribe((currentUserId) => {
        this.store.update(channelId, (channel) => {
          return {
            members: [...channel.members, user],
            unreadCount:
              user.userId === currentUserId
                ? channel.messageCount
                : channel.unreadCount,
          };
        });
      });
  }

  onMemberRemove(channelId: string, user: MemberUserDto) {
    // console.log('onMemberRemove', channelId, user);
    this.userService
      .getCurrentUser()
      .pipe(filterNilValue(), first(), pluck('id'))
      .subscribe((currentUserId) => {
        this.store.update(channelId, (channel) => {
          return {
            members: channel.members.filter((x) => x.userId != user.userId),
            unreadCount:
              user.userId === currentUserId ? 0 : channel.unreadCount,
          };
        });
      });
  }

  onChannelStateChanged(channelId: string, state: ChatChannelState) {
    // console.log('onChannelStateChanged', channelId, state);
    this.store.update(channelId, (channel) => {
      return {
        channelState: state,
      };
    });
    // this.refreshChannels();
  }

  //query methods
  ticketExists(
    referenceNumber: string,
    type: ChatChannelType
  ): Observable<string> {
    // console.log('ChatChannelType', ChatChannelType[type]);
    // Server ToDo -> referenceNumber + typeam ticket liefern
    // use selectAll.filterBy instead of selectEntity, to track newly added tickets

    return this.query
      .selectAll({
        filterBy: (x) =>
          x.channelName?.includes(referenceNumber) &&
          ChatChannelType[x.channelType] === ChatChannelType[type],
      })
      .pipe(
        map((x) => x[0]?.channelId)
        // tap((x) => console.log('ticketExists', x))
      );
  }

  ticketHasUnreadMessages(id: string): Observable<number> {
    return this.query.selectEntity(id, (x) => x.unreadCount);
    // .pipe(tap((x) => console.log('ticket has unread', x)));
  }

  ticketsForUser(type?: ChatChannelType): Observable<ChannelDto[]> {
    const isMember = (userId: number, x: ChannelDto) => {
      if (!x?.members || x.members?.length === 0) {
        return true;
      }

      return x.members.some((m) => m.userId === userId);
    };

    //add filter here

    // filter by user is member in channel (employee impersonate view)
    const userId$ = this.getUserImpersonated().pipe(
      switchMap((impersonateUser) => {
        if (impersonateUser) {
          return of(impersonateUser.userId);
        }
        return this.userService
          .getCurrentUser()
          .pipe(filterNilValue(), pluck('id'));
      })
    );
    return userId$.pipe(
      switchMap((userId) => {
        return this.query.selectAll({
          filterBy: (x) => {
            if (type && x.channelType !== type) {
              return false;
            }
            if (isMember(userId, x)) {
              return true;
            }
            return false;
          },
        });
      })
    );
  }

  getAnnouncements() {
    const type = ChatChannelType.Announcement;
    return this.query.channelsInitialized$.pipe(
      map((initialized) => (initialized ? initialized : undefined)),
      filterNilValue(),
      switchMap((initialized) => {
        return this.query
          .selectAll({
            filterBy: (x) => {
              if (type && x.channelType !== type) {
                return false;
              }
              return true;
            },
          })
          .pipe(
            map((channels) => {
              return _(channels)
                .filter(({ messages }) => messages?.length > 0)
                .orderBy(({ messages }) => {
                  const lastMessage = messages[messages?.length - 1];

                  if (!lastMessage.creator?.timestamp) {
                    console.log('creator not set');
                    return null;
                  }

                  const timestamp = lastMessage.creator.timestamp;

                  if (typeof timestamp === 'string') {
                    return new Date(timestamp);
                  }

                  return timestamp;
                }, 'desc')
                .value();
            })
            // tap((channels) => {
            //    console.log('getAnnouncements', channels);
            // })
          );
      })
    );
  }

  ticketsAll(type?: ChatChannelType) {
    const isMember = (userId: number, x: ChannelDto) => {
      if (x.channelType === ChatChannelType.Announcement) {
        return true;
      }

      return (
        x.members?.length > 0 && x.members.some((m) => m.userId === userId)
      );
    };

    //add filter here

    // filter by user is member in channel (employee impersonate view)
    const userId$ = this.getUserImpersonated().pipe(
      switchMap((impersonateUser) => {
        if (impersonateUser) {
          return of(impersonateUser.userId);
        }
        return this.userService
          .getCurrentUser()
          .pipe(filterNilValue(), pluck('id'));
      })
    );

    const timeFilter$ = this.getTimeFilter();
    const statusFilter$ = this.getStatusFilter();
    const customerFilter$ = this.getCustomerFilter();

    return this.query.channelsInitialized$.pipe(
      map((initialized) => (initialized ? initialized : undefined)),
      filterNilValue(),
      switchMap((initialized) => {
        return combineLatest([
          userId$,
          timeFilter$,
          statusFilter$,
          customerFilter$,
        ]).pipe(
          // distinctUntilChanged(),
          switchMap(([userId, timeFilter, statusFilter, customerFilter]) => {
            return this.query.selectAll({
              filterBy: (x) => {
                if (type && x.channelType !== type) {
                  return false;
                }
                if (isMember(userId, x)) {
                  //filters
                  switch (statusFilter) {
                    case ChannelStatusFilter.Open:
                      if (x.channelState !== ChatChannelState.Open) {
                        return false;
                      }
                      break;
                    case ChannelStatusFilter.InProgress:
                      if (x.channelState !== ChatChannelState.InProgress) {
                        return false;
                      }
                      break;
                    case ChannelStatusFilter.Enclosed:
                      if (x.channelState !== ChatChannelState.Enclosed) {
                        return false;
                      }
                      break;
                    default:
                      //ChannelStatusFilter.All
                      break;
                  }
                  const lastMessage =
                    x.messages[x.messages?.length - 1]?.creator?.timestamp;
                  switch (timeFilter) {
                    case ChannelTimeFilter.MoreThanOneDay:
                      if (
                        moment(lastMessage)
                          .add(1, 'days')
                          .isAfter(moment(), 'day')
                      ) {
                        return false;
                      }
                      break;
                    case ChannelTimeFilter.MoreThanThreeDays:
                      if (
                        moment(lastMessage)
                          .add(3, 'days')
                          .isAfter(moment(), 'day')
                      ) {
                        return false;
                      }
                      break;
                    case ChannelTimeFilter.MoreThanSevenDays:
                      if (
                        moment(lastMessage)
                          .add(7, 'days')
                          .isAfter(moment(), 'day')
                      ) {
                        return false;
                      }
                      break;

                    default:
                      // ChannelTimeFilter.All
                      break;
                  }
                  if (customerFilter && x.customerId != customerFilter) {
                    return false;
                  }

                  return true;
                }
                return false;
              },
            });
          }),
          map((channels) => {
            return _(channels)
              .filter(({ messages }) => messages?.length > 0)
              .orderBy(({ messages }) => {
                const lastMessage = messages[messages?.length - 1];

                if (!lastMessage.creator?.timestamp) {
                  console.log('creator not set');
                  return null;
                }

                const timestamp = lastMessage.creator.timestamp;

                if (typeof timestamp === 'string') {
                  return new Date(timestamp);
                }

                return timestamp;
              }, 'desc')
              .value();
          }),
          tap((channels) => {
            console.log('ticketsAll', channels);
            const activeId = this.query.getActiveId();
            const channelFounded = channels.find(
              (x) => x.channelId === activeId
            );
            // console.log(activeId, channelFounded);
            if (activeId && channelFounded === undefined) {
              //reset active if not in list
              console.log('reset active', activeId);

              this.setTicketActive(null);
            }
          })
        );
      })
    );
  }

  getUserImpersonated() {
    return this.query.selectImpersonateUser$;
  }

  getPossibleImpersonateUsers() {
    return this.query.selectPossibleImpersonateUsers$;
  }

  getTimeFilter() {
    return this.query.selectTimeFilter$;
  }

  getStatusFilter() {
    return this.query.selectStatusFilter$;
  }

  getCustomerFilter() {
    return this.query.selectCustomerFilter$;
  }

  getTicketActive() {
    return this.query.selectActive().pipe(filterNilValue());
  }
  getTicketActiveNullable() {
    return this.query.selectActive().pipe(
      map((x) => {
        //HACK
        {
          console.log('setActiveGetActive', x, this.query.getActive());
          return this.query.getActive();
        }
      }),
      tap((x) => {
        console.log('getTicketActiveNullable STORE', this.query.getActive());
        console.log('getTicketActiveNullable', x);
      })
    );
  }

  getAllCurrentUserTickets() {
    return this.query.selectAll().pipe(
      switchMap((channels) => {
        return this.userService.getCurrentUser().pipe(
          filterNilValue(),
          pluck('id'),
          map((userId) => {
            return channels.filter(
              (x) =>
                x.members.some((m) => m.userId === userId) ||
                x.channelType === ChatChannelType.Announcement
            );
          })
        );
      })
    );
  }

  getEmployeesWithTickets() {
    return this.userService
      .getCurrentUser()
      .pipe(filterNilValue(), pluck('id'))
      .pipe(
        switchMap((currentUserId) => {
          return this.query.selectAll().pipe(
            filterNilValue(),
            map((channels) => {
              const members = channels
                .map((x) => x.members)
                .reduce((acc, val) => acc.concat(val), []);

              const uniqueMembers = _.uniqBy(
                members.filter((x) => x.userRole === UserRole.DplEmployee),
                'userId'
              );
              console.log('new uniqueMembers', uniqueMembers);
              return uniqueMembers;
            })
          );
        }),
        distinctUntilKeyChanged('length') //only fires if the length of the employees array changes
      );
  }

  getCustomersWithTickets() {
    //don't use ticketsAll here, move ticketsAll user specific filter to own method
    return this.ticketsForUser().pipe(
      filterNilValue(),
      map((channels) => {
        const customers = channels
          .map((x) => {
            return {
              customerId: x.customerId,
              companyInfo: x.channelCompanyInfoText.split(' / ')[1],
            };
          })
          .reduce((acc, val) => acc.concat(val), []);

        const uniqueCustomers = _.uniqBy(customers, 'customerId');
        console.log('new uniqueCustomers', uniqueCustomers);
        return uniqueCustomers;
      }),
      switchMap((customers) => {
        return this.getCustomerFilter().pipe(
          first(),
          map((customerId) => {
            if (
              !customerId ||
              !customers.some((x) => x.customerId === customerId)
            ) {
              //reset customer if current selected not in list of customers
              this.setCustomerFilter(undefined);
            }
            return customers;
          }),
          map((customers) => customers)
        );
      })
      // distinctUntilKeyChanged('length')
    );
  }

  getEmployeeActionRequiredTickets(employeeUserId: number) {
    return this.query
      .selectAll({
        filterBy: (x) =>
          x.channelState === ChatChannelState.Open ||
          x.channelState === ChatChannelState.InProgress,
      })
      .pipe(
        filterNilValue(),
        map((channels) => {
          return channels.filter((x) => {
            const lastMessage = x.messages?.[x.messages?.length - 1];

            if (!lastMessage) {
              return false;
            }

            const isLastMessageFromDplEmployee =
              lastMessage.creator.userRole === UserRole.DplEmployee;

            const isEmployeeMember = x.members.some(
              (m) => m.userId === employeeUserId
            );

            return isEmployeeMember && !isLastMessageFromDplEmployee;
          });
        })
      );
  }

  getEmployeeActionRequiredFlag(employeeUserId: number) {
    return this.getEmployeeActionRequiredTickets(employeeUserId).pipe(
      map((messages) => {
        return messages?.length > 0;
      })
    );
  }

  getEmployeeActionRequiredTicketTypeFlag(ticketType: string) {
    return this.getUserImpersonated()
      .pipe(filterNilValue())
      .pipe(
        switchMap((impersonatedUserId) => {
          return this.getEmployeeActionRequiredTickets(
            impersonatedUserId.userId
          ).pipe(
            map((tickets) => {
              // console.log('tickets', tickets);
              return tickets.some(
                (ticket) => ticket.channelTypeString == ticketType
              );
            })
          );
        })
      );
  }

  getEmployeeActionRequiredTicket(channelId: string) {
    return this.getUserImpersonated()
      .pipe(filterNilValue())
      .pipe(
        switchMap((impersonatedUserId) => {
          return this.getEmployeeActionRequiredTickets(
            impersonatedUserId.userId
          ).pipe(
            map((tickets) => {
              return !!tickets.find((x) => x.channelId === channelId);
            })
          );
        })
      );
  }

  setImperonateUser(user?: ImpersonateChatUserDto) {
    this.store.setActive(null);
    this.store.update({ impersonateUser: user.self ? null : user });
  }

  setTimeFilter(filter: ChannelTimeFilter) {
    this.store.update({ timeFilter: filter });
  }

  setStatusFilter(filter: ChannelStatusFilter) {
    this.store.update({ statusFilter: filter });
  }

  setCustomerFilter(customerId?: number) {
    this.store.update({ customerFilter: customerId });
  }

  openDetailInNewTab(channel: ChannelDto) {
    console.log(channel);
    const type = getLinkType(channel.channelType);
    const identifier = channel.referenceNumber;

    this.linkData
      .get({
        type,
        identifier,
      })
      .pipe(
        first(),
        map((linkData) => {
          const command = this.deepLink.getNavigationCommand(linkData);
          const url = this.deepLink.generateUrl(command);

          return url;
        }),
        tap((url) => {
          window.open(url, '_blank').focus();
        })
      )
      .subscribe();
  }
}

export function getLinkType(channelType: ChatChannelType) {
  switch (channelType) {
    case ChatChannelType.AccountingRecord:
      return LinkDataType.Booking;
    case ChatChannelType.VoucherIssuer:
      return LinkDataType.VoucherIssuer;
    case ChatChannelType.VoucherRecipient:
      return LinkDataType.VoucherRecipient;
    case ChatChannelType.Demand:
    case ChatChannelType.Supply:
      return LinkDataType.Order;
    case ChatChannelType.DemandSelfTransport:
    case ChatChannelType.SupplySelfTransport:
      return LinkDataType.OrderSelfTransport;
    case ChatChannelType.LoadCarrierReceipt:
      return LinkDataType.LoadCarrierReceipt;
    case ChatChannelType.Offer:
      return LinkDataType.Offer;
    case ChatChannelType.OfferHotSpot:
      return LinkDataType.HotSpotOffer;
    // TODO: we need separate chat channel types for OrderLoadDemand / OrderLoadSupply
    // because they are both identified by DigitalCode and we cannot infer the direction
    case ChatChannelType.OrderLoad:
      return LinkDataType.OrderLoadDemand;
    case ChatChannelType.OrderLoadSupply:
      return LinkDataType.OrderLoadSupply;
    case ChatChannelType.OrderLoadDemand:
      return LinkDataType.OrderLoadDemand;
    case ChatChannelType.BalanceTransfer:
    case ChatChannelType.DeliveryNote:
    case ChatChannelType.ExternalAccountEntry:
    case ChatChannelType.General:
    case ChatChannelType.Transport:
    case ChatChannelType.UploadProcess:
    default:
      throw new ArgumentOutOfRangeError();
  }
}

export const MIN_DATE = new Date(-8640000000000000);

export function calculateUnreadCountForMessages(
  userId: number,
  {
    members,
    messages,
    readings,
    channelType,
  }: Pick<ChannelDto, 'members' | 'messages' | 'readings' | 'channelType'>
): number {
  const member = members?.find((i) => i.userId === userId);

  if (!member && channelType !== ChatChannelType.Announcement) {
    return 0;
  }

  const lastRead = readings?.find((i) => i.userId === userId);
  const lastReading = lastRead ? getDate(lastRead.timestamp) : MIN_DATE;

  const unreadMessagesFromOthers = (messages || []).filter((message) => {
    const isFromCurrentUser = message.creator.userId === userId;

    if (isFromCurrentUser && channelType !== ChatChannelType.Announcement) {
      return false;
    }

    const createdAt = getDate(message.creator.timestamp);

    const isUnread = createdAt > lastReading;

    return isUnread;
  });

  return unreadMessagesFromOthers?.length;
}

export function getDate(value: Date | string) {
  if (!value) {
    return null;
  }

  return typeof value === 'string' ? new Date(value) : value;
}
