import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Observable, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { MessagingChannelListService } from '../../services/messaging-channel-list.service';
import { MessagingChannelListItem } from '../../state/messaging-channel-list.store';
import { UserService } from '../../../user/services/user.service';

type ViewData = {
  isEmployee: boolean;
  store: ArrayStore;
};

@Component({
  selector: 'dpl-type-list',
  templateUrl: './type-list.component.html',
  styleUrls: ['./type-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeListComponent implements OnInit {
  open = true;
  channelTypesStore$: Observable<ArrayStore<MessagingChannelListItem>>;
  initalActiveTypeKey$: Observable<string>;

  viewData$: Observable<ViewData>;
  constructor(
    private messagingChannelListService: MessagingChannelListService,
    private messagingDataService: MessagingDataService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    const isEmployee$ = this.userService.getIsDplEmployee()
    const channelTypesStore$ = this.messagingChannelListService
      .getChannelListItems()


    this.viewData$ = combineLatest([channelTypesStore$, isEmployee$]).pipe(
      map(([channelTypes, isEmployee]) => {
        console.log(channelTypes)
        const viewData: ViewData = {
          store: new ArrayStore({
            key: 'key',
            data: channelTypes.sort((a, b) => a.key === 'Announcement' ? -1 : a.title.localeCompare(b.title)),
          }),
          isEmployee
        }
        console.log(viewData)
        return viewData
      })
    );

    //Todo: maybe move in ViewData
    this.initalActiveTypeKey$ =
      this.messagingChannelListService.getActiveChannelKey();
  }

  onSelectionChanged(e) {
    this.messagingChannelListService.activateChannelListItem(
      e.addedItems[0]?.key
    );
  }

  getRequiredActionTag(channelType: string) {
    if (channelType == '-1') {
      return of(false);
    } else {
      return this.messagingDataService.getEmployeeActionRequiredTicketTypeFlag(
        channelType
      );
    }
  }

}
