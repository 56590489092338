<dx-list
  *ngIf="height > 0"
  #dxList
  [dataSource]="messages"
  [height]="height + 'px'"
  [activeStateEnabled]="false"
  pageLoadMode="scrollBottom"
  [focusStateEnabled]="false"
  [hoverStateEnabled]="false"
  (onContentReady)="onMessageListContentReady($event)"
>
  <div
    *dxTemplate="let listData of 'item'"
    [class]="
      listData.creator?.userId === currentUser.id
        ? 'own-message'
        : 'other-message'
    "
  >
      <dpl-message
        [message]="listData"
        (copiedMessageText)="onCopyMessage($event)"
        [selectedLanguage]="selectedLanguage"
        style="width: -webkit-fill-available"
      >
      </dpl-message>
  </div>
</dx-list>
