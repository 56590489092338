import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import dxFileUploader from 'devextreme/ui/file_uploader';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { CompleteSingleProcessUploadFileInfoType } from '../../../core/services/dpl-api-services';
import { UploadedFilesQuery } from '../../../process-confirmation/state/uploaded-files.query';
import {
  UploadedFile,
  UploadedFilesStore,
} from '../../../process-confirmation/state/uploaded-files.store';

import { AzureGatewayService } from './azure-gateway.service';

@Component({
  selector: 'dpl-single-upload-form',
  templateUrl: './single-upload-form.component.html',
  styleUrls: ['./single-upload-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AzureGatewayService],
})
export class SingleUploadFormComponent implements OnInit, OnChanges {
  @Input() chunkSize?: number = 80000; // applies only for 'instantly' or 'useButtons' //default 200000
  @Input() allowMultipleFileUpload?: boolean = true;
  @Input() uploadMode?: string = 'instantly'; //'instantly' //'useButtons' //useForm - today not implemented in this component
  @Input() allowCanceling?: boolean = true
  @Input() uploadProcessCode: string;
  @Input() MAX_UPLOAD_COUNT?: number = 100; //MaxCount for UploadedFiles
  @Output() uploadCompleted = new EventEmitter<boolean>();
  @Output() uploadStarted = new EventEmitter<boolean>();
  @Input() fileType: CompleteSingleProcessUploadFileInfoType;
  @Input() selectButtonText = 'Datei auswählen';
  @Input() showFileList = true;


  viewData$: Observable<boolean>;
  reloadSub = new BehaviorSubject<boolean>(false);

  uploadChunk: ReturnType<AzureGatewayService['uploadChunkFn']>;

  //Todo: get AllowedFileExtensions out of CustomerAdministration maybe Account Settings
  allowedFileExtensions = [
    '.pdf', //PDF
    '.jpeg', //Picture
    '.png', //Picture
    '.jpg', //Picture
    '.gif', //Picture
  ];

  //Todo: get maxFileSize out of CustomerAdministration maybe Account Settings
  maxFileSize = 20000000; //20000 K // 20 M

  constructor(
    private azureGateway: AzureGatewayService,
    private uploadedFilesStore: UploadedFilesStore,
    private uploadedFilesQuery: UploadedFilesQuery
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uploadProcessCode?.currentValue) {
      this.azureGateway.init(this.uploadProcessCode);
    }
  }

  ngOnInit(): void {

    this.uploadChunk = this.azureGateway.uploadChunkFn();

    const reload$ = this.reloadSub.asObservable();
    this.viewData$ = combineLatest([reload$]).pipe(
      map(([reload]) => {
        return true;
      })
    );
  }

  filesUploaded(e) {
    console.log(e);
    this.uploadCompleted.next(true);
  }

  onAbortUpload(uploadInfo) {
    console.log(uploadInfo);
  }

  onUploaded(ev: {
    component: dxFileUploader;
    element: HTMLElement;
    file: File;
  }) {
    // query uploadedFiles
    // update uploadedFile completed and type

    //begin - add batch with file number
    const fileCountElement = document.createElement('div');

    fileCountElement.style.display = 'table-cell';
    fileCountElement.style.verticalAlign = 'middle';
    fileCountElement.style.background = '#3f51b5';
    fileCountElement.style.borderRadius = '50%';
    fileCountElement.style.padding = '0px 15px 0px 15px';
    fileCountElement.style.fontWeight = '800';
    fileCountElement.style.color = 'white';

    const fileElements = Array.from(
      ev.element.querySelectorAll('.dx-fileuploader-file-name')
    );
    const fileIndex = fileElements
      .map((e) => e.textContent)
      .findIndex((x) => x == ev.file.name);

    // find element index by filename
    const fileElement: Element = fileElements[fileIndex];

    fileCountElement.innerHTML = (fileIndex + 1).toString();


    // add file count as first element, before delete btn
    if (fileElement) {
      fileElement.parentElement.parentElement.parentElement.insertBefore(
        fileCountElement,
        fileElement.parentElement.parentElement.parentElement.firstChild
      );
    }

    //end - add batch with file number

    this.uploadedFilesQuery
      .selectAll({
        limitTo: 1,
        filterBy: (entity) =>
          entity.filename === ev.file.name && entity.fileSize === ev.file.size,
      })
      .pipe(
        first(),
        tap((uploadedFiles) => {
          if (uploadedFiles?.length > 0) {
            const uploadedFile = uploadedFiles[0];
            this.uploadedFilesStore.update(
              uploadedFile.fileId,
              (): Partial<UploadedFile> => {
                return {
                  completed: true,
                  type: this.fileType,
                  controlFileIndex: fileIndex,
                };
              }
            );
          }
        })
      )
      .subscribe();

    ev.element
      .querySelectorAll('.dx-fileuploader-cancel-button')
      .forEach((button, index) => {
        // remove event listeners
        button.replaceWith(button.cloneNode(true));
      });

    const cancelButtons = ev.element.querySelectorAll(
      '.dx-fileuploader-cancel-button'
    );

    const displayBlockClass = 'app-display-block';
    cancelButtons.forEach((button, index) => {
      button.addEventListener('click', () => {
        console.log('delete', button, index);
        ev.component.removeFile(index);
        // remove file from files store
        this.uploadedFilesQuery
          .selectAll({
            limitTo: 1,
            filterBy: (entity) =>
              entity.filename === ev.file.name &&
              entity.fileSize === ev.file.size,
          })
          .pipe(
            first(),
            tap((uploadedFiles) => {
              if (uploadedFiles.length > 0) {
                const uploadedFile = uploadedFiles[0];
                this.uploadedFilesStore.remove(uploadedFile.fileId);
                // ToDo remove from storage
              }
            })
          )
          .subscribe();
      });

      if (!button.classList.contains(displayBlockClass)) {
        button.classList.add(displayBlockClass);
      }

    });
  }

  onUploadStarted(ev) {
    console.log("uploadStarted", ev)
    this.uploadStarted.emit(true);
  }

  onValueChanged(e) {
    console.log('onValueChanged', e)
  }

}
