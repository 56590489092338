<ng-container *ngIf="viewData$ | async as data">
  <dx-scheduler
    timeZone="Europe/Berlin"
    [dataSource]="data.dataSource"
    [views]="['week', 'month']"
    currentView="week"
    [currentDate]="currentDate"
    [startDayHour]="7"
    [endDayHour]="18"
    [height]="950"
    [groupByDate]="true"
    dateCellTemplate="dateCellTemplate"
    dataCellTemplate="dataCellTemplate"
    [showAllDayPanel]="false"
    (onAppointmentClick)="disableClick($event)"
    (onAppointmentDblClick)="disableClick($event)"
    appointmentTemplate="appointment-template"
    [customizeDateNavigatorText]="customizeDateNavigatorText"
  >
    <!-- [customizeDateNavigatorText] function -->
    <!-- [groups]="['typeId']" -->
    <dxo-editing
      [allowAdding]="false"
      [allowUpdating]="false"
      [allowDeleting]="false"
    ></dxo-editing>
    <div *dxTemplate="let model of 'appointment-template'">
      <div>
        <div>{{ model.targetedAppointmentData.text }}</div>
        <div class="dropdown-appointment-dates">
          {{
            (model.targetedAppointmentData.displayStartDate
              | date: 'shortTime') +
              ' - ' +
              (model.targetedAppointmentData.displayEndDate | date: 'shortTime')
          }}
        </div>
        <div
          *ngIf="canRemove(model.targetedAppointmentData)"
          style="text-align: center; margin-top: 20px"
        >
          <dx-button
            (click)="removeException(model.targetedAppointmentData)"
            icon="minus"
            stylingMode="text"
            width="100%"
            style="border-radius: 0;"
            hint="Ausnahme entfernen"
          ></dx-button>
        </div>
        <div
          *ngIf="canAdd(model.targetedAppointmentData, data.isEmployee)"
          style="text-align: center; margin-top: 20px"
        >
          <dx-button
            (click)="
              addException(model.targetedAppointmentData, data.appointments)
            "
            icon="add"
            stylingMode="text"
            width="100%"
            style="border-radius: 0"
            hint="Ausnahme hinzufügen"
          ></dx-button>
        </div>
      </div>
    </div>
    <dxi-resource
      fieldExpr="typeId"
      label="Typ"
      [dataSource]="typeResources"
      [useColorAsDefault]="true"
    >
    </dxi-resource>
    <div
      *dxTemplate="let dataCell of 'dataCellTemplate'"
      [ngClass]="{
        'disable-date': isDisabledDateCell(
          dataCell.startDate,
          data.enabledDates
        )
      }"
    ></div>
    <div
      *dxTemplate="let dateCell of 'dateCellTemplate'"
      [ngClass]="{
        'disable-date': isDisabledDateCell(dateCell.date, data.enabledDates)
      }"
    >
      {{ dateCell.text }}
    </div>
  </dx-scheduler>
</ng-container>
