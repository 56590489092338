/* THIS (.ts) FILE IS GENERATED BY Tapper */
/* eslint-disable */
/* tslint:disable */

/** Transpiled from Dpl.B2b.Common.Enumerations.ChatChannelState */
export enum ChatChannelState {
    Open = 1,
    InProgress = 2,
    Enclosed = 3,
}

/** Transpiled from Dpl.B2b.Common.Enumerations.ChatChannelType */
export enum ChatChannelType {
    General = 0,
    VoucherIssuer = 1,
    VoucherRecipient = 2,
    AccountingRecord = 3,
    Demand = 4,
    Supply = 5,
    Transport = 6,
    OrderLoad = 7,
    LoadCarrierReceipt = 8,
    Offer = 9,
    OfferHotSpot = 10,
    ExternalAccountEntry = 11,
    BalanceTransfer = 12,
    DeliveryNote = 13,
    UploadProcess = 15,
    DemandSelfTransport = 16,
    SupplySelfTransport = 17,
    OrderLoadDemand = 18,
    OrderLoadSupply = 19,
    DemandMapSelfTransport = 20,
    SupplyMapSelfTransport = 21,
    Announcement = 1000,
}

/** Transpiled from Dpl.B2b.Common.Enumerations.ChatMemberRole */
export enum ChatMemberRole {
    Admin = 0,
    Member = 1,
    Guest = 2,
}

/** Transpiled from Dpl.B2b.Common.Enumerations.ChatMessagePriority */
export enum ChatMessagePriority {
    Low = 1,
    Medium = 2,
    High = 3,
}

/** Transpiled from Dpl.B2b.Common.Enumerations.UserRole */
export enum UserRole {
    Retailer = 0,
    Warehouse = 1,
    Shipper = 2,
    DplEmployee = 3,
    PointOfSale = 4,
    ShipperDepot = 5,
    VoucherUploader = 6,
}

