import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ValidationDataService } from '@app/shared/services';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  SubFormGroup,
} from 'ngx-sub-form';
import { of } from 'rxjs';

import { CustomersService } from '../../../customers/services/customers.service';
import { CountryPickerComponentMode } from '../country-picker/country-picker.component';

export interface LicensePlate {
  countryId: number;
  number: string;
}

@Component({
  selector: 'app-license-plate-form',
  template: `
    <ng-container *ngIf="formGroup" [formGroup]="formGroup">
      <app-country-picker
        [subForm]="formGroup.controls.countryId"
        [mode]="countryPickerMode"
        [prefill]="true"
        [required]="required"
      ></app-country-picker>
      <mat-form-field fxFlex>
        <mat-label>{{ getLicensePlatePaceholder() | async }}</mat-label>
        <input
          matInput
          [formControl]="formGroup.controls.number"
          #licensePlateNumber="ngForm"
          [dplHighlight]="licensePlateNumber"
        />
        <mat-error
          *ngIf="
            formGroup.controls.number?.touched &&
            formGroup.controls.number.errors?.required
          "
          ><span
            i18n="
              Pflichtfeld|Label Pflichtfeld@@LicensePlateFormErrorRequiredText"
            >Pflichtfeld</span
          ></mat-error
        >
        <mat-error *ngIf="formGroup.controls.number?.errors?.maxlength">
          <span i18n="MaxLength|Label Pflichtfeld@@MaxLength"
            >Die Eingabe ist zu lang</span
          >
        </mat-error>
      </mat-form-field>
    </ng-container>
  `,
  styles: [],
  providers: subformComponentProviders(LicensePlateFormComponent),
})
export class LicensePlateFormComponent
  extends NgxSubFormComponent<LicensePlate>
  implements OnChanges {
  @Input() required: boolean;
  // needed for Müller custom license plate placeholder
  @Input() isExternal = false;

  constructor(private customersService: CustomersService) {
    super();
  }

  countryPickerMode: CountryPickerComponentMode = 'licensePlate';

  protected emitInitialValueOnInit = false;

  protected getFormControls(): Controls<LicensePlate> {
    return {
      countryId: new SubFormGroup(null),
      number: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(ValidationDataService.maxLength.licensePlate),
      ]),
    };
  }

  public getDefaultValues() {
    return {
      countryId: null,
      number: null,
    } as LicensePlate;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.setRequiredState(this.required);
  }

  public setRequiredState(isRequired: boolean) {
    const number = this.formGroup.controls.number;
    number.setValidators(isRequired ? [Validators.required] : []);
    number.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  public getLicensePlatePaceholder() {
    if (this.isExternal) {
      // Müller requirement
      return this.customersService.getCustomDocumentLabel('ExtLicensePlate');
    } else {
      return of(
        $localize`:LicensePlate|Label Kennzeichen@@LicensePlate:Kennzeichen`
      );
    }
  }
}
