import { Directive, OnInit } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import moment from 'moment';

@Directive({
  selector: 'dxi-column[filterServerDateOnly]',
  // selector: 'dxi-column[dataType="date"]:not([calculateFilterExpression])',
})
export class DxColumnFilterDateOnlyDirective implements OnInit {
  constructor(private column: DxiColumnComponent) {}

  ngOnInit(): void {
    if (this.column.calculateFilterExpression) {
      return;
    }

    this.column.calculateFilterExpression = (
      filterValue,
      selectedFilterOperation
    ) => {
      if (selectedFilterOperation === '=') {
        return [
          [
            this.column.dataField,
            '>=',
            moment(filterValue).format('YYYY-MM-DD') + 'T00:00:00.000Z',
          ],
          'and',
          [
            this.column.dataField,
            '<',
            moment(filterValue).add(1, 'day').format('YYYY-MM-DD') +
              'T00:00:00.000Z',
          ],
        ];
      } else if (selectedFilterOperation === 'between') {
        if (
          selectedFilterOperation[0] === null ||
          selectedFilterOperation[1] === null
        ) {
          return null;
        } else if (filterValue[0] && !filterValue[1]) {
          return [
            [
              this.column.dataField,
              '>=',
              moment(filterValue[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
            ],
          ];
        } else if (!filterValue[0] && filterValue[1]) {
          return [
            [
              this.column.dataField,
              '<',
              moment(filterValue[1]).add(1,'day').format('YYYY-MM-DD') + 'T00:00:00.000Z',
            ],
          ];
        } else {
          return [
            [
              this.column.dataField,
              '>=',
              moment(filterValue[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
            ],
            'and',
            [
              this.column.dataField,
              '<',
              moment(filterValue[1]).add(1,'day').format('YYYY-MM-DD') + 'T00:00:00.000Z',
            ],
          ];
        }
      }

      return [
        this.column.dataField,
        selectedFilterOperation,
        moment(filterValue).format('YYYY-MM-DD') + 'T00:00:00.000Z',
      ];
    };
  }
}
