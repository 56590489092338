import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { interval, Observable, of } from 'rxjs';
import { first, map, startWith, switchMap } from 'rxjs/operators';

import { LoadingService } from '../../../../../../../libs/dpl-lib/src/lib/services/loading.service';
import { DplApiService } from '../../../core';
import {
  LoadCarrierReceipt,
  LoadCarrierReceiptContextType,
  LoadCarrierReceiptState,
  LoadCarrierReceiptType,
  ResourceAction,
} from '../../../core/services/dpl-api-services';
import {
  DynamicConfirmationDialogComponent,
  DynamicConfirmationDialogData,
  DynamicConfirmationDialogResult,
} from '../dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';

@Component({
  selector: 'dpl-edit-load-carrier-receipt-button',
  templateUrl: './edit-load-carrier-receipt-button.component.html',
  styleUrls: ['./edit-load-carrier-receipt-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLoadCarrierReceiptButtonComponent implements OnInit {
  @Input() receiptId: number;
  @Input() receiptStatus: LoadCarrierReceiptState;

  @Input() receiptCreated: Date;

  canEdit$: Observable<boolean>;

  loadCarrierReceiptStatus = LoadCarrierReceiptState;

  resourceAction = ResourceAction;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dpl: DplApiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.canEdit$ = interval(1000).pipe(
      startWith(0),
      map((x) => {
        return moment(this.receiptCreated)
          .add(30, 'minutes')
          .isAfter(moment(), 'minutes');
      })
    );
  }

  edit() {
    this.dialog
      .open<
        DynamicConfirmationDialogComponent,
        DynamicConfirmationDialogData,
        DynamicConfirmationDialogResult
      >(DynamicConfirmationDialogComponent, {
        data: {
          labels: {
            //todo: localize
            title: 'Warnung: Editieren storniert den Beleg',
            description:
              'Beim Editieren wird der Beleg zunächst storniert. Danach erfolgt der Aufruf der Maske, mit den Werten des stornierten Belegs. Möchten Sie fortfahren?',
            confirm: $localize`:VoucherServicePrintCancelDialogConfirmButtonText|Dialog Button Text Yes@@Yes:Ja`,
            reject: $localize`:VoucherServicePrintCancelDialogRejectButtonText|Dialog Button Text No@@No:Nein`,
            hideCancel: true,
          },
        },
        disableClose: true,
        autoFocus: false,
        width: '600px'
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap((result) => {
          if (!result.confirmed) {
            return of(null as LoadCarrierReceipt);
          }
          return (
            this.dpl.loadCarrierReceipts
              .patchLoadCarrierReceiptCancel(this.receiptId, { reason: 'edit' })
              // HACK development
              // this.dpl.loadCarrierReceipts
              // .getById(this.receiptId)
              .pipe(this.loadingService.showLoadingWhile())
          );
        })
      )
      .subscribe((result) => {
        if (result) {
          console.log(result);

          let extras: {
            queryParams: {
              receiptId: number;
              action: LoadCarrierReceiptType;
              context?: 'depot';
            };
          } = {
            queryParams: { receiptId: this.receiptId, action: result.type },
          };

          if (result.context == LoadCarrierReceiptContextType.Depot)
            extras.queryParams['context'] = 'depot';

          if (result.isPostCreated) {
            this.router.navigate(
              [
                'receipt',
                result.type === LoadCarrierReceiptType.Delivery
                  ? 'postincoming'
                  : 'postoutgoing',
              ],
              extras
            );
          } else {
            this.router.navigate(['receipt', 'create'], extras);
          }
        }
      });
  }
}
