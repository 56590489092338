<div fxLayout="column" fxLayoutGap="20px" style="margin: 20px;">
  <h3 i18n="IMPRESSUM|Headertext IMPRESSUM@@ImprintImpressumHeaderText">
    IMPRESSUM
  </h3>

  <div fxLayout="column">
    <div
      i18n="DPLDeutschePalettenLogistikGmbH|Adresstext Company Name DPL Deutsche Paletten Logistik GmbH@@ImprintAdressCompanyNameText">
      DPL Deutsche Paletten Logistik GmbH
    </div>
    <div i18n="Overweg|Adresstext Company Street Overweg 12@@ImprintAdressCompanyStreetText">
      Overweg 12
    </div>
    <div i18n="Adresstext|Adresstext Company City Zip D-59494 Soest@@ImprintAdressCompanyCityZipText">
      D-59494 Soest
    </div>
  </div>

  <div fxLayout="column">
    <div
      i18n="ImprintAdressCompanyTelephoneText|Company Telephone Tel: +49 2921 7899 - 0@@ImprintAdressCompanyTelephoneText">
      Tel: +49 2921 7899 - 0
    </div>
    <div
      i18n="ImprintAdressCompanyFaxphoneText|Company Faxphone Tel: +49 2921 7899 - 0@@ImprintAdressCompanyFaxphoneText">
      Fax: +49 2921 7899 - 178
    </div>
  </div>

  <div fxLayout="column">
    <div i18n="ImprintCompanyManagementText|Company Management Header Geschäftsleitung@@ImprintCompanyManagementText">
      Geschäftsleitung
    </div>
    <div i18n="HorstGutzeit|Company Manager Horst Gutzeit @@ImprintCompanyManagerText">
      Horst Gutzeit
    </div>
  </div>
  <div fxLayout="column">
    <div i18n="Handelsregister|Commercial Register Header Handelsregister@@ImprintCompanyCommercialRegisterHeaderText">
      Handelsregister
    </div>
    <div
      i18n="RegistergerichtAmtsgerichtArnsberg|Commercial Register Court Registergericht Amtsgericht Arnsberg@@ImprintCompanyCommercialRegisterCourtText">
      Registergericht: Amtsgericht Arnsberg
    </div>
    <div
      i18n="Registernummer|Commercial Register Number Registernummer: HRB 6127 @@ImprintCompanyCommercialRegisterNumberText">
      Registernummer: HRB 6127
    </div>
  </div>

  <div fxLayout="column">
    <div
      i18n="SitzderGesellschaft|Seat of the Company Sitz der Gesellschaft@@ImprintCompanyCommercialSeatOfTheCompanyText">
      Sitz der Gesellschaft
    </div>
    <div
      i18n="ImprintCompanyCommercialSeatOfTheCompanyCityZip|Seat of the Company Zip and City 59494 Soest @@ImprintCompanyCommercialSeatOfTheCompanyCityZip">
      59494 Soest
    </div>
  </div>
  <div fxLayout="column">
    <div
      i18n="ImprintCompanyCommercialSeatOfTheCompanySalesTaxIdentificationNumber|Sales Tax Identification Number Umsatzsteuer-Identifikationsnummer DE 206 323 118@@ImprintCompanyCommercialSeatOfTheCompanySalesTaxIdentificationNumber">
      Umsatzsteuer-Identifikationsnummer: DE 206 323 118
    </div>
  </div>
  <div fxLayout="column">
    <div i18n="Disclaimer|Disclaimer Header text Disclaimer@@ImprintCompanyCommercialDisclaimerHeaderText">
      Disclaimer
    </div>
    <div
      i18n="ImprintCompanyCommercialDisclaimerSubText|Disclaimer Sub Text Bei aller Sorgfalt kann keine Haftung für die Inhalte der Website gegeben werden.@@ImprintCompanyCommercialDisclaimerSubText">
      Bei aller Sorgfalt kann keine Haftung für die Inhalte der Website gegeben
      werden.
    </div>
  </div>
</div>