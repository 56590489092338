import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AppErrorHandlerService,
  DplApiService,
  LocalizationService,
} from '../../../core';
import {
  API_BASE_URL,
  BusinessHourException,
  BusinessHourExceptionType,
  LoadingLocation,
} from '../../../core/services/dpl-api-services';

type ViewData = {
  dataSource: CustomStore;
  dropdownExecptionType: any;
};

@Component({
  selector: 'dpl-loading-location-business-hours-exceptions',
  templateUrl: './loading-location-business-hours-exceptions.component.html',
  styleUrls: ['./loading-location-business-hours-exceptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLocationBusinessHoursExceptionsComponent implements OnInit {
  @Input() loadingLocationId?: number;
  baseUrl: string;
  viewData$: Observable<ViewData>;
  gridDataSource: LoadingLocation;

  constructor(
    private localizationService: LocalizationService,
    private appErrorHandlerService: AppErrorHandlerService,
    private dpl: DplApiService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.viewData$ = combineLatest([this.getTypeData()]).pipe(
      map(([dropdownExecptionType]) => {
        const dataSource = new CustomStore({
          key: 'id',
          loadMode: 'raw',
          load: async () => {
            return this.dpl.businessHourExceptionsService
              .getAll(this.loadingLocationId)
              .pipe(
                map((businessHourExceptions) => {
                  return businessHourExceptions.map((businessHourException) => {
                    return {
                      ...businessHourException,
                      fromTime: moment(
                        '1970-01-01:' +
                          businessHourException.fromDateTime
                            .toString()
                            .substring(11, 19)
                      ).toDate(),
                      toTime: moment(
                        '1970-01-01:' +
                          businessHourException.toDateTime
                            .toString()
                            .substring(11, 19)
                      ).toDate(),
                    };
                  });
                }),
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                })
              )
              .toPromise();
          },
          insert: (values: BusinessHourException) => {
            return this.dpl.businessHourExceptionsService
              .post({
                loadingLocationId: this.loadingLocationId,
                ...values,
              })
              .pipe(
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                })
              )
              .toPromise();
          },
          update: (key, values: BusinessHourException) => {
            //manipulate from/to send with UTC 0 - this.fixData()???
            return this.dpl.businessHourExceptionsService
              .patch(key, JSON.stringify(values))
              .pipe(
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                })
              )
              .toPromise();
          },
          remove: (key) => {
            return this.dpl.businessHourExceptionsService
              .delete(key, {})
              .pipe(
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                }),
                map(() => {
                  return;
                })
              )
              .toPromise();
          },
        });
        const viewData: ViewData = {
          dataSource,
          dropdownExecptionType,
        };
        return viewData;
      })
    );
  }

  getRequestTimezoneManipulation(businessHourException: BusinessHourException) {
    return {
      ...businessHourException,
      fromDateTime: !!businessHourException.fromDateTime
        ? moment(businessHourException.fromDateTime)
            .add(
              'minutes',
              moment(businessHourException.fromDateTime).utcOffset()
            )
            .toDate()
        : undefined,
      toDateTime: !!businessHourException.toDateTime
        ? moment(businessHourException.toDateTime)
            .add(
              'minutes',
              moment(businessHourException.toDateTime).utcOffset()
            )
            .toDate()
        : undefined,
    };
  }

  // get UserRole LookupData
  getTypeData() {
    const r = Object.keys(BusinessHourExceptionType).map((value, id) => {
      value = BusinessHourExceptionType[value as any];
      const display = this.localizationService.getTranslation(
        'BusinessHourExceptionType',
        value
      );
      return { id, value, display };
    });

    return of(r);
  }
  getTypeName = (data: any) => {
    const value = BusinessHourExceptionType[data.type as any];
    if(!value){
      return '';
    }
    const display = this.localizationService.getTranslation(
      'BusinessHourExceptionType',
      value
    );
    return display;
  };

  customValidationCallbackBusinessHourExceptions = (params) => {
    if (params.data.fromDateTime && params.data.toDateTime) {
      if (params.data.fromDateTime < params.data.toDateTime) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
}
