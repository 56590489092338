import { DplApiSort } from '../core/utils';
import { Sort } from '@angular/material/sort';
import {
  DayOfWeek,
  ListSortDirection,
  StatusContext,
  OrderQuantityType,
  OrderType,
} from '@app/api/dpl';
import { NormalizedSchema } from 'normalizr';
import * as _ from 'lodash';
import { Moment } from 'moment';
import moment from 'moment';

export const EMPTY_PAGINATION_RESULT = {
  perPage: 0,
  lastPage: 0,
  currentPage: 0,
  total: 0,
  data: [],
};

export function getMatSort<TSortOptions>(apiSort: DplApiSort<TSortOptions>) {
  const sort: Sort = {
    active: apiSort.sortBy as unknown as string,
    direction:
      apiSort.sortDirection === ListSortDirection.Descending ? 'desc' : 'asc',
  };
  return sort;
}

export function getEntities<TEntities extends { [key: string]: any[] }>(
  normalizedSchema: NormalizedSchema<{}, any>
) {
  const entities = _(normalizedSchema.entities)
    .mapValues((values) => _(values).values().value())
    .value();
  return entities as TEntities;
}

export function parseMomentToUtcDate(inDate: Moment) {
  return inDate.utcOffset(0, true).toDate();
}

export function getWeekdayByIso(weekdayNumber: number) {
  switch (weekdayNumber) {
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    case 7:
      return DayOfWeek.Sunday;
  }
}

export function getWeekdaySort(dayOfWeek: DayOfWeek) {
  switch (dayOfWeek) {
    case DayOfWeek.Monday:
      return 1;
    case DayOfWeek.Tuesday:
      return 2;
    case DayOfWeek.Wednesday:
      return 3;
    case DayOfWeek.Thursday:
      return 4;
    case DayOfWeek.Friday:
      return 5;
    case DayOfWeek.Saturday:
      return 6;
    case DayOfWeek.Sunday:
      return 7;
  }
}

export function formatDurationInMinutesToTimeZoneOffsetString(
  durationInMinutes: number
) {
  const duration = moment.duration(durationInMinutes, 'minutes').abs();

  const sign = durationInMinutes >= 0 ? '+' : '-';
  const hours = duration.hours().toString().padStart(2, '0');
  const minutes = duration.minutes().toString().padStart(2, '0');

  return `${sign}${hours}:${minutes}}`;
}

export function getDaysFromTime(t: number) {
  return Math.floor(t / (1000 * 60 * 60 * 24));
}

export function getHoursFromTime(t: number) {
  return Math.floor((t / (1000 * 60 * 60)) % 24);
}

export function getMinutesFromTime(t: number) {
  return Math.floor((t / 1000 / 60) % 60);
}

export function getSecondsFromTime(t: number) {
  return Math.floor((t / 1000) % 60);
}

export function isDispoAccount(
  account: { refLtmsAccountNumber?: string } | null
) {
  return account?.refLtmsAccountNumber?.includes('DIS');
}

export function isMinorAmount({
  quantityType,
  quantity,
  loadCarrierTypeId,
}: {
  quantityType: OrderQuantityType;
  quantity: number;
  loadCarrierTypeId: number;
}) {
  switch (quantityType) {
    case OrderQuantityType.Stacks:
    case OrderQuantityType.Load: {
      return false;
    }
    case OrderQuantityType.LoadCarrierQuantity: {
      // if EUR => minor <= 100
      if (loadCarrierTypeId === 8 && quantity <= 100) {
        return true;
      }

      // if DD => minor <= 132
      else if (loadCarrierTypeId === 3 && quantity <= 132) {
        return true;
      }

      return false;
    }

    default: {
      throw new Error('Unknown quantity type');
    }
  }
}

export function calculateShowIsPaidNote(
  account: { refLtmsAccountNumber?: string } | null,
  data: {
    quantityType: OrderQuantityType;
    quantity: number;
    loadCarrierTypeId: number;
    orderType: OrderType;
  }
) {
  if (data.orderType === OrderType.Supply) {
    return false;
  }
  if (!isDispoAccount(account)) {
    return false;
  }

  if (isMinorAmount(data)) {
    return false;
  }

  return true;
}

function isBeforeOrEqualToHour(pointInTime: Date, hour: number): boolean {
  const hours = pointInTime.getHours();
  const minutes = pointInTime.getMinutes();

  if (hours < hour) {
    return true;
  }

  if (hours === hour && minutes === 0) {
    return true;
  }

  return false;
}

function addBusinessDays(startDate: Date, numberOfDays: number): Date {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const endDate = new Date(startDate.getTime());
  let count = 0;

  while (count < numberOfDays) {
    endDate.setTime(endDate.getTime() + millisecondsPerDay);

    const day = endDate.getDay();
    // handle sunday / saturday (non business days)
    if (day !== 0 && day !== 6) {
      count++;
    }
  }

  return endDate;
}

export function getEarliestDateForOrderForDispoCustomers() {
  const now = new Date();

  const numberOfDaysAhead = isBeforeOrEqualToHour(now, 10) ? 2 : 3;
  const earliestDate = addBusinessDays(now, numberOfDaysAhead);

  return moment(earliestDate).startOf('day').add(1, 'day').toDate();
}
