import { Component, OnInit } from '@angular/core';
import { UserService } from '../user/services/user.service';
import { map, tap } from 'rxjs/operators';
import { User } from '../core/services/dpl-api-services';
import { Observable, combineLatest } from 'rxjs';
import { IUser } from '../user/state/user.model';

import { MessagingChannelListStore } from './state/messaging-channel-list.store';
import { MessagingChannelListQuery } from './state/messaging-channel-list.query';
import { MessagingChannelListService } from './services/messaging-channel-list.service';
import { MessagingDataService } from '../messaging-data/services/messaging-data.service';
import { MemberUserDto } from '../generated/Dpl.B2b.Contracts.Chat.Shared';

interface IViewData {
  user: IUser<number, number>;
  isEmployeeView?:MemberUserDto;
}

@Component({
  selector: 'dpl-messaging-system',
  templateUrl: './messaging-system.component.html',
  styleUrls: ['./messaging-system.component.scss'],
  providers: [
    MessagingChannelListStore,
    MessagingChannelListQuery,
    MessagingChannelListService,
  ],
})
export class MessagingSystemComponent implements OnInit {
  showChannel = true;
  openChannelList = true;
  open = true;
  resizableWidthChannelList = 900
  showEmployeeList = false;
  showOtherLayout = false;
  checkBoxValue: boolean | null = false;
  currentUser: User;
  viewData$: Observable<IViewData>;
  totalWidth: number;
  messageListHight = 517;
  copyText = '';
  channelTypeText$: Observable<string>;
  
  constructor(
    private userService: UserService,
    private messagingChannelListService: MessagingChannelListService,
    private messagingDataService:MessagingDataService
  ) { }

  ngOnInit(): void {
    const isEmployeeView$ = this.messagingDataService.getUserImpersonated();
    this.channelTypeText$ = this.messagingChannelListService.getActiveChannelTypeText();
    //Current User who is logged in
    const currentUser$ = this.userService.getCurrentUser().pipe(
      tap((currentUser) => {
        if (currentUser) {
          this.currentUser = {
            ...currentUser,
            externalPostingAccounts: null,
            postingAccounts: null,
            customers: null,
          };
        }
      })
    );




    this.viewData$ = combineLatest([
      currentUser$,
      isEmployeeView$,
      this.messagingChannelListService.initializeChannelList(),
    ]).pipe(
      map(([user,isEmployeeView]) => {
        const viewData: IViewData = {
          user,
          isEmployeeView
        };

        return viewData;
      })
    );

  }


  hideChannelList(e) {
    if (e === true) {
      this.openChannelList = false
    }
  }

}
