import { Component, Inject, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import {
  Address,
  LoadingLocationAddress,
  LoadingLocationCreateRequest,
} from '../../../core/services/dpl-api-services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ILoadingLocation } from '../../state/loading-location.model';
import { GoogleMapsService } from '../../../search/services/google-maps.service';
import { map } from 'rxjs/operators';
import { DplApiService, WrappedError } from '../../../core';
import { FormGroup, FormControl } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';
import { GeocoderResult } from '@agm/core';

type ViewData = {
  addresses: LoadingLocationAddress[];
};

type AddressValidationForm = {
  address: LoadingLocationAddress;
};

@Component({
  selector: 'dpl-loading-location-create-address-check-dialog',
  templateUrl: './loading-location-create-address-check-dialog.component.html',
  styleUrls: ['./loading-location-create-address-check-dialog.component.scss'],
})
export class LoadingLocationCreateAddressCheckDialogComponent
  implements OnInit
{
  lockSubmit = false;
  public formGroup: FormGroup<AddressValidationForm>;

  public viewData$: Observable<ViewData>;
  constructor(
    public dialogRef: MatDialogRef<
      LoadingLocationCreateAddressCheckDialogComponent,
      LoadingLocationCreateRequest
    >,
    @Inject(MAT_DIALOG_DATA)
    private data: LoadingLocationCreateRequest,
    private google: GoogleMapsService
  ) {}

  ngOnInit(): void {
    const manualAddress = this.data.address;

    this.formGroup = new FormGroup<AddressValidationForm>({
      address: new FormControl(manualAddress, Validators.required),
    });

    const addresses$ = this.google
      .getGeocodes({
        address: `${manualAddress.street1} ${manualAddress.postalCode}`,
      })
      .pipe(
        map(({ response, status }) => {
          const googleAddresses = (response || [])
            .map((result) => googleToLoadingLocationAddress(result))
            .filter((address) => !!address.street1)
            .map((address) => {
              return {
                ...address,
                street1: `${address.street1} ${address.street2}`,
                street2: undefined,
              };
            })
            .filter((address) => {
              const isSameAsManualAddress =
                address.street1 === manualAddress.street1 &&
                address.postalCode === manualAddress.postalCode &&
                address.city === manualAddress.city &&
                address.countryIso2Code === manualAddress.countryIso2Code;

              return !isSameAsManualAddress;
            });

          return [manualAddress, ...googleAddresses];
        })
      );

    this.viewData$ = combineLatest([addresses$]).pipe(
      map(([addresses]) => {
        const viewData: ViewData = {
          addresses,
        };

        return viewData;
      })
    );
  }

  public onCancel() {
    this.dialogRef.close();
  }

  public onConfirmAddress() {
    if (this.lockSubmit) {
      return;
    }

    const createRequest = {
      ...this.data,
      address: this.formGroup.controls.address.value,
    };

    this.dialogRef.close(createRequest);
  }
}

function googleToLoadingLocationAddress(
  result: google.maps.GeocoderResult
): LoadingLocationAddress {
  const address: LoadingLocationAddress = {};
  for (let i = 0; i < result.address_components.length; i++) {
    const component = result.address_components[i];
    for (let j = 0; j < component.types.length; j++) {
      const type = component.types[j];
      if (type === 'route') {
        address.street1 = component.long_name;
      } else if (type === 'street_number') {
        address.street2 = component.long_name;
      } else if (type === 'postal_code') {
        address.postalCode = component.long_name;
      } else if (type === 'locality') {
        address.city = component.long_name;
      }
      // else if (type === 'administrative_area_level_1') {
      //   address.stateIso2Code = component.short_name;
      // }
      else if (type === 'country') {
        address.countryIso2Code = component.short_name;
      }
    }
  }

  return address;
}
