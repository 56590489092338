import { Injectable } from '@angular/core';
import {
  MessagingChannelListItem,
  MessagingChannelListStore,
} from '../state/messaging-channel-list.store';
import { MessagingChannelListQuery } from '../state/messaging-channel-list.query';
import { MessagingDataService } from '../../messaging-data/services/messaging-data.service';
import {
  first,
  map,
  skipUntil,
  skipWhile,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import _ from 'lodash';
import { ChatChannelType } from '../../generated/Dpl.B2b.Common.Enumerations';
import { ChatChannelStatePipe } from '../pipes/chat-channel-state.pipe';
import { ChatChannelTypePipe } from '../pipes/chat-channel-type.pipe';
import { LoadingService } from '../../../../../../libs/dpl-lib/src/lib/services/loading.service';
import { Observable, timer } from 'rxjs';

@Injectable()
export class MessagingChannelListService {
  constructor(
    private loadingService: LoadingService,
    private messagingListStore: MessagingChannelListStore,
    private messagingChannelListQuery: MessagingChannelListQuery,
    private messagingChannelDataService: MessagingDataService,
    private chatChannelTypePipe: ChatChannelTypePipe
  ) { }

  allChannelKey = '-1';
  allChannelTitle = 'Alle'; //TODO Translation handling

  initializeChannelList() {
    let initial = true;

    return this.messagingChannelDataService.ticketsAll().pipe(
      tap((tickets) => {
        console.log('initializeChannelList ticketsAll', tickets);
        const channelList = _.map(
          _.groupBy(tickets, (x) => x.channelType),
          (value, key) => {
            // return ChannelListItem
            const listItem: MessagingChannelListItem = {
              key: ChatChannelType[key],
              title: this.chatChannelTypePipe.transform(ChatChannelType[key]),
              messagesCount: _.sumBy(value, (x) => x.messageCount),
              unreadCount: _.sumBy(value, (x) => x.unreadCount),
            };
            return listItem;
          }
        );

        this.messagingListStore.set([
          {
            key: this.allChannelKey,
            title: this.allChannelTitle,
            messagesCount: _.sumBy(channelList, (x) => x.messagesCount),
            unreadCount: _.sumBy(channelList.filter(channel => {
              if (channel.key !== ChatChannelType[1000]) {
                return channel
              } else {
                return false
              }

            }), (x) => x.unreadCount),
          },
          ...channelList,
        ]);

        if (initial) {
          this.activateChannelListItem(this.allChannelKey, initial);
          initial = false;
        }
      }),
      //Why we need this map?
      map(() => { })
    );
  }

  activateChannelListItem(activeChannelKey: string, initial?: boolean) {
    this.messagingListStore.setActive(activeChannelKey);
    const timer$ = timer(30000);
    const type: ChatChannelType | undefined =
      activeChannelKey !== this.allChannelKey
        ? ChatChannelType[activeChannelKey]
        : undefined;

    this.messagingChannelDataService
      .ticketsAll(type)
      .pipe(
        first(),
        // this.loadingService.showLoadingWhile(),
        // skipWhile((channels, index) => {
        //   return (
        //     channels.length === 0 &&
        //     initial !== (false || undefined) &&
        //     index < 1
        //   );
        // }),
        // takeUntil(timer$),
        // take(1),
        tap((channels) => {
          console.log('activateChannelListItem ticketsAll', channels);
          if (channels?.length === 0) {
            return;
          }
          const firstChannelIndex = channels.findIndex(channel => channel.channelType !== ChatChannelType.Announcement)
          const channel = activeChannelKey !== this.allChannelKey
            ? channels[0]
            : firstChannelIndex !== -1
              ? channels[firstChannelIndex] //Show first Channel that is not an Announcement
              : channels[0]; //Show Announcement as First Selected Channel

          //TODO: Maybe setActiveTicket only if the last Active Ticket isn't in the channels array
          this.messagingChannelDataService.setTicketActive(channel.channelId);
        })
      )
      .subscribe();
  }

  getChannelListItems() {
    return this.messagingChannelListQuery.selectAll();
  }

  getActiveChannelKey() {
    return this.messagingChannelListQuery
      .selectActiveId()
      .pipe(map((x) => (!!x ? x : this.allChannelKey)));
  }

  getActiveChannelTypeText() {
    return this.messagingChannelListQuery
      .selectActive()
      .pipe(map((x) => (!!x ? x.title : this.allChannelTitle)));
  }
}
