<div
  class="popover-messaging-system-channel-state"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="dialog-top-part">
    <div mat-dailog-title class="message-list">
      <div class="message-list-header">
        <dpl-dialog-header [showOwnIcons]="true">
          <!-- Remember the ng-content is in a flex-direction row reverse -->
          <span headerText>
            <span i18n="@@ChangeChannelStateDialogHeaderText"
              >Schließen des Vorgangs:
            </span>
            {{ channel.channelTitle }}
          </span>
          <div headerIcons>
            <dx-button
              icon="close"
              hint="Fenster schließen"
              i18n-hint="@@CloseDialogHint"
              (click)="onCancel()"
            >
            </dx-button>
          </div>
        </dpl-dialog-header>
      </div>
    </div>

    <div class="dialog-content">
      <div class="dialog-content-info-text">
        <p class="info-text" i18n="@@ChangeChannelStateDialogInfoText">
          Nach Klick auf "Ja" wird der Vorgang als abgeschlossen gekennzeichnet.
        </p>
        <!-- TODO: add a dropdown or something -->
      </div>
    </div>
  </div>
  <div class="dialog-bottom-part">
    <div class="dialog-content-question">
      <p class="text" i18n="@@ChangeChannelStateDialogQuestionText">
        Möchten Sie den Status ändern?
      </p>
    </div>
    <div class="dialog-content-question-actions">
      <dx-button
        hint="Schließe das Fenster"
        i18n-hint="@@CloseIconHint"
        text="Nein"
        i18n-text="@@CloseIconText"
        (onClick)="onCancel()"
      ></dx-button>
      <dx-button
        hint="Ändern des Status"
        i18n-hint="@@SaveChangeChannelStateDialogHint"
        (click)="closeTicket()"
        icon="save"
        text="Ja"
        i18n-text="@@Yes"
      ></dx-button>
    </div>
  </div>
</div>
