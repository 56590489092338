<div class="bottomFooter">
  <mat-toolbar class="footerRow" fxLayoutGap="20px">
    <a
      [routerLink]="['imprint']"
      class="footerLink"
      i18n="Impressum|Link for Impressum@@FooterImpressumLinkText"
      >Impressum</a
    >
    <a
      [routerLink]="['privacy']"
      class="footerLink"
      i18n="
        FooterDataProtectionText|Link for
        Datenschutzerklärung@@FooterDataProtectionText"
      >Datenschutzerklärung</a
    >
    <a
      [routerLink]="['support']"
      class="footerLink"
      i18n="FooterSupportPageText|Link for Support@@FooterSupportPageText"
      >Support</a
    >
    <a
      [routerLink]="['service-health']"
      class="footerLink"
      i18n="
        FooterServiceHealthPageText|Link for Service
        Health@@FooterServiceHealthPageText"
      >Service</a
    >
  </mat-toolbar>
</div>
