import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DplProblemDetails,
  ExpressCode,
  ExpressCodesApiService,
  CustomerPartner,
  PartnerType,
  StateItem,
  VoucherType,
  PrintType,
} from '@app/api/dpl';
import { LoadCarrierQuantity } from '@app/shared';
import { ErrorService, ValidationDataService } from '@app/shared/services';
import { LoadingService, NgxSingleFieldSubFormComponent } from '@dpl/dpl-lib';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomerDivisionsService } from '../../../customers/services/customer-divisions.service';
import { AccountsService } from '../../../accounts/services/accounts.service';
import { filterNil } from '@datorama/akita';
import {
  AppErrorHandlerService,
  LocalizationService,
  NotificationService,
} from '../../../core';
import { IndividualConfig } from 'ngx-toastr';

export interface VoucherExpressCode extends ExpressCode {
  demoProperties?: {
    recipient?: CustomerPartner | null;
    shipper?: CustomerPartner | null;
    subShipper?: CustomerPartner | null;
    supplier?: CustomerPartner | null;
    ntg?: number;
    voucherType?: VoucherType;
    loadCarrier?: LoadCarrierQuantity[];
  };
}

export type ExpressCodePreset = keyof Pick<
  ExpressCode,
  'voucherPresets' | 'destinationAccountPreset' | 'loadCarrierReceiptPreset'
>;

type ViewData = {
  icon?: 'check_circle' | 'remove_circle';
  color?: 'primary' | 'accent' | 'warn';
};

type ExpressCodeStatus = 'valid' | 'invalid' | 'resetted';

@Component({
  selector: 'dpl-express-code',
  template: `
    <ng-container *ngIf="viewData$ | async as data">
      <div fxLayout="row" *ngIf="formGroup" [formGroup]="formGroup">
        <mat-form-field fxFlex="1 0 auto" appearance="outline">
          <mat-label i18n="DigitalCode|Label Digital-Code@@DigitalCode"
            >DPL-Digital-Code</mat-label
          >

          <input
            matInput
            placeholder="DPL-Digital-Code"
            [formControl]="formGroup.controls.innerControl"
            (keydown.enter)="onValidateExpressCode()"
            i18n-placeholder="DigitalCode|Label Digital-Code@@DigitalCode"
          />

          <mat-icon *ngIf="data.icon" matSuffix [color]="data.color">{{
            data.icon
          }}</mat-icon>
          <mat-error *ngIf="formGroup.controls.innerControl.errors?.minlength">
            <span i18n="MinLength|Die Eingabe ist zu kurz@@MinLength"
              >Die Eingabe ist zu kurz</span
            >
          </mat-error>
          <mat-error *ngIf="formGroup.controls.innerControl.errors?.maxlength">
            <span i18n="MaxLength|Die Eingabe ist zu lang@@MaxLength"
              >Die Eingabe ist zu lang</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  `,
  styles: [],
})
export class ExpressCodeComponent
  extends NgxSingleFieldSubFormComponent<string>
  implements OnInit
{
  @Input() preset: ExpressCodePreset;
  @Input() printType: PrintType;
  
  @Input() noActionCode:string; //used in load carrier receipt edit

  @Output() validatedExpressCode = new EventEmitter<ExpressCode>();

  private override = {
    closeButton: true,
    tapToDismiss: true,
  } as Partial<IndividualConfig>;

  loading$ = new BehaviorSubject<boolean>(false);
  expressCodeStatus$ = new BehaviorSubject<ExpressCodeStatus>('resetted');

  viewData$: Observable<ViewData>;

  constructor(
    private expressCodeService: ExpressCodesApiService,
    private customerDivisionsService: CustomerDivisionsService,
    private accountsService: AccountsService,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private appErrorHandlerService: AppErrorHandlerService,
    private notification: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    const expressCode$ = this.formGroup.controls.innerControl.valueChanges.pipe(
      startWith(this.formGroup.controls.innerControl.value),
      distinctUntilChanged(),
      tap((value) => console.log('expressCode$', value)),
      tap(() => this.expressCodeStatus$.next('resetted')),
      tap(() => this.formGroup.controls.innerControl.markAsTouched()),
      tap((code) => {
        this.formGroup.updateValueAndValidity();
        if (code?.length === ValidationDataService.maxLength.expressCode && code !== this.noActionCode) {
          this.onValidateExpressCode();
        } else if(this.noActionCode && code===this.noActionCode){
          this.expressCodeStatus$.next('valid')
         }
      }),
      switchMap(() => EMPTY),
      startWith(null)
    );

    const expressCodeStatus$ = this.expressCodeStatus$.asObservable();

    this.viewData$ = combineLatest([expressCodeStatus$, expressCode$]).pipe(
      map(([status]) => {
        const viewData: ViewData = this.getViewData(status);
        return viewData;
      })
    );
  }

  getViewData(status: ExpressCodeStatus): ViewData {
    switch (status) {
      case 'valid':
        return {
          icon: 'check_circle',
          color: 'primary',
        };
      case 'invalid':
        return {
          icon: 'remove_circle',
          color: 'warn',
        };

      default:
        return {};
    }
  }

  findStateItem(detail: DplProblemDetails, code: string): StateItem | null {
    let retValue: StateItem = null;
    detail.ruleStates.forEach((items) => {
      items.forEach((item) => {
        if ((item) => item.messageId === code) {
          retValue = item;
        }
      });
    });
    return retValue;
  }

  onValidateExpressCode() {
    console.log(this.formGroup);
    if (this.formGroup.valid && this.formGroup.value) {
      const code = this.formGroup.value.toUpperCase();

      combineLatest([
        this.accountsService.getActiveAccount().pipe(filterNil),
        this.customerDivisionsService.getActiveDivision().pipe(filterNil),
      ])
        .pipe(
          first(),
          switchMap(([account, division]) => {
            return this.expressCodeService
              .getByCode({
                expressCode: code,
                printType: this.printType,
                issuingCustomerDivisionId: division.id,
                issuingPostingAccountId: account.id,
              })
              .pipe(
                catchError((error) => {
                  this.formGroup.controls.innerControl.reset();
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                }),
                tap((data) => {
                  if (data) {
                    if (!data[this.preset]) {
                      this.formGroup.controls.innerControl.reset();
                      this.expressCodeStatus$.next('invalid');
                      this.notification.showError(
                        $localize`:DigitalCodeNotAllowedToUseHere|Error Message Digital Code isn't allowed here@@ExpressCodeDigitalCodeNotAllowedToUseHereErrorMessage:Der Digital-Code kann hier nicht verwendet werden.`,
                        $localize`:UndefinedError|UndefinedError@@UndefinedError:Fehlermeldung`,
                        this.override
                      );
                      return;
                    }

                    this.validatedExpressCode.next(data);
                    this.expressCodeStatus$.next('valid');
                  }
                }),
                this.loadingService.showLoadingWhile()
              );
          })
        )
        .subscribe();
    }
  }

  protected getFormControl() {
    return new UntypedFormControl(null, [
      Validators.minLength(ValidationDataService.maxLength.expressCode),
      Validators.maxLength(ValidationDataService.maxLength.expressCode),
    ]);
  }
}
