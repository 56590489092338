import {
  Component,
  Inject,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import * as _ from 'lodash';
import { subformComponentProviders } from 'ngx-sub-form';
import { of } from 'rxjs';

import { NgxSingleFieldSubFormComponent } from '../../../../../../../libs/dpl-lib/src';
import { AuthenticationService, DplApiService } from '../../../core';
import {
  API_BASE_URL, Customer,
  CustomerPartner,
  CustomerPartnerLookup,
  PartnerDirectoryType,
  PartnerType
} from '../../../core/services/dpl-api-services';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { CustomersService } from '../../../customers/services/customers.service';
import { filterNil } from '@datorama/akita';

@Component({
  selector: 'dpl-ext-account-picker',
  templateUrl: './ext-account-picker.component.html',
  styleUrls: ['./ext-account-picker.component.scss'],
  providers: subformComponentProviders(ExtAccountPickerComponent),
})
export class ExtAccountPickerComponent
  extends NgxSingleFieldSubFormComponent<CustomerPartner>
  implements OnChanges {
  partnersDs: DataSource;
  baseUrl: string;
  activeCustomerId: number;
  constructor(
    private authenticationService: AuthenticationService,
    private customersService: CustomersService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super();
    this.baseUrl = baseUrl;
    this.customersService.getActiveCustomer().pipe(filterNil).subscribe(customer => {
      this.activeCustomerId = customer.id;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.partnersDs = new DataSource({
      store: AspNetData.createStore({
        key: ['id', 'directoryId'],
        loadUrl: this.baseUrl + '/partners/search',
        loadParams: {
          partnerDirectoryTypes: [PartnerDirectoryType.ExternalAccountOnly],
          customerId: this.activeCustomerId
        },
        onBeforeSend: this.authenticationService.addAuthHeaderForDevExpress(),
      }),
      filter: [
        ['ExternalPostingAccountId', '>', 0]
      ],
      sort:[{"selector":"CompanyName","desc":false}]
    });
  }

  displayExpr(data?: CustomerPartnerLookup) {
    if (!data) {
      return;
    }

    return `${data.companyName} (${data.directoryName})`;
  }

  protected transformFromFormGroup(formValue: any): any {
    return super.transformFromFormGroup(formValue);
  }

  transformToFormGroup(request: any): any {
    return super.transformToFormGroup(request);
  }
}
