import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CreateMultiProcessUploadPresetsResponseProcess } from '../../core/services/dpl-api-services';
import { ISupportingDocumentsState } from './supporting-documents.store';

export interface IProcessesState extends CreateMultiProcessUploadPresetsResponseProcess{  
  supportingDocuments: ISupportingDocumentsState[]|null;
  modified?:boolean;
}

export interface ProcessesState extends EntityState<IProcessesState> {
  
}

@Injectable()
@StoreConfig({ name: 'processes' })
export class ProcessesStore extends EntityStore<ProcessesState> {

  constructor() {
    super();
  }

}
