import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { subformComponentProviders } from 'ngx-sub-form';
import { title } from 'process';
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { APP_CONFIG, DplLiveConfiguration } from '../../../../config';
import { AddressesQuery } from '../../../addresses/state/addresses.query';
import { AddressesStore } from '../../../addresses/state/addresses.store';
import {
  AppErrorHandlerService,
  DplApiService,
  WrappedError,
} from '../../../core';
import {
  Address,
  API_BASE_URL,
  LoadingLocationCreateRequest,
  LoadingLocationOwnershipType,
  LoadingLocationType,
  OrderType,
  ResourceAction,
} from '../../../core/services/dpl-api-services';
import { ICustomerDivision } from '../../../customers/state/customer-division.model';
import { ILoadingLocation } from '../../../loading-locations/state/loading-location.model';
import { LoadingLocationsQuery } from '../../../loading-locations/state/loading-locations.query';
import { LoadingLocationsStore } from '../../../loading-locations/state/loading-locations.store';
import {
  DynamicConfirmationDialogComponent,
  DynamicConfirmationDialogData,
  DynamicConfirmationDialogResult,
  LoadingLocationOwnershipTypePipe,
  LoadingLocationTypePipe,
} from '../../../shared';
import {
  LoadingLocationCreateDialogComponent,
  LoadingLocationDialogData,
} from '../loading-location-create-dialog/loading-location-create-dialog.component';
import {
  LoadingLocationEditDialogComponent,
  LoadingLocationEditDialogData,
} from '../loading-location-edit-dialog/loading-location-edit-dialog.component';
import { LoadingLocationCreateAddressCheckDialogComponent } from '../loading-location-create-address-check-dialog/loading-location-create-address-check-dialog.component';

export type LoadingLocationLookupForm = {
  loadingLocation: ILoadingLocation;
  searchValue: string;
};

type ViewData = {
  gridDataSource: DataSource;
  loadingLocation: ILoadingLocation<Address>;
};

@Component({
  selector: 'dpl-loading-location-lookup',
  templateUrl: './loading-location-lookup.component.html',
  styleUrls: ['./loading-location-lookup.component.scss'],
  providers: subformComponentProviders(LoadingLocationLookupComponent),
})
export class LoadingLocationLookupComponent implements OnInit {
  @Input() currentDivision: ICustomerDivision<ILoadingLocation<Address>>;
  @Input() title?: string;
  @Input() placeholder?: '';
  @Input() required?: boolean = false;
  @Input() orderType: OrderType;
  @Input() preSelectedLocationId?: number;
  @ViewChild('gridBox') gridBox: DxDropDownBoxComponent;

  protected emitInitialValueOnInit = false;
  baseUrl: string;
  searchValue: string = '';
  dropDownOpened = false;
  dropDownValue: any[];
  dropDownDisplayValue: any[];
  dropDownOptions: any;
  viewData$: Observable<ViewData>;
  formGroup: FormGroup<LoadingLocationLookupForm>;
  resourceAction = ResourceAction;

  constructor(
    @Inject(APP_CONFIG) public config: DplLiveConfiguration,
    private dialog: MatDialog,
    private addressQuery: AddressesQuery,
    private cd: ChangeDetectorRef,
    private addressStore: AddressesStore,
    private loadingLocationStore: LoadingLocationsStore,
    private fb: FormBuilder,
    private loadingLocationsQuery: LoadingLocationsQuery,
    private dpl: DplApiService,
    private typePipe: LoadingLocationTypePipe,
    private ownershipType: LoadingLocationOwnershipTypePipe,
    private appErrorHandlerService: AppErrorHandlerService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      loadingLocation: new UntypedFormControl(),
      searchValue: new UntypedFormControl(),
    });

    //update loading location on init
    const loadingLocationSearchRequest = {
      customerDivisionId: this.currentDivision.id,
    };
    // console.log('loadingLocationSearchRequest', loadingLocationSearchRequest);
    const loadingLocations$ = this.dpl.loadingLocations
      .get(loadingLocationSearchRequest)
      .pipe(
        tap((loadingLocations) => {
          this.loadingLocationStore.upsertMany(
            loadingLocations.map((x) => {
              return { ...x, address: x.address?.id };
            })
          );
          this.addressStore.upsertMany(loadingLocations.map((x) => x.address));
        })
      );

    // TODO set initial loadingLocation - ToDo set defaultLoadingLocation for division
    if (this.currentDivision.loadingLocations?.length > 0) {
      const possibleLocations = this.currentDivision.loadingLocations.filter(
        (x) =>
          this.orderType === OrderType.Supply
            ? x.type === LoadingLocationType.Loading || !x.type
            : x.type === LoadingLocationType.Unloading || !x.type
      );

      if (possibleLocations.length > 0) {
        console.log('preSelectedLocationId', this.preSelectedLocationId);
        if (this.preSelectedLocationId) {
          this.formGroup.controls.loadingLocation.patchValue(
            possibleLocations.find((x) => x.id === this.preSelectedLocationId)
          );
        } else {
          this.formGroup.controls.loadingLocation.patchValue(
            this.currentDivision.defaultLoadingLocationId &&
              possibleLocations.find(
                (x) => x.id === this.currentDivision.defaultLoadingLocationId
              )
              ? possibleLocations.find(
                  (x) => x.id === this.currentDivision.defaultLoadingLocationId
                )
              : possibleLocations[0]
          );
        }
      }
    }

    const loadingLocation$: Observable<ILoadingLocation<Address>> =
      this.formGroup.controls.loadingLocation.valueChanges.pipe(
        startWith(this.formGroup.value.loadingLocation),
        distinctUntilChanged(),
        tap((loadingLocation) => {
          // console.log('loadingLocationChanges', loadingLocation);
          if (loadingLocation?.id) {
            this.loadingLocationStore.setActive(loadingLocation.id);
          } else {
            this.loadingLocationStore.setActive(null);
          }
        })
      );

    const searchValue$ = this.formGroup.controls.searchValue.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged()
    );
    this.viewData$ = combineLatest([
      loadingLocation$,
      searchValue$,
      loadingLocations$,
    ]).pipe(
      map(([loadingLocation, searchValue, loadingLocations]) => {
        const locationType =
          this.orderType == OrderType.Supply
            ? LoadingLocationType.Loading
            : LoadingLocationType.Unloading;
        const gridDataSource = new DataSource({
          store: new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: async () => {
              return combineLatest([
                this.loadingLocationsQuery
                  .selectAll({
                    filterBy: (entity) =>
                      entity.type == null || entity.type === locationType,
                  })
                  .pipe(
                    first(),
                    catchError((error) => {
                      if (error.isApiException) {
                        throw this.appErrorHandlerService.convertGridError(
                          error
                        );
                      }
                      throw error;
                    })
                  ),
                this.addressQuery.selectAll().pipe(
                  first(),
                  catchError((error) => {
                    if (error.isApiException) {
                      throw this.appErrorHandlerService.convertGridError(error);
                    }
                    throw error;
                  })
                ),
              ])
                .pipe(
                  map(([loadingLocations, addresses]) => {
                    return loadingLocations.map((location) => {
                      return {
                        ...location,
                        address: addresses.find(
                          (x) => x.id === location.address
                        ),
                      };
                    });
                  })
                )
                .toPromise();
            },
          }),
          searchExpr: [
            'id',
            'displayName',
            'address.street1',
            'address.postalCode',
            'address.city',
            'address.stateName',
            'address.countryName', //Todo check if countryName search works
          ],
          searchValue: searchValue,
        });
        const viewData = {
          loadingLocation,
          gridDataSource,
        };
        return viewData;
      })
    );
  }

  onSelectionloadingLocationDataGridChanged(
    selectedLocation: ILoadingLocation<Address>,
    event
  ) {
    this.gridBox.instance.close();
    if (selectedLocation) {
      this.dialog
        .open<
          DynamicConfirmationDialogComponent,
          DynamicConfirmationDialogData,
          DynamicConfirmationDialogResult
        >(DynamicConfirmationDialogComponent, {
          data: {
            labels: {
              title: $localize`:@@LoadingLocationChangeTitle:Ladestelle wechseln`,
              description: $localize`:@@LoadingLocationChangeDescription:Beim Wechsel der Ladestelle gehen alle bisherigen Eingaben verloren. Sind Sie sicher?`,
              styleDescription: true,
            },
            print: {
              show: false,
            },
            redirect: { show: false },
            defaultValues: {
              print: false,
              redirect: false,
            },
          },
          disableClose: true,
          autoFocus: false,
          width: '600px'
        })
        .afterClosed()
        .pipe(
          first(),
          tap((result) => {
            if (result?.confirmed) {
              this.changeLoadingLocationSelection(event);
            }
          })
        )
        .subscribe();
    } else {
      this.changeLoadingLocationSelection(event);
    }
  }

  changeLoadingLocationSelection(event) {
    this.dropDownValue =
      event?.selectedRowsData?.length > 0
        ? event?.selectedRowsData[0]
          ? event?.selectedRowsData[0]
          : null
        : null;
    this.dropDownOpened = false;
    this.cd.detectChanges();
  }

  clearSearchValue() {
    this.formGroup.controls.searchValue.reset();
  }

  onDropDownOptionChanged(e) {
    if (e.name === 'value') {
      this.dropDownOpened = false;
      this.cd.detectChanges();
    }
  }

  onCreateNewLoadingLocation() {
    this.dropDownOpened = false;
    const data: LoadingLocationDialogData = { orderType: this.orderType };

    return this.dialog
      .open<
        LoadingLocationCreateDialogComponent,
        LoadingLocationDialogData,
        LoadingLocationCreateRequest
      >(LoadingLocationCreateDialogComponent, {
        data: data,
        width: '800px',
        // height: '800px',
      })
      .afterClosed()
      .pipe(
        switchMap((createRequest) => {
          if (!createRequest) {
            return of(null);
          }

          return this.dialog
            .open<
              LoadingLocationCreateAddressCheckDialogComponent,
              LoadingLocationCreateRequest,
              LoadingLocationCreateRequest
            >(LoadingLocationCreateAddressCheckDialogComponent, {
              data: createRequest,
              width: '800px',
            })
            .afterClosed();
        }),
        first(),
        filter((createRequest) => !!createRequest),
        switchMap((createRequest) => {
          //add customer division id to createRequest
          return this.dpl.loadingLocations.post({...createRequest, customerDivisionId:this.currentDivision.id}).pipe(
            map((successLoadingLocation) => {
              const loadingLocation: ILoadingLocation<Address> = {
                ...successLoadingLocation,
                address: {
                  ...createRequest.address,
                  id: successLoadingLocation.address.id,
                  country: successLoadingLocation.address?.country,
                  countryName: successLoadingLocation.address?.countryName,
                },
              };

              return loadingLocation;
            })
          );
        }),
        tap({
          next: (loadingLocation) => {
            if (!loadingLocation) {
              return;
            }

            //add to stores
            this.addressStore.add(loadingLocation.address);
            this.loadingLocationStore.add({
              ...loadingLocation,
              address: loadingLocation.address.id,
              detail: {
                stackHeightMax: loadingLocation.detail?.stackHeightMax,
                stackHeightMin: loadingLocation.detail?.stackHeightMin,
                supportsJumboVehicles:
                  loadingLocation.detail?.supportsJumboVehicles,
                supportsPartialMatching:
                  loadingLocation.detail?.supportsPartialMatching,
                supportsRearLoading:
                  loadingLocation.detail?.supportsRearLoading,
                supportsSideLoading:
                  loadingLocation.detail?.supportsSideLoading,
              },
            });
            // this.loadingLocationStore.setActive(response.id);
            this.formGroup.controls.loadingLocation.patchValue(loadingLocation);
          },
          error: (error: WrappedError) => {
            console.log('error');
            throw error; // Display error is handled by app-error-handler
          },
        })
      )
      .subscribe();
  }

  displayNmaeDisplayExpr(item: ILoadingLocation<Address>) {
    return item.displayName
      ? item.displayName
      : item.id + ' - ' + item.address?.city;
  }

  getDropDownDisplayExpr = (item: ILoadingLocation<Address>) => {
    const displayName = item?.displayName
      ? item.displayName
      : item?.id + ' - ' + item?.address?.city;
    let typeText = '';
    switch (item?.type) {
      case LoadingLocationType.Loading:
        typeText = this.typePipe.transform(LoadingLocationType.Loading);
        break;
      case LoadingLocationType.Unloading:
        typeText = this.typePipe.transform(LoadingLocationType.Unloading);
        break;
      default:
        typeText = `${this.typePipe.transform(
          LoadingLocationType.Unloading
        )}, ${this.typePipe.transform(LoadingLocationType.Loading)}`;
        break;
    }

    const ownershipText =
      item?.ownershipType === LoadingLocationOwnershipType.Self
        ? this.ownershipType.transform(LoadingLocationOwnershipType.Self)
        : this.ownershipType.transform(LoadingLocationOwnershipType.Other);
    return `${displayName} (${typeText}, ${ownershipText})`;
  };

  getAdressString(address) {
    return address
      ? `${address.city}, ${address.postalCode}, ${address.street1}`
      : '';
  }

  editLoadingLocation(location: ILoadingLocation<Address>) {
    const data: LoadingLocationEditDialogData = {
      orderType: this.orderType,
      existingLocation: location,
    };

    const dialog = this.dialog.open<
      LoadingLocationEditDialogComponent,
      LoadingLocationEditDialogData,
      ILoadingLocation<Address>
    >(LoadingLocationEditDialogComponent, {
      data: data,
      width: '800px',
      // height: '800px',
    });

    dialog
      .afterClosed()
      .pipe(
        first(),
        tap((changedLocation) => {
          // console.log('after edit');
          if (!changedLocation) {
            return;
          }

          this.loadingLocationStore.replace(changedLocation.id, {
            ...changedLocation,
            address: changedLocation.address?.id,
          });
          this.formGroup.controls.loadingLocation.patchValue(null);
          this.formGroup.controls.loadingLocation.patchValue(changedLocation);
        })
      )
      .subscribe();
  }
  infoLoadingLocation(location: ILoadingLocation<Address>) {
    const data: LoadingLocationEditDialogData = {
      orderType: this.orderType,
      existingLocation: location,
      onlyInfo: true,
    };

    const dialog = this.dialog.open<
      LoadingLocationEditDialogComponent,
      LoadingLocationEditDialogData,
      ILoadingLocation<Address>
    >(LoadingLocationEditDialogComponent, {
      data: data,
      width: '800px',
      // height: '800px',
    });

    dialog.afterClosed().pipe(first()).subscribe();
  }
}
