import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import {
  ChannelDto,
  ChatUserDto,
  MemberUserDto,
} from '../../generated/Dpl.B2b.Contracts.Chat.Shared';

export enum ChannelTimeFilter {
  All = 'All',
  MoreThanOneDay = 'MoreThanOneDay',
  MoreThanThreeDays = 'MoreThanThreeDays',
  MoreThanSevenDays = 'MoreThanSevenDays',
}

export enum ChannelStatusFilter {
  All = 'All',
  Open = 'Open',
  InProgress = 'InProgress',
  Enclosed = 'Enclosed',
}

export interface ImpersonateChatUserDto extends ChatUserDto {
  self: boolean;
}

export function createInitialState(): MessagingChannelsState {
  return {
    initialized: undefined,
    active: undefined,
    impersonateUser: undefined,
    posibleResponsibleUsers: undefined,
    possibleEditorUsers: undefined,
    statusFilter: ChannelStatusFilter.All,
    timeFilter: ChannelTimeFilter.All,
    customerFilter:undefined,
    channelsInitialized: false,
  };
}

export interface IChannelDto extends ChannelDto {
  channelTypeString: string;
}

export interface MessagingChannelsState
  extends EntityState<IChannelDto, string>,
    ActiveState<string> {
  impersonateUser?: MemberUserDto;
  posibleResponsibleUsers?: ImpersonateChatUserDto[];
  possibleEditorUsers?: ImpersonateChatUserDto[];
  timeFilter?: ChannelTimeFilter;
  statusFilter?: ChannelStatusFilter;
  customerFilter?:number;
  initialized: boolean | undefined;
  channelsInitialized: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'messaging-channels',
  idKey: 'channelId',
  //disableDeepFreeze https://github.com/salesforce/akita/issues/371
  deepFreezeFn: (obj) => obj,
})
export class MessagingChannelsStore extends EntityStore<MessagingChannelsState> {
  constructor() {
    super(createInitialState());
  }
}
