import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadedFile, UploadedFilesStore } from '../../../process-confirmation/state/uploaded-files.store';


@Component({
  selector: 'dpl-show-uploaded-files',
  templateUrl: './show-uploaded-files.component.html',
  styleUrls: ['./show-uploaded-files.component.scss'],
})
export class ShowUploadedFilesComponent {
  @Input() item: UploadedFile
  @Input() index: number
  @Output() deleteItem = new EventEmitter<UploadedFile>();

  constructor(
    private uploadedFilesStore: UploadedFilesStore,
  ) {

  }

  removeItem(item: UploadedFile) {
    this.uploadedFilesStore.remove(item.fileId)
    this.deleteItem.emit(item)
  }


}
