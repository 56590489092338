import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  Address,
  LoadingLocationCreateRequest,
  OrderType,
} from '../../../core/services/dpl-api-services';
import { ILoadingLocation } from '../../state/loading-location.model';
import { LocationCreateUseCase, LocationCreateMode } from '../loading-location-create-form/loading-location-create-form.component';

export interface LoadingLocationDialogData {
  orderType: OrderType;
}

@Component({
  selector: 'dpl-loading-location-create-dialog',
  templateUrl: './loading-location-create-dialog.component.html',
  styleUrls: ['./loading-location-create-dialog.component.scss'],
})
export class LoadingLocationCreateDialogComponent implements OnInit {
  data: LoadingLocationDialogData;
  formValid = false;
  createRequest: LoadingLocationCreateRequest;
  locationCreateUseCase=LocationCreateUseCase;
  locationCreateMode=LocationCreateMode;

  constructor(
    public dialogRef: MatDialogRef<
      LoadingLocationCreateDialogComponent,
      LoadingLocationCreateRequest
    >,
    @Inject(MAT_DIALOG_DATA) data: LoadingLocationDialogData
  ) {
    this.data = data;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onCreateLoadingLocation(event: Event): void {
    this.dialogRef.close(this.createRequest);
  }

  formValidChange(valid) {
    this.formValid = valid;
  }

  onChange(createRequest: LoadingLocationCreateRequest) {
    this.createRequest = createRequest;
  }

  ngOnInit(): void {}
}

export type LoadingLocationCreateDialogResponse = {
  address: Address;
  loadingLocation: ILoadingLocation<number>;
};
