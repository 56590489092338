import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ExternalLoadCarrierQualitiesStore, ExternalLoadCarrierQualitiesState } from './external-load-carrier-qualities.store';

@Injectable({ providedIn: 'root' })
export class ExternalLoadCarrierQualitiesQuery extends QueryEntity<ExternalLoadCarrierQualitiesState> {

  constructor(protected store: ExternalLoadCarrierQualitiesStore) {
    super(store);
  }

}
