import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { EmployeeNoteType, EmployeeNote } from '@app/api/dpl';
import * as _ from 'lodash';

@Component({
  selector: 'dpl-note-info-icon',
  templateUrl: './note-info-icon.component.html',
  styleUrls: ['./note-info-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteInfoIconComponent implements OnInit {
  @Input() notes?: EmployeeNote[];
  @Input() detailRow?: boolean;
  @Output() selected: EventEmitter<boolean> = new EventEmitter();
  employeeNoteType? = EmployeeNoteType;
  noteType?: EmployeeNoteType;
  constructor() {}

  ngOnInit() {
    if (!!this.notes) {      
      this.noteType = _.orderBy(this.notes, (x) => x.createdAt, 'desc')[0]?.type;
    }
  }

  clicked() {
    this.selected.emit(true);
  }
}
