import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DynamicConfirmationDialogComponent,
  DynamicConfirmationDialogData,
  DynamicConfirmationDialogResult,
} from '@app/shared/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { UserService } from 'apps/dpl-live/src/app/user/services/user.service';
import { IUser } from 'apps/dpl-live/src/app/user/state/user.model';
import {
  combineLatest,
  EMPTY,
  NEVER,
  Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CustomerDivisionsService } from '../../services/customer-divisions.service';
import { CustomersService } from '../../services/customers.service';
import { ICustomer } from '../../state/customer.model';
import { ICustomerDivision } from '../../state/customer-division.model';
import { ActivatedRoute } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CustomerDivisionsQuery } from '../../state/customer-divisions.query';

interface IViewData {
  user: IUser<number, number>;
  customers: (ICustomer<number> & { divisions: ICustomerDivision[] })[];
  currentDivision: string;
}

@Component({
  selector: 'customer-division-selector',
  templateUrl: './customer-division-selector.component.html',
  styleUrls: ['./customer-division-selector.component.scss'],
})
export class CustomerDivisionSelectorComponent implements OnInit, OnDestroy {
  isNotEmployee$: Observable<boolean>;
  viewData$: Observable<IViewData>;
  form: UntypedFormControl;
  previouslySelectedDivisionId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private customerDivisionService: CustomerDivisionsService,
    private customersService: CustomersService,
    private query: CustomerDivisionsQuery,
    private user: UserService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngOnDestroy(): void {}

  ngOnInit() {
    this.isNotEmployee$ = this.user.getIsDplEmployee().pipe(map(employee=>!employee));  
    this.form = this.fb.control(null);

    const user$ = this.user.getCurrentUser();

    const customers$ = combineLatest([
      this.customersService.getCustomers(),
      this.customerDivisionService.getDivisions(),
    ]).pipe(
      map(([customers, divisions]) => {
        return customers
          .map((customer) => {
            return {
              ...customer,
              divisions: divisions.filter((i) => i.customerId === customer.id),
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      })
    );

    const activeDivision$ = customers$.pipe(
      first(),
      switchMap((customers) => {
        return this.customerDivisionService.getActiveDivision().pipe(
          map((division) => {
            if (!division) {
              if (!customers?.length || !customers[0].divisions?.length) {
                return null;
              }
              return customers[0].divisions[0];
            }
            return division;
          }),
          filter((division) => !!division),
          distinctUntilKeyChanged('id'),
          tap((division) => {
            if (!division.id) {
              return;
            }
            this.form.patchValue(division.id);
          })
        );
      }),
      tap((division) => {
        console.log(
          'previouslySelectedDivisionId',
          this.previouslySelectedDivisionId
        );
        this.previouslySelectedDivisionId = division.id;
      })
    );

    // sync active division with query param
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        map((params) => params['divisionId']),
        filterNilValue(),
        distinctUntilChanged(),
        tap((divisionId) => {
          const exists = !!this.query.getEntity(divisionId);
          if (!exists) {
            return;
          }

          this.form.patchValue(divisionId);
          this.customerDivisionService.setActive(divisionId);
        })
      )
      .subscribe();

    const formSync$ = this.form.valueChanges.pipe(
      tap((id) => console.log('formSync$', id)),
      distinctUntilChanged(),
      switchMap((id) => this.showDialog(id)),
      switchMap(() => NEVER),
      startWith(null)
    );

    this.viewData$ = combineLatest([
      user$,
      customers$,
      activeDivision$,
      formSync$,
    ]).pipe(
      map(([user, customers, activeDivision]) => {
        const customerOfCurrentDivision = customers.find(
          (x) => x.divisions.findIndex((y) => y.id === activeDivision.id) >= 0
        )?.address.city;

        return <IViewData>{
          user,
          customers,
          currentDivision:
            activeDivision.name + ' (' + customerOfCurrentDivision + ')',
        };
      })
    );
  }

  private showDialog(id: number) {
    if (
      !this.previouslySelectedDivisionId ||
      this.previouslySelectedDivisionId === id
    ) {
      return EMPTY;
    }

    return this.dialog
      .open<
        DynamicConfirmationDialogComponent,
        DynamicConfirmationDialogData,
        DynamicConfirmationDialogResult
      >(DynamicConfirmationDialogComponent, {
        data: {
          labels: {
            title: $localize`:ChangeDivisionDialogTitle|Titel für Dialog Abteilung wechseln@@ChangeDivisionDialogTitle:Abteilung wechseln`,
            description: $localize`:ChangeDivisionDialogDescription|Frage für Dialog Abteilungswechsel bestaetigen@@ChangeDivisionDialogDescription:Möchten Sie die Abteilung wechseln?`,
            confirm: $localize`:ChangeDivisionDialogConfirmButtonText|Button Confirm Text@@Yes:Ja`,
            cancel: $localize`:ChangeDivisionDialogCancelButtonText|Button Chancel Text@@No:Nein`,
          },
        },
        width: '600px',
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result?.confirmed) {
            this.form.patchValue(this.previouslySelectedDivisionId);
            return EMPTY;
          }

          this.customerDivisionService.setActive(id);
          return this.customerDivisionService.getDivisions().pipe(
            tap((divisions) => {
              const division = divisions.find((x) => x.id === id);
              this.customersService.setActiveCustomer(division.customerId);
            })
          );
        }),
        tap(() => window.location.reload())
      );
  }
}
