import { Injectable, Injector } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import * as _ from 'lodash';
import {
  ApiException,
  DplProblemDetails,
  StateItem,
} from '../../core/services/dpl-api-services';
import { WrappedError } from '../../core/services/app-error-handler.service';
import { LocalizationService, NotificationType } from '../../core';
import { throwError } from 'rxjs';

type Primative = string | number | boolean;

/**
 * Is Array unique, only for Primitive, uses set - available in modern browser
 * @param array
 * @constructor
 */
export function isUnique<T extends Primative>(array: Array<T>) {
  const unique = new Set(array).size === array.length;
  return unique;
}

/**@returns {Array} bytes of UTF-16 Big Endian without BOM*/
function stringToUtf16ByteArray(str) {
  var bytes = [];
  //currently the function returns without BOM. Uncomment the next line to change that.
  //bytes.push(254, 255);  //Big Endian Byte Order Marks
  for (var i = 0; i < str.length; ++i) {
    var charCode = str.charCodeAt(i);
    //char > 2 bytes is impossible since charCodeAt can only return 2 bytes
    bytes.push((charCode & 0xff00) >>> 8); //high byte (might be 0)
    bytes.push(charCode & 0xff); //low byte
  }
  return bytes;
}

function checkForDuplicates(array, keyName) {
  const mappedData = array.map((item) => item[keyName]) as Array<Primative>;
  return !isUnique(mappedData);
}

// function checkForDuplicates(source, keyName) {
//   return source.filter((item, index, array) => {
//     return array.findIndex(t => t[keyName] === item[keyName]) === index;
//   }).length !== source.length
// }

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private papa: Papa) {}

  /**
   * @param {File} csvFile
   * @param {Object} config
   * @param worker
   * @param preview
   */
  public validateFile(
    csvFile,
    config,
    worker: boolean = false,
    preview: number = null,
    encoding = 'UTF-8'
  ): Promise<any> {
    const that = this;
    return new Promise(function (resolve, reject) {
      that.papa.parse(csvFile, {
        skipEmptyLines: true,
        delimiter: ';',
        preview: preview,
        worker: worker,
        comments: '#',
        encoding: encoding, //OR ANSI
        complete: function (results) {
          resolve(that._prepareDataAndValidateFile(results.data, config));
        },
        error: function (error, file) {
          reject({ error: error, file: file });
        },
      });
    });
  }

  /**
   * @param {Array} csvData
   * @param {Object} config
   * @private
   */
  private _prepareDataAndValidateFile(csvData, config) {
    const file = {
      inValidMessages: [],
      data: [],
    };

    csvData.forEach(function (row, rowIndex) {
      const columnData = {};
      const headers = [];

      for (let i = 0; i < config.headers.length; i++) {
        const data = config.headers[i];

        if (!data.optional) {
          headers.push(data);
        }
      }

      if (row.length < headers.length) {
        return;
      }

      row.forEach(function (columnValue, columnIndex) {
        const valueConfig = config.headers[columnIndex];

        if (!valueConfig) {
          return;
        }

        // header validation, skip if isHeaderNameOptional
        if (rowIndex === 0) {
          if (
            config.isHeaderNameOptional &&
            columnValue.localeCompare(valueConfig.name) == 0
          ) {
            return;
          }

          if (!config.isHeaderNameOptional) {
            //const string1= valueConfig.name;
            //const string2= columnValue;
            //const different = string1.normalize().toLocaleString() != string2.normalize().toLocaleString().

            const compare = columnValue.localeCompare(valueConfig.name);

            if (compare != 0) {
              file.inValidMessages.push(
                _.isFunction(valueConfig.headerError)
                  ? valueConfig.headerError(columnValue)
                  : $localize`:@@OverviewNameCSVService:Überschrift name ` +
                      columnValue +
                      $localize`:@@IsMissingOrIncorrectCSVService: ist nicht korrekt oder fehlt`
              );
            }
            return;
          }
        }

        if (valueConfig.required && !columnValue.length) {
          file.inValidMessages.push(
            _.isFunction(valueConfig.requiredError)
              ? valueConfig.requiredError(
                  valueConfig.name,
                  rowIndex + 1,
                  columnIndex + 1
                )
              : String(
                  valueConfig.name +
                    $localize`:@@ValueIsRequiredField: ist ein Pflichtfeld (` +
                    (rowIndex + 1) +
                    $localize`:@@RowBackSlash: Zeile / ` +
                    (columnIndex + 1) +
                    $localize`:@@SpaceColumn: Spalte)`
                )
          );
        } else if (valueConfig.validate && !valueConfig.validate(columnValue)) {
          file.inValidMessages.push(
            _.isFunction(valueConfig.validateError)
              ? valueConfig.validateError(
                  valueConfig.name,
                  rowIndex + 1,
                  columnIndex + 1
                )
              : String(
                  valueConfig.name +
                    $localize`:@@isNotAllowedInput: ist keine gültige Eingabe (` +
                    (rowIndex + 1) +
                    $localize`:@@RowBackSlash: Zeile / ` +
                    (columnIndex + 1) +
                    $localize`:@@SpaceColumn: Spalte)`
                )
          );
        }

        if (valueConfig.optional) {
          columnData[valueConfig.inputName] = columnValue;
        }

        if (valueConfig.isArray) {
          columnData[valueConfig.inputName] = columnValue
            .split(',')
            .map(function (value) {
              return value.trim();
            });
        } else {
          columnData[valueConfig.inputName] = columnValue;
        }
      });

      if (Object.keys(columnData).length) {
        file.data.push(columnData);
      }
    });

    this._checkUniqueFields(file, config);

    return file;
  }

  /**
   * @param {Object} file
   * @param {Object} config
   * @private
   */
  private _checkUniqueFields(file, config) {
    if (!file.data.length) {
      return;
    }

    config.headers
      .filter(function (header) {
        return header.unique;
      })
      .forEach(function (header) {
        const hasDuplicates = checkForDuplicates(file.data, header.inputName);
        if (hasDuplicates) {
          file.inValidMessages.push(
            _.isFunction(header.uniqueError)
              ? header.uniqueError(header.name)
              : String(
                  $localize`:@@CsvServiceNotUniqColumnPartOne:Die Spalte ` +
                    `'${header.name}'` +
                    $localize`:@@CsvServiceNotUniqColumnPartTwo: ist nicht eindeutig`
                )
          );
        }
      });
  }
}
