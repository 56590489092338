import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../../core/services';
import { TranslatePipeEnumBase } from './translate-pipe.base';

@Pipe({
  name: 'businessHourExceptionType'
})
export class BusinessHourExceptionTypePipe extends TranslatePipeEnumBase {
  name: string = 'BusinessHourExceptionType';

  constructor(private ls: LocalizationService) {
    super(ls);
  }
}

