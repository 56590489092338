import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShowAttachmentDialogComponent } from '../show-attachment-dialog/show-attachment-dialog.component';
import { User } from '../../../core/services/dpl-api-services';
import { MessageDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { first, map, tap } from 'rxjs/operators';
import { LanguagesService } from '../../../master-data/languages/services/languages.service';
import { Observable, combineLatest } from 'rxjs';
import { ILanguage } from '../../../master-data/languages/state/language.model';
import { UserService } from '../../../user/services/user.service';
import { UserRole } from '../../../generated/Dpl.B2b.Common.Enumerations';

type ViewData = {
  activeLanguage: ILanguage;
  currentUser: User;
};
@Component({
  selector: 'dpl-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnChanges {
  @Input() message?: MessageDto;
  @Input() isAnnouncementLoginDialog?: boolean = false;
  @Input() selectedLanguage?: string = '';
  @Output() copiedMessageText = new EventEmitter<any>();
  showExtended = false;
  pinnedMessage = false;
  defaultShownMessageLength = 50;
  messageUserRole = UserRole;
  viewData$: Observable<ViewData>;
  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private languageService: LanguagesService,
    @Inject(LOCALE_ID) private locale_id?: string
  ) { }

  ngOnInit() {
    const currentUser$ = this.userService.getCurrentUser().pipe(
      map((currentUser) => {
        if (currentUser) {
          return {
            ...currentUser,
            externalPostingAccounts: null,
            postingAccounts: null,
            customers: null,
          };
        }
      })
    );
    const currentLanguage$ = this.languageService
      .getActiveLanguage()
      .pipe(first());

    this.viewData$ = combineLatest(currentLanguage$, currentUser$).pipe(
      map(([activeLanguage, currentUser]) => {
        return {
          activeLanguage,
          currentUser
        };
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLanguage) {
      console.log(this.selectedLanguage)
    }
  }

  openDialogForAttachments(message: MessageDto) {
    this.dialog.open<ShowAttachmentDialogComponent>(
      ShowAttachmentDialogComponent, {
      minWidth: '450px',
      maxWidth: '1200px',
      width: '35%',
      maxHeight: '600px',
      panelClass: 'messaging-system-dialog-panel',
      data: message,
    }
    )
  }


  logMessageText(text: string) {
    //Copy one text in Message Input, now only one message can be copied and overwrites old ones
    this.copiedMessageText.emit(text)
  }


  logCreatedByName(user: User) {
    //Log User, later use for a direct messaging channel
    console.log(user)
  }


  pinMessage(data) {
    //Todo: send q request to pin a message
    console.log(data)
    this.pinnedMessage = true
  }

  unpinMessage(data) {
    //Todo: send q request to unpin a message
    console.log(data)
    this.pinnedMessage = false
  }



  getTranslatedMessageBody(message: MessageDto) {
    switch (this.selectedLanguage) {
      case 'de':
        if (message.translations?.de) {
          return message.translations?.de
        } else {
          //show an info if translation didn't exist
          // Original Message
          return message.message.body
        }
      case 'en':
        if (message.translations?.en) {
          return message.translations?.en
        } else {
          // Original Message
          return message.message.body
        }
      case 'fr':
        if (message.translations?.fr) {
          return message.translations?.fr
        } else {
          // Original Message
          return message.message.body
        }
      case 'es':
        if (message.translations?.es) {
          return message.translations?.es
        } else {
          // Original Message
          return message.message.body
        }
      case 'pl':
        if (message.translations?.pl) {
          return message.translations?.pl
        } else {
          // Original Message
          return message.message.body
        }
      case 'it':
        if (message.translations?.it) {
          return message.translations?.it
        } else {
          // Original Message
          return message.message.body
        }
      default:
        // Original Message
        return message.message?.body
    }
  }

  getImage(data: ViewData) {
    if (data.currentUser.upn === this.message.creator?.userUpn) {
      //My Message Avatar
      return "assets/images/profile-me.png"
    } else {
      switch (this.message.creator?.userRole) {
        case UserRole.DplEmployee:
          return "assets/dpl-pooling-logo.png"
        case UserRole.PointOfSale:
          return "assets/images/profile-role-pointofsale.png"
        case UserRole.Retailer:
          return "assets/images/profile-role-retailer.png"
        case UserRole.Shipper:
          return "assets/images/profile-role-shipper.png"
        case UserRole.ShipperDepot:
          return "assets/images/profile-role-shipper.png"
        case UserRole.VoucherUploader:
          return "assets/images/profile-role-uploader.png"
        case UserRole.Warehouse:
          return "assets/images/profile-role-warehouse.png"
        default:
          // If the user have no Role
          return "assets/images/profile-me.png"
          break;
      }
    }

  }
}
