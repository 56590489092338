import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SessionStore, SessionState } from './session.store';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  isLoggedInAndNotExpired$ = this.select(
    (state) => state.isLoggedInAndNotExpired
  ).pipe(
    filter((isLoggedInAndNotExpired) => isLoggedInAndNotExpired !== undefined)
  );
  
  currentSession$ = this.isLoggedInAndNotExpired$.pipe(
    switchMap((isLoggedInAndNotExpired) =>
      isLoggedInAndNotExpired ? this.select() : of(null)
    )
  );

  constructor(protected store: SessionStore) {
    super(store);
  }
}
