<ng-container *ngIf="viewData$ | async as data; else loading">
  <div class="messaging-system-channel-header-and-icon">
    <div class="messaging-system-channel-header">
      <div class="list-header">
        <div class="list-name" i18n="@@ChannelListHeaderText">
          Vorgangsliste
        </div>
        <div class="list-info">
          {{ getSelectedTypeList() | async }}
        </div>
      </div>
      <div class="channel-list-header-controls">
        <div class="sorting-select-box">
          <mat-select
            #selectBox
            class="channelsortingselector"
            [value]="currentSortOption"
            (selectionChange)="onSortOptionChanged($event)"
            title="Sortierung der Vorgänge"
            i18n-title="@@MessagingSystemChannelListSortingHint"
          >
            <mat-select-trigger>
              <div style="color: white">
                {{ sortingDict[currentSortOption]?.text }}
              </div>
            </mat-select-trigger>
            <mat-option
              *ngFor="let sortableField of sortableFields"
              [value]="sortableField.id"
            >
              {{ sortableField.text }}
            </mat-option>
          </mat-select>
        </div>
        <dx-button
          class="sorting-channel-list-icon"
          [icon]="sortOrder === 'desc' ? 'arrowdown' : 'arrowup'"
          (click)="changeSortOrder()"
          [hint]="getSortOrderHint(sortOrder)"
        ></dx-button>
        <!-- <div class="spin-icon">
          <dx-button icon="spinleft" (click)="onSpinLeft()"> </dx-button>
        </div> -->
      </div>
    </div>
  </div>
  <div id="channel-list">
    <dx-list
      #channelList
      [dataSource]="data.ticketsStore"
      [selectionMode]="'single'"
      [searchEnabled]="true"
      [searchExpr]="[
        'channelName',
        'channelTitle',
        'unreadCount',
        'referenceNumber',
        'channelId',
        'messageCount',
        'creator.userUpn',
        'creator.userName',
        'channelCompanyInfoText',
        'lastReading'
      ]"
      searchMode="contains"
      [selectedItemKeys]="activeChannelId$ | async"
      height="776px"
      showScrollbar="always"
    >
      <div
        *dxTemplate="let dataSource of 'item'"
        (click)="selectChannel(dataSource.channelId)"
      >
        <div class="channel-item">
          <div class="channel-info">
            <div class="channel-name">{{ dataSource.channelTitle }}</div>
            <div class="channel-company-info">
              {{ getCompanyInfoText(dataSource) }}
            </div>
            <div class="divider"></div>
            <div
              class="channel-last-message"
              [innerHTML]="
                dataSource.messages[dataSource.messageCount - 1]?.message?.body
                  | safe : 'html'
              "
            ></div>
            <div class="channel-list-time-row">
              <div class="channel-last-message-at">
                <div class="channel-last-message-creator">
                  <span>
                    {{
                      dataSource.messages[dataSource.messageCount - 1]?.creator
                        ?.userName
                    }}</span
                  >
                </div>
                <ng-container
                  *ngIf="data.isEmployee; else showChannelStateText"
                ></ng-container>
                <span>
                  {{
                    dataSource.messages[dataSource.messageCount - 1]?.creator
                      ?.timestamp | dateEx
                  }}</span
                >
              </div>
            </div>

            <ng-template #showChannelStateText>
              <ng-container *ngIf="dataSource.channelState; else notAssigned">
                {{ dataSource.channelState | chatChannelState }}
              </ng-container>
              <ng-template #notAssigned>
                <div class="channel-no-responsive-member">
                  <span i18n="@@New">Neu</span>
                </div>
              </ng-template>
            </ng-template>
          </div>
          <div class="channel-controls">
            <ng-container *ngIf="data.isEmployee">
              <dx-button
                icon="isnotblank"
                [hint]="dataSource.channelState | chatChannelState"
                [class]="
                  dataSource.channelState === channelState.Open
                    ? 'red-icon'
                    : dataSource.channelState === channelState.InProgress
                    ? 'yellow-icon'
                    : dataSource.channelState === channelState.Enclosed
                    ? 'green-icon'
                    : 'grey-icon'
                "
                (click)="openChannelStateDialog(dataSource, $event)"
                [disabled]="dataSource.channelState === channelState.Enclosed"
              >
              </dx-button>

              <dx-button
                icon="card"
                i18n-hint="@@ChannelResponsiveMemberIconHint"
                hint="Bearbeiter ändern"
                (click)="openResposiveMemberDialog(dataSource, $event)"
              >
              </dx-button>
            </ng-container>
          </div>
          <ng-container *ngIf="dataSource.unreadCount">
            <div
              class="channel-unread-count"
              [matBadge]="dataSource.unreadCount"
              matBadgeColor="warn"
              [matbadgeHidden]="dataSource.unreadCount === 0"
            ></div>
          </ng-container>
          <ng-container
            *ngIf="getMessageRequiredActionTag(dataSource.channelId) | async"
          >
            <div
              class="channel-unread-count"
              [matBadge]="'!'"
              matBadgeColor="warn"
            ></div>
          </ng-container>
        </div>
      </div>
    </dx-list>
  </div>
</ng-container>

<ng-template #loading>
  <dpl-loading-spinner-on-page></dpl-loading-spinner-on-page>
</ng-template>
