import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  languages: any[] = [
    {
      ID: 1,
      Name: 'de',
      ImageSrc: 'assets/images/de.png',
    },
    {
      ID: 2,
      Name: 'en',
      ImageSrc: 'assets/images/eng.png',
    },
  ];
  selectedLanguageCode: string = $localize`:@@LanguageCode:de`;
  selectedLanguage = this.languages[0];

  constructor() {}

  selectLanugage(selectedItem) {
    console.log('selectedItem', selectedItem);
    if (selectedItem.value.Name !== this.selectedLanguage) {
      this.selectedLanguage = selectedItem.value;
      if (window.location.hostname != 'localhost') {
        //on dev environment and if no change do not reload
        this.selectedLanguageCode = selectedItem.value.Name;
        var home = window.location.origin;
        window.location.href = home + '/' + this.selectedLanguageCode;
      }
    }
  }

  ngOnInit() {
    console.log(
      'selctedLanguage',
      this.selectedLanguage,
      this.selectedLanguageCode
    );
  }
}
