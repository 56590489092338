import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { LoadCarrier } from '../../../core/services/dpl-api-services';
import { ILoadCarrier } from '../../../master-data/load-carriers/state/load-carrier.model';
export interface TransferLoadCarrier extends LoadCarrier {
  limit: number;
}

export interface LoadCarrierPickerState
  extends EntityState<LoadCarrier, number>,
    ActiveState<number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'load-carrier-picker' })
export class LoadCarrierPickerStore extends EntityStore<LoadCarrierPickerState> {
  constructor() {
    super();
  }
}
