import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ValidationDataService } from '@app/shared/services';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  SubFormGroup,
} from 'ngx-sub-form';
import { CustomerPartnerLookup } from '../../../core/services/dpl-api-services';

import { LicensePlate } from '../license-plate-form/license-plate-form.component';

export interface DriverInfo {
  driverName: string;
  companyName: string;
  licensePlate: LicensePlate;
}

@Component({
  selector: 'app-driver-info-form',
  template: `
    <mat-card *ngIf="formGroup" fxFlex [formGroup]="formGroup">
      <mat-card-header>
        <mat-card-title
          >{{ title }}<strong *ngIf="required"> *</strong></mat-card-title
        >
        <div fxFlex></div>
        <button
          *ngIf="shipper"
          mat-stroked-button
          (click)="copyShipperCompanyName()"
          [disabled]="
            formGroup.controls.companyName.value !== '' &&
            formGroup.controls.companyName.value !== null
          "
          i18n="
            Buttontext Anl. Sped.
            übernehmen@@DriverInfoFormCopyShipperCompanyNameButtonText"
        >
          Anl. Sped. übernehmen
        </button>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <mat-form-field fxFlex="50%">
          <mat-label i18n="CompanyNameOnTruck|Label Name@@CompanyNameOnTruck">
            Firmenname (auf LKW)
          </mat-label>

          <input
            matInput
            [formControl]="formGroup.controls.companyName"
            #companyName="ngForm"
            [dplHighlight]="companyName"
          />
          <mat-error
            *ngIf="
              formGroup.controls.companyName?.touched &&
              formGroup.controls.companyName.errors?.required
            "
            ><span
              i18n="
                Shown Error Text Pflichtfeld@@DriverInfoFormRequiredErrorText"
              >Pflichtfeld</span
            ></mat-error
          >
          <mat-error *ngIf="formGroup.controls.companyName?.errors?.maxlength">
            <span i18n="MaxLength|Label Pflichtfeld@@MaxLength"
              >Die Eingabe ist zu lang</span
            >
          </mat-error>
        </mat-form-field>
        <div fxFlex fxLayout="row" fxLayoutGap="10px">
          <app-license-plate-form
            fxLayoutGap="10px"
            [subForm]="formGroup.controls.licensePlate"
            [required]="licensePlateRequired"
            [isExternal]="isExternal"
          ></app-license-plate-form>
          <mat-form-field fxFlex>
            <mat-label
              i18n="NameOfTheDriver|Label Name des Fahrers@@NameOfTheDriver"
            >
              Name des Fahrers
            </mat-label>
            <input
              matInput
              [formControl]="formGroup.controls.driverName"
              #driverName="ngForm"
              [dplHighlight]="driverName"
            />
            <mat-error
              *ngIf="
                formGroup.controls.driverName?.touched &&
                formGroup.controls.driverName.errors?.required
              "
              ><span
                i18n="
                  Shown Error Text Pflichtfeld@@DriverInfoFormRequiredErrorText"
                >Pflichtfeld</span
              ></mat-error
            >
            <mat-error *ngIf="formGroup.controls.driverName?.errors?.maxlength">
              <span i18n="MaxLength|Label Pflichtfeld@@MaxLength"
                >Die Eingabe ist zu lang</span
              >
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  providers: subformComponentProviders(DriverInfoFormComponent),
})
export class DriverInfoFormComponent
  extends NgxSubFormComponent<DriverInfo>
  implements OnChanges
{
  constructor() {
    super();
  }

  @Input() title: string;
  @Input() shipper: CustomerPartnerLookup;
  @Input() required: boolean;

  // needed for Müller custom license plate placeholder
  @Input() isExternal = false;
  @Input() licensePlateRequiredInput: boolean;

  licensePlateRequired: boolean = false;

  protected emitInitialValueOnInit = false;

  protected getFormControls(): Controls<DriverInfo> {
    return {
      driverName: new UntypedFormControl(null, [
        // Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(ValidationDataService.maxLength.driverName),
      ]),
      companyName: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(ValidationDataService.maxLength.companyName),
      ]),
      licensePlate: new SubFormGroup(null, [
        Validators.required,
        // Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
      ]),
    };
  }

  public getDefaultValues(): DriverInfo {
    return {
      driverName: null,
      companyName: null,
      licensePlate: null,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (this.required || this.licensePlateRequiredInput) {
      this.licensePlateRequired = true;
    } else {
      this.licensePlateRequired = false;
    }
    this.setRequiredState(this.required);
  }

  copyShipperCompanyName() {
    this.formGroup.controls.companyName.setValue(this.shipper.companyName);
  }

  public setRequiredState(isRequired: boolean) {
    //driver name not required
    // const driverName = this.formGroup.controls.driverName;
    // driverName.setValidators(isRequired ? [Validators.required] : []);
    // driverName.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    const companyName = this.formGroup.controls.companyName;
    companyName.setValidators(isRequired ? [Validators.required] : []);
    companyName.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }
}
