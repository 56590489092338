<mat-toolbar color="primary">
  <span i18n="@@HeadingCreateLoadingLocationAddressCheck"
    >Wählen Sie eine adresse</span
  >
</mat-toolbar>

<ng-container *ngIf="viewData$ | async as data; else loading">
  <mat-dialog-content fxLayout="column">
    <form fxLayout="column" [formGroup]="formGroup">
      <mat-radio-group
        fxLayout="column"
        fxLayoutGap="5px"
        [formControl]="formGroup.controls.address"
      >
        <mat-radio-button
          *ngFor="let address of data.addresses"
          [value]="address"
          >{{ address.street1 }}, {{ address.postalCode }} {{ address.city }},
          {{ address.countryIso2Code }}</mat-radio-button
        >
      </mat-radio-group>
    </form>
  </mat-dialog-content>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin: auto"></mat-spinner>
</ng-template>

<mat-dialog-actions>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
    style="width: 100%"
  >
    <button mat-raised-button (click)="onCancel()" i18n="@@Cancel">
      Abbrechen
    </button>
    <button
      mat-raised-button
      (click)="onConfirmAddress()"
      [disabled]="formGroup.invalid"
      cdkFocusInitial
      i18n="@@CreateLoadingLocation"
    >
      Be-/Entladestelle erstellen
    </button>
  </div>
</mat-dialog-actions>
