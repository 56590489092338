<ng-container *ngIf="viewData$ | async as data; else loading">
  <div mat-dailog-title class="message-list">
    <div class="message-list-header">
      <!-- ChannelInfo Template -->
      <div
        cdkDragHandle
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        class="channel-header"
      >
        <div class="channel-header-area">
          <div class="channel-name">
            {{ data.ticket.channelTitle }}
          </div>
          <div class="channel-header-controls">
            <div class="language-select-div">
              <mat-select
                class="channellanguageselector"
                [formControl]="selectedLanguageControl"
                style="max-width: 100px; height: 100%"
              >
                <mat-select-trigger>
                  <div style="color: white">
                    {{
                      languageDict[selectedLanguageControl.value$ | async]?.name
                    }}
                  </div>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let language of languages"
                  [value]="language.value"
                >
                  {{ language.name }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        {{ data.ticket.creator?.timestamp | dateEx }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="data.ticket.messages?.length > 0; else noMessages">
    <div id="chat-window">
      <ng-container
        *ngIf="selectedLanguageControl.value$ | async as languageValue"
      >
        <div
          *ngIf="languageValue.length > 0"
          class="lanuage-changed-info"
          i18n="@@ShowOnLoginLanguageChangedInfo"
        >
          Sie haben eine andere Sprache als Original ausgewählt. Die angezeigte
          Übersetzung wurde automatisch generiert.
        </div>
      </ng-container>
      <dx-list
        #dxList
        [dataSource]="data.ticket.messages"
        [height]="messageListHight + 'px'"
        [activeStateEnabled]="false"
        [showScrollbar]="'always'"
        [focusStateEnabled]="false"
        [hoverStateEnabled]="false"
      >
        <div *dxTemplate="let listData of 'item'">
          <dpl-announcement-message-template
            style="width: -webkit-fill-available"
            [isDialog]="true"
            [message]="listData"
            [selectedLanguage]="selectedLanguageControl.value$ | async"
          >
          </dpl-announcement-message-template>
        </div>
      </dx-list>
    </div>
  </ng-container>
  <div id="chat-window">
    <div class="announcements-controls">
      <button
        mat-raised-button
        i18n-title="
          Dialog Schließen und nicht mehr
          Anzeigen@@MessagingDialogCloseHintShowAnnouncementOnLogin"
        i18n="
          Dialog Schließen und nicht mehr
          Anzeigen@@MessagingDialogCloseTextShowAnnouncementOnLogin"
        title="Schließen und nicht mehr Anzeigen"
        (click)="closeAnnoncementForNextLogin(data.ticket)"
      >
        Schließen und nicht mehr Anzeigen
      </button>
      <button
        mat-raised-button
        i18n-title="@@CloseDialogHint"
        i18n="@@Close"
        title="Schließe das Fenster"
        (click)="closeAnnoncement()"
      >
        Schließen
      </button>
    </div>
  </div>
</ng-container>
<ng-template #noMessages>
  <div id="chat-window">
    <span i18n="@@NoMessagesForMessageListText">
      Keine Nachrichten vorhanden
    </span>
  </div>
</ng-template>
<ng-template #loading>
  <div class="center">
    <dpl-loading-spinner-on-page></dpl-loading-spinner-on-page>
  </div>
</ng-template>
