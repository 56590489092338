import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingLocationsRoutingModule } from './loading-locations-routing.module';
import { LoadingLocationCreateDialogComponent } from './components/loading-location-create-dialog/loading-location-create-dialog.component';
import { LoadingLocationCreateFormComponent } from './components/loading-location-create-form/loading-location-create-form.component';
import { LoadingLocationEditDialogComponent } from './components/loading-location-edit-dialog/loading-location-edit-dialog.component';
import { LoadingLocationLookupComponent } from './components/loading-location-lookup/loading-location-lookup.component';
import { SharedModule } from '../shared';
import { LoadingLocationCreateAddressCheckDialogComponent } from './components/loading-location-create-address-check-dialog/loading-location-create-address-check-dialog.component';
import { LoadingLocationBusinessHoursModule } from '../loading-location-business-hours/loading-location-business-hours.module';

const components = [
  LoadingLocationLookupComponent,
  LoadingLocationCreateFormComponent,
  LoadingLocationCreateDialogComponent,
  LoadingLocationEditDialogComponent,
];

@NgModule({
  declarations: [
    ...components,
    LoadingLocationCreateAddressCheckDialogComponent,
  ],
  imports: [CommonModule, SharedModule, LoadingLocationsRoutingModule, LoadingLocationBusinessHoursModule],
  exports: [...components],
})
export class LoadingLocationsModule {}
