import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filterNil } from '@datorama/akita';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import _ from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment-business-days';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { LoadingService } from '../../../../../../../libs/dpl-lib/src';
import {
  DplApiService,
  NotificationService,
  WrappedError,
} from '../../../core';
import {
  Address,
  BusinessHourException,
  BusinessHourExceptionType,
  BusinessHours,
  CreateBusinessHourExceptionRequest,
  DplProblemDetails,
} from '../../../core/services/dpl-api-services';
import { ILoadingLocation } from '../../../loading-locations/state/loading-location.model';
import { CountriesQuery } from '../../../master-data/countries/state/countries.query';
import {
  CalendarWeekPipe,
  DynamicConfirmationDialogComponent,
  DynamicConfirmationDialogData,
  DynamicConfirmationDialogResult,
} from '../../../shared';
import { getWeekdaySort } from '../../../shared/utils';
import { UserService } from '../../../user/services/user.service';
import {
  LoadingLocationBusinessHoursSchedulerAddExceptionComponent,
  LoadingLocationBusinessHoursSchedulerAddExceptionDialogData,
  LoadingLocationBusinessHoursSchedulerAddExceptionDialogResult,
} from '../loading-location-business-hours-scheduler-add-exception/loading-location-business-hours-scheduler-add-exception.component';

type Appointment = {
  text: string;
  startDate: Date;
  endDate: Date;
  allDay?: boolean;
  typeId: number;
  originalId: number;
};

type Resource = {
  id: number;
  text: string;
  color: string;
};

type ViewData = {
  dataSource: DataSource;
  enabledDates: Date[];
  appointments: Appointment[];
  isEmployee: boolean;
};

@Component({
  selector: 'dpl-loading-location-business-hours-scheduler',
  templateUrl: './loading-location-business-hours-scheduler.component.html',
  styleUrls: ['./loading-location-business-hours-scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLocationBusinessHoursSchedulerComponent
  implements OnInit, OnChanges
{
  @Input() loadingLocation: ILoadingLocation<Address>;
  @Input() readOnly = false;
  loadingLocationSub = new BehaviorSubject<ILoadingLocation<Address>>(null);
  loadingLocation$ = this.loadingLocationSub.asObservable().pipe(filterNil);
  currentDate: Date = moment().toDate();

  reloadSub = new BehaviorSubject<boolean>(true);
  reload$ = this.reloadSub.asObservable();
  viewData$: Observable<ViewData>;
  typeResources: Resource[] = [
    { id: 1, text: 'Standard', color: 'limegreen' },
    { id: 2, text: 'Ausnahme', color: 'orangered' },
  ];

  constructor(
    private dpl: DplApiService,
    private loadingService: LoadingService,
    private calendarWeekPipe: CalendarWeekPipe,
    private datePipe: DatePipe,
    private countriesQuery: CountriesQuery,
    private countryStatesQuery: CountriesQuery,
    private dialog: MatDialog,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadingLocation) {
      this.loadingLocationSub.next(this.loadingLocation);
    }
  }

  ngOnInit(): void {
    const isEmployee$ = this.userService.getIsDplEmployee();

    const businessHours$ = this.readOnly
      ? of(this.loadingLocation.businessHours)
      : combineLatest([this.loadingLocation$, this.reload$]).pipe(
          switchMap(([loadingLocation]) => {
            console.log('businessHours$');
            return this.dpl.businessHoursApiService.getAll(loadingLocation.id);
          })
        );

    const businessHoursExceptions$ = this.readOnly
      ? of(this.loadingLocation.businessHourExceptions)
      : combineLatest([this.loadingLocation$, this.reload$]).pipe(
          switchMap(([loadingLocation]) => {
            console.log('businessHoursExceptions$ ');
            return this.dpl.businessHourExceptionsService.getAll(
              loadingLocation.id
            );
          })
        );

    const holidays$ = this.loadingLocation$.pipe(
      switchMap((loadingLocation) => {
        return combineLatest([
          this.countriesQuery.selectEntity(
            loadingLocation.address.country,
            (x) => x.publicHolidays
          ),
          this.countryStatesQuery.selectEntity(
            loadingLocation.address.state,
            (x) => x.publicHolidays
          ),
        ]);
      }),
      map(([countryHoliday, stateHoliday]) => {
        const holidays = [
          ...(countryHoliday ? countryHoliday : []),
          ...(stateHoliday ? stateHoliday : []),
        ];
        return [...new Set(holidays.map((x) => x.date))];
      })
    );

    const appointments$ = combineLatest([
      businessHours$,
      businessHoursExceptions$,
      holidays$,
    ]).pipe(
      map(([businessHours, businessHoursExceptions, holidays]) => {
        return this.getAppointments(
          businessHours,
          businessHoursExceptions,
          holidays
        );
      })
    );

    this.viewData$ = combineLatest([appointments$, isEmployee$]).pipe(
      map(([appointments, isEmployee]) => {
        const dataSource = new DataSource({
          store: new CustomStore({
            loadMode: 'raw',
            load: () => {
              return appointments;
            },
          }),
          paginate: false,
        });

        const viewData: ViewData = {
          dataSource,
          enabledDates: appointments.map((x) => x.startDate),
          appointments,
          isEmployee: isEmployee,
        };
        return viewData;
      })
    );
  }

  getAppointments(
    businessHours: BusinessHours[],
    businessHoursExceptions: BusinessHourException[],
    holidays: Date[]
  ) {
    // loop weeks
    // check exception inside business hour
    // check
    const previousWeekCount = -4;
    const nextWeekCount = 26;
    const baseDate = moment().startOf('week').add(previousWeekCount, 'weeks');

    const appointments: Appointment[] = [];
    for (let index = 0; index < previousWeekCount + nextWeekCount; index++) {
      let weekStart = baseDate.clone().add(index, 'weeks');
      //loop business hour days
      for (const businessHour of businessHours) {
        const businessMoment = this.getBusinessHourMoment(
          weekStart,
          businessHour
        );
        // ASSUMPTION: all exceptions starts and ends inside business hour time span! no day overlapping
        // https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/ --> bracket meaning

        //check all day exceptions exists
        const allDayExceptions = businessHoursExceptions.filter((exception) => {
          return (
            moment(exception.fromDateTime).isSame(
              businessMoment.businessStart,
              'minute'
            ) &&
            moment(exception.toDateTime).isSame(
              businessMoment.businessEnd,
              'minute'
            )
          );
        });
        //check exception with start inside business hour
        const exceptionsInside = _.orderBy(
          businessHoursExceptions.filter((exception) => {
            return (
              moment(exception.fromDateTime).isBetween(
                businessMoment.businessStart,
                businessMoment.businessEnd,
                'minute',
                '[]'
              ) &&
              moment(exception.toDateTime).isBetween(
                businessMoment.businessStart,
                businessMoment.businessEnd,
                'minute',
                '[]'
              ) &&
              allDayExceptions.findIndex((allDayException) => {
                return allDayException.id === exception.id;
              }) === -1
            );
          }),
          (x) => x.fromDateTime,
          'asc'
        );

        //only add businessHour and exception items if businessMomentStart is not in holiday
        if (
          holidays.findIndex((holiday) => {
            return moment(holiday).isSame(businessMoment.businessStart, 'day');
          }) === -1
        ) {
          if (allDayExceptions.length === 0 && exceptionsInside.length === 0) {
            //no exceptions
            appointments.push({
              text: 'Geöffnet',
              typeId: 1,
              startDate: businessMoment.businessStart.toDate(),
              endDate: businessMoment.businessEnd.toDate(),
              originalId: businessHour.id,
            });
          } else if (
            allDayExceptions.length === 1 &&
            exceptionsInside.length === 0
          ) {
            //all day exception
            console.log('allDayExceptions', allDayExceptions[0]);
            appointments.push({
              text: 'Geschlossen - Ausnahme',
              typeId: 2,
              startDate: businessMoment.businessStart.toDate(),
              endDate: businessMoment.businessEnd.toDate(),
              originalId: allDayExceptions[0].id,
            });
          } else if (
            allDayExceptions.length === 0 &&
            exceptionsInside.length > 0
          ) {
            let lastToTime = businessMoment.businessStart.clone();
            for (const exceptionInside of exceptionsInside) {
              if (
                businessMoment.businessStart.isSame(
                  moment(exceptionInside.fromDateTime),
                  'minute'
                )
              ) {
                //closed start of day
                appointments.push({
                  text: 'Geschlossen - Ausnahme',
                  typeId: 2,
                  startDate: lastToTime.toDate(),
                  endDate: moment(exceptionInside.toDateTime).toDate(),
                  originalId: exceptionInside.id,
                });
                lastToTime = moment(exceptionInside.toDateTime);
              } else if (
                moment(exceptionInside.fromDateTime).isSameOrAfter(
                  lastToTime,
                  'minute'
                )
              ) {
                if (
                  !lastToTime.isSame(
                    moment(exceptionInside.fromDateTime),
                    'minute'
                  )
                ) {
                  appointments.push({
                    text: 'Öffnungszeit',
                    typeId: 1,
                    startDate: lastToTime.toDate(),
                    endDate: moment(exceptionInside.fromDateTime).toDate(),
                    originalId: businessHour.id,
                  });
                }
                appointments.push({
                  text: 'Geschlossen - Ausnahme',
                  typeId: 2,
                  startDate: moment(exceptionInside.fromDateTime).toDate(),
                  endDate: moment(exceptionInside.toDateTime).toDate(),
                  originalId: exceptionInside.id,
                });
                lastToTime = moment(exceptionInside.toDateTime);
              }
            }
            if (businessMoment.businessEnd.isAfter(lastToTime, 'minute')) {
              appointments.push({
                text: 'Öffnungszeit',
                typeId: 1,
                startDate: lastToTime.toDate(),
                endDate: businessMoment.businessEnd.toDate(),
                originalId: businessHour.id,
              });
            }
          } else {
            //throw multiple expcetions error !!!
            throw '!!!multiple expcetions error !!!';
          }
        }
      }
    }
    return appointments;
  }

  private getBusinessHourMoment(
    weekStart: Moment,
    businessHour: BusinessHours
  ) {
    const businessStart = weekStart
      .clone()
      .add(getWeekdaySort(businessHour.dayOfWeek) - 1, 'days')
      .add(
        parseInt(businessHour.fromTime.toString().substring(11, 13)),
        'hours'
      )
      .add(
        parseInt(businessHour.fromTime.toString().substring(14, 16)),
        'minutes'
      );
    const businessEnd = weekStart
      .clone()
      .add(getWeekdaySort(businessHour.dayOfWeek) - 1, 'days')
      .add(parseInt(businessHour.toTime.toString().substring(11, 13)), 'hours')
      .add(
        parseInt(businessHour.toTime.toString().substring(14, 16)),
        'minutes'
      );
    return { businessStart, businessEnd };
  }

  isDisabledDateCell(date: Date, enabledDates: Date[]) {
    return (
      enabledDates.findIndex((enabledDate) =>
        moment(date).isSame(enabledDate, 'day')
      ) === -1
    );
  }

  disableClick(e: any) {
    e.cancel = true;
  }

  canAdd(businessHour: Appointment, isEmployee: boolean) {
    // ToDo hasPermission needed, to add exception?
    if (!this.readOnly) {
      if (
        businessHour.typeId === 1 &&
        moment(businessHour.startDate).isAfter(moment(), 'day')
      ) {
        if (isEmployee) {
          // employee ignore lead time
          return true;
        } else if (moment().hours() < 12) {
          //check before 12:00
          return true;
        }
        // after 12 - businessbetween min 1 day
        return moment(businessHour.startDate).businessDiff(moment()) > 1;
      }
      return false;
    }
  }

  canRemove(exception: Appointment) {
    if (!this.readOnly) {
      //hasPermission + future date
      if (
        exception.typeId === 2 &&
        moment(exception.startDate).isAfter(moment(), 'day')
      ) {
        return true;
      }
    }
    return false;
  }

  removeException(exception: Appointment) {
    this.dpl.businessHourExceptionsService
      .delete(exception.originalId, {})
      .pipe(
        this.loadingService.showLoadingWhile(),
        first(),
        tap(() => this.reloadSub.next(true))
      )
      .subscribe();
  }

  addException(businessHour: Appointment, appointments: Appointment[]) {
    const dayBusinessHours = appointments.filter(
      (x) =>
        x.typeId === 1 &&
        moment(x.startDate).isSame(moment(businessHour.startDate), 'days')
    );
    const dayExceptions = appointments.filter(
      (x) =>
        x.typeId === 2 &&
        moment(x.startDate).isSame(moment(businessHour.startDate), 'days')
    );
    const minTime = dayBusinessHours[0].startDate;
    let dayMinTime = minTime;
    const maxTime = dayBusinessHours[0].endDate;
    let dayMaxTime = maxTime;
    const canAllDay =
      dayExceptions.length === 0 && dayBusinessHours.length === 1;
    if (dayBusinessHours.length > 1) {
      // set minTime to first businessHour start
      dayMinTime = _.minBy(dayBusinessHours, (x) =>
        x.startDate.getTime()
      ).startDate;
      // set maxTime to last businessHour end
      dayMaxTime = _.maxBy(dayBusinessHours, (x) =>
        x.endDate.getTime()
      ).endDate;
    }
    // add dialog
    this.dialog
      .open<
        LoadingLocationBusinessHoursSchedulerAddExceptionComponent,
        LoadingLocationBusinessHoursSchedulerAddExceptionDialogData,
        LoadingLocationBusinessHoursSchedulerAddExceptionDialogResult
      >(LoadingLocationBusinessHoursSchedulerAddExceptionComponent, {
        data: {
          minTime,
          dayMinTime,
          maxTime,
          dayMaxTime,
          canAllDay,
        },
        width: '550px',
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap((result) => {
          if (result) {
            const createRequest: CreateBusinessHourExceptionRequest = {
              type: BusinessHourExceptionType.Closed,
              loadingLocationId: this.loadingLocation.id,
              fromDateTime: result.fromTime,
              toDateTime: result.toTime,
            };
            if (result.allDay) {
              createRequest.fromDateTime = dayMinTime;
              createRequest.toDateTime = dayMaxTime;
              //todo enalble all day for multiple businesshours --> create multiple exceptions
            }
            return this.dpl.businessHourExceptionsService
              .post(createRequest)
              .pipe(
                this.loadingService.showLoadingWhile(),
                catchError((err) => {
                  // check "exisiting orders error";
                  if (
                    (
                      WrappedError.parse(err)
                        .ProblemDetails as DplProblemDetails
                    ).ruleStates?.find((x) =>
                      x.find(
                        (y) =>
                          y.messageId ===
                          'Error|BusinessHourExceptions|ExistingOrders'
                      )
                    )
                  ) {
                    return this.dialog
                      .open<
                        DynamicConfirmationDialogComponent,
                        DynamicConfirmationDialogData,
                        DynamicConfirmationDialogResult
                      >(DynamicConfirmationDialogComponent, {
                        data: {
                          labels: {
                            title: 'Fehler: Anlegen der Ausnahme nicht möglich',
                            description:
                              'Für den gewünschten Zeitpunkt der Ausnahme liegen bereits geplante Aufträge vor. Die Depotbetreuung wurde über Ihre gewünschte Schließungszeit informiert und wird sich mit Ihnen in Verbindung setzen.',
                            confirm: 'Ok',
                            hideCancel: true,
                          },
                        },
                        width: '600px'
                      })
                      .afterClosed()
                      .pipe(tap(() => this.notificationService.clear()));
                  }
                  throw err;
                })
              );
          }
          return of(null);
        }),

        tap(() => this.reloadSub.next(true))
      )
      .subscribe();
  }

  customizeDateNavigatorText = (e) => {
    //e.startDate.getDate()
    //e.enddate.getDate()
    return `${this.calendarWeekPipe.transform(
      e.startDate
    )} (${this.datePipe.transform(
      e.startDate,
      'd.M'
    )}-${this.datePipe.transform(e.endDate, 'd.M.y')})`;

    // if (!this.currentView || this.currentView === 'day')
    //      return DatePipe.transform(e.startDate, 'EEEE, d MMMM y');

    // if (this.currentView === 'month')
    //      return DatePipe.transform(e.startDate, 'MMMM y');

    //   return e.startDate.getDate() + "-" + DatePipe.transform(e.enddate, 'd MMMM y');
  };
}
