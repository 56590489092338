import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dpl-required-hint',
  templateUrl: './required-hint.component.html',
  styleUrls: ['./required-hint.component.scss'],
})
export class RequiredHintComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
