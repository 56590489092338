<ng-container *hasPermission="resourceAction.UpdateOrder">
  <button
    i18n-title="Buttontitel Editieren@@EditOrderButtonButtonTitle"
    mat-icon-button
    *ngIf="canEdit()"
    title="Auftrag editieren"
    (click)="edit()"
  >
    <mat-icon>edit</mat-icon>
  </button>
</ng-container>