import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MessagingSystemUser } from '../../messaging-system-types';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { Observable } from 'rxjs';
import { MemberUserDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { UserService } from '../../../user/services/user.service';
import { distinctUntilChanged, distinctUntilKeyChanged, map, switchMap } from 'rxjs/operators';
import _ from 'lodash';

interface EmployeeUserListItem extends MemberUserDto {
  actionRequired: Observable<boolean>;
}
@Component({
  selector: 'dpl-messaging-system-user-list',
  templateUrl: './messaging-system-user-list.component.html',
  styleUrls: ['./messaging-system-user-list.component.scss'],
})
export class MessagingSystemUserListComponent implements OnInit, OnDestroy {
  @Input() users$: Observable<MemberUserDto[]>;
  @Output() employeeUserSelected: EventEmitter<MemberUserDto> =
    new EventEmitter();

  constructor(
    private messagingDataService: MessagingDataService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.users$ = this.messagingDataService.getEmployeesWithTickets().pipe(      
      switchMap((users) => {
        return this.userService.getCurrentUser().pipe(
          map((currentUser) => {
            return _.orderBy(              
              users.filter((user) => user.userId !== currentUser.id),
              ['lastName', 'firstName'],
              ['asc', 'asc']
            ).map((user) => {
              return {
                ...user,
                actionRequired:
                  this.messagingDataService.getEmployeeActionRequiredFlag(
                    user.userId
                  ),
              };
            });
          })
        );
      })
    );
  }

  onSelectionChanged(e) {
    // const selectedUser: MemberUserDto = e.addedItems[0];
    // this.messagingDataService.setImperonateUser(selectedUser);
  }

  ngOnDestroy(): void {
    this.messagingDataService.setImperonateUser(null);
  }
}
