<mat-card>
  <mat-card-content>
    <div *ngIf="viewData$ | async as data">
      <ng-container *ngIf="!!data.activeCustomer; else noActive">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div
              i18n="
                CustomerSelectionActiveCustomerClassLabelDivText|Container DIV
                Text Aktuell verwalteter
                Kunde@@CustomerSelectionActiveCustomerClassLabelDivText"
              class="label"
            >
              Aktuell verwalteter Kunde:
            </div>
            <div>
              {{ data.activeCustomer.name }} ({{
                data.activeCustomer.refErpCustomerNumber
              }}) |
              {{ data.activeCustomer.address | address : 'long' }}
            </div>
            <div>
              <button mat-icon-button (click)="reset()">
                <mat-icon
                  i18n-title="
                    Mat-Edit-Icon Titel Kunde
                    wechseln@@CustomerSelectionActiveCustomerMatIconButtonMatEditIconTitle"
                  title="Kunde wechseln"
                  >edit</mat-icon
                >
              </button>
            </div>
          </div>
          <div *ngIf="routeCanIgnoreLeadTime$ | async">
            <mat-slide-toggle
              color="primary"
              (change)="setIgnoreLeadTime($event)"
              [checked]="ignoreLeadTime$ | async"
              labelPosition="before"
            >
              <!-- change subject | binding store obs | visibility route needs/start avaialabilities/start -->
              Vorlaufzeit ignorieren
            </mat-slide-toggle>
          </div>
        </div>
      </ng-container>
      <ng-template #noActive>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <mat-form-field>
            <input
              type="text"
              i18n-placeholder="
                Mat-Form-Field-Input Placeholder Kunde
                wechseln@@CustomerSelectionNotActiveCustomerMatFormFieldSearchCustomerInputPlaceholder"
              placeholder="Kunden suchen"
              matInput
              [matAutocomplete]="auto"
              [formControl]="customerControl"
            />

            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event.option.value)"
              [displayWith]="displayFn"
              panelWidth="auto"
            >
              <mat-option
                *ngFor="let option of options$ | async"
                [value]="option"
              >
                <div
                  [innerHtml]="
                    option.displayNameLong + ' | ' + (option.address | address)
                      | highlight : customerControl.value
                  "
                ></div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="cancel" (click)="reset()">
              <mat-icon
                i18n-title="
                  Mat-Close-Icon Titel Kunde
                  wechseln@@CustomerSelectionNotActiveCustomerMatIconButtonMatCloseIconTitle"
                title="Kunde wechseln"
                >close</mat-icon
              >
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>
