<div
  class="popover-messaging-system-attachment-dialog"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="dialog-top-part">
    <div mat-dailog-title class="message-list">
      <div class="message-list-header">
        <dpl-dialog-header [showOwnIcons]="true">
          <!-- Remember the ng-content is in a flex-direction row reverse -->
          <span headerText 
          i18n="@@ShowAttachmentDialogHeaderText"
            >Anhänge für die Nachricht</span
          >
          <div headerIcons>
            <dx-button
              i18n-hint="@@CloseDialogHint"
              icon="close"
              hint="Fenster schließen"
              (click)="onCancel()"
            >
            </dx-button>
          </div>
        </dpl-dialog-header>
      </div>
    </div>

    <div class="dialog-content">
      <div class="dialog-content-info-text">
        <span i18n="@@ShowAttachmentDialogContentInfo"
          >Hier können Sie die Dateien, die der Nachricht zugeordnet werden
          können, runterladen.</span
        >
      </div>
      <div class="dialog-content-file-uploader">
        <!-- FileUpload -->
        <dx-scroll-view class="dialog-content-file-uploader-scroll-view">
          <dx-list
            [dataSource]="message.attachments"
            [activeStateEnabled]="false"
            [focusStateEnabled]="false"
            [hoverStateEnabled]="false"
            [searchEnabled]="false"
            showScrollbar="always"
          >
            <div *dxTemplate="let data of 'item'; let i = index">
              <div class="uploaded-attachment-list-item">
                <span class="uploaded-attachment-index">{{ i + 1 }}</span>
                <dx-button
                  i18n-hint="@@DownloadAttachmentHint"
                  icon="download"
                  hint="Datei herrunterladen"
                  (click)="openAttachment(data.id)"
                >
                </dx-button>
                <span>{{ data?.fileName }}</span>
              </div>
            </div>
          </dx-list>
        </dx-scroll-view>
      </div>
    </div>
  </div>
  <div class="dialog-bottom-part">
    <div class="dialog-content-question-actions">
      <dx-button (onClick)="onCancel()" i18n-text="@@Close" text="Schließen">
      </dx-button>
    </div>
  </div>
</div>
