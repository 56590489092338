import { Injectable } from '@angular/core';
import {
  applyTransaction,
  filterNil,
  selectPersistStateInit,
} from '@datorama/akita';
import * as _ from 'lodash';
import {
  combineLatest,
  EMPTY,
  Observable,
  of,
  ReplaySubject,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  switchMap,
  tap,
} from 'rxjs/operators';

import { AccountsStore } from '../../accounts/state/accounts.store';
import { BalancesStore } from '../../accounts/state/balances.store';
import { AddressesStore } from '../../addresses/state/addresses.store';
import { NotificationService } from '../../core';
import { AuthenticationService } from '../../core/services/authentication.service';
import {
  ApiException,
  DplEmployeeCustomer,
  User,
  UserRole,
} from '../../core/services/dpl-api-services';
import { DplApiService } from '../../core/services/dpl-api.service';
import { CustomerDivisionsStore } from '../../customers/state/customer-divisions.store';
import { CustomersStore } from '../../customers/state/customers.store';
import { LoadingLocationsStore } from '../../loading-locations/state/loading-locations.store';
import { LoadCarriersService } from '../../master-data/load-carriers/services/load-carriers.service';
import { ExternalPostingAccountsStore } from '../external-posting-accounts/state/external-posting-accounts.store';
import { AadRole, IUser } from '../state/user.model';
import { UserQuery } from '../state/user.query';
import { UserStore } from '../state/user.store';
import { normalizeUserData } from './user.normalize';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  ensureUserData$: ReplaySubject<void> = new ReplaySubject(1);
  loadCarrierDict = new ReplaySubject<{}>();
  initialized: boolean;
  constructor(
    private auth: AuthenticationService,
    private dpl: DplApiService,
    private userStore: UserStore,
    private userQuery: UserQuery,
    private customersStore: CustomersStore,
    private divisionsStore: CustomerDivisionsStore,
    private postingAccountsStore: AccountsStore,
    private externalPostingAccountsStore: ExternalPostingAccountsStore,
    private balancesStore: BalancesStore,
    private loadingLocationsStore: LoadingLocationsStore,
    private addressesStore: AddressesStore,
    private loadCarrierService: LoadCarriersService,
    private notification: NotificationService
  ) {}

  isLoading() {
    return this.userQuery.selectLoading();
  }

  getCurrentUser() {
    return this.userQuery.user$;
  }

  getIsDplEmployee() {
    return this.getCurrentUser().pipe(
      filterNil,
      pluck('role'),
      map((role) => role === UserRole.DplEmployee),
      distinctUntilChanged()
    );
  }

  getUserRole() {
    return this.getCurrentUser().pipe(
      filterNil,
      pluck('role'),
      distinctUntilChanged()
    );
  }

  getPermissions() {
    return this.userQuery.permissions$;
  }

  refreshUserData(customer?: DplEmployeeCustomer): Observable<void> {
    return combineLatest([
      this.auth.isLoggedIn(),
      selectPersistStateInit(),
    ]).pipe(
      switchMap(([isLoggedIn]) => {
        this.userStore.setLoading(true);
        if (!isLoggedIn) {
          return EMPTY;
        }
        // check customer
        return this.dpl.user
          .get({
            customerId: customer ? customer.customerId : undefined,
          })
          .pipe(
            catchError((error) => {
              // if user is authorized but there was a server error
              if (ApiException.isApiException(error) && error.status === 500) {
                return throwError(error);
              }
              // on any other error => likely Unauthorized
              else {
                this.notification.showError(
                  $localize`:@@UserHasNoAccessPermission:User hat keine Zugriffsberechtigung.`
                );
              }

              return of(null);
            })
          );
      }),
      filterNil,
      map((userData: User) => ({
        userData,
        entities: normalizeUserData(userData),
      })),
      map((data) => {
        const { externalPostingAccounts } = data.userData;
        return {
          ...data.entities,
          externalPostingAccounts,
        };
      }),
      tap((entities) => {
        applyTransaction(() => {
          console.log('REFRESH USERDATA', entities);

          this.addressesStore.set(entities.addresses ? entities.addresses : []);
          this.loadingLocationsStore.set(
            entities.loadingLocations ? entities.loadingLocations : []
          );

          this.divisionsStore.set(entities.divisions ? entities.divisions : []);
          this.postingAccountsStore.set(
            entities.postingAccounts ? entities.postingAccounts : []
          );

          // // HACK: ensure load carrier type ids are retrieved from the server
          // this.postingAccountsStore.update(
          //   () => true,
          //   () => {
          //     return {
          //       loadCarrierTypeIds: [],
          //     };
          //   }
          // );

          this.customersStore.set(entities.customers ? entities.customers : []);

          // employee - check customer divisionId is set
          if (customer && customer.divisionId) {
            this.divisionsStore.setActive(customer.divisionId);
          } else {
            const active = this.divisionsStore.getValue().active;
            if (!active) {
              // make sure there always is an active division / posting account / customer
              this.divisionsStore.setActive(
                this.divisionsStore.getValue().ids[0]
              );
            }
          }
          // employee - check customer postingAccountId is set
          if (customer && customer.postingAccountId) {
            this.postingAccountsStore.setActive(customer.postingAccountId);
          } else {
            this.postingAccountsStore.setActive(
              this.postingAccountsStore.getValue().ids[0]
            );
          }
          // employee - set active customer customerId
          if (customer && customer.customerId) {
            this.customersStore.setActive(customer.customerId);
          } else {
            const active = this.customersStore.getValue().active;
            if (!active) {
              this.customersStore.setActive(
                this.customersStore.getValue().ids[0]
              );
            }
          }

          this.externalPostingAccountsStore.set(
            entities.externalPostingAccounts,
            {
              activeId: entities.externalPostingAccounts.length
                ? entities.externalPostingAccounts[0].id
                : undefined,
            }
          );

          const userData: IUser<number, number> = {
            ...this.userStore.getValue(),
            ...entities.user[Object.keys(entities.user)[0]],
          };

          // this needs to be the last store we update as we rely on the loading flag of this store
          this.userStore.update(userData);
          this.userStore.setLoading(false);
        });
      }),
      map((i) => null)
    );
  }

  getLoadCarrierDict() {
    return this.loadCarrierService.getLoadCarriers().pipe(
      filter((loadCarriers) => loadCarriers && loadCarriers.length > 0),
      first(),
      map((loadCarriers) => {
        return _(loadCarriers)
          .keyBy((i) => i.id)
          .value();
      })
    );
  }

  updateBalance(postingAccountId: number) {
    this.dpl.postingAccounts.getBalances(postingAccountId).pipe();
  }

  selectDplAdmin() {
    return this.userQuery.select().pipe(
      filter(
        (x) => !!x && x.role === UserRole.DplEmployee && x.aadRoles.length > 0
      ),
      map((x) => {
        if (x.aadRoles.find((role) => role === AadRole.UserAdmin)) {
          return AadRole.UserAdmin;
        }
        if (x.aadRoles.find((role) => role === AadRole.Admin)) {
          return AadRole.Admin;
        }
      })
    );
  }
}
