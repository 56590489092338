<dpl-channel [isDialog]="true" [dialogIsOpen]="dialogIsOpen" [showOwnIcons]="true" [showChannelInfo]="true">
  <div headerIcons>
    <dx-button
      icon="close"
      i18n-hint="@@CloseWindow"
      hint="Fenster schließen"
      (click)="onCancel()"
    >
    </dx-button>
  </div>
</dpl-channel>
