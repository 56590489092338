import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isInteger } from 'lodash';
import { LoadingService } from '../../../../../../../libs/dpl-lib/src';
import { DplApiService } from '../../../core';
import {
  Address,
  LoadingLocationAdministration,
  LoadingLocationCreateRequest,
  OrderType,
} from '../../../core/services/dpl-api-services';
import { ILoadingLocation } from '../../../loading-locations/state/loading-location.model';
import { LoadingLocationDialogData } from '../loading-location-create-dialog/loading-location-create-dialog.component';
import { LoadingLocationForm, LocationCreateMode, LocationCreateUseCase } from '../loading-location-create-form/loading-location-create-form.component';

export interface LoadingLocationEditDialogData {
  orderType?: OrderType;
  existingLocation?: ILoadingLocation<Address>;
  onlyInfo?: boolean;
  adminLoadingLocation?: LoadingLocationAdministration;
}

@Component({
  selector: 'dpl-loading-location-edit-dialog',
  templateUrl: './loading-location-edit-dialog.component.html',
  styleUrls: ['./loading-location-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLocationEditDialogComponent implements OnInit {
  data: LoadingLocationEditDialogData;
  existingLocationCreate: LoadingLocationCreateRequest;

  lockSubmit = false;
  formValid = false;
  editRequest: LoadingLocationCreateRequest;
  locationCreateUseCase=LocationCreateUseCase;
  locationCreateMode=LocationCreateMode;

  constructor(
    public dialogRef: MatDialogRef<
      LoadingLocationEditDialogComponent,
      ILoadingLocation<Address>
    >,
    @Inject(MAT_DIALOG_DATA) data: LoadingLocationEditDialogData,
    private dpl: DplApiService,
    private loadingService: LoadingService
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    // map location to createLoadingLocationForm object
    if (this.data.existingLocation) {
      this.existingLocationCreate = {
        ...this.data.existingLocation,
        ...this.data.existingLocation.detail,
      };
    } else {
      this.existingLocationCreate = { ...this.data.adminLoadingLocation };
      this.data.orderType = OrderType.Supply;
    }
  }

  formValidChange(valid) {
    console.log('FORM VALID', valid);
    this.formValid = valid;
  }

  onChange(editRequest: LoadingLocationCreateRequest) {
    this.editRequest = editRequest;
  }

  onCancel(): void {
    this.dialogRef.close();
    // this.lockSubmit = false;
  }

  onSave(event: Event): void {
    if (this.lockSubmit) return;
    this.lockSubmit = true;
    //get changed Properties
    const editableProperties = [
      'displayName',
      'type',
      'ownershipType',
      'description',
      'companyName',
      'supportsRearLoading',
      'supportsSideLoading',
      'supportsJumboVehicles',
      'stackHeightMin',
      'stackHeightMax',
      'email',
      'phone',
      'fax',
    ];
    //To do additonal properties for admin loading locations?
    const changes: string[] = [];
    for (const property of editableProperties) {
      if (this.existingLocationCreate[property] != this.editRequest[property]) {
        console.log('CHANGE', this.editRequest[property]);
        if (
          this.editRequest[property] === null ||
          isInteger(this.editRequest[property])
        ) {
          changes.push(`${property}:${this.editRequest[property]}`);
        } else {
          changes.push(`${property}:"${this.editRequest[property]}"`);
        }
      }
    }

    this.dpl.loadingLocations
      .patch(
        this.data.existingLocation
          ? this.data.existingLocation.id
          : this.data.adminLoadingLocation.id,
        `{${changes.join(',')}}`
      )
      .pipe(this.loadingService.showLoadingWhile())
      .subscribe((result) => {
        const response: ILoadingLocation<Address> = {
          ...result,
          address: {
            // adress is readonly for 'customer ad-hoc loading locations'
            ...(this.data.existingLocation
              ? this.data.existingLocation.address
              : result.address),
          },
        };
        console.log('edited loadingLocation', response);
        this.dialogRef.close(response);
      });
  }
}
