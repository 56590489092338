import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'calendarWeek',
})
export class CalendarWeekPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(dateInWeek: Date, format: 'range' = null): any {
    const date = (moment as any)(dateInWeek) as moment.Moment;
    const calenderWeek = $localize`:Eingabe Bezeichnung Wochenkalenderauswahl Control|calendarWeekPickerInput@@calendarWeekPickerInput:KW`;

    if (format === 'range') {
      const startDate = date.weekday(0);
      const endDate = startDate.clone().add(6, 'day');
      return (
        calenderWeek +
        ` ${startDate.week()} (${this.datePipe.transform(
          startDate.toDate(),
          'dd.MM'
        )} - ${this.datePipe.transform(endDate.toDate())})`
      );
    }
    return calenderWeek + ` ${date.week()}`;
  }
}
