import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PermissionResourceType, ResourceAction, UserRole } from '@app/api/dpl';
import { DxiConstantLineComponent } from 'devextreme-angular/ui/nested';
import * as _ from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
  AppFeature,
  PermissionsService,
} from '../../core/services/permissions.service';
import { UserService } from '../../user/services/user.service';

interface PermissionData {
  actions: ResourceAction[];
  resource?: PermissionResourceType;
  referenceId?: number;
}

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  @Input('hasPermission') hasPermission:
    | ResourceAction
    | [ResourceAction, PermissionResourceType, number]
    | {
        actions: ResourceAction[];
        resource?: PermissionResourceType;
        referenceId?: number;
      };

  @Input('hasPermissionFeature') feature: AppFeature;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    let hidden = true;

    this.userService
      .getCurrentUser()
      .pipe(
        untilDestroyed(this),
        map((user) => user.role),
        switchMap((role) => {
          if (this.hasPermission === "Update") {
            debugger;
          }
          if (role === UserRole.DplEmployee) {
            return of(true);
          }

          if (this.feature) {
            const hasAccessToFeature =
              this.permissionsService.getHasRoleAccessToFeature(
                this.feature,
                role
              );
            if (!hasAccessToFeature) {
              return of(false);
            }
          }

          const { actions, resource, referenceId } = this.getPermissionData();
          const hasPermissionObsArray = resource
            ? _(this.permissionsService.getResourceTypesInHierarchy(resource))
                .map((resource) => {
                  return actions.map((action) => {
                    return this.permissionsService.hasPermission(
                      action,
                      resource,
                      referenceId
                    );
                  });
                })
                .flatten()
                .value()
            : actions.length === 1 ? actions.map((action) => {
                return this.permissionsService.hasPermission(
                  action,
                  resource,
                  referenceId
                );
              }): this.permissionsService.hasAtLeastOnePermission(actions, resource, referenceId);





          return combineLatest(hasPermissionObsArray).pipe(
            map((hasPermissionArray) => {
              // dpl employee
              return hasPermissionArray.some((hasPermission) => hasPermission);
            })
          );
        }),
        tap((hasPermission) => {
          if (hasPermission) {
            if (hidden) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              hidden = false;
            }
          } else {
            this.viewContainer.clear();
            hidden = true;
          }
        })
      )
      .subscribe({
        complete() {
          if (!hidden) {
            this.viewContainer.clear();
            hidden = true;
          }
        },
        error() {
          if (!hidden) {
            this.viewContainer.clear();
            hidden = true;
          }
        },
      });
  }

  ngOnDestroy() {}

  getPermissionData(): PermissionData {
    if (HasPermissionDirective.isPermissionDataObject(this.hasPermission)) {
      return this.hasPermission;
    }

    if (!Array.isArray(this.hasPermission)) {
      const action = this.hasPermission;
      return {
        actions: [action],
      };
    }

    const [action, resource, referenceId] = this.hasPermission;

    return {
      actions: [action],
      resource,
      referenceId,
    };
  }

  static isPermissionDataObject(value: any): value is PermissionData {
    return typeof value === 'object' && value.actions !== undefined;
  }
}
