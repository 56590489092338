import { Injectable } from '@angular/core';
import { combineQueries, filterNil, QueryEntity } from '@datorama/akita';
import * as _ from 'lodash';
import {
  filter,
  map,
  publishReplay,
  refCount,
  switchMap,
} from 'rxjs/operators';
import { ExternalLoadCarrierQualitiesQuery } from './external-load-carrier-qualities.query';
import { ExternalLoadCarrierTypesQuery } from './external-load-carrier-types.query';
import { IExternalLoadCarrier } from './external-load-carrier.model';
import {
  ExternalLoadCarriersStore,
  ExternalLoadCarriersState,
} from './external-load-carriers.store';

@Injectable({ providedIn: 'root' })
export class ExternalLoadCarriersQuery extends QueryEntity<ExternalLoadCarriersState> {
  active$ = this.selectActive();
  all$ = this.selectLoadCarriers();
  allByActiveType$ = this.allByActiveType();
  firstLoadCarrierActiveTypeId$ = this.allByActiveType$.pipe(filterNil,map(x=>{return x.length>0? x[0].id:null}))  

  constructor(
    protected store: ExternalLoadCarriersStore,
    private types: ExternalLoadCarrierTypesQuery,
    private qualities: ExternalLoadCarrierQualitiesQuery
  ) {
    super(store);
  }

  getLoadCarrierById(loadCarrierId: number) {
    return this.selectEntity(loadCarrierId);
  }

  private selectLoadCarriers() {
    return this.selectLoading().pipe(
      filter((loading) => !loading),
      switchMap(() => {
        return combineQueries([
          this.selectAll(),
          this.types.selectAll({ asObject: true }),
          this.qualities.selectAll({ asObject: true }),
        ]);
      }),
      map(([loadCarriers, types, qualities]) => {
        return _(loadCarriers)
          .map((i) => {
            return <IExternalLoadCarrier>{
              ...i,
              type: types[i.type],
              quality: qualities[i.quality],
            };
          })
          .orderBy([(i) => i.type.order, (i) => i.quality.order])
          .value();
      }),
      publishReplay(1),
      refCount()
    );
  }

  private allByActiveType() {
    return this.selectLoading().pipe(
      filter((loading) => !loading),
      switchMap(() => {
        return this.types.selectActiveId().pipe(
          filterNil,
          switchMap((activeTypeId) => {
            return this.selectAll({ filterBy: (x) => x.type === activeTypeId });
          })          
        );
      })
    );
  }
}
