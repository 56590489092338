
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CompleteSingleProcessUploadFileInfoType, CompleteSingleProcessUploadRequestForm } from '../../core/services/dpl-api-services';
export interface UploadedFile {
  fileId: number;
  uploadUrl: string;
  filename: string;
  fileSize: number;
  completed:boolean;
  type?: CompleteSingleProcessUploadFileInfoType;
  controlFileIndex?:number;
}

export interface UploadedFilesState extends EntityState<UploadedFile, number> {}

@Injectable()
@StoreConfig({ name: 'uploaded-files', idKey: 'fileId' })
export class UploadedFilesStore extends EntityStore<UploadedFilesState> {
  constructor() {
    super();
  }
}
