import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'dpl-valid-icon-button',
  templateUrl: './valid-icon-button.component.html',
  styleUrls: ['./valid-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidIconButtonComponent implements OnInit {  
  @Input() valid$:Observable<boolean>;

  constructor() { }

  ngOnInit(): void {
  }

}
