<ng-container>
  <dx-list
    #userList
    [dataSource]="users$ | async"
    [selectionMode]="'single'"
    [searchEnabled]="true"
    searchExpr="lastName"
    searchMode="contains"
    height="755px"
    showScrollbar="always"
    (onSelectionChanged)="onSelectionChanged($event)"
  >
    <div *dxTemplate="let data of 'item'">
      <div class="user-item">
        <div class="user-info">
          <div class="user-name">
            <span
              matBadge="!"
              matBadgeOverlap="false"
              [matBadgeHidden]="!(data.actionRequired | async)"
              matBadgeColor="warn"
              >{{ data.lastName }}, {{ data.firstName }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </dx-list>
</ng-container>
