import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { ShowAttachmentDialogComponent } from '../show-attachment-dialog/show-attachment-dialog.component';

@Component({
  selector: 'dpl-announcement-message-template',
  templateUrl: './announcement-message-template.component.html',
  styleUrls: ['./announcement-message-template.component.scss'],
})
export class AnnouncementMessageTemplateComponent implements OnChanges{
  @Input() message?: MessageDto;
  @Input() selectedLanguage?: string = '';
  @Input() isDialog?: boolean = false;
  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLanguage) {
      console.log(this.selectedLanguage)
    }
  }

  openDialogForAttachments(message: MessageDto) {
    this.dialog.open<ShowAttachmentDialogComponent>(
      ShowAttachmentDialogComponent, {
      minWidth: '450px',
      maxWidth: '1200px',
      width: '35%',
      maxHeight: '600px',
      panelClass: 'messaging-system-dialog-panel',
      data: message,
    }
    )
  }

  getTranslatedMessageBody(message: MessageDto) {
    switch (this.selectedLanguage) {
      case 'de':
        if (message.translations?.de) {
          return message.translations?.de
        } else {
          //show an info if translation didn't exist
          // Original Message
          return message.message.body
        }
      case 'en':
        if (message.translations?.en) {
          return message.translations?.en
        } else {
          // Original Message
          return message.message.body
        }
      case 'fr':
        if (message.translations?.fr) {
          return message.translations?.fr
        } else {
          // Original Message
          return message.message.body
        }
      case 'es':
        if (message.translations?.es) {
          return message.translations?.es
        } else {
          // Original Message
          return message.message.body
        }
      case 'pl':
        if (message.translations?.pl) {
          return message.translations?.pl
        } else {
          // Original Message
          return message.message.body
        }
      case 'it':
        if (message.translations?.it) {
          return message.translations?.it
        } else {
          // Original Message
          return message.message.body
        }
      default:
        // Original Message
        return message.message?.body
    }
  }
}
