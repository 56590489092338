<ng-container *ngIf="currentUser$ | async as currentUser; else loggedOut">
  <a mat-button [matMenuTriggerFor]="userProfile">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <span>
        {{ currentUser.name }}
      </span>
      <mat-icon style="margin-left: 12px">account_circle</mat-icon>
    </div>
  </a>

  <mat-menu #userProfile="matMenu">
    <a
      mat-menu-item
      i18n="ContactSupport|Label Kontakt Support@@ContactSupport"
      [routerLink]="['contact']"
      >Kontakt / Support
      <!-- Todo: maybe Remove the word Support after we have a extra page --></a
    >
    <mat-divider></mat-divider>
    <a mat-menu-item (click)="logout()" i18n="Logout|Label Abmelden@@Logout"
      >Abmelden</a
    >
  </mat-menu>
</ng-container>
<ng-template #loggedOut>
  <a mat-button (click)="login()" i18n="Login|Label Anmelden@@Login">Login</a>
</ng-template>
