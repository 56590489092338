import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User, UserRole } from '../../../core/services/dpl-api-services';
import { Observable, combineLatest, of, timer } from 'rxjs';
import { UserService } from '../../../user/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import { filterNilValue } from '@datorama/akita';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  publishReplay,
  refCount,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ChannelDto,
  ChatUserDto,
  MemberDto,
  MemberUserDto,
} from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import {
  ChatChannelState,
  ChatChannelType,
} from '../../../generated/Dpl.B2b.Common.Enumerations';
import { ChatChannelStatePipe } from '../../pipes/chat-channel-state.pipe';
import { ChangeChannelStateDialogComponent } from '../change-channel-state-dialog/change-channel-state-dialog.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormControl } from '@ngneat/reactive-forms';
import { MembersDialogComponent } from '../members-dialog/members-dialog.component';
import { MessagingChannelsQuery } from '../../../messaging-data/state/messaging-channels.query';
import { ResponsiveMemberChangeDialogComponent } from '../responsive-member-change-dialog/responsive-member-change-dialog.component';
import _ from 'lodash';

type ViewData = {
  currentUser: User;
  ticket: ChannelDto;
  possibleResponsibleUsers?: ChatUserDto[];
};
export type LanguageSelectType = {
  id: number;
  name: string;
  value: string;
};

@Component({
  selector: 'dpl-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements OnInit, OnDestroy {
  @Input() showOwnIcons? = false;
  @Input() showChannelInfo? = true;
  @Input() isDialog? = false;
  @Input() dialogIsOpen? = false;
  messageListHight?: number = 517;
  copyText: any = '';
  visible = false;
  popOverVisible = false;
  viewData$: Observable<ViewData>;
  hasActiveTicket$: Observable<boolean>;
  channelState = ChatChannelState;
  selectedLanguageControl: FormControl<string>;
  activeTicket: ChannelDto;
  userRole = UserRole;
  languages: LanguageSelectType[] = [
    {
      id: 0,
      name: $localize`:@@LanguageCodeOriginal:Original`,
      value: '',
    },
    {
      id: 1,
      name: $localize`:@@LanguageCodeDE:Deutsch`,
      value: 'de',
    },
    {
      id: 2,
      name: $localize`:@@LanguageCodeEN:Englisch`,
      value: 'en',
    },
    {
      id: 3,
      name: $localize`:@@LanguageCodeFR:Französisch`,
      value: 'fr',
    },
    {
      id: 4,
      name: $localize`:@@LanguageCodeIT:Italienisch`,
      value: 'it',
    },
    {
      id: 5,
      name: $localize`:@@LanguageCodeES:Spanisch`,
      value: 'es',
    },
    {
      id: 5,
      name: $localize`:@@LanguageCodePL:Polnisch`,
      value: 'pl',
    },
  ];

  languageDict = this.languages.reduce((prev, curr) => {
    prev[curr.value] = curr;
    return prev;
  }, {});

  constructor(
    private channelStatePipe: ChatChannelStatePipe,
    private userService: UserService,
    private messagingDataService: MessagingDataService,
    private dialog: MatDialog,
    private messagingChannelsQuery: MessagingChannelsQuery
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void { }

  ngOnInit(): void {
    const CHANNEL_READ_TIMEOUT = 3 * 1000;

    this.selectedLanguageControl = new FormControl();

    const currentUser$ = this.userService
      .getCurrentUser()
      .pipe(filterNilValue());

    this.hasActiveTicket$ = this.isDialog ? of(true) : this.messagingDataService
      .getTicketActiveNullable()
      .pipe(
        map((ticket) => {
          console.log('hasActiveTicket', ticket);
          return !!ticket;
        }),
        tap((hasActiveTicket) =>
          console.log('hasActiveTicket', hasActiveTicket)
        )
      );

    const ticket$ = this.messagingDataService.getTicketActive().pipe(
      tap((ticket) => {
        this.activeTicket = ticket;
      })
    );

    const setLastReading$ = ticket$.pipe(
      untilDestroyed(this),
      filter((i) => i && i.unreadCount > 0),
      switchMap((ticket) => {
        return timer(CHANNEL_READ_TIMEOUT).pipe(mapTo(ticket));
      }),
      untilDestroyed(this),
      tap((ticket) => {
        if (
          this.activeTicket === ticket &&
          this.isDialog &&
          this.dialogIsOpen
        ) {
          this.messagingDataService.setLastReading(ticket.channelId);
        } else {
          if (this.activeTicket === ticket && !this.isDialog && ticket.channelType !== ChatChannelType.Announcement) {
            this.messagingDataService.setLastReading(ticket.channelId);
          } else {
            console.log('DidNotSetLastReading')
          }
        }
      })
    );

    const language$ = this.selectedLanguageControl.value$.pipe(
      filterNilValue(),
      untilDestroyed(this),
      distinctUntilChanged()
    );

    combineLatest([setLastReading$, ticket$, language$]).subscribe();

    // reset language when channel changes
    const selectedLanguageControlValue$ = combineLatest([
      currentUser$.pipe(distinctUntilKeyChanged('id')),
      ticket$.pipe(filterNilValue(), distinctUntilKeyChanged('channelId')),
    ]).pipe(untilDestroyed(this), mapTo(this.languages[0].value));

    this.selectedLanguageControl.setValue(selectedLanguageControlValue$);

    this.selectedLanguageControl.value$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        switchMap((selectedLanguage) => {
          return ticket$.pipe(
            tap((channel) => {
              if (channel) {
                this.messagingDataService.translateChannel(
                  channel?.channelId,
                  selectedLanguage
                );
              }
            })
          );
        })
      )
      .subscribe();

    const possibleResponsibleUsers$ =
      this.messagingChannelsQuery.selectPossibleImpersonateUsers$.pipe(
        tap((users) => {
          console.log('getPossibleRespnsibleUsers', users);
        })
      );

    this.viewData$ = combineLatest([
      currentUser$,
      ticket$,
      possibleResponsibleUsers$,
    ]).pipe(
      map(([currentUser, ticket, possibleResponsibleUsers]) => {
        const viewData: ViewData = {
          currentUser: {
            ...currentUser,
            externalPostingAccounts: null,
            postingAccounts: null,
            customers: null,
          },
          ticket,
          possibleResponsibleUsers,
        };
        // console.log('channel viewData', viewData);
        return viewData;
      })
    );
  }

  onListHightChange(e) {
    this.messageListHight = e;
  }

  onCopyMessage(e) {
    this.copyText = e;
  }

  translateChannelState(channelState: string) {
    return this.channelStatePipe.transform(channelState);
  }

  openChannelStateDialog(ticket: ChannelDto) {
    this.dialog.open<ChangeChannelStateDialogComponent>(
      ChangeChannelStateDialogComponent,
      {
        minWidth: '250px',
        maxWidth: '800px',
        width: '25%',
        panelClass: 'messaging-system-dialog-panel',
        data: ticket,
        disableClose: true,
      }
    );
  }

  openResponsiveMembersDialog(ticket: ChannelDto) {
    this.dialog.open<ResponsiveMemberChangeDialogComponent>(
      ResponsiveMemberChangeDialogComponent,
      {
        minWidth: '250px',
        maxWidth: '800px',
        width: '25%',
        panelClass: 'messaging-system-dialog-panel',
        data: ticket,
        disableClose: true,
      }
    );
  }

  openMembersDialog(ticket: ChannelDto) {
    this.dialog.open<MembersDialogComponent>(MembersDialogComponent, {
      minWidth: '250px',
      maxWidth: '800px',
      width: '25%',
      panelClass: 'messaging-system-dialog-panel',
      data: ticket,
      disableClose: true,
    });
  }

  openInNewTab(ticket: ChannelDto) {
    if (ticket.channelType !== 1000) {
      this.messagingDataService.openDetailInNewTab(ticket);
    }
  }

  setRepsonsibleUser(channelId, userId: number) {
    this.messagingDataService.setResponsibleUser(channelId, userId);
  }

  membersCellInfo(member: MemberUserDto[]) {
    return _(member)
      .sortBy((member) => member.lastName)
      .value()
      .map((value) => value.firstName + value.lastName);
  }

  checkIfTicketIsRead(ticket: ChannelDto, currentUser: User) {
    const userHasReadAnnouncement = ticket.readings.findIndex(readings => readings.userId === currentUser.id) >= 0
    const ticketHasUnreadCounts = ticket.unreadCount > 0
    if (!userHasReadAnnouncement || ticketHasUnreadCounts) {
      return true
    } else {
      return false
    }
  }

  closeAnnoncementForNextLogin(ticket: ChannelDto) {
    this.messagingDataService.setLastReading(ticket.channelId)
  }
}
