import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';



@Component({
  selector: 'dpl-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatButtonComponent {

  
  


 







}
