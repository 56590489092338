import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  ActiveState,
} from '@datorama/akita';
import { IExternalLoadCarrierType } from './external-load-carrier-type.model';

export interface ExternalLoadCarrierTypesState
  extends EntityState<IExternalLoadCarrierType, number>,
    ActiveState<number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'external-load-carrier-types' })
export class ExternalLoadCarrierTypesStore extends EntityStore<ExternalLoadCarrierTypesState> {
  constructor() {
    super();
  }
}
