import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'dpl-grid-loadcarrier-cell',
  templateUrl: './grid-loadcarrier-cell.component.html',
  styleUrls: ['./grid-loadcarrier-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridLoadcarrierCellComponent implements OnInit {
  @Input() carrier?: string;
  @Input() carrierId?: string;
  @Input() hint?: string;

  popOverVisible = false;
  randomId = Math.floor(Math.random() * 100 + 1);
  constructor() {}

  ngOnInit(): void {}
}
