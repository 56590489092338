import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProcessesStore, ProcessesState } from './processes.store';

@Injectable()
export class ProcessesQuery extends QueryEntity<ProcessesState> {

  constructor(protected store: ProcessesStore) {
    super(store);
  }

}
