<ng-container *ngIf="viewData$ | async as data">
  <div class="message-input-content">
    <div #editorContainer class="editor-container">
      <dx-html-editor
        [height]="isDialog ? '243px' : '250px'"
        width="100%"
        #htmlEditor
        valueType="html"
        [(value)]="editorValue"
        placeholder="Hier können Sie Ihre Nachricht eingeben."
        i18n-placeholder="@@MessageInputHtmlEditorPlaceholder"
        [disabled]="disableSendButton"
        (onContentReady)="onContentReady($event)"
        (onInitialized)="onInitialized($event)"
        (onFocusOut)="onFocusOut($event)"
      >
        <dxo-toolbar>
          <dxi-item name="bold"></dxi-item>
          <dxi-item name="italic"></dxi-item>
          <dxi-item name="strike"></dxi-item>
          <dxi-item name="underline"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item>
            <div *dxTemplate (click)="openFileUploadDialog()">
              <div class="attachment-icon">
                <dx-button
                  icon="attach"
                  hint="Öffnen des Dialogs zum Hochladen von Dateien"
                  i18n-hint="@@MessageInputAttachmentOpenDialogHint"
                >
                </dx-button>
                <ng-container *ngIf="uploadedFilesLength > 0">
                  <span
                    class="has-attachments"
                    title="Hochgeladene Dateien"
                    [matBadge]="uploadedFilesLength"
                    matBadgeColor="primary"
                    i18n-title="@@MessageInputHasAttachmentsDialogTitle"
                  ></span>
                </ng-container>
              </div>
            </div>
          </dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="codeBlock"></dxi-item>
          <dxi-item name="blockquote"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <ng-container *ngIf="!disableHeightChange">
            <dxi-item
              widget="dxButton"
              [options]="expandButtonOptions"
            ></dxi-item>
            <dxi-item name="separator"></dxi-item>
          </ng-container>
        </dxo-toolbar>
        <ng-container
          *ngIf="data.isEmployee && selectedChannel.channelType !== 1000"
        >
          <dxi-mention
            valueExpr="userId"
            itemTemplate="mentionItemTemplate"
            [displayExpr]="getMentionDisplayExpr"
            [searchExpr]="['userName', 'userUpn', 'userId']"
            [searchEnabled]="true"
            [searchMode]="'contains'"
            [dataSource]="data.mentionDataSource"
          >
            <div *dxTemplate="let mentionItem of 'mentionItemTemplate'">
              {{ mentionItem.userId }} {{ mentionItem.userName }}
            </div>
          </dxi-mention>
        </ng-container>
        <!-- TODO: fix Blockqoute with HTML CODE -->
        <!-- <ng-container *ngIf="copiedText?.length > 0">
        <blockquote>{{ copiedText }}</blockquote>
      </ng-container> -->
        <!--  <dxo-media-resizing [enabled]="true"> </dxo-media-resizing> -->
      </dx-html-editor>
      <div class="message-input-buttons">
        <dx-button
          class="send"
          icon="message"
          i18n-text="@@MessagingInputSendButton"
          text="Send"
          hint="Senden der Nachricht"
          i18n-hint="@@MessageInputSendButtonHint"
          (onClick)="sendMessage()"
          [disabled]="disableSendButton"
        >
        </dx-button>
      </div>
    </div>
  </div>
</ng-container>
