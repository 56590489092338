import { InjectionToken } from '@angular/core';

export const IMPORT_CSV_CONFIG = new InjectionToken<csvConfig>(
  'IMPORT_CSV_CONFIG'
);

export interface csvConfig {
  header:any[],
  headerError(e:any): string
  isHeaderNameOptional:boolean
}
