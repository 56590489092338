import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { GlobalSettingsState } from './global-settings.model';

/**
 * Create initial state
 */
export function createInitialState(): GlobalSettingsState {
  return {
    ignoreLeadTime: false,
  };
}

/**
 * GlobalSettings store
 *
 * @export
 * @class GlobalSettingsStore
 * @extends {Store<GlobalSettingsState>}
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global-settings' })
export class GlobalSettingsStore extends Store<GlobalSettingsState> {
  constructor() {
    super(createInitialState());
  }
}
