<div class="uploaded-list-item">
  <span class="uploaded-file-index">{{ index + 1 }}</span>
  <dx-button
    icon="close"
    hint="Eintrag entfernen"
    i18n-hint="@@RemoveItemHint"
    (click)="removeItem(item)"
  >
  </dx-button>
  <span> {{ item.filename }}</span>
</div>
