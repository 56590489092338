export interface IGlobalNotification {
    // date format MM.DD.YYYY
    startDate?: Date;
    endDate?: Date;
    textEN?: string;
    textDE?: string;
    level?: NotificationLevel 

}

export enum NotificationLevel {
    warning = "Warning",
    error = "Error",
    info = "Info"
}