import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { LOADING_MAX_DURATION } from '@dpl/dpl-lib';

import { APP_CONFIG, DplLiveConfiguration } from '../config';
import { IMPORT_CSV_CONFIG } from '../csvConfig';
import { environment } from '../environments/environment';
import { isMsalIframe } from '../utils';
import { AddressesModule } from './addresses/addresses.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactModule } from './contact/contact.module';
import { API_BASE_URL } from './core/services/dpl-api-services';
import { CustomersModule } from './customers/customers.module';
import { LoadingLocationsModule } from './loading-locations/loading-locations.module';
import { MasterDataModule } from './master-data/master-data.module';
import { ProfileModule } from './profile/profile.module';
import { SharedModule } from './shared/shared.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { UserModule } from './user/user.module';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { GoogleMapsService } from './search/services/google-maps.service';
import { MessagingSystemModule } from './messaging-system/messaging-system.module';

// think about conditional loading of sub modules or creatinga parent module for app
// based on current window location window.location.pathname === "/msal-callback"

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    environment.production
      ? []
      : AkitaNgDevtools.forRoot({
          name: isMsalIframe() ? 'iFrame' : undefined,
        }),
    AkitaNgRouterStoreModule,
    MasterDataModule,
    UserModule,
    CustomersModule,
    LoadingLocationsModule,
    AddressesModule,
    MessagingSystemModule,
    ProfileModule,
    UserSettingsModule,
    ContactModule,
    AgmCoreModule.forRoot(),
  ],
  providers: [
    // sets the base url to the api
    {
      provide: API_BASE_URL,
      useFactory: (config: DplLiveConfiguration) => config.app.apiBaseUrl,
      deps: [APP_CONFIG],
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: API_BASE_URL },
    },
    {
      provide: LOADING_MAX_DURATION,
      useFactory: (config: DplLiveConfiguration) =>
        config.app.ui.loading.durationMax,
      deps: [APP_CONFIG],
    },
    {
      provide: LAZY_MAPS_API_CONFIG,
      useFactory: (config: DplLiveConfiguration) => {
        return {
          ...config.googleMaps,
          // to manage language  / region via language picker
          // we would need to store it somewhere like localStorage
          language:
            localStorage && localStorage.getItem('language')
              ? localStorage.getItem('language')
              : 'de',
          // region:
          //   localStorage && localStorage.getItem("region")
          //     ? localStorage.getItem("region")
          //     : "DE"
        };
      },
      deps: [APP_CONFIG],
    },

    GoogleMapsService,
    {
      // Reihenfolge ist vorgegeben -> Reference,CompanyName,Street1,Street2,PostalCode,City,StateIso2Code,CountryIso2Code,Type
      provide: IMPORT_CSV_CONFIG,
      useValue: {
        headers: [
          {
            name: 'Reference',
            inputName: 'Reference',
            unique: true,
            uniqueError: function (headerName) {
              return (
                $localize`:AppModulesReferenceUniqueError|Error Spalte ist nicht eindeutig@@AppModulesReferenceUniqueErrorPartOne:Die Spalte ` +
                `'${headerName}'` +
                $localize`:AppModulesReferenceUniqueError|Error Spalte ... ist nicht eindeutig@@AppModulesReferenceUniqueErrorPartTwo: ist nicht eindeutig`
              );
            },
            required: true,
            requiredError: (headerName, rowNumber, columnNumber) => {
              return (
                `'${headerName}'` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartTwo: ist ein Pflichtfeld ` +
                `(${rowNumber}` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartFour: Zeile, ` +
                `${columnNumber}` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartSix: Spalte)`
              );
            },
          },
          {
            name: 'CompanyName',
            inputName: 'CompanyName',
            required: true,
            requiredError: (headerName, rowNumber, columnNumber) => {
              return (
                `'${headerName}'` +
                $localize`:AppModulesCompanyNameRequiredError|Error required@@AppModulesReferenceRequiredErrorPartTwo: ist ein Pflichtfeld ` +
                `(${rowNumber}` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartFour: Zeile, ` +
                `${columnNumber}` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartSix: Spalte)`
              );
            },
          },
          {
            name: 'Street1',
            inputName: 'Street1',
            optional: true,
          },
          {
            name: 'Street2',
            inputName: 'Street2',
            optional: true,
          },
          {
            name: 'PostalCode',
            inputName: 'PostalCode',
            optional: true,
          },
          {
            name: 'City',
            inputName: 'City',
            optional: true,
          },
          {
            name: 'CountryISO3Code',
            inputName: 'CountryISO3Code',
            optional: true,
          },
          {
            name: 'Type',
            inputName: 'Type',
            required: true,
            requiredError: (headerName, rowNumber, columnNumber) => {
              return (
                `'${headerName}'` +
                $localize`:AppModulesCompanyNameRequiredError|Error required@@AppModulesReferenceRequiredErrorPartTwo: ist ein Pflichtfeld ` +
                `(${rowNumber}` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartFour: Zeile, ` +
                `${columnNumber}` +
                $localize`:AppModulesReferenceRequiredError|Error required@@AppModulesReferenceRequiredErrorPartSix: Spalte)`
              );
            },
          },
          {
            name: 'CountryIso2Code',
            inputName: 'CountryIso2Code',
            optional: true,
          },
          {
            name: 'StateIso2Code',
            inputName: 'StateIso2Code',
            optional: true,
          },
          {
            name: 'ReferenceOld',
            inputName: 'ReferenceOld',
            optional: true,
          },
        ],
        headerError: (e) => (columnValue) => {
          return (
            $localize`:AppModulesHandelError|Handel Error in App module @@AppModulesHandelErrorPartOne:Die Spalte ` +
            `'${columnValue}'` +
            $localize`:AppModulesHandelError|Handel Error in App module @@AppModulesHandelErrorPartTwo: ist nicht korrekt, fehlt oder ist undefiniert!`
          );
        },
        isHeaderNameOptional: false,
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: 'fill',
        floatLabel: 'auto',
      },
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
