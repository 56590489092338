<ng-container *ngIf="!!noteType">
  <button
    mat-icon-button
    *ngIf="true"
    title="von DPL bearbeitet"
    i18n-title="
      NoteInfoIconModifyByDpl|Label Von Dpl bearbeitet@@NoteInfoIconModifyByDpl"
    (click)="clicked()"
  >
    <ng-container [ngSwitch]="noteType">
      <mat-icon
        i18n-title="
          NoteInfoIconDplNoteDetailRow|Title Weitere Informationen in der
          Detail-Row@@NoteInfoIconDplNoteDetailRow"
        title="Weitere Informationen in der Detail-Row"
      >
        <ng-container *ngSwitchCase="employeeNoteType.Create">
          add
        </ng-container>
        <ng-container *ngSwitchCase="employeeNoteType.Updated">
          create
        </ng-container>
        <ng-container *ngSwitchCase="employeeNoteType.Cancellation">
          clear
        </ng-container>
        <ng-container *ngSwitchDefault> warning </ng-container>
      </mat-icon>
    </ng-container>
  </button>
</ng-container>
<ng-container *ngIf="!!detailRow">
    <button
      mat-icon-button
      *ngIf="true"
      i18n-title="
        NoteInfoIconDetailRow|Title Weitere Kundeninformationen in der
        Detail-Row@@NoteInfoIconDetailRow"
      title="Weitere Kundeninformationen in der Detail-Row"
    >
      <mat-icon> info </mat-icon>
    </button>
</ng-container>
