import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

export interface MessagingChannelListItem {
  key: string;
  title: string;
  messagesCount: number;
  unreadCount: number;
}

export interface MessagingChannelListState
  extends EntityState<MessagingChannelListItem, string>,
    ActiveState<string> {}

@Injectable()
@StoreConfig({ name: 'messaging-channel-list', idKey: 'key' })
export class MessagingChannelListStore extends EntityStore<MessagingChannelListState> {
  constructor() {
    super();
  }
}
