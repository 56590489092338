import { Injectable } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable({
  providedIn: 'root',
})
export class LocalizationLibaryService {
  constructor() {
  }

  // $localize`:meaning|description@@id:source message text`;

  Global = {
    ConfirmRequestMessage: () => {
      return $localize`:GlobalConfirmRequestMessage|General confirmation of an action@@GlobalConfirmRequestMessage:Sind sie sicher?`;
    },
    ConfirmDeleteTitle: () => {
      return $localize`:GlobalConfirmDeleteTitle|General Title of confirm dialog@@GlobalConfirmDeleteTitle:Löschen bestätigen`
    },
    ServerErrorMessage: () => {
      return $localize`:GlobalServerErrorMessage|General Server Error@@GlobalServerErrorMessage:Der Server meldet einen Fehler`
    }
  }
}
