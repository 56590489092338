import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NonEmptyRuleDirective } from '@app/shared/directives/non-empty-rule.directive';
import { TrimFormFieldsDirective } from '@app/shared/directives/trim-form-fields-directive';
import { NgxSubFormModule } from 'ngx-sub-form';

import { CoreModule } from '../core/core.module';
import { CustomersModule } from '../customers/customers.module';
import {
  AddressCityFormComponent,
  AddressFormComponent,
  BasketListComponent,
  BusinessHoursComponent,
  ConfirmActionDialogComponent,
  CountryPickerComponent,
  DigitalCodeLookupComponent,
  DriverInfoFormComponent,
  DynamicConfirmationDialogComponent,
  ExpressCodeComponent,
  GoogleMapsPlacesLookupComponent,
  GoogleMapsPlacesSelectorDialogComponent,
  IssuerHeaderFormComponent,
  LicensePlateFormComponent,
  LoadCarrierFormComponent,
  LoadCarrierInOutFormComponent,
  LoadCarrierPickerComponent,
  LoadCarriersFormComponent,
  LoadingSpinnerComponent,
  PageNotFoundComponent,
  PartnerLookupComponent,
  PostingAccountPickerComponent,
  PricingComponent,
  PrintLanguagePickerComponent,
  PrintSettingsFormComponent,
  RequiredHintComponent,
  ShipperFormComponent,
  TermsComponent,
  UserRole,
} from './components';
import { AccountBalanceInfoComponent } from './components/account-balance-info/account-balance-info.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { BookingDirectionPickerComponent } from './components/booking-direction-picker/booking-direction-picker.component';
import { BusinessHourExceptionsComponent } from './components/business-hour-exceptions/business-hour-exceptions.component';
import { CancelOrderButtonComponent } from './components/cancel-order-button/cancel-order-button.component';
import { CancelVoucherButtonComponent } from './components/cancel-voucher-button/cancel-voucher-button.component';
import { ColumnEmployeeNoteComponent } from './components/column-employee-note/column-employee-note.component';
import { CustomerCustomLabelComponent } from './components/customer-custom-label/customer-custom-label.component';
import { CustomerSelectionComponent } from './components/customer-selection/customer-selection.component';
import { DateFromToDialogComponent } from './components/date-from-to-dialog/date-from-to-dialog.component';
import { ExtAccountPickerComponent } from './components/ext-account-picker/ext-account-picker.component';
import { GlobalNotificationsComponent } from './components/global-notifications/global-notifications.component';
import { GridIconComponent } from './components/grid-icon/grid-icon.component';
import { GridLoadcarrierCellComponent } from './components/grid-loadcarrier-cell/grid-loadcarrier-cell.component';
import { GridNtgCellComponent } from './components/grid-ntg-cell/grid-ntg-cell.component';
import { GridStatusCellComponent } from './components/grid-status-cell/grid-status-cell.component';
import { GridTextTooltipComponent } from './components/grid-text-tooltip/grid-text-tooltip.component';
import { InfoLoadingDirectionComponent } from './components/info-loading-direction/info-loading-direction.component';
import { InfoQuantitySuffixComponent } from './components/info-quantity-suffix/info-quantity-suffix.component';
import { InfoSuffixComponent } from './components/info-suffix/info-suffix.component';
import { InputFieldDialogComponent } from './components/input-field-dialog/input-field-dialog.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LoadCarrierDisplayTitleComponent } from './components/load-carrier-display-title/load-carrier-display-title.component';
import { LoadCarrierReceiptButtonComponent } from './components/load-carrier-receipt-button/load-carrier-receipt-button.component';
import { LoadingSpinnerOnPageComponent } from './components/loading-spinner-on-page/loading-spinner-on-page.component';
import { LocationBusinessHoursDateComponent } from './components/location-business-hours-date/location-business-hours-date.component';
import { NoteInfoIconComponent } from './components/note-info-icon/note-info-icon.component';
import { NoteInfoComponent } from './components/note-info/note-info.component';
import { NoticePendingTranfersComponent } from './components/notice-pending-tranfers/notice-pending-tranfers.component';
import { OnBehalfOfDialogComponent } from './components/on-behalf-of-dialog/on-behalf-of-dialog.component';
import { PartnerCreateDialogComponent } from './components/partner-create-dialog/partner-create-dialog.component';
import { PartnerCreateFormComponent } from './components/partner-create-form/partner-create-form.component';
import { SelectCustomerMessageComponent } from './components/select-customer-message/select-customer-message.component';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { SingleUploadFormComponent } from './components/single-upload-form/single-upload-form.component';
import { TriStateCheckboxComponent } from './components/tri-state-checkbox/tri-state-checkbox.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ValidIconButtonComponent } from './components/valid-icon-button/valid-icon-button.component';
import {
  DxColumnFilterDateOnlyDirective,
  HasPermissionDirective,
  OnClickDisableUntilDirective,
  RouteHighlightDirective,
} from './directives';
import { AlphaOnlyDirective } from './directives/alpha-only.directive';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { ForbiddenEmptyFieldsCombinationValidatorDirective } from './directives/forbidden-empty-fields-combination.directive';
import { LowerCaseInputDirective } from './directives/lower-case-input.directive';
import { UpperCaseInputDirective } from './directives/upper-case-input.directive';
import {
  FooterComponent,
  HeaderComponent,
  HomeComponent,
  ImprintComponent,
  PrivacyComponent,
  SupportComponent,
  TopNavigationComponent,
} from './layout';
import { DefaultContentComponent } from './layout/components/default-content/default-content.component';
import { DividerComponent } from './layout/components/divider/divider.component';
import {
  AccountingRecordStatusPipe,
  AccountingRecordTypePipe,
  AddressPipe,
  BalanceTransferStatusPipe,
  BusinessHourExceptionPipe,
  BusinessHourExceptionTypePipe,
  BusinessHourPipe,
  CalendarWeekPipe,
  CountryPipe,
  DateExPipe,
  DayOfWeekPipe,
  DigitalCodePipe,
  DistancePipe,
  DocumentTypePipe,
  JournalStatusPipe,
  LanguagePipe,
  LoadCarrierPipe,
  LoadCarrierReceiptDepoPresetCategoryPipe,
  LoadCarrierReceiptStatusPipe,
  LoadCarrierReceiptTriggerPipe,
  LoadCarrierReceiptTypePipe,
  LoadingLocationAdminTypePipe,
  LoadingLocationOwnershipTypePipe,
  LoadingLocationTypePipe,
  NumberExPipe,
  OrderAccessOperatorPipe,
  OrderAccessScopePipe,
  OrderAccessTypePipe,
  OrderLoadStatusPipe,
  OrderStatusPipe,
  OrderTypePipe,
  PartnerPipe,
  PartnerTypePipe,
  PeriodFilterPipe,
  PermissionActionPipe,
  PermissionResourcePipe,
  ProposalStatusPipe,
  SafePipe,
  TransferDirectionFilterPipe,
  TransportBidStatusPipe,
  TransportStatusPipe,
  UserRolePipe,
  VoucherReasonType,
  VoucherStatusPipe,
  VoucherTypePipe,
  NewLinePipe,
} from './pipes';
import { BookingPeriodFilterPipe } from './pipes/booking-period-filter.pipe';
import { EmployeeNoteReasonPipe } from './pipes/employee-note-reason.pipe';
import { EmployeeNoteTypePipe } from './pipes/employee-note-type.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { StorageTypePipe } from './pipes/storage-type.pipe';
import { TransportTypePipe } from './pipes/transport-type.pipe';
import { UploadProcessSubmissionStatusPipe } from './pipes/upload-process-submission-status.pipe';
import {
  DocumentsService,
  PrintService,
  TitleService,
  ValidationDataService,
} from './services';
import { TransportVoucherButtonsComponent } from './components/transport-voucher-buttons/transport-voucher-buttons.component';
import { EditOrderButtonComponent } from './components/edit-order-button/edit-order-button.component';
import { EditLoadCarrierReceiptButtonComponent } from './components/edit-load-carrier-receipt-button/edit-load-carrier-receipt-button.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ActionsService } from './services/actions.service';

const pipes = [
  BalanceTransferStatusPipe,
  AccountingRecordStatusPipe,
  AccountingRecordTypePipe,
  AddressPipe,
  CalendarWeekPipe,
  CountryPipe,
  DateExPipe,
  DayOfWeekPipe,
  DigitalCodePipe,
  DistancePipe,
  LanguagePipe,
  LoadCarrierPipe,
  LoadCarrierReceiptDepoPresetCategoryPipe,
  LoadCarrierReceiptTypePipe,
  LoadCarrierReceiptTriggerPipe,
  LoadCarrierReceiptStatusPipe,
  NumberExPipe,
  OrderAccessOperatorPipe,
  OrderAccessScopePipe,
  OrderAccessTypePipe,
  OrderLoadStatusPipe,
  OrderStatusPipe,
  OrderTypePipe,
  SafePipe,
  TransportStatusPipe,
  TransportBidStatusPipe,
  VoucherReasonType,
  VoucherStatusPipe,
  VoucherTypePipe,
  UserRolePipe,
  BusinessHourExceptionPipe,
  DocumentTypePipe,
  TransportTypePipe,
  PartnerPipe,
  PartnerTypePipe,
  EmployeeNoteTypePipe,
  EmployeeNoteReasonPipe,
  PermissionActionPipe,
  PermissionResourcePipe,
  StorageTypePipe,
  JournalStatusPipe,
  PeriodFilterPipe,
  ProposalStatusPipe,
  TransferDirectionFilterPipe,
  LoadingLocationAdminTypePipe,
  LoadingLocationTypePipe,
  LoadingLocationOwnershipTypePipe,
  BookingPeriodFilterPipe,
  BusinessHourPipe,
  UploadProcessSubmissionStatusPipe,
  FileSizePipe,
  NewLinePipe,
];

const components = [
  AccountBalanceInfoComponent,
  AddressCityFormComponent,
  AddressFormComponent,
  BasketListComponent,
  BusinessHoursComponent,
  ConfirmActionDialogComponent,
  CountryPickerComponent,
  CustomerSelectionComponent,
  DigitalCodeLookupComponent,
  DriverInfoFormComponent,
  DynamicConfirmationDialogComponent,
  ExpressCodeComponent,
  GoogleMapsPlacesLookupComponent,
  GoogleMapsPlacesSelectorDialogComponent,
  IssuerHeaderFormComponent,
  LanguageSelectorComponent,
  LicensePlateFormComponent,
  LoadCarrierFormComponent,
  LoadCarrierInOutFormComponent,
  LoadCarrierPickerComponent,
  LoadCarrierReceiptButtonComponent,
  LoadCarriersFormComponent,
  LoadingSpinnerComponent,
  PartnerLookupComponent,
  PostingAccountPickerComponent,
  PricingComponent,
  PrintLanguagePickerComponent,
  PrintSettingsFormComponent,
  ShipperFormComponent,
  SelectCustomerMessageComponent,
  TermsComponent,
  PartnerCreateFormComponent,
  PartnerCreateDialogComponent,
  SendEmailDialogComponent,
  UserRole,
  RequiredHintComponent,
  OnBehalfOfDialogComponent,
  NoteInfoComponent,
  CancelVoucherButtonComponent,
  GridTextTooltipComponent,
  TriStateCheckboxComponent,
  PageNotFoundComponent,
  LocationBusinessHoursDateComponent,
  GridStatusCellComponent,
  GridIconComponent,
  GridNtgCellComponent,
  CancelOrderButtonComponent,
  SupportComponent,
  EditOrderButtonComponent,
];

const directives = [
  HasPermissionDirective,
  OnClickDisableUntilDirective,
  RouteHighlightDirective,
  LowerCaseInputDirective,
  UpperCaseInputDirective,
  AlphaOnlyDirective,
  DigitOnlyDirective,
  NonEmptyRuleDirective,
  TrimFormFieldsDirective,
  DxColumnFilterDateOnlyDirective,
];

const services = [
  DocumentsService,
  PrintService,
  ValidationDataService,
  TitleService,
  ActionsService,
];

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TopNavigationComponent,
    UserProfileComponent,
    HomeComponent,
    ImprintComponent,
    PrivacyComponent,
    // custom pipes
    ...pipes,
    // custom components
    ...components,
    // custom directives
    ...directives,
    // TODO check if we actually need these components + directives
    InputFieldDialogComponent,
    DateFromToDialogComponent,
    ForbiddenEmptyFieldsCombinationValidatorDirective,
    AccountBalanceInfoComponent,
    ColumnEmployeeNoteComponent,
    NoteInfoIconComponent,
    LoadCarrierDisplayTitleComponent,
    DefaultContentComponent,
    CustomerCustomLabelComponent,
    LoadingSpinnerOnPageComponent,
    AdminMenuComponent,
    ExtAccountPickerComponent,
    GlobalNotificationsComponent,
    GridLoadcarrierCellComponent,
    SingleUploadFormComponent,
    BookingDirectionPickerComponent,
    ValidIconButtonComponent,
    DividerComponent,
    BusinessHourExceptionsComponent,
    BusinessHourExceptionTypePipe,
    InfoSuffixComponent,
    InfoQuantitySuffixComponent,
    NoticePendingTranfersComponent,
    InfoLoadingDirectionComponent,
    TransportVoucherButtonsComponent,
    EditLoadCarrierReceiptButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    // custom modules
    CoreModule,
    CustomersModule,
    NgxSubFormModule,
    DragDropModule,
  ],
  exports: [
    CoreModule,
    NgxSubFormModule,
    HeaderComponent,
    FooterComponent,
    // custom pipes
    ...pipes,
    // custom components
    ...components,
    // custome directives
    ...directives,
    // TODO check if we need these exports
    InputFieldDialogComponent,
    DateFromToDialogComponent,
    ForbiddenEmptyFieldsCombinationValidatorDirective,
    ColumnEmployeeNoteComponent,
    NoteInfoIconComponent,
    CustomerCustomLabelComponent,
    LoadingSpinnerOnPageComponent,
    AdminMenuComponent,
    ExtAccountPickerComponent,
    GridLoadcarrierCellComponent,
    SingleUploadFormComponent,
    BookingDirectionPickerComponent,
    ValidIconButtonComponent,
    BusinessHourExceptionsComponent,
    BusinessHourExceptionTypePipe,
    InfoSuffixComponent,
    InfoQuantitySuffixComponent,
    NoticePendingTranfersComponent,
    InfoLoadingDirectionComponent,
    TransportVoucherButtonsComponent,
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    // custom pipes
    ...pipes,
    // custom services
    ...services,
  ],
})
export class SharedModule {}
