// Taken from
//https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/Demos/FileUploader/AzureDirectUploading/azure-file-system.js
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { UploadsApiService } from '../../../core/services/dpl-api-services';
import { UploadedFilesStore } from '../../../process-confirmation/state/uploaded-files.store';

@Injectable()
export class AzureGatewayService {
  private uploadProzessCode: string;

  constructor(
    private http: HttpClient,
    private uploadProzess: UploadsApiService, //endpointUrl?, //onRequestExecuted?,
    private uploadedFilesStore: UploadedFilesStore
  ) {
    // var deleteBlob = function (name) {
    //   return getAccessUrl('DeleteBlob', name).then(function (accessUrl) {
    //     return executeRequest({
    //       url: accessUrl,
    //       method: 'DELETE',
    //     });
    //   });
    // };
  }

  init(uploadProzessCode: string) {
    this.uploadProzessCode = uploadProzessCode;
  }

  getUploadAccessUrl(fileName: string, fileSize: number) {
    return this.uploadProzess
      .createUploadUrl(this.uploadProzessCode, fileName)
      .pipe(
        tap((response) => {
          this.uploadedFilesStore.add({
            fileId: response.fileId,
            uploadUrl: response.uploadUrl,
            filename: fileName,
            fileSize: fileSize,
            completed: false,
          });
        })
      )

      .toPromise();
  }

  reset() {
    this.uploadProzessCode = null;
  }

  putBlock(uploadUrl, blockIndex, blockBlob) {
    var blockId = this.getBlockId(blockIndex);
    var params = {
      comp: 'block',
      blockid: blockId,
    };
    return this.http
      .put(uploadUrl, blockBlob, {
        headers: {
          processData: 'false',
          contentType: 'false',
        },
        params,
      })
      .toPromise();
  }

  putBlockList(uploadUrl, blockCount) {
    var content = this.getBlockListContent(blockCount);
    var params = {
      comp: 'blocklist',
    };
    return this.http
      .put(uploadUrl, content, {
        params,
      })
      .toPromise();
  }

  private getBlockListContent(blockCount) {
    var contentParts = [
      '<?xml version="1.0" encoding="utf-8"?>',
      '<BlockList>',
    ];

    for (var i = 0; i < blockCount; i += 1) {
      var blockContent = '  <Latest>' + this.getBlockId(i) + '</Latest>';
      contentParts.push(blockContent);
    }

    contentParts.push('</BlockList>');
    return contentParts.join('\n');
  }

  private getBlockId(blockIndex) {
    var res = blockIndex + '';
    while (res.length < 10) {
      res = '0' + res;
    }
    return btoa(res);
  }

  uploadChunkFn() {
    return (
      file,
      uploadInfo: {
        chunkIndex: number;
        chunkBlob: any;
        chunkCount: number;
        customData: { accessUrl: string; fileId: number };
      }
    ) => {
      return of(uploadInfo.chunkIndex)
        .pipe(
          switchMap((chunkIndex) => {
            if (chunkIndex !== 0) {
              return of(uploadInfo.customData.accessUrl);
            }
            console.log('FileSize', file);
            return this.getUploadAccessUrl(file.name, file.size).then(
              ({ uploadUrl, fileId }) => {
                uploadInfo.customData.accessUrl = uploadUrl;
                uploadInfo.customData.fileId = fileId;
                return uploadUrl;
              }
            );
          }),
          switchMap((accessUrl) => {
            return this.putBlock(
              accessUrl,
              uploadInfo.chunkIndex,
              uploadInfo.chunkBlob
            );
          }),
          // wait until last chunk then execute putBlockList
          filter(() => uploadInfo.chunkIndex === uploadInfo.chunkCount - 1),
          switchMap(() => {
            return this.putBlockList(
              uploadInfo.customData.accessUrl,
              uploadInfo.chunkCount
            );
          })
        )
        .toPromise();
    };
  }
}
