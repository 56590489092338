<mat-card>
  <mat-card-content>
    <div
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="center center"
      i18n="
        SelectCustomerMessageText|Select Customer Message Text Bitte wählen Sie
        einen Kunden aus, um Aktionen 'im Namen von'
        durchzuführen@@SelectCustomerMessageText"
    >
      Bitte wählen Sie einen Kunden aus, um Aktionen "im Namen von"
      durchzuführen
    </div>
  </mat-card-content>
</mat-card>
