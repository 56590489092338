<div
  fxLayout="column"
  *ngIf="
    transportType == orderTransportType.Self &&
    status == orderStatus.TransportPlanned
  "
>
  <dx-button
  *hasPermission="resourceAction.PrintTransportVoucher"
    icon="pdffile"
    hint="Drucken"
    i18n-hint="@@Print"
    (click)="onPrintDocument()"    
    class="voucher-icon-button"
  >
  </dx-button>
  <dx-button
    *hasPermission="resourceAction.SendEmailTransportVoucher"
    icon="email"
    hint="Email"
    (click)="onSendEmailDocument()"
    class="voucher-icon-button"
  >
  </dx-button>
</div>
