import { Pipe } from '@angular/core';

import { LocalizationService } from '../../core/services';
import { LoadCarrierReceiptDepotPresetCategory } from '../../core/services/dpl-api-services';
import { TranslatePipeEnumBase } from './translate-pipe.base';

@Pipe({
  name: 'loadCarrierReceiptDepotPresetCategory',
})
export class LoadCarrierReceiptDepoPresetCategoryPipe extends TranslatePipeEnumBase {
  name: string = 'LoadCarrierReceiptDepoPresetCategory';

  constructor(ls: LocalizationService) {
    super(ls);
  }

  transform(
    loadCarrierReceiptDepoPresetCategoryType:
      | LoadCarrierReceiptDepotPresetCategory
      | any
  ) {
    // if (format === 'default') {
    //   return super.transform(loadCarrierReceiptDepoPresetCategoryType)
    // }
    switch (loadCarrierReceiptDepoPresetCategoryType) {
      case LoadCarrierReceiptDepotPresetCategory.External:
        return $localize`:LoadCarrierReceiptDepoPresetCategoryExternalPipeReturnValue|LoadCarrierReceiptDepoPresetCategoryPipe Return Value Extern@@LoadCarrierReceiptDepoPresetCategoryExternalPipeReturnValue:Extern`;
      case LoadCarrierReceiptDepotPresetCategory.Internal:
        return $localize`:LoadCarrierReceiptDepoPresetCategoryInternalPipeReturnValue|LoadCarrierReceiptDepoPresetCategoryPipe Return Value Intern@@LoadCarrierReceiptDepoPresetCategoryInternalPipeReturnValue:Intern`;
      default:
        throw new Error(
          $localize`:@@LoadCarrierReceiptDepotPresetCategoryDoesNotExist:LoadCarrierReceiptDepotPresetCategory does not exist` +
            `: ${loadCarrierReceiptDepoPresetCategoryType}`
        );
    }
  }
}
