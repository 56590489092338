<div fxLayout="column" *ngIf="possibleUsers$ | async as possibleUsers">
  <dx-select-box
    [items]="possibleUsers"
    displayExpr="userName"
    valueExpr="userId"
    [value]="selectedUserId$ | async"
    (onValueChanged)="selectUser(possibleUsers, $event.value)"
    height="36px"
  >
    <div *dxTemplate="let item of 'item'">
      <div>
        <span *ngIf="item.self; else other">
          {{ item.userName }}
          <span i18n="@@MessagingSystemTypeListPosibleuserSelfItemExtraText"
            >(ICH)</span
          ></span
        >
        <ng-template #other>
          <span
            matBadge="!"
            matBadgeOverlap="false"
            [matBadgeHidden]="!(requiresAction(item.userId) | async)"
            matBadgeColor="warn"
            >{{ item.userName }}
          </span>
        </ng-template>
      </div>
    </div>
  </dx-select-box>

  <!-- zeit -->
  <h6 class="filter-title" i18n="@@MessagingSystemTimeFilterTitle">
    Zeitfilter (letzte Nachricht)
  </h6>
  <dx-select-box
    [items]="timeFilters"
    displayExpr="text"
    valueExpr="value"
    placeholder="Zeit auswählen"
    i18n-placeholder="@@MessagingSystemTypeListSelectTimePlaceholder"
    [value]="selectedTimeFilter$ | async"
    (onValueChanged)="selectTimeFilter($event.value)"
    height="36px"
  ></dx-select-box>

  <!-- status -->
  <h6 class="filter-title" i18n="@@MessagingSystemStatusFilterTitle">
    Statusfilter
  </h6>
  <dx-select-box
    [items]="statusFilters"
    displayExpr="text"
    valueExpr="value"
    placeholder="Status auswählen"
    i18n-placeholder="@@MessagingSystemTypeListSelectStatusPlaceholder"
    [value]="selectedStatusFilter$ | async"
    (onValueChanged)="selectStatusFilter($event.value)"
    height="36px"
  ></dx-select-box>
  <h6 class="filter-title" i18n="@@MessagingSystemCustomerFilterTitle">
    Kundenfilter
  </h6>
  <dx-select-box
    [items]="possibleCustomers$ | async"
    displayExpr="companyInfo"
    valueExpr="customerId"
    placeholder="Kunde auswählen"
    i18n-placeholder="@@MessagingSystemTypeListSelectCustomerPlaceholder"
    [value]="selectedCustomerId$ | async"
    height="36px"
    (onValueChanged)="selectCustomer($event.value)"
    [showClearButton]="true"
    [searchEnabled]="true"
    searchMode="contains"
    searchExpr="companyInfo"
  >
  </dx-select-box>
</div>
