import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from '@app/api/dpl';
import * as moment from 'moment';

import { getOffsetSinceStartOfWeek } from '../../core';

export const daysOfWeekOptionDict = {
  0: { label: $localize`:@@MondayAbr:Mo`, value: 0 },
  1: { label: $localize`:@@TuesdayAbr:Di`, value: 1 },
  2: { label: $localize`:@@WednesdayAbr:Mi`, value: 2 },
  3: { label: $localize`:@@ThursdayAbr:Do`, value: 3 },
  4: { label: $localize`:@@FridayAbr:Fr`, value: 4 },
  5: { label: $localize`:@@SaturdayAbr:Sa`, value: 5 },
};

type DayOfWeekFormat = 'long' | 'short' | 'tiny';

@Pipe({
  name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {
  constructor() {}

  transform(
    input: DayOfWeek | string | Date | moment.Moment,
    format: DayOfWeekFormat
  );
  transform(input: number, weekStart: Date);
  transform(
    input: DayOfWeek | string | Date | moment.Moment | number,
    formatOrWeekStart: DayOfWeekFormat | Date = 'long'
  ): string {
    if (typeof input === 'number') {
      const weekStart = formatOrWeekStart as Date;
      const date = moment(weekStart);
      date.add(input, 'day');
      return `${(daysOfWeekOptionDict[input] || {}).label} (${date.format(
        'L'
      )})`;
    }

    const format = formatOrWeekStart as DayOfWeekFormat;
    const momentFormat = this.getMomentFormat(format);

    if (
      input instanceof Date ||
      moment.isMoment(input) ||
      moment(input).isValid()
    ) {
      return moment(input).format(momentFormat);
    }

    return moment
      .utc()
      .weekday(getOffsetSinceStartOfWeek(input as DayOfWeek))
      .format(momentFormat);
  }

  private getMomentFormat(format: DayOfWeekFormat) {
    switch (format) {
      case 'long':
        return 'dddd';

      case 'short':
        return 'ddd';

      case 'tiny':
        return 'ddd';
      default:
        break;
    }
  }
}
