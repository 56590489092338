<mat-toolbar color="primary">
  <span>Neue Öffnungszeit</span>
</mat-toolbar>
<div mat-dialog-content fxLayout="column" fxLayoutGap="20px" [formGroup]="form">
  <div fxLayout="row" fxLayoutGap="20px">
    <dx-tag-box
      label="Wochentag/e auswählen"
      [items]="getDayOfWeekData()"
      displayExpr="display"
      valueExpr="value"
      
      width="100%"
      formControlName="daysOfWeek"
    ></dx-tag-box>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <dx-date-box type="time" label="Von" [formControlName]="'fromTime'">
    </dx-date-box>
    <dx-date-box type="time" label="Bis" [formControlName]="'toTime'">
    </dx-date-box>
  </div>
</div>
<mat-dialog-actions>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
    style="width: 100%"
  >
    <button mat-raised-button (click)="onCancel()" i18n="@@Cancel">
      Abbrechen
    </button>
    <button
      mat-raised-button
      (click)="onSave()"
      [disabled]="!form.valid"
      cdkFocusInitial
    >
      Öffnungszeit-/en erstellen
    </button>
  </div>
</mat-dialog-actions>
