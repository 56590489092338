import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { GlobalSettingsState } from './global-settings.model';
import { GlobalSettingsStore } from './global-settings.store';


/**
 * GlobalSettings query
 *
 * @export
 * @class GlobalSettingsQuery
 * @extends {Query<GlobalSettingsState>}
 */
@Injectable({ providedIn: 'root' })
export class GlobalSettingsQuery extends Query<GlobalSettingsState> {

  constructor(protected store: GlobalSettingsStore) {
    super(store);
  }
}
