import { Component, OnInit } from '@angular/core';
import {
  ExternalLoadCarrierReceiptType,
  LoadCarrierReceiptType,
  PermissionResourceType,
  ResourceAction,
  UserRole,
} from '@app/api/dpl';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../../../../core/services/authentication.service';
import { PermissionsService } from '../../../../core/services/permissions.service';
import { UserService } from '../../../../user/services/user.service';
import { IUser } from '../../../../user/state/user.model';
import { OrderLoadsViewType, OrdersViewType } from '../../../types';
import { CustomerDivisionsService } from '../../../../customers/services/customer-divisions.service';

interface IViewData {
  user: IUser<number, number>;
  userRole: UserRole;
  showAll: boolean;
  dividerPostCreate: boolean;
  activeDivisionId: number;
}

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit {
  viewData$: Observable<IViewData>;
  ordersViewType = OrdersViewType;
  orderLoadsViewType = OrderLoadsViewType;
  userRole = UserRole;
  permissionResourceType = PermissionResourceType;
  resourceAction = ResourceAction;
  loadCarrierReceiptType = LoadCarrierReceiptType;
  externalLoadCarrierReceiptType = ExternalLoadCarrierReceiptType;

  // debugUpns = ['kt.it@dpl-ltms.com'];
  constructor(
    private auth: AuthenticationService,
    private userService: UserService,
    private permissionsService: PermissionsService,
    private customerDivisionService: CustomerDivisionsService
  ) {}

  ngOnInit() {
    const dividerPostCreate$ = this.userService.getIsDplEmployee().pipe(
      switchMap((isEmployee) => {
        return isEmployee
          ? of(true)
          : combineLatest([
              this.permissionsService.hasPermission(
                ResourceAction.CreatePostLoadCarrierReceiptIncoming,
                undefined,
                undefined
              ),
              this.permissionsService.hasPermission(
                ResourceAction.CreatePostLoadCarrierReceiptOutgoing,
                undefined,
                undefined
              ),
            ]).pipe(
              map(([incoming, outgoing]) => {
                console.log(incoming);
                return incoming || outgoing;
              })
            );
      })
    );

    const activeDivisionId$ = this.userService.getCurrentUser().pipe(switchMap(user => {
      return user && user.role === UserRole.DplEmployee ? of(null) : this.customerDivisionService.getActiveDivisionId();
    }));

    this.viewData$ = combineLatest([
      this.auth.isLoggedIn(),
      this.userService.getCurrentUser(),
      this.permissionsService.hasAtLeastOnePermission([
        ResourceAction.CreatePostLoadCarrierReceiptIncoming,
        ResourceAction.CreatePostLoadCarrierReceiptOutgoing,
      ]),
      activeDivisionId$,
    ]).pipe(
      map(([isLoggedIn, user, dividerPostCreate, activeDivision]) => {
        const showAll =
          // user && this.debugUpns.find(x => x === (user as User).upn)
          user && user.role === UserRole.DplEmployee ? true : false;
        console.log(user);
        const viewData: IViewData = {
          user,
          userRole: user ? user.role : undefined,
          showAll,
          dividerPostCreate: dividerPostCreate,
          activeDivisionId: activeDivision,
        };
        console.log('viewData', viewData);
        return viewData;
      })
    );
  }
}
