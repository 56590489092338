/* THIS (.ts) FILE IS GENERATED BY TypedSignalR.Client.TypeScript */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { HubConnection, IStreamResult, Subject } from '@microsoft/signalr';
import { IControlCenterHub, IControlCenterReceiver } from './Dpl.B2b.Chat.Hubs.ControlCenter';
import { IMessagingHub, IMessagingReceiver } from './Dpl.B2b.Chat.Hubs.Messaging';
import { ChannelDto, MemberDto, ReadingStatDto, MentionDto, ChatUserDto, MessageDto, ReceiveChannel } from '../Dpl.B2b.Contracts.Chat.Shared';
import { RequestChannels, ReceiveUserConnected, ReceiveUserDisconnected, ReceiveWriteMessage, ReceiveEditMessage, ReceiveChannelStateChanged, ReceiveAssignChannelMember, ReceiveRemoveChannelMember, ReceiveTranslateChannel, ReceiveReadChannel, ReceiveDeleteMessage, ReceiveDeleteChannel } from '../Dpl.B2b.Contracts.Chat.Messaging';
import { ChatChannelType, UserRole } from '../Dpl.B2b.Common.Enumerations';


// components

export type Disposable = {
    dispose(): void;
}

export type HubProxyFactory<T> = {
    createHubProxy(connection: HubConnection): T;
}

export type ReceiverRegister<T> = {
    register(connection: HubConnection, receiver: T): Disposable;
}

type ReceiverMethod = {
    methodName: string,
    method: (...args: any[]) => void
}

class ReceiverMethodSubscription implements Disposable {

    public constructor(
        private connection: HubConnection,
        private receiverMethod: ReceiverMethod[]) {
    }

    public readonly dispose = () => {
        for (const it of this.receiverMethod) {
            this.connection.off(it.methodName, it.method);
        }
    }
}

// API

export type HubProxyFactoryProvider = {
    (hubType: "IControlCenterHub"): HubProxyFactory<IControlCenterHub>;
    (hubType: "IMessagingHub"): HubProxyFactory<IMessagingHub>;
}

export const getHubProxyFactory = ((hubType: string) => {
    if(hubType === "IControlCenterHub") {
        return IControlCenterHub_HubProxyFactory.Instance;
    }
    if(hubType === "IMessagingHub") {
        return IMessagingHub_HubProxyFactory.Instance;
    }
}) as HubProxyFactoryProvider;

export type ReceiverRegisterProvider = {
    (receiverType: "IControlCenterReceiver"): ReceiverRegister<IControlCenterReceiver>;
    (receiverType: "IMessagingReceiver"): ReceiverRegister<IMessagingReceiver>;
}

export const getReceiverRegister = ((receiverType: string) => {
    if(receiverType === "IControlCenterReceiver") {
        return IControlCenterReceiver_Binder.Instance;
    }
    if(receiverType === "IMessagingReceiver") {
        return IMessagingReceiver_Binder.Instance;
    }
}) as ReceiverRegisterProvider;

// HubProxy

class IControlCenterHub_HubProxyFactory implements HubProxyFactory<IControlCenterHub> {
    public static Instance = new IControlCenterHub_HubProxyFactory();

    private constructor() {
    }

    public readonly createHubProxy = (connection: HubConnection): IControlCenterHub => {
        return new IControlCenterHub_HubProxy(connection);
    }
}

class IControlCenterHub_HubProxy implements IControlCenterHub {

    public constructor(private connection: HubConnection) {
    }

    public readonly userInfo = async (): Promise<any> => {
        return await this.connection.invoke("UserInfo");
    }
}

class IMessagingHub_HubProxyFactory implements HubProxyFactory<IMessagingHub> {
    public static Instance = new IMessagingHub_HubProxyFactory();

    private constructor() {
    }

    public readonly createHubProxy = (connection: HubConnection): IMessagingHub => {
        return new IMessagingHub_HubProxy(connection);
    }
}

class IMessagingHub_HubProxy implements IMessagingHub {

    public constructor(private connection: HubConnection) {
    }

    public readonly channel = async (channelId: string): Promise<ChannelDto> => {
        return await this.connection.invoke("Channel", channelId);
    }

    public readonly channelMembers = async (channelId: string): Promise<MemberDto[]> => {
        return await this.connection.invoke("ChannelMembers", channelId);
    }

    public readonly channels = async (request: RequestChannels): Promise<ChannelDto[]> => {
        return await this.connection.invoke("Channels", request);
    }

    public readonly closeChannel = async (channelId: string): Promise<void> => {
        return await this.connection.invoke("CloseChannel", channelId);
    }

    public readonly createTicket = async (referenceNumber: string, channelType: ChatChannelType, customerDivisionId: (number | undefined)): Promise<ChannelDto> => {
        return await this.connection.invoke("CreateTicket", referenceNumber, channelType, customerDivisionId);
    }

    public readonly createAnnouncement = async (title: string, message: string, userRoles: UserRole[], showOnLogin: boolean): Promise<ChannelDto> => {
        return await this.connection.invoke("CreateAnnouncement", title, message, userRoles, showOnLogin);
    }

    public readonly assignChannelMember = async (channelId: string, userId: number): Promise<void> => {
        return await this.connection.invoke("AssignChannelMember", channelId, userId);
    }

    public readonly removeChannelMember = async (channelId: string, userId: number): Promise<void> => {
        return await this.connection.invoke("RemoveChannelMember", channelId, userId);
    }

    public readonly readChannel = async (channelId: string): Promise<void> => {
        return await this.connection.invoke("ReadChannel", channelId);
    }

    public readonly readingStats = async (): Promise<ReadingStatDto[]> => {
        return await this.connection.invoke("ReadingStats");
    }

    public readonly translateChannel = async (channelId: string, targetLanguage: string): Promise<void> => {
        return await this.connection.invoke("TranslateChannel", channelId, targetLanguage);
    }

    public readonly writeMessage = async (channelId: string, body: string, attachments: number[], mentions: number[]): Promise<void> => {
        return await this.connection.invoke("WriteMessage", channelId, body, attachments, mentions);
    }

    public readonly editMessage = async (messageId: string, body: string, attachments: number[], mentions: number[]): Promise<void> => {
        return await this.connection.invoke("EditMessage", messageId, body, attachments, mentions);
    }

    public readonly mentions = async (channelId: string): Promise<MentionDto[]> => {
        return await this.connection.invoke("Mentions", channelId);
    }

    public readonly assignResponsibleUser = async (channelId: string, userId: number): Promise<void> => {
        return await this.connection.invoke("AssignResponsibleUser", channelId, userId);
    }

    public readonly divisionContributors = async (divisionIds: number[]): Promise<ChatUserDto[]> => {
        return await this.connection.invoke("DivisionContributors", divisionIds);
    }

    public readonly customerContributors = async (customerIds: number[]): Promise<ChatUserDto[]> => {
        return await this.connection.invoke("CustomerContributors", customerIds);
    }

    public readonly channelsStream = (request: RequestChannels): IStreamResult<ChannelDto> => {
        return this.connection.stream("ChannelsStream", request);
    }

    public readonly deleteMessage = async (messageId: string): Promise<void> => {
        return await this.connection.invoke("DeleteMessage", messageId);
    }

    public readonly deleteChannel = async (channelId: string): Promise<void> => {
        return await this.connection.invoke("DeleteChannel", channelId);
    }

    public readonly messageHistory = async (messageId: string): Promise<MessageDto[]> => {
        return await this.connection.invoke("MessageHistory", messageId);
    }
}


// Receiver

class IControlCenterReceiver_Binder implements ReceiverRegister<IControlCenterReceiver> {

    public static Instance = new IControlCenterReceiver_Binder();

    private constructor() {
    }

    public readonly register = (connection: HubConnection, receiver: IControlCenterReceiver): Disposable => {



        const methodList: ReceiverMethod[] = [
        ]

        return new ReceiverMethodSubscription(connection, methodList);
    }
}

class IMessagingReceiver_Binder implements ReceiverRegister<IMessagingReceiver> {

    public static Instance = new IMessagingReceiver_Binder();

    private constructor() {
    }

    public readonly register = (connection: HubConnection, receiver: IMessagingReceiver): Disposable => {

        const __onUserConnected = (...args: [ReceiveUserConnected]) => receiver.onUserConnected(...args);
        const __onUserDisconnected = (...args: [ReceiveUserDisconnected]) => receiver.onUserDisconnected(...args);
        const __onWriteMessage = (...args: [ReceiveWriteMessage]) => receiver.onWriteMessage(...args);
        const __onEditMessage = (...args: [ReceiveEditMessage]) => receiver.onEditMessage(...args);
        const __onChannelStateChanged = (...args: [ReceiveChannelStateChanged]) => receiver.onChannelStateChanged(...args);
        const __onAssignChannelMember = (...args: [ReceiveAssignChannelMember]) => receiver.onAssignChannelMember(...args);
        const __onRemoveChannelMember = (...args: [ReceiveRemoveChannelMember]) => receiver.onRemoveChannelMember(...args);
        const __onTranslateChannel = (...args: [ReceiveTranslateChannel]) => receiver.onTranslateChannel(...args);
        const __onChannelRead = (...args: [ReceiveReadChannel]) => receiver.onChannelRead(...args);
        const __onAssignResponsibleUser = (...args: [string, string, ChatUserDto]) => receiver.onAssignResponsibleUser(...args);
        const __onCreateChannel = (...args: [ReceiveChannel]) => receiver.onCreateChannel(...args);
        const __onDeleteMessage = (...args: [ReceiveDeleteMessage]) => receiver.onDeleteMessage(...args);
        const __onDeleteChannel = (...args: [ReceiveDeleteChannel]) => receiver.onDeleteChannel(...args);

        connection.on("OnUserConnected", __onUserConnected);
        connection.on("OnUserDisconnected", __onUserDisconnected);
        connection.on("OnWriteMessage", __onWriteMessage);
        connection.on("OnEditMessage", __onEditMessage);
        connection.on("OnChannelStateChanged", __onChannelStateChanged);
        connection.on("OnAssignChannelMember", __onAssignChannelMember);
        connection.on("OnRemoveChannelMember", __onRemoveChannelMember);
        connection.on("OnTranslateChannel", __onTranslateChannel);
        connection.on("OnChannelRead", __onChannelRead);
        connection.on("OnAssignResponsibleUser", __onAssignResponsibleUser);
        connection.on("OnCreateChannel", __onCreateChannel);
        connection.on("OnDeleteMessage", __onDeleteMessage);
        connection.on("OnDeleteChannel", __onDeleteChannel);

        const methodList: ReceiverMethod[] = [
            { methodName: "OnUserConnected", method: __onUserConnected },
            { methodName: "OnUserDisconnected", method: __onUserDisconnected },
            { methodName: "OnWriteMessage", method: __onWriteMessage },
            { methodName: "OnEditMessage", method: __onEditMessage },
            { methodName: "OnChannelStateChanged", method: __onChannelStateChanged },
            { methodName: "OnAssignChannelMember", method: __onAssignChannelMember },
            { methodName: "OnRemoveChannelMember", method: __onRemoveChannelMember },
            { methodName: "OnTranslateChannel", method: __onTranslateChannel },
            { methodName: "OnChannelRead", method: __onChannelRead },
            { methodName: "OnAssignResponsibleUser", method: __onAssignResponsibleUser },
            { methodName: "OnCreateChannel", method: __onCreateChannel },
            { methodName: "OnDeleteMessage", method: __onDeleteMessage },
            { methodName: "OnDeleteChannel", method: __onDeleteChannel }
        ]

        return new ReceiverMethodSubscription(connection, methodList);
    }
}

