<div
  class="popover-messaging-system-channel-state"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="dialog-top-part">
    <div mat-dailog-title class="message-list">
      <div class="message-list-header">
        <dpl-dialog-header [showOwnIcons]="true">
          <!-- Remember the ng-content is in a flex-direction row reverse -->
          <span headerText>
            <span i18n="@@MembersDialogHeaderText">Vorgangsmitglieder: </span>
            {{ channel.channelTitle }}
          </span>
          <div headerIcons>
            <dx-button
              icon="close"
              hint="Fenster schließen"
              i18n-hint="@@CloseDialogHint"
              (click)="onCancel()"
            >
            </dx-button>
          </div>
        </dpl-dialog-header>
      </div>
    </div>

    <div class="dialog-content">
      <div class="dialog-content-info-text">
        <p i18n="@@MembersDialogContentInfoText">
          Mitglieder, die benachrichtigt werden:
        </p>
        <dx-list
          #dxList
          [dataSource]="channel.members"
          [activeStateEnabled]="false"
          [focusStateEnabled]="false"
          [hoverStateEnabled]="false"
        >
          <div *dxTemplate="let listData of 'item'; let i = index">
            <div class="show-members">
              <span class="member-index">{{ i + 1 }}</span>
              <!-- TODO: highLight Responsive Member -->
              <span class="show-member-name">
                {{ listData.firstName }} {{ listData.lastName }}
              </span>
            </div>
          </div>
        </dx-list>
      </div>
    </div>
  </div>
  <div class="dialog-bottom-part">
    <div class="dialog-content-question"></div>
    <div class="dialog-content-question-actions">
      <dx-button
        hint="Schließe das Fenster"
        i18n-hint="@@CloseIconHint"
        text="Schließen"
        i18n-text="@@CloseIconText"
        (onClick)="onCancel()"
      ></dx-button>
    </div>
  </div>
</div>
