import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { formatDurationInMinutesToTimeZoneOffsetString } from '../utils';

@Pipe({
  name: 'businessHour',
})
export class BusinessHourPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date | string, utcOffsetInMinutes: number): any {
    if (!value) {
      return null;
    }

    const timeZone = formatDurationInMinutesToTimeZoneOffsetString(
      utcOffsetInMinutes || 0
    );

    return this.datePipe.transform(value, 'HH:mm', timeZone);
  }
}
