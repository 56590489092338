import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Setting } from '../../core/services/dpl-api-services';

import { AccountOverviewType } from '../services/accounts.service.types';
import { IAccount } from './account.model';

export interface AccountsState
  extends EntityState<IAccount, number>,
    ActiveState<number> {
  ui: {
    selectorExpanded: boolean;
    accountOverviewType: AccountOverviewType;
  };
}

const initialState: AccountsState = {
  setting: null,
  active: null,
  ui: {
    selectorExpanded: true,
    accountOverviewType: AccountOverviewType.Record,
  },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'accounts', idKey: 'id', cache: { ttl: 15 * 60 * 1000 } })
export class AccountsStore extends EntityStore<AccountsState> {
  constructor() {
    super(initialState);
  }

  updateSelectorExpanded(selectorExpanded: boolean) {
    this.update({
      ui: { ...this.getValue().ui, selectorExpanded },
    });
  }

  updateAccountOverviewType(accountOverviewType: AccountOverviewType) {
    // remove if not needed
    this.update({
      ui: { ...this.getValue().ui, accountOverviewType },
    });
  }
}
