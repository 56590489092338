<ng-container *ngIf="headerText">
    <div class="list-header">
      <div class="list-name">
        {{ headerText }}
      </div>
      <div class="list-info">
        {{ infoText }}
      </div>
    </div>
  </ng-container>

 