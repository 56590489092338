<mat-toolbar color="primary">
  <span i18n="@@SendEmailDailogToolbarText"
    >Auftragsticket als Email senden</span
  >
</mat-toolbar>

<form #form [formGroup]="formGroup" class="dialog-form">
  <mat-form-field class="control-full-width">
    <input
      matInput
      placeholder="Email"
      formControlName="email"
      [errorStateMatcher]="matcher"
    />
    <!--    <mat-hint>Auftragsticket versenden</mat-hint>-->
    <mat-error
      *ngIf="
        formGroup.get('email').hasError('email') &&
        !formGroup.get('email').hasError('required')
      "
      i18n="@@EmailIsInvalid"
    >
      Bitte geben Sie eine gültige E-Mail-Adresse ein
    </mat-error>
    <mat-error
      *ngIf="formGroup.get('email').hasError('required')"
      i18n="@@EmailIsRequired"
    >
      Email ist <strong>Pflichtfeld</strong>
    </mat-error>
  </mat-form-field>
</form>

<mat-dialog-actions>
  <button mat-button (click)="onCancel()" i18n="Cancel|Label Abbrechen@@Cancel">
    Abbrechen
  </button>
  <button
    #submitButton
    mat-button
    cdkFocusInitial
    [disabled]="sendEmailDisabled"
    i18n="@@SendButtonText"
  >
    Senden
  </button>
</mat-dialog-actions>
