<div class="channel-header">
    <div class="channel-header-area">
      <div class="channel-name">
        <ng-content select="[headerText]"></ng-content>
      </div>
      <div class="channel-header-controls">
        <!-- <dx-button icon="info" hint="Informationen" class="info-icon">
        </dx-button> -->
        <ng-container *ngIf="showOwnIcons">
          <!-- !Parent Component Icons ! -->
          <ng-content select="[headerIcons]"></ng-content>
        </ng-container>
      </div>
    </div>
  </div>
