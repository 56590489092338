import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export type ConfirmationDialogResult = 'yes' | 'no';

@Component({
  selector: 'dpl-confirmation-dialog',
  template: `
    <div fxLayout="column" fxLayoutGap="10px">
      <mat-toolbar color="primary">
        <span
          i18n="
            ConfirmationDialogReceiptPressMatToolbarSpan| Span Quittung
            drucken?@@ConfirmationDialogReceiptPressMatToolbarSpan"
          >Quittung drucken?</span
        >
      </mat-toolbar>
      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button
          i18n="Nein| Button Nein @@ConfirmationDialogReceiptPressNoButton"
          mat-raised-button
          (click)="onAnswerTapped('no')"
        >
          Nein
        </button>
        <button
          i18n="Ja| Button ja @@ConfirmationDialogReceiptPressYesButton"
          mat-raised-button
          color="primary"
          (click)="onAnswerTapped('yes')"
        >
          Ja
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<
      ConfirmationDialogComponent,
      ConfirmationDialogResult
    >
  ) {}

  onAnswerTapped(result: ConfirmationDialogResult) {
    this.dialogRef.close(result);
  }

  ngOnInit() {}
}
