<ng-container *hasPermission="resourceAction.CancelLoadCarrierReceipt">
  <ng-container *ngIf="canEdit$ | async">
    <button
      i18n-title="Buttontitel Editieren@@EditReceiptButtonButtonTitle"
      mat-icon-button
      *ngIf="receiptStatus === loadCarrierReceiptStatus.Issued"
      title="Quittung editieren"
      (click)="edit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
</ng-container>
