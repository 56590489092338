import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { SmallQuantitySetting } from '../../../core/services/dpl-api-services';

@Component({
  selector: 'dpl-info-quantity-suffix',
  templateUrl: './info-quantity-suffix.component.html',
  styleUrls: ['./info-quantity-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoQuantitySuffixComponent implements OnInit {
  @Input() fieldName: string;
  @Input() headingText: string;
  @Input() quantities: SmallQuantitySetting[];
  popOverVisible = false;

  displayedColumns = ['quantity', 'stackHeight'];
  dataSource: { quantity: string; stackHeight: string }[];

  constructor() {}

  ngOnInit(): void {
    console.log(this.quantities);
    this.dataSource = this.quantities.map((x) => {
      return {
        quantity: x.min == x.max ? `${x.min}` : `${x.min} - ${x.max}`,
        stackHeight: x.validStackHeights.join(','),
      };
    });
  }
}
