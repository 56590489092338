import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { ChatAttachmentsApiService } from '../../../core/services/dpl-api-services';
import { Observable } from 'rxjs';
import { first, map, subscribeOn, take, tap } from 'rxjs/operators';


type ViewData = {
  attachmentUrls: string[];
};

@Component({
  selector: 'dpl-show-attachment-dialog',
  templateUrl: './show-attachment-dialog.component.html',
  styleUrls: ['./show-attachment-dialog.component.scss'],
})
export class ShowAttachmentDialogComponent implements OnInit {
  viewData$: Observable<ViewData>;
  constructor(
    private chatAttachmentsService: ChatAttachmentsApiService,
    public dialogRef: MatDialogRef<ShowAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: MessageDto
  ) { }

  ngOnInit(): void {

    console.log(this.message)
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  openAttachment(attachementId: string) {
    return this.chatAttachmentsService.createChatAttachmentUrl({ attachementId }).pipe(
      first(),
      map((url: string) => {
        return url;
      }),
      tap((url) => {
        console.log(url)
        window.open(url, '_blank').focus();
      })
    )
      .subscribe();

  }
}


