import { Injectable } from '@angular/core';

import {
  EntityState,
  ActiveState,
  EntityStore,
  StoreConfig,
  Store,
} from '@datorama/akita';
import { IGlobalNotification, NotificationLevel } from './global-notifications.model';

export interface GlobalNotificationsState extends IGlobalNotification
{

}
export function createInitialState(): GlobalNotificationsState {
    return {        
    };
  }
  

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'globalNotifications' })
export class GlobalNotificationsStore extends Store<GlobalNotificationsState> {
  constructor() {
    super(createInitialState());
  }
}
