import { Component, Input, OnInit } from '@angular/core';
import { ResourceAction } from '../../../../core/services/dpl-api-services';

@Component({
  selector: 'dpl-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements OnInit {

  @Input() text: string;
  @Input() permissions: ResourceAction[];
  @Input() divisionId: number;

  constructor() { }

  ngOnInit(): void {

  }

}
