<div
  class="popover-messaging-system-responive-member"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="dialog-top-part">
    <div mat-dailog-title class="message-list">
      <div class="message-list-header">
        <dpl-dialog-header [showOwnIcons]="true">
          <!-- Remember the ng-content is in a flex-direction row reverse -->
          <span headerText i18n="@@ResponsiveMemberDialogHeaderTextPartOne">
            Bearbeiter des Vorgangs
          </span>
          {{ channel.channelTitle }}
          <span i18n="@@ResponsiveMemberDialogHeaderTextPartTwo">werden</span>
          <div headerIcons>
            <dx-button
              icon="close"
              hint="Fenster schließen"
              (click)="onCancel()"
            >
            </dx-button>
          </div>
        </dpl-dialog-header>
      </div>
    </div>
    <div class="dialog-bottom-part">
      <div class="select-responsive-users">
        <ng-container
          *ngIf="
            possibleResponsibleUsers$ | async as selectableResponsiveUsers;
            else noSelectableResponsiveUsers
          "
        >
          <span i18n="@@SelectableResponsiveUserInfoText">
            Bitte wählen Sie einen Bearbeiter aus
          </span>
          <dx-select-box
            [items]="selectableResponsiveUsers"
            [value]="channel.responsibleUser?.userId"
            displayExpr="userName"
            valueExpr="userId"
            (onValueChanged)="
              setRepsonsibleUser(channel.channelId, $event.value)
            "
            class="responsible-select-user"
          >
          </dx-select-box>
        </ng-container>
        <ng-template #noSelectableResponsiveUsers>
          <span i18n="@@noSelectableResponsiveUserText"
            >Sie können noch keinen User auswählen</span
          >
        </ng-template>
      </div>
      <div class="dialog-content-question">
        <p class="text" i18n="ResponsiveMemberDialogContentQuestion">
          Mit der Bestätigung dieses Fensters setzen Sie den ausgewählten User
          als Bearbeiter. Möchten Sie dies wirklich tun?
        </p>
      </div>
      <div class="dialog-content-question-actions">
        <dx-button
          hint="Schließe das Fenster"
          i18n-hint="@@CloseDialogHint"
          icon="close"
          text="Nein"
          i18n-text="@@No"
          (onClick)="onCancel()"
        ></dx-button>
        <dx-button
          hint="Ändern des Bearbeiters"
          i18n-hint="@@ChangeResponsiveUserHint"
          icon="save"
          text="Ja"
          i18n-text="@@Yes"
          (onClick)="setResponsiveMember()"
        ></dx-button>
      </div>
    </div>
  </div>
</div>
