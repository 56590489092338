import { Injectable } from '@angular/core';
import {  Query, QueryEntity } from '@datorama/akita';
import { EMPTY, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    createInitialState,
  GlobalNotificationsState,
  GlobalNotificationsStore,
} from './global-notifications.store';

@Injectable({ providedIn: 'root' })
export class GlobalNotificationsQuery extends Query<GlobalNotificationsState> {
  globalNotifications$ = this.select().pipe(
    map(
      (notification) => {
          if (notification && notification.startDate <= new Date() &&
          notification.endDate >= new Date()) {
              return notification
          }
          return createInitialState();
      }
        
    )
  );

  constructor(protected store: GlobalNotificationsStore) {
    super(store);
  }
}
