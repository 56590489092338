<ng-container [formGroup]="formGroup">
  <dx-select-box
    [formControl]="formGroup.controls.innerControl"
    [dataSource]="partnersDs"
    [displayExpr]="displayExpr"      
    [searchTimeout]="200"
    [minSearchLength]="0"
    [searchEnabled]="true"
    searchExpr="companyName"
    [showClearButton]="true"
  ></dx-select-box>
</ng-container>
