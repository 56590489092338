<div class="announcement-message-template">
  <div
    [class]="isDialog ? 'message-for-dialog' : 'message-for-messaging-system'"
    [innerHTML]="getTranslatedMessageBody(message) | safe : 'html'"
  ></div>
  <div class="announcement-message-action-controls">
    <ng-container *ngIf="message.attachments?.length > 0">
      <dx-button
        icon="attach"
        [hint]="'Zeige die Links zu den Dateien der Nachricht'"
        i18n-hint="@@OpenDialogShowAttachmentsIconHint"
        (click)="openDialogForAttachments(message)"
      >
      </dx-button>
      <span
        [matBadge]="message.attachments?.length"
        matBadgeColor="primary"
        class="has-attachments"
        title="Hochgeladene Dateien"
        i18n-title="@@MessageHasAttachmentButtonTitle"
      ></span>
    </ng-container>
  </div>
</div>
