import { Component, OnInit } from '@angular/core';
import { ResourceAction } from '../../../../core/services/dpl-api-services';
import { Router } from '@angular/router';
import { UserService } from '../../../../user/services/user.service';
import { Observable } from 'rxjs';
import { IUser } from '../../../../user/state/user.model';
import { MessagingDataService } from 'apps/dpl-live/src/app/messaging-data/services/messaging-data.service';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser$: Observable<IUser<number, number>>;
  resourceAction = ResourceAction;
  unread_count = 4;

  unreadMessageCountAllChannels$: Observable<number>

  constructor(
    private router: Router,
    private user: UserService,
    private messagingData: MessagingDataService
  ) {}


  ngOnInit() {
    this.currentUser$ = this.user.getCurrentUser();

    this.unreadMessageCountAllChannels$ = this.messagingData.getAllCurrentUserTickets().pipe(
      map(tickets => {
        const unreadMessageCountAllChannels =  tickets.reduce((prev, ticket) =>{          
          return prev + ticket.unreadCount;
        }, 0);

        return unreadMessageCountAllChannels;
      }),
      distinctUntilChanged()
    )
  }

  onNavigateToMessagingSystem() {
    this.router.navigate(['/messaging-system']);
  }
}
