import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { filter } from 'rxjs/operators';

import {
  MessagingChannelsState,
  MessagingChannelsStore,
} from './messaging-channels.store';

@Injectable({ providedIn: 'root' })
export class MessagingChannelsQuery extends QueryEntity<MessagingChannelsState> {
  constructor(protected store: MessagingChannelsStore) {
    super(store);
  }

  initialized$ = this.select((state) => state.initialized).pipe(
    filter((x) => x !== undefined)
  );
  channelsInitialized$ = this.select((state) => state.channelsInitialized).pipe(
    filter((x) => x !== undefined)
  );

  selectImpersonateUser$ = this.select((state) => state.impersonateUser);
  selectPossibleImpersonateUsers$ = this.select(
    (state) => state.posibleResponsibleUsers
  );
  selectPossibleEditorUsers$ = this.select(
    (state) => state.possibleEditorUsers
  );
  selectTimeFilter$ = this.select((state) => state.timeFilter);
  selectStatusFilter$ = this.select((state) => state.statusFilter);
  selectCustomerFilter$ = this.select((state) => state.customerFilter);
}
