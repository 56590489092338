import { Injectable } from '@angular/core';
import { BalanceTransferCreateRequest } from '@app/api/dpl';
import { filter, map } from 'rxjs/operators';

import { DplApiService } from '../../core/services';
import { TransferLimitationQuery } from '../state/transfer-limitations.query';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  constructor(private dpl: DplApiService,
    private transferLimitationQuery: TransferLimitationQuery) {}

  //transfer object
  transfer(transferRequest: BalanceTransferCreateRequest) {
    return this.dpl.balanceTransfer.create(transferRequest);
  }

  getTransferLimitations() {
    return this.dpl.balanceTransfer.getTransferLimitations()
  }


  getAllowedTargetAccounts() {
    // active TransferLimitation must be set!
    return this.transferLimitationQuery.active$.pipe(
      filter((limitation) => limitation !== undefined),
      map((limitation) => {
        return limitation?.balanceTransferTargets
           })
    );

  }

  getAllowedSourceAccounts() {
    // active TransferLimitation must be set!
    return this.transferLimitationQuery.all$.pipe(
      filter((limitation) => limitation !== undefined),

    );

  }
}
