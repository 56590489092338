import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BusinessHourException } from '../../../core/services/dpl-api-services';
import { LoadingLocationsQuery } from '../../../loading-locations/state/loading-locations.query';

@Component({
  selector: 'dpl-business-hour-exceptions',
  templateUrl: './business-hour-exceptions.component.html',
  styleUrls: ['./business-hour-exceptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessHourExceptionsComponent implements OnInit {
  exceptions$: Observable<BusinessHourException[]>;

  constructor(private loadingLocationsQuery: LoadingLocationsQuery) {}

  ngOnInit(): void {
    this.exceptions$ = this.loadingLocationsQuery
      .selectActive((entity) => entity.businessHourExceptions)
      .pipe(
        map((exceptions) => {
          return exceptions
            ? _.sortBy(
                exceptions.filter((x) =>
                  moment(x.fromDateTime).isSameOrAfter(moment(), 'days')
                ),
                (y) => y.fromDateTime
              )
            : [];
        }),
        tap((exceptions) => console.log('Exceptions', exceptions))
      );
  }
}
