<ng-container *ngIf="viewData$ | async as data">
  <ng-container *ngIf="data.canManage">
    <a
      mat-button
      [matMenuTriggerFor]="adminMenu"
      >Administration</a
    >
    <mat-menu #adminMenu="matMenu">
      <a
        mat-menu-item
        [routerLink]="['customer-administration']"
      >
        Kundenverwaltung
      </a>
      <a
        mat-menu-item
        [routerLink]="['loading-location-administration']"
        [queryParams]="{ erpSearch: 'true' }"
      >
        Ladestellenverwaltung
      </a>
      <a
        *ngIf="data.userAADRole !== AadRole.UserAdmin"
        mat-menu-item
        [routerLink]="['address-administration']"
      >
        Adressverwaltung
      </a>
      <a
        *ngIf="data.userAADRole !== AadRole.UserAdmin"
        mat-menu-item
        [routerLink]="['partner-administration']"
      >
        Partnerverwaltung
      </a>
      <a
        *ngIf="data.userAADRole !== AadRole.UserAdmin"
        mat-menu-item
        [routerLink]="['scenario-administration']"
      >
        Szenarienverwaltung
      </a>
      <a
        *ngIf="data.userAADRole !== AadRole.UserAdmin"
        mat-menu-item
        [routerLink]="['upload-process-create']"
      >
        Upload-Prozess
      </a>
      <a
        *ngIf="data.canPublish"
        mat-menu-item
        [routerLink]="['announcements']"
      >
        Ankündigungen
      </a>
    </mat-menu>
  </ng-container>
</ng-container>
