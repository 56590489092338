import { Injectable } from '@angular/core';
import { LoadCarrierPickerContext } from '../components/load-carrier-picker/load-carrier-picker.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypesService {
  constructor() {}

  getDocumentTypeIdForLoadCarrierPickerContext(
    context: LoadCarrierPickerContext
  ) {
    switch (context) {
      case 'voucher':
        return 3;
      case 'deliveryReceipt':
      case 'pickupReceipt':
      case 'exchangeReceipt':
        return 6;
      case 'delivery':
      case 'pickup':
        return 6;
      case 'extDemand':
      case 'extDemandSelfTransport':
      case 'extSupply':
      case 'extSupplySelfTransport':
        // ToDo ID=8?
        return 6;
      default:
        return null;
    }
  }
}
