<div
  class="popover-messaging-system-file-upload"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <ng-container *ngIf="viewData$ | async as data">
    <div class="dialog-top-part">
      <div mat-dailog-title class="message-list">
        <div class="message-list-header">
          <dpl-dialog-header [showOwnIcons]="true">
            <!-- Remember the ng-content is in a flex-direction row reverse -->
            <span headerText>
              <span i18n="@@FileUploadDialogHeaderText">Anhänge für </span>
              {{ dialogData.channel.channelTitle }}</span
            >
            <div headerIcons>
              <dx-button
                icon="close"
                hint="Fenster schließen"
                i18n-hint="@@CloseIconHint"
                (click)="onSave(data.uploadProcessCode)"
              >
              </dx-button>
            </div>
          </dpl-dialog-header>
        </div>
      </div>
      <div class="dialog-content">
        <div class="dialog-content-file-uploader">
          <!-- FileUpload -->
          <ng-container
            *ngIf="data.filesUploaded?.length < 3; else fileUploadInfo"
          >
            <dx-scroll-view class="dialog-content-file-uploader-scroll-view">
              <dpl-single-upload-form
                [uploadProcessCode]="data.uploadProcessCode"
                [allowMultipleFileUpload]="false"
                [allowCanceling]="true"
                (uploadCompleted)="uploadCompleted($event)"
                (uploadStarted)="uploadStarted($event)"
                [showFileList]="true"
                [fileType]="completeSingleProcessUploadFileInfoType.Main"
                selectButtonText="Datei auswählen oder hineinziehen"
                i18n-selectButtonText="@@MessagingSystemFileUploadButtonText"
              ></dpl-single-upload-form>
            </dx-scroll-view>
          </ng-container>
          <ng-template #fileUploadInfo>
            <p
              class="info-file-uploaded"
              i18n="@@FileUploadDialogToManyFilesInfo"
            >
              Sie können maximal 3 Dateien für eine Nachricht hochladen.
            </p>
          </ng-template>
        </div>
      </div>
      <div
        class="dialog-content-uploaded-files"
        *ngIf="data.filesUploaded?.length > 0"
      >
        <div class="dialog-content-uploaded-files-header">
          <p i18n="@@FileUploadDialogContentUplodedFilesHeader">
            Hochgeladene Dateien
          </p>
        </div>
        <dx-list
          #dxList
          [dataSource]="data.filesUploaded"
          [activeStateEnabled]="false"
          [focusStateEnabled]="false"
          [hoverStateEnabled]="false"
        >
          <div *dxTemplate="let listData of 'item'; let i = index">
            <div class="show-uploaded-file">
              <dpl-show-uploaded-files
                [item]="listData"
                [index]="i"
                (deleteItem)="removeItem($event)"
              ></dpl-show-uploaded-files>
            </div>
          </div>
        </dx-list>
      </div>
    </div>
    <div class="dialog-bottom-part">
      <dx-button
        hint="Fenster schließen"
        i18n-hint="@@CloseIconHint"
        color="primary"
        text="Schließen"
        i18n-text="@@CloseText"
        (onClick)="onSave(data.uploadProcessCode)"
      >
      </dx-button>
    </div>
  </ng-container>
</div>
