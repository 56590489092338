<ng-container *ngIf="viewData$ | async as data">
  <div
    [class]="
      isAnnouncementLoginDialog
        ? 'message-component-row annoncement-dialog'
        : message.creator?.userId === data.currentUser?.id
        ? 'own-message message-component-row'
        : 'other-message message-component-row'
    "
  >
    <div
      [class]="
        message.pinned
          ? 'pin message-component dx-theme-background-color'
          : 'unpin message-component dx-theme-background-color'
      "
    >
      <div id="message-row">
        <div class="photo">
          <img [src]="getImage(data)" />
        </div>
        <div class="message-info">
          <div class="name">
            {{ message.creator?.userName }}
          </div>
          <div class="date">
            {{ message.creator?.timestamp | dateEx }}
          </div>
        </div>
        <div>
          <ng-container *ngIf="message.mentionedUser">
            <span class="dx-mention" spellcheck="false">
              <span>@</span>
              <span>{{ message.mentionedUser?.userUpn }}</span>
            </span>
          </ng-container>
          <div
            [class]="'long-message'"
            [innerHTML]="getTranslatedMessageBody(message) | safe : 'html'"
          ></div>
        </div>

        <div class="message-action-controls">
          <ng-container *ngIf="message.attachments?.length > 0">
            <dx-button
              icon="attach"
              [hint]="'Zeige die Links zu den Dateien der Nachricht'"
              i18n-hint="@@OpenDialogShowAttachmentsIconHint"
              (click)="openDialogForAttachments(message)"
            >
            </dx-button>
            <span
              [matBadge]="message.attachments?.length"
              matBadgeColor="primary"
              class="has-attachments"
              title="Hochgeladene Dateien"
              i18n-title="@@MessageHasAttachmentButtonTitle"
            ></span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
