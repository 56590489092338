import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ResourceAction,
  UserRole,
  VoucherStatus,
  VoucherType,
} from '@app/api/dpl';
import { voucherTypeToDocumentTypeId } from '@app/core';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

import { CustomerDivisionsService } from '../../../customers/services/customer-divisions.service';
import { CustomersService } from '../../../customers/services/customers.service';
import { ICustomer } from '../../../customers/state/customer.model';
import { UserService } from '../../../user/services/user.service';
import { VoucherService } from '../../../voucher/services/voucher.service';

type ViewData = {
  disabled: boolean;
  showButton: boolean;
};

@Component({
  selector: 'dpl-cancel-voucher-button',
  templateUrl: './cancel-voucher-button.component.html',
  styleUrls: ['./cancel-voucher-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelVoucherButtonComponent implements OnInit {
  @Input() voucher: any; //ToDo export type VoucherExport from Server
  @Output() canceled = new EventEmitter<boolean>();
  viewData$: Observable<ViewData>;
  resourceAction = ResourceAction;
  userRole = UserRole;

  constructor(
    private voucherService: VoucherService,
    private divisionService: CustomerDivisionsService,
    private userService: UserService,
    private customerService: CustomersService
  ) {}

  ngOnInit(): void {
    const customer$ = this.customerService
      .getActiveCustomer()
      .pipe(filter((x) => !!x));
    const division$ = this.divisionService
      .getActiveDivision()
      .pipe(filter((x) => !!x));
    const isEmployee$ = this.userService.getIsDplEmployee();
    const userRole$ = this.userService.getUserRole();
    this.viewData$ = combineLatest([
      customer$,
      division$,
      isEmployee$,
      userRole$,
    ]).pipe(
      map(([customer, division, isEmployee, userRole]) => {
        let disabled = true;

        const timeSpan = moment(moment()).diff(
          this.voucher.issuedDate,
          'hours',
          true
        );

        // employee handling
        if (
          (this.voucher.statusEnum === VoucherStatus.Issued ||
            this.voucher.statusEnum === VoucherStatus.Submitted) &&
          isEmployee
        ) {
          disabled = false;
        }
        //check voucher is issued by active division
        else if (
          (this.voucher.statusEnum === VoucherStatus.Issued ||
            this.voucher.statusEnum === VoucherStatus.Submitted) &&
          division.id === this.voucher.divisionId
        ) {
          if (
            this.voucher.typeEnum === VoucherType.Original &&
            timeSpan <=
              this.getCancellationTimeSpan(
                customer,
                voucherTypeToDocumentTypeId(this.voucher.typeEnum),
                24
              )
          ) {
            disabled = false;
          }
          if (
            this.voucher.typeEnum === VoucherType.Digital &&
            timeSpan <=
              this.getCancellationTimeSpan(
                customer,
                voucherTypeToDocumentTypeId(this.voucher.typeEnum),
                1
              )
          ) {
            disabled = false;
          }
        } else if (
          this.voucher.typeEnum === VoucherType.Direct &&
          this.voucher.statusEnum === VoucherStatus.Accounted
        ) {
          //Direktbuchung
          if (
            timeSpan <=
            this.getCancellationTimeSpan(
              customer,
              // use documentSettings from original
              voucherTypeToDocumentTypeId(VoucherType.Original),
              24
            )
          ) {
            disabled = false;
          }
        }
        const viewData: ViewData = {
          disabled,
          showButton:
            isEmployee ||
            userRole === UserRole.Retailer ||
            userRole === UserRole.PointOfSale,
        };
        return viewData;
      })
    );
  }

  onCancel() {
    // return this.voucherService.cancel(this.voucher, 'grid').pipe(
    //   delay(200),
    //   tap((x) => this.canceled.emit(true))
    // );
  }

  private getCancellationTimeSpan(
    customer: ICustomer<number>,
    documentTypeId: number,
    defaultCancellationTimeSpan: number
  ): number {
    return (
      customer.documentSettings.find(
        (c) => c.cancellationTimeSpan > 0 && c.documentTypeId == documentTypeId
      )?.cancellationTimeSpan ?? defaultCancellationTimeSpan
    );
  }
}
