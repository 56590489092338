import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { multiEmailsValidator } from '@app/shared/validators/multiEmailsValidator';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { Controls, NgxAutomaticRootFormComponent } from 'ngx-sub-form';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { DplApiService, LocalizationService } from '../../../core';
import {
  Address,
  BusinessHourException,
  BusinessHourExceptionType,
  BusinessHours,
  Country,
  DayOfWeek,
  LoadingLocationAddress,
  LoadingLocationCreateRequest,
  LoadingLocationOwnershipType,
  LoadingLocationType,
  OrderType,
} from '../../../core/services/dpl-api-services';
import { ValidationDataService } from '../../../shared';

type ViewData = {
  countries: Country[];
  businessHours: DataSource;
  businessHourExceptions: DataSource;
  dayOfWeeksTypeData: any;
  loadingLocationTypes: LoadingLocationType[];
  loadingLocationOwnershipTypes: LoadingLocationOwnershipType[];
  dropdownExecptionType: any;
};

export enum LocationCreateUseCase {
  Customer = 'Customer',
  Admin = 'Admin',
}

export enum LocationCreateMode {
  Create = 'Create',
  Edit = 'Edit',
}

interface Tab {
  text: string;
  content: string;
}

export interface LoadingLocationForm {
  // id?: number;
  // addressId?: number;
  //Rampe
  companyName?: string | null;
  displayName?: string | null;
  types?: LoadingLocationType[] | null;
  ownershipType?: LoadingLocationOwnershipType | null;
  description?: string | null;
  //Adresse
  address?: LoadingLocationAddress | null;
  //Verladevarianten
  loadingOptions?: {
    supportsRearLoading?: boolean;
    supportsSideLoading?: boolean;
    supportsJumboVehicles?: boolean;
  } | null;

  //Stapelhöhe
  stackHeightMin?: number;
  stackHeightMax?: number;

  //BusinessHours
  businessHours?: BusinessHours[] | null;
  businessHourExceptions?: BusinessHourException[] | null;
  //Lieferanweißung
  deliveryInstructions?: string | null;
  //Vorlauf
  fulfillmentPreRegistrationRequired?: boolean | null;
  fulfillmentPreRegistrationHours?: number | null;
  //Kontakt
  mainContactPerson?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    fax: string;
  } | null;

  phone?: string | null;
  fax?: string | null;
  email?: string | null;
}

function validateLoadingOptions(group: UntypedFormGroup): ValidationErrors {
  if (group) {
    const { supportsSideLoading, supportsRearLoading, supportsJumboVehicles } =
      group.getRawValue();

    if (!supportsSideLoading && !supportsRearLoading && !supportsJumboVehicles)
      return { noLoadingOptionSelected: true };

    // if (supportsJumboVehicles == true && !supportsRearLoading)
    //   return { noJumboWithoutRearLoading: true };
  }
  return null;
}

@Component({
  selector: 'dpl-loading-location-create-form',
  templateUrl: './loading-location-create-form.component.html',
  styleUrls: ['./loading-location-create-form.component.scss'],
})
export class LoadingLocationCreateFormComponent
  extends NgxAutomaticRootFormComponent<
    LoadingLocationCreateRequest,
    LoadingLocationForm
  >
  implements OnInit, OnChanges, OnDestroy
{
  @Input() dataInput: Required<LoadingLocationCreateRequest>;
  @Input() orderType: OrderType;
  @Input() useCase: LocationCreateUseCase;
  @Input() mode: LocationCreateMode;
  @Output('onChange')
  dataOutput: EventEmitter<LoadingLocationCreateRequest> = new EventEmitter();

  @Output() formValid = new EventEmitter<boolean>();

  @Input() onlyInfo = false;

  addresses: Address[];
  viewData$: Observable<ViewData>;
  maxHeight: number = 0;
  minHeight: number = 0;

  businessHoursDataSource: DataSource;

  locationCreateUseCase = LocationCreateUseCase;
  locationCreateMode = LocationCreateMode;

  tabIndex: number;

  //Todo move getting default businessHours
  businessHours$: Observable<BusinessHours[]> = of([]);

  //Test data
  businessHourExceptions$: Observable<BusinessHourException[]> = of(null);

  constructor(
    cd: ChangeDetectorRef,
    private dpl: DplApiService,
    private localizationService: LocalizationService
  ) {
    super(cd);
  }

  getDefaultValues(): Partial<LoadingLocationForm> {
    return {
      //Adresse
      address: {
        street1: null,
        street2: null,
        city: null,
        countryIso2Code: null,
        postalCode: null,
      },
      fulfillmentPreRegistrationRequired: false,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnInit(): void {
    super.ngOnInit();
    //set LoadingLocationType based on OrderType
    if (!this.dataInput) {
      // new loading location
      this.formGroup.controls.types.patchValue(
        this.orderType === OrderType.Supply
          ? [LoadingLocationType.Loading]
          : [LoadingLocationType.Unloading]
      );
    } else {
      this.formGroup.markAllAsTouched();

      if (
        this.useCase === LocationCreateUseCase.Customer &&
        this.mode === LocationCreateMode.Edit
      ) {
        this.formGroup.controls.address.disable();
      }
      // if(this.useCase === LocationCreateUseCase.Admin){
      //   // use for admin mode form changes
      // }
      if (this.mode === LocationCreateMode.Create) {
        this.formGroup.controls.businessHours.setValidators([
          Validators.required,
        ]);
        this.formGroup.controls.businessHours.updateValueAndValidity();
      }
    }

    const countries$ = this.dpl.countries.get();
    const requiredAvis$ =
      this.formGroup.controls.fulfillmentPreRegistrationRequired.valueChanges.pipe(
        startWith(
          this.formGroup.controls.fulfillmentPreRegistrationRequired.value
        ),
        tap((active) => {
          if (!this.dataInput) {
            //only for new items
            if (active) {
              this.formGroup.controls.fulfillmentPreRegistrationHours.setValidators(
                [Validators.required, Validators.min(12), Validators.max(168)]
              );
              this.formGroup.controls.mainContactPerson.controls.lastName.setValidators(
                [Validators.required]
              );
              this.formGroup.controls.mainContactPerson.controls.email.setValidators(
                [Validators.required, multiEmailsValidator()]
              );
            } else {
              this.formGroup.controls.fulfillmentPreRegistrationHours.clearValidators();
              this.formGroup.controls.fulfillmentPreRegistrationHours.setValidators(
                [Validators.min(12), Validators.max(168)]
              );
              this.formGroup.controls.mainContactPerson.controls.lastName.clearValidators();
              this.formGroup.controls.mainContactPerson.controls.email.clearValidators();
              this.formGroup.controls.mainContactPerson.controls.email.setValidators(
                [multiEmailsValidator()]
              );
            }
            this.formGroup.controls.fulfillmentPreRegistrationHours.updateValueAndValidity();
            this.formGroup.controls.mainContactPerson.controls.lastName.updateValueAndValidity();
            this.formGroup.controls.mainContactPerson.controls.email.updateValueAndValidity();
          }
        })
      );

    const formValid$ = this.formGroup.statusChanges.pipe(
      startWith(this.formGroup.status),
      map((formStatus) => {        
        return formStatus == 'VALID';
      }),
      tap((valid) => {
        this.formValid.next(valid);
      })
    );

    const formSync$ = combineLatest([requiredAvis$, formValid$]).pipe(
      switchMap(() => EMPTY), // we do not wanne trigger new view data
      startWith(null) // needed so viewdata outputs values
    );

    //formSync

    const bhData$ = combineLatest([
      this.businessHours$,
      this.businessHourExceptions$,
    ]).pipe(
      map(([businessHours, businessHoursExceptions]) => {
        return {
          businessHours,
          businessHoursExceptions,
        };
      })
    );
    this.viewData$ = combineLatest([
      countries$,
      bhData$,
      this.getDayOfWeeksTypeData(),
      this.getTypeData(),
      formSync$,
    ]).pipe(
      map(([countries, bhdata, dayOfWeeksTypeData, dropdownExecptionType]) => {
        const loadingLocationTypes = this.getPossibleLoadingLocationTypes();
        const loadingLocationOwnershipTypes =
          this.getPossibleLoadingLocationOwnershipTypes();
        const businessHoursDataSource = new DataSource(
          new ArrayStore({
            key: 'id',
            data: bhdata.businessHours,
            onInserted: (value: BusinessHours, key: number) => {              
              this.formGroup.controls.businessHours.value
                ? this.formGroup.controls.businessHours.patchValue(
                    this.formGroup.controls.businessHours.value.concat(value)
                  )
                : [this.formGroup.controls.businessHours.value];
            },
            onUpdated: (key: number, value: BusinessHours) => {
              this.formGroup.controls.businessHours.value ??
                this.formGroup.controls.businessHours.patchValue(
                  this.formGroup.controls.businessHours.value
                    .filter((x) => x.id !== key)
                    .concat(value)
                );
            },
            onRemoved: (key: number) => {
              this.formGroup.controls.businessHours.value ??
                this.formGroup.controls.businessHours.patchValue(
                  this.formGroup.controls.businessHours.value.filter(
                    (x) => x.id !== key
                  )
                );
            },
            onLoaded: (result: BusinessHours[]) => {
              this.formGroup.controls.businessHours.patchValue(result);
            },
          })
        );
        const businessHourExceptionsDataSource = new DataSource(
          new ArrayStore({
            key: 'id',
            data: bhdata.businessHoursExceptions,
            onInserted: (value: BusinessHourException, key: number) => {
              this.formGroup.controls.businessHourExceptions.value
                ? this.formGroup.controls.businessHourExceptions.patchValue(
                    this.formGroup.controls.businessHourExceptions.value.concat(
                      value
                    )
                  )
                : [this.formGroup.controls.businessHourExceptions.value];
            },
            onUpdated: (key: number, value: BusinessHourException) => {
              this.formGroup.controls.businessHourExceptions.value ??
                this.formGroup.controls.businessHourExceptions.patchValue(
                  this.formGroup.controls.businessHourExceptions.value
                    .filter((x) => x.id !== key)
                    .concat(value)
                );
            },
            onRemoved: (key: number) => {
              this.formGroup.controls.businessHourExceptions.value ??
                this.formGroup.controls.businessHourExceptions.patchValue(
                  this.formGroup.controls.businessHourExceptions.value.filter(
                    (x) => x.id !== key
                  )
                );
            },
            onLoaded: (result: BusinessHourException[]) => {
              this.formGroup.controls.businessHourExceptions.patchValue(result);
            },
          })
        );

        const viewData: ViewData = {
          countries,
          dayOfWeeksTypeData,
          businessHours: businessHoursDataSource,
          businessHourExceptions: businessHourExceptionsDataSource,
          loadingLocationTypes,
          loadingLocationOwnershipTypes,
          dropdownExecptionType,
        };
        return viewData;
      }),
      tap((viewData) => {
        this.tabIndex = 0;
        if (this.dataInput) {
          //hack
          if ((this.dataInput.address as any)?.country)
            //set country in edit mode
            this.formGroup.controls.address.controls.countryIso2Code.patchValue(
              viewData.countries.find(
                (x) => x.id === (this.dataInput.address as any).country
              ).iso2Code
            );
        }
        if (this.onlyInfo) {
          this.formGroup.disable();
        }
      })
    );
  }

  getPossibleLoadingLocationTypes() {
    return Object.values(LoadingLocationType);
  }
  getPossibleLoadingLocationOwnershipTypes() {
    return Object.values(LoadingLocationOwnershipType);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getDayOfWeekSortValues(value) {
    let sortValue = 0;
    switch (value.dayOfWeek) {
      case DayOfWeek.Monday:
        sortValue = 1;
        break;
      case DayOfWeek.Tuesday:
        sortValue = 2;
        break;
      case DayOfWeek.Wednesday:
        sortValue = 3;
        break;
      case DayOfWeek.Thursday:
        sortValue = 4;
        break;
      case DayOfWeek.Friday:
        sortValue = 5;
        break;
      case DayOfWeek.Saturday:
        sortValue = 6;
        break;
      case DayOfWeek.Sunday:
        sortValue = 7;
        break;
    }
    return sortValue;
  }

  protected getFormControls(): Controls<LoadingLocationForm> {
    return {
      //Rampe
      companyName: new UntypedFormControl(null, [
        Validators.maxLength(ValidationDataService.maxLength.companyName),
        Validators.required,
      ]),
      displayName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(ValidationDataService.maxLength.companyName),
      ]),
      ownershipType: new UntypedFormControl(null, [Validators.required]),
      types: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(null),
      //Adresse
      address: new UntypedFormGroup({
        street1: new UntypedFormControl(null, [Validators.required]),
        street2: new UntypedFormControl(null),
        postalCode: new UntypedFormControl(null, [Validators.required]),
        city: new UntypedFormControl(null, [Validators.required]),
        countryIso2Code: new UntypedFormControl(null, [Validators.required]),
      }),
      //Verladevarianten
      loadingOptions: new UntypedFormGroup(
        {
          supportsRearLoading: new UntypedFormControl(null),
          supportsSideLoading: new UntypedFormControl(null),
          supportsJumboVehicles: new UntypedFormControl(null),
        },
        { validators: validateLoadingOptions }
      ),
      //Stapelhöhe
      stackHeightMin: new UntypedFormControl(null, [Validators.required]),
      stackHeightMax: new UntypedFormControl(null, [Validators.required]),
      //BusinessHours
      businessHours: new UntypedFormControl(null),
      businessHourExceptions: new UntypedFormControl(null),
      //Lieferanweißung
      deliveryInstructions: new UntypedFormControl(null),
      //Vorlauf

      fulfillmentPreRegistrationHours: new UntypedFormControl(null, []),

      fulfillmentPreRegistrationRequired: new UntypedFormControl(null),

      mainContactPerson: new UntypedFormGroup({
        firstName: new UntypedFormControl(null),
        lastName: new UntypedFormControl(null),
        email: new UntypedFormControl(null),
        phoneNumber: new UntypedFormControl(null),
        fax: new UntypedFormControl(null),
      }),

      phone: new UntypedFormControl(null),
      fax: new UntypedFormControl(null),
      email: new UntypedFormControl(null, [multiEmailsValidator()]),
    };
  }

  protected transformFromFormGroup(
    loadingLocationForm: LoadingLocationForm
  ): LoadingLocationCreateRequest {
    return {
      address: loadingLocationForm.address,
      businessHours: loadingLocationForm.businessHours
        ? this.getRequestTimezoneManipulation(
            loadingLocationForm.businessHours
          ).map((businessHour) => {
            //remove id - new location and business hours
            return {
              dayOfWeek: businessHour.dayOfWeek,
              fromTime: businessHour.fromTime,
              toTime: businessHour.toTime,
            };
          })
        : [],
      businessHourExceptions: loadingLocationForm.businessHourExceptions
        ? this.getRequestTimezoneManipulation(
            loadingLocationForm.businessHourExceptions
          ).map((businessHourException: BusinessHourException) => {
            //remove id - new location and business hour exceptions
            return {
              type: businessHourException.type,
              fromDateTime: businessHourException.fromDateTime,
              toDateTime: businessHourException.toDateTime,
            };
          })
        : [],
      stackHeightMin: loadingLocationForm.stackHeightMin,
      stackHeightMax: loadingLocationForm.stackHeightMax,
      supportsPartialMatching: false,
      supportsRearLoading:
        loadingLocationForm.loadingOptions?.supportsRearLoading,
      supportsSideLoading:
        loadingLocationForm.loadingOptions?.supportsSideLoading,
      supportsJumboVehicles:
        loadingLocationForm.loadingOptions?.supportsJumboVehicles,
      ownershipType: loadingLocationForm.ownershipType,
      type:
        loadingLocationForm.types?.length > 0
          ? loadingLocationForm.types.length == 2
            ? null
            : loadingLocationForm.types[0]
          : null,
      deliveryInstructions: loadingLocationForm.deliveryInstructions,
      companyName: loadingLocationForm.companyName,
      displayName: loadingLocationForm.displayName,
      description: loadingLocationForm.description,
      mainContactPerson: {
        ...loadingLocationForm.mainContactPerson,
        mobileNumber: loadingLocationForm.mainContactPerson?.fax,
      },
      fulfillmentPreRegistrationRequired:
        loadingLocationForm.fulfillmentPreRegistrationRequired,
      fulfillmentPreRegistrationHours:
        loadingLocationForm.fulfillmentPreRegistrationHours,
      fax: loadingLocationForm.fax,
      email: loadingLocationForm.email,
      phone: loadingLocationForm.phone,
    };
  }

  protected transformToFormGroup(
    obj: LoadingLocationCreateRequest,
    defaultValues: Partial<LoadingLocationForm>
  ): LoadingLocationForm {
    return {
      address: obj.address,
      businessHourExceptions: [],
      businessHours: [],
      companyName: obj.companyName,
      deliveryInstructions: obj.deliveryInstructions,
      description: obj.description,
      displayName: obj.displayName,
      email: obj.email,
      fax: obj.fax,
      phone: obj.phone,
      fulfillmentPreRegistrationHours: obj.fulfillmentPreRegistrationHours,
      fulfillmentPreRegistrationRequired:
        obj.fulfillmentPreRegistrationRequired,
      ownershipType: obj.ownershipType,
      stackHeightMax: obj.stackHeightMax,
      stackHeightMin: obj.stackHeightMin,
      loadingOptions: {
        supportsJumboVehicles: obj.supportsJumboVehicles,
        supportsRearLoading: obj.supportsRearLoading,
        supportsSideLoading: obj.supportsSideLoading,
      },
      types: obj.type
        ? [obj.type]
        : [LoadingLocationType.Loading, LoadingLocationType.Unloading],
      mainContactPerson: {
        lastName: obj.mainContactPerson?.lastName,
        email: obj.mainContactPerson?.email,
        phoneNumber: obj.mainContactPerson?.phoneNumber,
        fax: obj.mainContactPerson?.mobileNumber,
        firstName: obj.mainContactPerson?.firstName,
      },
    };
  }

  getWeekDay(i: BusinessHours) {
    switch (i.dayOfWeek) {
      case DayOfWeek.Monday:
        return 'Montag';
      case DayOfWeek.Tuesday:
        return 'Dienstag';
      case DayOfWeek.Wednesday:
        return 'Mittwoch';
      case DayOfWeek.Thursday:
        return 'Donnerstag';
      case DayOfWeek.Friday:
        return 'Freitag';
      case DayOfWeek.Saturday:
        return 'Samstag';
      case DayOfWeek.Sunday:
        return 'Sonntag';
    }
  }

  getDayOfWeeksTypeData() {
    const r = Object.keys(DayOfWeek).map((value, id) => {
      value = DayOfWeek[value as any];
      const display = this.localizationService.getTranslation(
        'DayOfWeek',
        value
      );
      return { id, value, display };
    });
    return of(r);
  }

  getRequestTimezoneManipulation(
    businessHours: BusinessHours[] | BusinessHourException[]
  ) {
    if (!businessHours) {
      return businessHours;
    }
    return (businessHours as any[])?.map((businessHour) => {
      return {
        ...businessHour,
        fromTime: moment(businessHour.fromTime)
          .add('minutes', moment(businessHour.fromTime).utcOffset())
          .toDate(),
        toTime: moment(businessHour.toTime)
          .add('minutes', moment(businessHour.toTime).utcOffset())
          .toDate(),
      };
    });
  }

  onSelectTab(e) {
    this.tabIndex = e.itemIndex;
  }

  getTypeName = (data: any) => {
    const value = BusinessHourExceptionType[data.type as any];
    const display = this.localizationService.getTranslation(
      'BusinessHourExceptionType',
      value
    );
    return display;
  };

  customValidationCallbackBusinessHourExceptions = (params) => {
    console.log(
      'customValidationCallbackBusinessHourExceptions',
      params?.column?.dataField,
      params
    );
    if (params.data.fromDateTime && params.data.toDateTime) {
      if (params.data.fromDateTime < params.data.toDateTime) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // get UserRole LookupData
  getTypeData() {
    const r = Object.keys(BusinessHourExceptionType).map((value, id) => {
      value = BusinessHourExceptionType[value as any];
      const display = this.localizationService.getTranslation(
        'BusinessHourExceptionType',
        value
      );
      return { id, value, display };
    });

    return of(r);
  }

  getLoadingLocationTypeDisabled(type: LoadingLocationType) {
    switch (type) {
      case LoadingLocationType.Loading:
        return this.orderType === OrderType.Supply;
      case LoadingLocationType.Unloading:
        return this.orderType === OrderType.Demand;
    }
  }

  businessExceptionsOnEditorPreparing(e: any) {
    console.log('businessExceptionsOnEditorPreparing', e);
    // tries to trigger validation #7880
    if (e.parentType == 'dataRow' && e.dataField == 'fromDateTime') {
      e.editorOptions.onValueChanged = (args) => {
        console.log('fromDt', e, args);
        e.setValue(args.value);
        e.row.data['toDateTime'] = args.value;
      };
    }
    if (e.parentType == 'dataRow' && e.dataField == 'toDateTime') {
      e.editorOptions.onValueChanged = (args) => {
        console.log('fromDt', e, args);
        e.setValue(args.value);
        e.row.data['toDateTime'] = args.value;
      };
    }
  }
}
