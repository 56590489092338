import { Component, Inject } from '@angular/core';
import { ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dpl-members-dialog',
  templateUrl: './members-dialog.component.html',
  styleUrls: ['./members-dialog.component.scss'],
})
export class MembersDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<MembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public channel: ChannelDto
  ) { }

  onCancel() {
    this.dialogRef.close();
  }
}
