import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  NgxSingleFieldSubFormComponent,
  WrappedControlForm,
} from '@dpl/dpl-lib';
import { AccountsService } from 'apps/dpl-live/src/app/accounts/services/accounts.service';
import { subformComponentProviders } from 'ngx-sub-form';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CustomerDivisionsService } from '../../../customers/services/customer-divisions.service';
import { TransferService } from '../../../transfer/services/transfer.service';
import _ from 'lodash';

export type AccountSelectorType = {
  id: number;
  name: string;
  disabled: boolean;
};

export type AccountsFilterFn = (
  accounts: AccountSelectorType[]
) => Observable<AccountSelectorType[]>;

@Component({
  selector: 'app-posting-account-picker',
  template: `
    <mat-form-field
      fxFlex
      class="app-form-field-auto"
      *ngIf="formGroup"
      [formGroup]="formGroup"
    >
      <mat-label>
        <span *ngIf="label; else fallbackLabel">{{ label }}</span>
        <ng-template #fallbackLabel>
          <span i18n="BookingAccount|Label Buchungskonto@@BookingAccount"
            >Buchungskonto</span
          >
        </ng-template>
      </mat-label>
      <mat-select [formControl]="formGroup.controls.innerControl">
        <mat-option
          *ngFor="let account of accounts$ | async"
          [value]="account.id"
          [disabled]="account.disabled"
        >
          {{ account.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [],
  providers: subformComponentProviders(PostingAccountPickerComponent),
})
export class PostingAccountPickerComponent
  extends NgxSingleFieldSubFormComponent<number>
  implements OnInit, OnChanges
{
  @Input() context:
    | 'Source'
    | 'TransferSource'
    | 'TransferTarget'
    | 'DepotReceipt'
    | 'Submission' = 'Source';
  @Input() label: string;
  @Input() filterFn?: AccountsFilterFn;
  accounts$: Observable<AccountSelectorType[]>;

  defaultValue: WrappedControlForm<number>;

  constructor(
    private accountService: AccountsService,
    private divisionService: CustomerDivisionsService,
    private transferServivce: TransferService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const accountId$ = this.formGroup.controls.innerControl.valueChanges.pipe(
      tap((accountId: number) => {
        if (!accountId) {
          return;
        }
        this.defaultValue = {
          innerControl: accountId,
        };
        if (
          this.context === 'TransferTarget' ||
          this.context === 'TransferSource'
        ) {
          this.defaultValue = { innerControl: undefined };
        }

        if (this.context === 'Source' || this.context === 'DepotReceipt') {
          this.accountService.setActiveAccount(accountId);
        }
      })
    );

    const accountsContext$ = this.getAccountContext(this.context);

    const accountsInCurrentDivision$ = combineLatest([
      this.divisionService.getActiveDivision(),
      accountsContext$,
    ]).pipe(
      map(([division, accounts]) => {
        if (
          !division ||
          this.context === 'TransferSource' ||
          this.context === 'TransferTarget' ||
          this.context === 'Submission'
        ) {
          return accounts;
        }

        return accounts.filter(
          (account) =>
            account.id === division.postingAccountId ||
            division.postingAccounts?.some((i) => i.id === account.id)
        );
      })
    );

    const accounts$ = accountsInCurrentDivision$.pipe(
      switchMap((accounts) => {
        if (!this.filterFn) {
          return of(accounts);
        }
        return this.filterFn(accounts);
      })
    );

    const selectedAccount$ =
      this.context === 'TransferSource' || this.context === 'TransferTarget'
        ? of(null)
        : this.accountService.getActiveAccount();

    const formSync$ = combineLatest([accountId$]).pipe(
      switchMap(() => EMPTY) // we do not wanne trigger new view data
      //startWith(null) // needed so viewdata outputs values
    );

    this.accounts$ = combineLatest([accounts$, selectedAccount$]).pipe(
      takeUntil(formSync$),
      tap(([accounts, selectedAccount]) => {
        if (
          !accounts ||
          accounts.length === 0 ||
          (this.formGroup.controls.innerControl.value &&
            accounts.some(
              (x) => x.id === this.formGroup.controls.innerControl.value
            ))
        ) {
          return;
        }
        //For destination don't pre select first account
        if (this.context === 'Source' || this.context === 'DepotReceipt' || this.context === 'Submission') {
          this.formGroup.controls.innerControl.setValue(
            selectedAccount && accounts.find((x) => x.id === selectedAccount.id)
              ? selectedAccount.id
              : accounts[0].id
          );
        }
      }),
      map(([accounts, selectedAccount]) => {
        return accounts;
      })
    );
  }

  ngOnInit() {}

  protected getDefaultValues() {
    return this.defaultValue;
  }

  private getAccountContext(context: typeof this.context) {
    switch (context) {
      case 'Source': {
        return this.accountService.getAllAccounts().pipe(
          map((accounts) => {
            return accounts.map((account) => {
              const accountSelectorType: AccountSelectorType = {
                id: account.id,
                name: account.name,
                disabled: false,
              };
              return accountSelectorType;
            });
          })
        );
      }
      case 'Submission': {
        return combineLatest([
          this.divisionService.getActiveDivision(),
          this.accountService.getAllAccounts(),
        ]).pipe(
          map(([division, accounts]) => {
            const submissionAccount = division.submissionPostingAccountId
              ? accounts.find(
                  (i) => i.id === division.submissionPostingAccountId
                )
              : null;

            if (!submissionAccount) {
              const accountTypes = _(accounts)
                .map((account) => {
                  const accountSelectorType: AccountSelectorType = {
                    id: account.id,
                    name: account.name,
                    disabled: false,
                  };
                  return accountSelectorType;
                })
                // ensure default posting account is listed first
                .orderBy((i) => (i.id === division.postingAccountId ? 0 : 1))
                .value();

                return accountTypes;
            }

            const accountSelectorType: AccountSelectorType = {
              id: submissionAccount.id,
              name: submissionAccount.name,
              disabled: false,
            };

            return [accountSelectorType];
          })
        );
      }
      case 'DepotReceipt': {
        // use all accounts, verwender+depot own divisions (spediteur, depot)
        //this.accountService.getAllDepotAccounts().pipe(
        return this.accountService.getAllAccounts().pipe(
          map((accounts) => {
            return accounts.map((account) => {
              const accountSelectorType: AccountSelectorType = {
                id: account.id,
                name: account.name,
                disabled: false,
              };
              return accountSelectorType;
            });
          })
        );
      }
      case 'TransferSource': {
        return this.transferServivce.getAllowedSourceAccounts().pipe(
          map((accounts) => {
            return accounts.map((account) => {
              const accountSelectorType: AccountSelectorType = {
                id: account.refLtmsAccountId,
                name: account.postingAccountName,
                disabled: account.transactionsRemaining === 0,
              };
              return accountSelectorType;
            });
          })
        );
      }

      default: {
        return this.transferServivce.getAllowedTargetAccounts().pipe(
          map((accounts) => {
            return accounts.map((account) => {
              const accountSelectorType: AccountSelectorType = {
                id: account.refLtmsAccountId,
                name: account.accountName,
                disabled:
                  !account.balanceTransferLoadCarriers ||
                  account.balanceTransferLoadCarriers.findIndex(
                    (lc) => lc.amountRemaining > 0
                  ) < 0,
              };
              return accountSelectorType;
            });
          })
        );
      }
    }
  }
}
