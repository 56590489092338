<dpl-global-notifications></dpl-global-notifications>
<div class="topHeader">
  <mat-toolbar class="headerRow" color="primary" fxLayoutGap="12px">
    <!-- <a mat-button class="siteLogo" [routerLink]="['']"
      ><img src="assets/dpl-pooling-logo-trans.png" /><span
        i18n="homePagetitle|Home Seitentitel@@homePagetitle"
        >Live Pooling</span
      ></a
    > -->
    <app-top-navigation></app-top-navigation>
    <span fxFlex></span>
    <app-user-role></app-user-role>
    <customer-division-selector></customer-division-selector>
    <dpl-admin-menu></dpl-admin-menu>
    <app-user-profile></app-user-profile>
    <ng-container *ngIf="currentUser$ | async as currentUser"
      ><ng-container
        *ngIf="
          unreadMessageCountAllChannels$ | async as unreadCount;
          else noUnread
        "
      >
        <div
          class="messaging-system-icon"
          (click)="onNavigateToMessagingSystem()"
        >
          <button
            mat-icon-button
            title="Zum Messagingsystem navigieren"
            i18n-title="@@navigateToHeader"
          >
            <mat-icon>mail</mat-icon>
          </button>

          <div
            class="channel-unread-count"
            [matBadge]="unreadCount"
            matBadgeColor="warn"
          ></div>
        </div>
      </ng-container>
      <ng-template #noUnread>
        <div
          class="messaging-system-icon"
          (click)="onNavigateToMessagingSystem()"
        >
          <button
            mat-icon-button
            title="Zum Messagingsystem navigieren"
            i18n-title="@@navigateToHeader"
          >
            <mat-icon>mail</mat-icon>
          </button>
        </div>
      </ng-template>
    </ng-container>
    <app-language-selector></app-language-selector>
  </mat-toolbar>

  <!-- <mat-toolbar
    fxHide
    fxShow.lt-lg
    class="headerRow"
    color="primary"

  >
    <mat-toolbar-row >
      <a mat-button class="siteLogo" [routerLink]="['']"
        ><img src="assets/dpl-pooling-logo-trans.png" /><span
          i18n="homePagetitle|Home Seitentitel@@homePagetitle"
          >Live Pooling</span
        ></a
      >
      <app-top-navigation></app-top-navigation>

    </mat-toolbar-row>
    <mat-toolbar-row   fxLayoutGap="12px"
    fxLayout="row" fxLayoutAlign="end center">
      <app-user-role></app-user-role>
      <customer-division-selector></customer-division-selector>
      <dpl-admin-menu></dpl-admin-menu>
      <app-user-profile></app-user-profile>
      <app-language-selector></app-language-selector>
    </mat-toolbar-row>
  </mat-toolbar> -->
</div>
