import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLanguageFromLocale } from '../../../core/utils';
import { NotificationLevel } from '../../state/global-notifications/global-notifications.model';
import { GlobalNotificationsQuery } from '../../state/global-notifications/global-notifications.query';

interface ViewData {
  text: string;
  level: NotificationLevel;
}
@Component({
  selector: 'dpl-global-notifications',
  templateUrl: './global-notifications.component.html',
  styleUrls: ['./global-notifications.component.scss'],
})
export class GlobalNotificationsComponent implements OnInit {
  NotificationLevel: NotificationLevel;
  viewData$: Observable<ViewData>;

  constructor(
    private query: GlobalNotificationsQuery,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    this.viewData$ = this.query.globalNotifications$.pipe(
      map((notification) => {
        if (notification && notification.startDate && notification.textDE) {
          const viewData = {
            text:
              getLanguageFromLocale(this.locale || 'de') === 'de'
                ? notification.textDE
                : notification.textEN,
            level: notification.level,
          };
          return viewData;
        }
      })
    );
  }
}
