import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'dpl-channel-list-header',
  templateUrl: './channel-list-header.component.html',
  styleUrls: ['./channel-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelListHeaderComponent {
  @Input() headerText?: string;
  @Input() infoText?: string = "";


}
