import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { API_BASE_URL, OgcGeometryType, User, UserRole } from '@app/api/dpl';
import { LoadingService } from '@dpl/dpl-lib';
import { combineLatest, EMPTY, from, NEVER, Observable, of, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { isMsalIframe } from '../utils';
import { AuthenticationService } from './core/services/authentication.service';
import { MasterDataService } from './master-data/services/master-data.service';
import { GlobalNotificationsService } from './shared/services/global-notifications.service';
import { TitleService } from './shared/services/title.service';
import { UserService } from './user/services/user.service';
import { MessagingDataService } from './messaging-data/services/messaging-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowOnLoginAnnouncementsDialogComponent } from './messaging-system/components/show-on-login-announcements-dialog/show-on-login-announcements-dialog.component';

import { ChannelDto } from './generated/Dpl.B2b.Contracts.Chat.Shared';
import { IChannelDto } from './messaging-data/state/messaging-channels.store';
import { filterNilValue } from '@datorama/akita';
import { startsWith } from 'lodash';

interface IViewData {
  isLoggedIn: boolean;
  isInitialized: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  viewData$: Observable<IViewData>;
  userRole = UserRole;
  loading$: Observable<boolean>;
  ticketIsOpen: boolean = false;
  constructor(
    private auth: AuthenticationService,
    private masterData: MasterDataService,
    private userData: UserService,
    private title: Title,
    private dialog: MatDialog,
    private titleService: TitleService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private globalNotificationService: GlobalNotificationsService,
    private messagingDataService: MessagingDataService,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {
    this.matIconRegistry.addSvgIcon(
      'dpl_incoming',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/incoming.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'dpl_outgoing',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/outgoing.svg'
      )
    );
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me

  ngOnInit(): void {
    if (isMsalIframe()) {
      return;
    }

    this.globalNotificationService.getGlobalNotifications().subscribe();
    this.messagingDataService.initialize().subscribe();

    this.loading$ = this.loadingService.getLoading();

    const isAuthReady$ = this.auth.isReady();
    const isLoggedInAndNotExpired$ = this.auth.isLoggedIn();

    const appData$ = isLoggedInAndNotExpired$.pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return NEVER;
        }

        return combineLatest([
          this.userData.refreshUserData(),
          this.masterData.refreshMasterData(),
        ]);
      })
    );

    
    const minLoadingTime$ = timer(750); // to display loading bars for a certain time

    const title$ = this.titleService.buildTitle().pipe(
      tap((title) => {
        this.title.setTitle(title);
      })
    );

    const intialized$ = combineLatest([
      appData$,
      minLoadingTime$,
      title$,
    ]).pipe(
      map(() => true),
      startWith(false)
    );

    const currentUser$ = this.userData.getCurrentUser();

    const showTicketDialog$ = currentUser$.pipe(
      filterNilValue(),
      switchMap((currentUser) => {        
        return this.messagingDataService.getAnnouncements().pipe(
          tap((announcementTickets) => {
            const foundedIndex = announcementTickets.findIndex((value) => {
              return value.announcementShowOnLogin;
            });
            if (announcementTickets.length > 0 && foundedIndex >= 0) {
              if (!this.ticketIsOpen) {
                this.openAnnouncementTicketDialog(
                  announcementTickets[foundedIndex],
                  currentUser
                );
              } else {
                console.log('announcement dialog was open');
              }
            }
          })
        );
      }),
      switchMap(() => EMPTY),
      startWith(null)
    );

    this.viewData$ = combineLatest([
      isAuthReady$,
      isLoggedInAndNotExpired$,
      intialized$,
      currentUser$,
      showTicketDialog$,
    ]).pipe(
      map(([isAuthReady, isLoggedIn, isInitialized, currentUser]) => {
        return <IViewData>{
          isLoggedIn,
          isInitialized,
        };
      })
    );
  }

  openAnnouncementTicketDialog(ticket: ChannelDto, currentUser) {
    if (ticket && currentUser && currentUser.id) {
      const userHasReadAnnouncement =
        ticket.readings.findIndex(
          (readings) => readings.userId === currentUser.id
        ) >= 0;
      if (!userHasReadAnnouncement) {
        this.ticketIsOpen = true;
        this.dialog
          .open<ShowOnLoginAnnouncementsDialogComponent>(
            ShowOnLoginAnnouncementsDialogComponent,
            {
              minWidth: '600px',
              maxWidth: '1200px',
              width: '40%',
              minHeight: '600px',
              maxHeight: '1000px',
              panelClass: 'messaging-system-dialog-panel',
              disableClose: true,
              data: ticket,
            }
          )
          .afterClosed()
          .pipe(
            first(),
            tap((x) => {
              console.log(x);
              // if ((currentUser as User).role !== UserRole.DplEmployee) {
              //   this.ticketIsOpen = false
              // }
            })
          )
          .subscribe();
      } else {
        console.log('no unread Announcements');
      }
    } else {
      console.log('undefined Ticket or User');
    }
  }
}
