import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'dpl-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor() {}
  //TODO check if info must be translated
  // infos = {
  //   name: $localize`:ContactInfoName|Deutsche Paletten Logistik GmbH@@ContactInfoName:Deutsche Paletten Logistik GmbH`,
  //   postalCode:  $localize`:ContactInfoPostalCode|Plz für DPL@@ContactInfoPostalCode:59494`,
  //   city:  $localize`:ContactInfoCity|DPL Hauptstandort@@ContactInfoCity:Soest`,
  //   street: $localize`:ContactInfoStreet|@@ContactInfoStreet:Overweg 12`,
  //   state: 10,
  //   country: 1,
  //   email: $localize`:ContactInfoEmail|DPL Info Email@@ContactInfoEmail:info@dpl-pooling.com`,
  //   phone: '+49 (0)2921 7899-178',
  // };

  infos = {
    name: 'Deutsche Paletten Logistik GmbH',
    postalCode: '59494',
    city: 'Soest',
    street: 'Overweg 12',
    state: 10,
    country: 1,
    email: 'info@dpl-pooling.com',
    phone: '+49 (0)2921 7899-0',
  };

  get locationText(): string {
    return `D-${this.infos.postalCode} ${this.infos.city}, ${this.infos.street}`;
  }

  ngOnInit() {}
}
