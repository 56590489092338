<ng-container *ngIf="viewData$ | async as data">
  <div fxLayout="row" fxLayoutGap="10px" [formGroup]="formGroup">
    <div fxLayout="column" fxFlex="1 1 auto">
      <dx-drop-down-box
        [displayExpr]="getDropDownDisplayExpr"
        [placeholder]="placeholder"
        [showClearButton]="true"
        [dataSource]="data.gridDataSource"
        [formControl]="formGroup.controls.loadingLocation"
        [value]="dropDownValue"
        [(opened)]="dropDownOpened"
        [dropDownOptions]="dropDownOptions"
        [deferRendering]="false"
        style="padding: 7px 0 1px; width: 600px; margin-bottom: 20px"
        #gridBox
      >
        <div *dxTemplate="let contentData of 'content'">
          <div fxLayout="column">
            <div
              fxLayout="row"
              fxLayoutGap="20px"
              fxLayoutAlign="space-between"
            >
              <div>
                <mat-form-field>
                  <mat-label i18n="Label Suchtext@@SearchText"
                    >Suchtext</mat-label
                  >
                  <input
                    matInput
                    type="text"
                    id="searchValue"
                    [formControl]="formGroup.controls.searchValue"
                  />
                  <button
                    matSuffix
                    mat-icon-button
                    i18n-aria-label="@@Clear"
                    aria-label="Clear"
                    (click)="clearSearchValue()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <!-- todo check permission -->
              <button
                mat-raised-button
                (click)="onCreateNewLoadingLocation()"
                i18n="Neue Ladestelle@@CreateLoadingLocation"
                style="height: 40px"
                *hasPermission="resourceAction.CreateLoadingLocation"
              >
                Neue Be-/Entladestelle
              </button>
            </div>
            <dx-data-grid
              [dataSource]="data.gridDataSource"
              height="100%"
              id="loadinglocatriongrid"
              (onSelectionChanged)="
                onSelectionloadingLocationDataGridChanged(
                  data.loadingLocation,
                  $event
                )
              "
            >
              <dxo-remote-operations
                [filtering]="false"
                [paging]="true"
                [sorting]="false"
                [summary]="false"
                [grouping]="false"
                [groupPaging]="false"
              >
              </dxo-remote-operations>
              <dxi-column
                dataField="displayName"
                dataType="string"
                caption="Be-/Entladestelle"
                i18n-caption="@@LoadingStation"
                cellTemplate="displayNameCellTemplate"
              >
              </dxi-column>
              <!-- Document Cell Template-->
              <div *dxTemplate="let data of 'displayNameCellTemplate'">
                {{ displayNmaeDisplayExpr(data.data) }}
              </div>
              <dxi-column
                dataField="address"
                caption="Adresse"
                i18n-caption="@@JournalAddressColumnCaption"
                [allowFiltering]="false"
                cellTemplate="addressDisplayTemplate"
              >
                <div *dxTemplate="let adressData of 'addressDisplayTemplate'">
                  {{ getAdressString(adressData.value) }}
                </div>
              </dxi-column>
              <dxo-selection mode="single"></dxo-selection>
              <dxo-filter-row [visible]="false"></dxo-filter-row>
              <dxo-paging [pageSize]="5"></dxo-paging>
              <dxo-pager
                [showPageSizeSelector]="false"
                [allowedPageSizes]="[5, 10, 20]"
                [showInfo]="false"
                [showNavigationButtons]="true"
              ></dxo-pager>
              <dxo-grouping [autoExpandAll]="true"> </dxo-grouping>
            </dx-data-grid>
          </div>
        </div>
      </dx-drop-down-box>
      <ng-template #minCharacters>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          style="color: red; font-weight: bold"
        >
          <span i18n="Mindestens@@AtLeast">Mindestens </span>
          &nbsp;{{ 1 }}&nbsp;
          <span i18n="Zeichen zur Suche eingeben@@InfoSearchCharacters">
            Zeichen zur Suche eingeben</span
          >
        </div>
      </ng-template>
    </div>
    <div
      fxLayout="row"
      fyLayoutGap="5px"
      *ngIf="formGroup.value.loadingLocation"
    >
      <!-- todo maybe add titles -->
      <a
        mat-icon-button
        color="primary"
        (click)="infoLoadingLocation(formGroup.value.loadingLocation)"
        ><mat-icon>info</mat-icon></a
      >
      <!-- todo check permission -->
      <a
        mat-icon-button
        (click)="editLoadingLocation(formGroup.value.loadingLocation)"
        *hasPermission="resourceAction.UpdateLoadingLocation"
        ><mat-icon>edit</mat-icon></a
      >
      <!-- todo click action, check permission -->
      <a mat-icon-button *hasPermission="resourceAction.DeleteLoadingLocation"
        ><mat-icon>close</mat-icon></a
      >
    </div>
  </div>
</ng-container>
