import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import {
  DayOfWeek,
  LoadingLocation,
} from '../../../core/services/dpl-api-services';
import { getWeekdayByIso } from '../../utils';

@Component({
  selector: 'dpl-location-business-hours-date',
  templateUrl: './location-business-hours-date.component.html',
  styleUrls: ['./location-business-hours-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationBusinessHoursDateComponent implements OnInit {
  @Input() date: Date;
  @Input() loadingLocation: LoadingLocation;

  businessHours: {
    from: Date;
    to: Date;
  }[] = [];

  constructor() {}

  ngOnInit(): void {
    if (
      this.loadingLocation?.businessHours &&
      this.loadingLocation?.businessHours.length > 0
    ) {
      // console.log(this.loadingLocation?.businessHours);
      const weekdayTitle = getWeekdayByIso(moment(this.date).isoWeekday());
      this.businessHours = _.orderBy(this.loadingLocation.businessHours
        .filter((x) => x.dayOfWeek === weekdayTitle)
        .map((x) => {
          return { from: x.fromTime, to: x.toTime };
        }),['from'],['asc']);
    }
  }

  
}
