import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'dpl-grid-ntg-cell',
  templateUrl: './grid-ntg-cell.component.html',
  styleUrls: ['./grid-ntg-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridNtgCellComponent implements OnInit {
  @Input() text?: string;
  @Input() hint?: string;

  popOverVisible = false;
  randomId = Math.floor(Math.random() * 100 + 1);
  constructor() {}

  ngOnInit(): void {}
}
