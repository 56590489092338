import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MessagingDataService } from '../../../messaging-data/services/messaging-data.service';
import {
  ChannelStatusFilter,
  ChannelTimeFilter,
  ImpersonateChatUserDto,
} from '../../../messaging-data/state/messaging-channels.store';
import { UserService } from '../../../user/services/user.service';
import _ from 'lodash';

export interface ChannelFilterItem {
  text: string;
  value: ChannelTimeFilter | ChannelStatusFilter;
  hint?: string;
  color?: string;
}

@Component({
  selector: 'dpl-type-list-header',
  templateUrl: './type-list-header.component.html',
  styleUrls: ['./type-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeListHeaderComponent implements OnInit {
  possibleUsers$: Observable<ImpersonateChatUserDto[]>;
  selectedTimeFilter$: Observable<ChannelTimeFilter>;
  selectedStatusFilter$: Observable<ChannelStatusFilter>;
  selectedUserId$: Observable<number>;
  channelTimeFilter = ChannelTimeFilter;
  channelStatusFilter = ChannelStatusFilter;
  possibleCustomers$: Observable<ImpersonateChatUserDto[]>;
  selectedCustomerId$: Observable<number>;

  timeFilters: ChannelFilterItem[] = [
    {
      text: $localize`:@@ChannelFilterTimeItemAll:Alle`,
      value: ChannelTimeFilter.All,
    },
    {
      text: $localize`:@@ChannelFilterTimeItemMoreThanOneDay:> 1 Tag`,
      value: ChannelTimeFilter.MoreThanOneDay,
    },
    {
      text: $localize`:@@ChannelFilterTimeItemMoreThanThreeDays:> 3 Tage`,
      value: ChannelTimeFilter.MoreThanThreeDays,
    },
    {
      text: $localize`:@@ChannelFilterTimeItemMoreThanSevenDays:> 7 Tage`,
      value: ChannelTimeFilter.MoreThanSevenDays,
    },
  ];
  statusFilters: ChannelFilterItem[] = [
    {
      text: $localize`:@@ChannelFilterStatusItemAll:Alle`,
      value: ChannelStatusFilter.All,
    },
    {
      text: $localize`:@@ChannelFilterStatusItemOpen:Offen`,
      value: ChannelStatusFilter.Open,
      color: 'red',
    },
    {
      text: $localize`:@@ChannelFilterStatusItemInProgress:In Bearbeitung`,
      value: ChannelStatusFilter.InProgress,
      color: 'orange',
    },
    {
      text: $localize`:@@ChannelFilterStatusItemEnclosed:Geschlossen`,
      value: ChannelStatusFilter.Enclosed,
      color: 'green',
    },
  ];

  constructor(
    private userService: UserService,
    private messagingDataService: MessagingDataService
  ) {}

  ngOnInit(): void {
    this.possibleUsers$ = this.messagingDataService
      .getPossibleImpersonateUsers()
      .pipe(
        map((users) => {
          return _.orderBy(users, (x) => x.userName, 'asc');
        })
      );

    this.selectedUserId$ = this.userService.getCurrentUser().pipe(
      switchMap((user) => {
        return this.messagingDataService.getUserImpersonated().pipe(
          map((impersonatedUser) => {
            return impersonatedUser ? impersonatedUser.userId : user.id;
          })
        );
      })
    );
    this.selectedTimeFilter$ = this.messagingDataService.getTimeFilter();

    this.selectedStatusFilter$ = this.messagingDataService.getStatusFilter();

    this.possibleCustomers$ =
      this.messagingDataService.getCustomersWithTickets();
    this.selectedCustomerId$ = this.messagingDataService.getCustomerFilter();
  }

  selectTimeFilter(value: ChannelTimeFilter) {
    console.log('time filter', value);
    this.messagingDataService.setTimeFilter(value);
  }

  selectStatusFilter(value: ChannelStatusFilter) {
    console.log('status filter', value);
    this.messagingDataService.setStatusFilter(value);
  }

  selectUser(possibleUsers: ImpersonateChatUserDto[], userId: number) {
    console.log('select user', userId);
    const possibleUser = possibleUsers.find((x) => x.userId == userId);
    this.messagingDataService.setImperonateUser(possibleUser);
  }

  selectCustomer(customerId?: number) {
    console.log('select customer', customerId);
    this.messagingDataService.setCustomerFilter(customerId);
  }

  requiresAction(userId: number) {
    return this.messagingDataService.getEmployeeActionRequiredFlag(userId);
  }
}
