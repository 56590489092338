<div class="contact" fxLayout="column" fxLayoutAlign="center center">
  <mat-list>
    <mat-list-item>
      <h2 i18n="ContactTitle|Kontakte and Support@@ContactTitle">
        Kontakt & Support
      </h2>
    </mat-list-item>
    <mat-list-item
      ><h3 i18n="ContactSubtitle|Impressum@@ContactSubtitle">
        Impressum
      </h3></mat-list-item
    >
    <mat-list-item>
      <mat-icon matListIcon>business</mat-icon>
      {{ infos.name }}
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>location_on</mat-icon>
      {{ locationText }}
    </mat-list-item>

    <mat-list-item>
      <mat-icon matListIcon>phone</mat-icon>
      {{ infos.phone }}
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>email</mat-icon>
      {{ infos.email }}
    </mat-list-item>
  </mat-list>
</div>
