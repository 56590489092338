import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { ExternalAccoutingEntryType } from '../../../core/services/dpl-api-services';

@Component({
  selector: 'dpl-booking-direction-picker',
  template: `
    <button
      mat-icon-button
      color="primary"
      (click)="toggleDirection()"
      [disabled]="disabled || clickDisabled"
    >
      <ng-container [ngSwitch]="selectedEntryType">
        <mat-icon *ngSwitchCase="entryType.Credit">arrow_back</mat-icon>
        <mat-icon *ngSwitchCase="entryType.Debit">arrow_forward</mat-icon>
      </ng-container>
    </button>
  `,
  styles: [],
  host: {
    '(blur)': 'onTouched()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BookingDirectionPickerComponent,
      multi: true,
    },
  ],
})
export class BookingDirectionPickerComponent
  extends ControlValueAccessor<ExternalAccoutingEntryType>
  implements OnInit {
  @Input() clickDisabled?: boolean = false;
  entryType = ExternalAccoutingEntryType;

  selectedEntryType = ExternalAccoutingEntryType.Debit;
  disabled = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.onChange(this.selectedEntryType);
  }

  toggleDirection() {
    switch (this.selectedEntryType) {
      case ExternalAccoutingEntryType.Credit:
        this.writeValue(ExternalAccoutingEntryType.Debit);
        break;
      case ExternalAccoutingEntryType.Debit:
        this.writeValue(ExternalAccoutingEntryType.Credit);
        break;

      default:
        break;
    }

    this.onChange(this.selectedEntryType);
  }

  writeValue(value: ExternalAccoutingEntryType): void {
    this.selectedEntryType = value || ExternalAccoutingEntryType.Debit;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
