import { Injectable } from '@angular/core';

import {
  AccessLinksApiService,
  AccountingRecordsApiService,
  AddressesAdministrationApiService,
  BalanceTransfersApiService,
  BusinessHoursApiService,
  BusinessHourExceptionApiService,
  ContactPersonsApiService,
  CountriesApiService,
  CustomerDivisionsAdministrationApiService,
  CustomerPartnersAdministrationApiService,
  CustomersAdministrationApiService,
  CustomersApiService,
  DivisionDocumentSettingsAdministrationApiService,
  DocumentsApiService,
  DocumentSettingsApiService,
  DplEmployeeApiService,
  ExpressCodesApiService,
  ExternalAccountingEntriesApiService,
  ExternalAccountingTransactionApiService,
  ExternalLoadCarrierReceiptsApiService,
  IpSecurityApiService,
  LoadCarrierReceiptsApiService,
  LoadCarriersApiService,
  LoadCarrierSortingApiService,
  LoadingLocationsAdministrationApiService,
  LoadingLocationsApiService,
  MasterDataApiService,
  NumberSequencesApiService,
  OfferingsApiService,
  OrderGroupsApiService,
  OrderLoadsApiService,
  OrderMatchesApiService,
  OrderProposalsApiService,
  OrdersApiService,
  OrganizationsAdministrationApiService,
  PartnerDirectoriesApiService,
  PartnersAdministrationApiService,
  PartnersApiService,
  PermissionsAdministrationApiService,
  PostingAccountBalancesApiService,
  PostingAccountsAdministrationApiService,
  PostingAccountsApiService,
  PostingRequestsApiService,
  ServiceContractsApiService,
  SettingApiService,
  UploadsApiService,
  UserAdministrationApiService,
  UserApiService,
  UserGroupAdministrationApiService,
  VouchersApiService,
  VouchersSummariesApiService,
  TransportVouchersApiService,
  SelfAdministrationApiService,
  CustomerPartnersApiService,
  LinkDataApiService,
} from './dpl-api-services';

@Injectable({
  providedIn: 'root',
})
export class DplApiService {
  constructor(
    public postingAccounts: PostingAccountsApiService,
    public balanceTransfer: BalanceTransfersApiService,
    public accountingRecords: AccountingRecordsApiService,
    public countries: CountriesApiService,
    public customers: CustomersApiService,
    public documents: DocumentsApiService,
    public documentSettings: DocumentSettingsApiService,
    public employeeService: DplEmployeeApiService,
    public expressCodes: ExpressCodesApiService,
    public loadCarriers: LoadCarriersApiService,
    public loadCarrierReceipts: LoadCarrierReceiptsApiService,
    public loadingLocations: LoadingLocationsApiService,
    public masterData: MasterDataApiService,
    public offerings: OfferingsApiService,
    public orderLoads: OrderLoadsApiService,
    public orderMatches: OrderMatchesApiService,
    public orders: OrdersApiService,
    public orderGroups: OrderGroupsApiService,
    public partnerDirectories: PartnerDirectoriesApiService,
    public partners: PartnersApiService,
    public postingRequests: PostingRequestsApiService,
    public accessLinks: AccessLinksApiService,
    public uploads: UploadsApiService,
    public user: UserApiService,
    public vouchers: VouchersApiService,
    public voucherSummaries: VouchersSummariesApiService,
    public userAdmistrationService: UserAdministrationApiService,
    public numberSequencesApiService: NumberSequencesApiService,
    public groupAdministrationService: UserGroupAdministrationApiService,
    public permissionAdministrationService: PermissionsAdministrationApiService,
    public organizationsAdministrationService: OrganizationsAdministrationApiService,
    public customersAdministrationService: CustomersAdministrationApiService,
    public divisionsAdministrationService: CustomerDivisionsAdministrationApiService,
    public postingAccountsAdministrationService: PostingAccountsAdministrationApiService,
    public addressesAdministrationService: AddressesAdministrationApiService,
    public partnersAdministrationService: PartnersAdministrationApiService,
    public divisionDocumentSettingsAdministrationApiService: DivisionDocumentSettingsAdministrationApiService,
    public loadingLocationsAdministrationService: LoadingLocationsAdministrationApiService,
    public postingAccountBalancesApiService: PostingAccountBalancesApiService,
    public loadCarrierSortingService: LoadCarrierSortingApiService,
    public customerPartnersAdministrationService: CustomerPartnersAdministrationApiService,
    public customerPartnersApiService: CustomerPartnersApiService,
    public orderProposalsService: OrderProposalsApiService,
    public externalAccountingEntriesService: ExternalAccountingEntriesApiService,
    public externalLoadCarrierReceiptsService: ExternalLoadCarrierReceiptsApiService,
    public externalAccountingTransactionApiService: ExternalAccountingTransactionApiService,
    public serviceContracsApiService: ServiceContractsApiService,
    public settingApiService: SettingApiService,
    public ipService: IpSecurityApiService,
    public contactPersonsService: ContactPersonsApiService,
    public businessHourExceptionsService: BusinessHourExceptionApiService,
    public transportVouchersService: TransportVouchersApiService,
    public selfAdministrationApiService: SelfAdministrationApiService,
    public businessHoursApiService: BusinessHoursApiService,
    private linkData: LinkDataApiService
  ) {}
}
