import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DxDataGridComponent,
  DxValidationGroupComponent,
} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import * as moment from 'moment';
import { IndividualConfig } from 'ngx-toastr';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import {
  AppErrorHandlerService,
  DplApiService,
  LocalizationService,
  NotificationService,
} from '../../../core';
import {
  API_BASE_URL,
  BusinessHours,
  DayOfWeek,
  LoadingLocation,
} from '../../../core/services/dpl-api-services';
import { getWeekdaySort } from '../../../shared/utils';
import {
  LoadingLocationBusinessHoursBulkAddDialogComponent,
  LoadingLocationBusinessHoursBulkAddDialogData,
  LoadingLocationBusinessHoursBulkAddDialogResult,
} from '../loading-location-business-hours-bulk-add-dialog/loading-location-business-hours-bulk-add-dialog.component';

type ViewData = {
  dataSource: CustomStore;
  dayOfWeeksTypeData: any;
};

@Component({
  selector: 'dpl-loading-location-business-hours',
  templateUrl: './loading-location-business-hours.component.html',
  styleUrls: ['./loading-location-business-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLocationBusinessHoursComponent implements OnInit {
  @Input() loadingLocationId: number;
  @Input() dataSource: DataSource;
  baseUrl: string;
  viewData$: Observable<ViewData>;
  selectDefaultValuePopUpDayOfWeek: DayOfWeek;
  selectDefaultValuePopUpId: any;
  selectDefaultValuePopUpFromTime: any;
  selectDefaultValuePopUpToTime: any;
  gridDataSource: LoadingLocation;
  businessHourId: number;

  @ViewChild('popUpValidationGroup', { static: false })
  validationGroup: DxValidationGroupComponent;
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  saveButtonDisable: boolean = true;
  dayOfWeeksTypeData: { id; value; display }[];

  constructor(
    private dpl: DplApiService,
    private localizationService: LocalizationService,
    private appErrorHandlerService: AppErrorHandlerService,
    private notification: NotificationService,
    private dialog: MatDialog,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl;
  }

  private override = {
    closeButton: true,
    tapToDismiss: true,
  } as Partial<IndividualConfig>;

  ngOnInit(): void {
    console.log('LOADED');
    this.dayOfWeeksTypeData = this.getDayOfWeeksTypeData();
    if (!this.dataSource) {
      this.dataSource = new DataSource({
        store: new CustomStore({
          key: 'id',
          loadMode: 'raw',
          load: async () => {
            return this.dpl.businessHoursApiService
              .getAll(this.loadingLocationId)
              .pipe(
                map((businessHours) => {
                  const hours = businessHours.map((businessHour) => {
                    return {
                      ...businessHour,
                      fromTime: moment(
                        '1970-01-01:' +
                          businessHour.fromTime
                            .toString()
                            .substring(11, 19)
                      ).toDate(),
                      toTime: moment(
                        '1970-01-01:' +
                        businessHour.toTime
                            .toString()
                            .substring(11, 19)
                      ).toDate(),
                    };
                  });
                  return hours;
                }),
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                })
              )
              .toPromise();
          },
          insert: (values: BusinessHours) => {
            console.log('INSERT VALUES', values);
            return this.dpl.businessHoursApiService
              .post({
                loadingLocationId: this.loadingLocationId,
                ...values,
                ...this.getRequestTimezoneManipulation(values),
              })
              .pipe(
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                })
              )
              .toPromise();
          },
          update: (key, values: BusinessHours) => {
            return this.dpl.businessHoursApiService
              .patch(
                key,
                JSON.stringify(this.getRequestTimezoneManipulation(values))
              )
              .pipe(
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                })
              )
              .toPromise();
          },
          remove: (key) => {
            return this.dpl.businessHoursApiService
              .delete(key, {})
              .pipe(
                catchError((error) => {
                  if (error.isApiException) {
                    throw this.appErrorHandlerService.convertGridError(error);
                  }
                  throw error;
                }),
                map(() => {
                  return;
                })
              )
              .toPromise();
          },
        }),
      });
    }
  }

  getRequestTimezoneManipulation(businessHour: BusinessHours) {
    console.log('before', businessHour);
    const after = {
      ...businessHour,
      fromTime: businessHour.fromTime
        ? moment(businessHour.fromTime)
            .add('minutes', moment(businessHour.fromTime).utcOffset())
            .toDate()
        : undefined,
      toTime: businessHour.toTime
        ? moment(businessHour.toTime)
            .add('minutes', moment(businessHour.toTime).utcOffset())
            .toDate()
        : undefined,
    };
    console.log('after', after);
    return after;
  }

  getDayOfWeekSortValues(value: BusinessHours) {
    return getWeekdaySort(value?.dayOfWeek);
  }

  getDayOfWeeksTypeData() {
    const r = Object.keys(DayOfWeek).map((value, id) => {
      value = DayOfWeek[value as any];
      const display = this.localizationService.getTranslation(
        'DayOfWeek',
        value
      );
      return { id, value, display };
    });
    return r;
  }

  customValidationCallbackBusinessHours = (params) => {
    if (params.data.fromTime && params.data.toTime) {
      const fromTime = moment(params.data.fromTime, 'YYYY-MM-DD HH:mm:ss');
      const toTime = moment(params.data.toTime, 'YYYY-MM-DD HH:mm:ss');
      if (fromTime.format('HH:mm:ss') < toTime.format('HH:mm:ss')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  onBulkAdd() {
    const dialog = this.dialog.open<
      LoadingLocationBusinessHoursBulkAddDialogComponent,
      LoadingLocationBusinessHoursBulkAddDialogData,
      LoadingLocationBusinessHoursBulkAddDialogResult
    >(LoadingLocationBusinessHoursBulkAddDialogComponent, {
      data: {},
      width: '800px',
      // height: '800px',
    });

    dialog
      .afterClosed()
      .pipe(
        first(),
        switchMap((result) => {
          if (!result) return of(null);
          this.grid.instance.beginCustomLoading(
            'Öffnungszeit-/en werden erstellt'
          );
          const requests$: Observable<BusinessHours>[] = [];

          for (const dayOfWeek of result.daysOfWeek) {
            //single server calls for each workday
            requests$.push(
              from(
                this.grid.instance.getDataSource().store().insert({
                  dayOfWeek,
                  fromTime: result.fromTime, //time conversation handled by store
                  toTime: result.toTime,
                })
              )
            );
          }

          return forkJoin(requests$).pipe(
            switchMap(() =>
              of(from(this.grid.instance.getDataSource().reload()))
            )
          );
        }),
        tap(() => {
          this.grid.instance.endCustomLoading();
        })
      )
      .subscribe();
  }
}
