import { Injectable } from '@angular/core';
import { QueryEntity, combineQueries, EntityUIQuery } from '@datorama/akita';
import { AccountsStore, AccountsState } from './accounts.store';
import { BalancesQuery } from './balances.query';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { IAccount } from './account.model';
import { Observable, of } from 'rxjs';
import { Setting } from '../../core/services/dpl-api-services';

@Injectable({ providedIn: 'root' })
export class AccountsQuery extends QueryEntity<AccountsState> {
  accounts$ = this.selectAccounts();
  activeAccount$ = this.selectActiveAccount();
  selectorExpanded$ = this.select((state) => state.ui.selectorExpanded);
  accountOverviewType$ = this.select((state) => state.ui.accountOverviewType);


  constructor(
    protected store: AccountsStore,
    private balancesQuery: BalancesQuery
  ) {
    super(store);
  }

  private selectAccounts() {
    return this.selectAll().pipe(publishReplay(1), refCount());
  }

  private selectActiveAccount() {
    return this.selectActive().pipe(publishReplay(1), refCount());
  }
}
