import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagingSystemComponent } from './messaging-system.component';
import { TypeListComponent } from './components/type-list/type-list.component';
import { ChannelListComponent } from './components/channel-list/channel-list.component';
import { MessageInputComponent } from './components/message-input/message-input.component';
import { DevExtremeModule } from 'devextreme-angular';
import { ChannelListHeaderComponent } from './components/channel-list-header/channel-list-header.component';
import { MessagingSystemUserListComponent } from './components/messaging-system-user-list/messaging-system-user-list.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MessagingSystemDialogComponent } from './components/messaging-system-dialog/messaging-system-dialog.component';
import { ShowAttachmentDialogComponent } from './components/show-attachment-dialog/show-attachment-dialog.component';
import { MessageComponent } from './components/message/message.component';
import { SharedModule } from '../shared';
import { MessagingDataModule } from '../messaging-data/messaging-data.module';
import { ChannelComponent } from './components/channel/channel.component';
import { ChatChannelStatePipe } from './pipes/chat-channel-state.pipe';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { ChangeChannelStateDialogComponent } from './components/change-channel-state-dialog/change-channel-state-dialog.component';
import { ResponsiveMemberChangeDialogComponent } from './components/responsive-member-change-dialog/responsive-member-change-dialog.component';
import { ChatChannelTypePipe } from './pipes/chat-channel-type.pipe';
import { MessageListComponent } from './components/message-list/message-list.component';
import { ShowUploadedFilesComponent } from './components/show-uploaded-files/show-uploaded-files.component';
import { MembersDialogComponent } from './components/members-dialog/members-dialog.component';
import { TypeListHeaderComponent } from './components/type-list-header/type-list-header.component';
import { MessagingSystemButtonComponent } from './components/messaging-system-button/messaging-system-button.component';
import { ShowOnLoginAnnouncementsDialogComponent } from './components/show-on-login-announcements-dialog/show-on-login-announcements-dialog.component';
import { AnnouncementMessageTemplateComponent } from './components/announcement-message-template/announcement-message-template.component';
@NgModule({
  declarations: [
    MessagingSystemComponent,
    TypeListComponent,
    ChannelListComponent,
    MessageInputComponent,
    ChannelListHeaderComponent,
    MessagingSystemUserListComponent,
    FileUploadDialogComponent,
    MessagingSystemDialogComponent,
    ShowAttachmentDialogComponent,
    MessageComponent,
    ChannelComponent,
    ChatChannelStatePipe,
    ChatChannelTypePipe,
    DialogHeaderComponent,
    ChangeChannelStateDialogComponent,
    ResponsiveMemberChangeDialogComponent,
    MessageListComponent,
    ShowUploadedFilesComponent,
    MembersDialogComponent,
    TypeListHeaderComponent,
    MessagingSystemButtonComponent,
    ShowOnLoginAnnouncementsDialogComponent,
    AnnouncementMessageTemplateComponent,
  ],
  imports: [
    CommonModule,
    DevExtremeModule,
    DragDropModule,
    MessagingDataModule,
    SharedModule,
  ],
  exports: [
    MessagingSystemButtonComponent,
    AnnouncementMessageTemplateComponent,
    MessagingSystemDialogComponent,
    ShowOnLoginAnnouncementsDialogComponent,
  ],
  providers: [ChatChannelStatePipe, ChatChannelTypePipe],
})
export class MessagingSystemModule { }
