import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { combineLatest, iif, interval, of } from 'rxjs';
import { delay, first, map, startWith, switchMap, tap } from 'rxjs/operators';

import {
  delayCreation,
  LoadingService,
} from '../../../../../../libs/dpl-lib/src';
import { DplApiService, voucherTypeToDocumentTypeId } from '../../core';
import {
  Address,
  BalanceTransfer,
  BalanceTransferDirection,
  EmployeeNoteType,
  LoadCarrierReceiptState,
  LoadCarrierReceiptType,
  LoadingLocationOwnershipType,
  Order,
  OrderProposal,
  OrderStatus,
  OrderTransportType,
  OrderType,
  ResourceAction,
  UserRole,
  Voucher,
  VoucherExport,
  VoucherStatus,
  VoucherType,
} from '../../core/services/dpl-api-services';
import { PermissionsService } from '../../core/services/permissions.service';
import { UserService } from '../../user/services/user.service';
import {
  ConfirmActionDialogComponent,
  ConfirmActionDialogData,
  ConfirmActionDialogResult,
} from '../components';
import { SendEmailDialogComponent } from '../components/send-email-dialog/send-email-dialog.component';
import { DocumentsService } from './documents.service';
import { OnBehalfOfService } from './on-behalf-of.service';
import { PrintContext, PrintService } from './print.service';
import { VoucherService } from '../../voucher/services/voucher.service';
import { ICustomer } from '../../customers/state/customer.model';
import { ICustomerDivision } from '../../customers/state/customer-division.model';
import { ILoadingLocation } from '../../loading-locations/state/loading-location.model';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private userService: UserService,
    private onBehalfOfService: OnBehalfOfService,
    private dpl: DplApiService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private documentsService: DocumentsService,
    private printService: PrintService,
    private voucherService: VoucherService
  ) {}

  canEditOrder(
    divisionId: number,
    activeDivision: number,
    status: OrderStatus
  ) {
    // return text, icon?, order?
    return this.permissionsService
      .hasPermissionOnDivision(ResourceAction.UpdateOrder)
      .pipe(
        first(),
        map((hasPermission) => {
          if (!hasPermission) {
            return false;
          }
          if (divisionId !== activeDivision) {
            return false;
          }
          switch (status) {
            case OrderStatus.Pending:
            case OrderStatus.InCoordination:
            case OrderStatus.InExamination:
              return true;
            default:
              return false;
          }
        })
      );
  }

  editOrder(
    orderId: number,
    type: OrderType,
    transportType: OrderTransportType
  ) {
    switch (type) {
      case OrderType.Supply:
        this.router.navigate(['availabilities'], {
          queryParams: { orderId },
        });
        break;
      case OrderType.Demand:
        if (transportType === OrderTransportType.Self) {
          this.router.navigate(['offers/request'], {
            queryParams: { orderId },
          });
        } else {
          this.router.navigate(['needs'], {
            queryParams: { orderId },
          });
        }
        break;

      default:
        break;
    }
  }

  canCancelOrder(status: OrderStatus) {
    return this.userService.getIsDplEmployee().pipe(
      map((isEmployee) => {
        switch (status) {
          case OrderStatus.Pending:
          case OrderStatus.InCoordination:
          case OrderStatus.InExamination:
            return true;
          case OrderStatus.TransportPlanned:
          case OrderStatus.Fulfilled:
          case OrderStatus.Cancelled:
            return false;
          case OrderStatus.CancellationRequested:
            return isEmployee ? true : false;
        }
      })
    );
  }

  cancelOrder(orderId: number) {
    return this.userService.getIsDplEmployee().pipe(
      switchMap((isEmployee) => {
        const employeeCancel$ = this.onBehalfOfService
          .openOnBehalfofDialog(EmployeeNoteType.Cancellation)
          .pipe(
            switchMap((executionResult) => {
              if (!executionResult) {
                return of(null as Order);
              }
              return this.dpl.orders
                .cancel(orderId, {
                  dplNote: executionResult,
                })
                .pipe(this.loadingService.showLoadingWhile());
            })
          );
        const userCancel$ = delayCreation(() =>
          this.dialog
            .open<
              ConfirmActionDialogComponent,
              ConfirmActionDialogData,
              ConfirmActionDialogResult
            >(ConfirmActionDialogComponent, {
              data: {
                title: $localize`:OrderServiceCancelDialogTitle|Dialog Title for Cancel Storno@@OrderServiceCancelDialogTitle:Stornieren`, //ToDo adjust tile "Stornieren"|"Stornierung anfragen"
                context: 'cancel',
              },
              disableClose: true,
              autoFocus: false,
            })
            .afterClosed()
            .pipe(
              switchMap((result) => {
                if (!result || !result.confirmed) {
                  return of(null as Order);
                }
                return this.dpl.orders
                  .cancel(orderId, {
                    note: result.note,
                  })
                  .pipe(this.loadingService.showLoadingWhile());
              })
            )
        );
        return iif(() => isEmployee, employeeCancel$, userCancel$).pipe(
          delay(200)
        );
      })
    );
  }

  canCreateReceipt(
    locationOwnershipType: LoadingLocationOwnershipType,
    transportType: OrderTransportType,
    status: OrderStatus
  ) {
    // console.log(status, transportType, locationOwnershipType)
    if (
      locationOwnershipType === LoadingLocationOwnershipType.Self &&
      transportType != OrderTransportType.Self &&
      status === OrderStatus.TransportPlanned
    ) {
      return of(true);
    }
    return of(false);
  }

  createReceipt(digitalCode: string, type: OrderType, depotContext: boolean) {
    const getAction: (orderType: OrderType) => LoadCarrierReceiptType = (
      orderType: OrderType
    ) => {
      switch (orderType) {
        case OrderType.Supply:
          return LoadCarrierReceiptType.Pickup;
        case OrderType.Demand:
          return LoadCarrierReceiptType.Delivery;
        default:
          throw new Error(
            $localize`:@@UnkownOrderType:Unkown orderType` + `: ${orderType}`
          );
      }
    };

    if (depotContext) {
      this.router.navigate(['/receipt/create', digitalCode], {
        queryParams: {
          action: getAction(type),
          context: 'depot',
        },
      });
    } else {
      this.router.navigate(['/receipt/create', digitalCode], {
        queryParams: {
          action: getAction(type),
        },
      });
    }
  }

  canViewReceipt(
    locationOwnershipType: LoadingLocationOwnershipType,
    status: OrderStatus,
    refLmsAvail2DeliId?: number
  ) {
    if (
      locationOwnershipType === LoadingLocationOwnershipType.Self &&
      refLmsAvail2DeliId &&
      status === OrderStatus.Fulfilled
    ) {
      return of(true);
    }
    return of(false);
  }

  viewDocument(type: OrderType, refLmsAvail2DeliId?: number) {
    return this.documentsService.printByAvailToDeliId(
      refLmsAvail2DeliId,
      type,
      false
    );
  }

  canPrintTransportVoucher(
    transportType: OrderTransportType,
    status: OrderStatus
  ) {
    if (
      transportType === OrderTransportType.Self &&
      status === OrderStatus.TransportPlanned
    ) {
      return this.permissionsService.hasPermissionOnDivision(
        ResourceAction.PrintTransportVoucher
      );
    }
    return of(false);
  }

  canSendEmailTransportVoucher(
    transportType: OrderTransportType,
    status: OrderStatus
  ) {
    if (
      transportType === OrderTransportType.Self &&
      status === OrderStatus.TransportPlanned
    ) {
      return this.permissionsService.hasPermissionOnDivision(
        ResourceAction.SendEmailTransportVoucher
      );
    }
    return of(false);
  }

  printTransportVoucher(
    digitalCode: string,
    divisionId: number,
    localeId: string
  ) {
    return this.dpl.transportVouchersService
      .print(divisionId, digitalCode, {
        printDateTimeOffset: new Date().getTimezoneOffset(),
        localeId,
      })
      .pipe(
        this.loadingService.showLoadingWhile(),
        switchMap((transportVoucher) => {
          return this.printService.printUrl(
            transportVoucher.downloadLink,
            false,
            PrintContext.TransportVoucher,
            transportVoucher
          );
        })
      );
  }

  sendEmailTransportVoucher(orderId: number, digitalCode: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      orderId: orderId,
      digitalCode: digitalCode,
    };

    const dialog = this.dialog.open(SendEmailDialogComponent, dialogConfig);

    return dialog.afterClosed().pipe(
      first(),
      tap((response) => {
        console.log('Dialog Rückgabe', response);
      })
    );
  }

  canOffersNoAction(
    status: OrderStatus,
    proposals: OrderProposal[],
    numberOfOpenProposals: number
  ) {
    if (
      status != OrderStatus.Cancelled &&
      proposals?.length > 0 &&
      numberOfOpenProposals === 0 &&
      proposals?.every((x) => x.status !== 'Accepted')
    ) {
      return of(true);
    }
    return of(false);
  }

  canOffersOpen(
    status: OrderStatus,
    proposals: OrderProposal[],
    numberOfOpenProposals: number
  ) {
    if (
      status != OrderStatus.Cancelled &&
      proposals?.length > 0 &&
      numberOfOpenProposals > 0
    ) {
      return of(true);
    }
    return of(false);
  }

  canOffersAccepted(
    status: OrderStatus,
    proposals: OrderProposal[],
    numberOfOpenProposals: number
  ) {
    if (
      status != OrderStatus.Cancelled &&
      proposals?.length > 0 &&
      numberOfOpenProposals === 0 &&
      proposals?.some((x) => x.status === 'Accepted')
    ) {
      return of(true);
    }
    return of(false);
  }

  canReceiptEdit(
    receiptStatus: LoadCarrierReceiptState,
    receiptCreatedAt: Date,
    documentStateId: number
  ) {
    return this.canReceiptCancel(
      receiptStatus,
      receiptCreatedAt,
      documentStateId
    );
  }

  canReceiptCancel(
    receiptStatus: LoadCarrierReceiptState,
    receiptCreatedAt: Date,
    documentStateId: number
  ) {
    if (documentStateId === 255) {
      return of(false);
    }
    return this.userService.getIsDplEmployee().pipe(
      switchMap((isEmployee) => {
        if (isEmployee && receiptStatus != LoadCarrierReceiptState.Canceled) {
          return of(true);
        } else if (receiptStatus != LoadCarrierReceiptState.Issued) {
          return of(false);
        }

        return this.permissionsService
          .hasPermissionOnDivision(ResourceAction.CancelLoadCarrierReceipt)
          .pipe(
            switchMap((hasPermission) => {
              if (!hasPermission) {
                return of(false);
              }
              return interval(1000).pipe(
                startWith(0),
                map((x) => {
                  return moment(receiptCreatedAt)
                    .add(30, 'minutes')
                    .isAfter(moment(), 'minutes');
                })
              );
            })
          );
      })
    );
  }

  canSortingChangeEnable(
    receiptStatus: LoadCarrierReceiptState,
    sortingRequired: boolean,
    sortingCompleted: boolean,
    receiptType: LoadCarrierReceiptType
  ) {
    if (
      receiptStatus === LoadCarrierReceiptState.Issued &&
      !sortingRequired &&
      !sortingCompleted &&
      receiptType === LoadCarrierReceiptType.Delivery
    ) {
      return this.userService.getIsDplEmployee();
    }
    return of(false);
  }

  canSortingChangeDisable(
    receiptStatus: LoadCarrierReceiptState,
    sortingRequired: boolean,
    sortingCompleted: boolean,
    receiptType: LoadCarrierReceiptType
  ) {
    if (
      receiptStatus === LoadCarrierReceiptState.Issued &&
      sortingRequired &&
      !sortingCompleted &&
      receiptType === LoadCarrierReceiptType.Delivery
    ) {
      return this.userService.getIsDplEmployee();
    }
    return of(false);
  }

  canSortingCreate(
    receiptStatus: LoadCarrierReceiptState,
    sortingRequired: boolean,
    sortingCompleted: boolean
  ) {
    if (
      receiptStatus === LoadCarrierReceiptState.Issued &&
      sortingRequired &&
      !sortingCompleted
    ) {
      return this.permissionsService.hasPermissionOnDivision(
        ResourceAction.CreateSorting
      );
    }
    return of(false);
  }

  canVoucherCancel(
    voucher: VoucherExport,
    customer: ICustomer<number>,
    division: ICustomerDivision<ILoadingLocation<Address>>
  ) {
    const isEmployee$ = this.userService.getIsDplEmployee();
    const userRole$ = this.userService.getUserRole();
    return combineLatest([isEmployee$, userRole$]).pipe(
      map(([isEmployee, userRole]) => {
        let disabled = true;

        const timeSpan = moment(moment()).diff(
          voucher.issuedDate,
          'hours',
          true
        );

        // employee handling
        if (
          (voucher.statusEnum === VoucherStatus.Issued ||
            voucher.statusEnum === VoucherStatus.Submitted) &&
          isEmployee
        ) {
          disabled = false;
        }
        //check voucher is issued by active division
        else if (
          (voucher.statusEnum === VoucherStatus.Issued ||
            voucher.statusEnum === VoucherStatus.Submitted) &&
          division.id === voucher.divisionId
        ) {
          if (
            voucher.typeEnum === VoucherType.Original &&
            timeSpan <=
              this.getCancellationTimeSpan(
                customer,
                voucherTypeToDocumentTypeId(voucher.typeEnum),
                24
              )
          ) {
            disabled = false;
          }
          if (
            voucher.typeEnum === VoucherType.Digital &&
            timeSpan <=
              this.getCancellationTimeSpan(
                customer,
                voucherTypeToDocumentTypeId(voucher.typeEnum),
                1
              )
          ) {
            disabled = false;
          }
        } else if (
          voucher.typeEnum === VoucherType.Direct &&
          voucher.statusEnum === VoucherStatus.Accounted
        ) {
          //Direktbuchung
          if (
            timeSpan <=
            this.getCancellationTimeSpan(
              customer,
              // use documentSettings from original
              voucherTypeToDocumentTypeId(VoucherType.Original),
              24
            )
          ) {
            disabled = false;
          }
        }
        return (
          !disabled &&
          (isEmployee ||
            userRole === UserRole.Retailer ||
            userRole === UserRole.PointOfSale)
        );
      })
    );
  }

  private getCancellationTimeSpan(
    customer: ICustomer<number>,
    documentTypeId: number,
    defaultCancellationTimeSpan: number
  ): number {
    return (
      customer.documentSettings.find(
        (c) => c.cancellationTimeSpan > 0 && c.documentTypeId == documentTypeId
      )?.cancellationTimeSpan ?? defaultCancellationTimeSpan
    );
  }

  voucherCancel(voucher: VoucherExport) {
    return this.voucherService.cancel(voucher, 'grid').pipe(delay(200));
  }

  canTransferAccept(transfer: BalanceTransfer) {
    return of(
      transfer.status === 'Pending' &&
        transfer.direction === BalanceTransferDirection.Destination
    );
  }

  canTransferReject(transfer: BalanceTransfer) {
    return of(
      transfer.status === 'Pending' &&
        transfer.direction === BalanceTransferDirection.Destination
    );
  }

  canTransferCancel(transfer: BalanceTransfer) {
    return of(
      transfer.status === 'Pending' &&
        transfer.direction === BalanceTransferDirection.Source
    );
  }
}
