import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {
  ErrorHandler,
  Inject,
  InjectionToken,
  LOCALE_ID,
  NgModule,
  TRANSLATIONS,
  TRANSLATIONS_FORMAT,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { DplLibModule } from '@dpl/dpl-lib';
import {
  AppInsightsConfig,
  AppInsightsService,
  ApplicationInsightsModule,
} from '@markpieszak/ng-application-insights';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { loadMessages, locale } from 'devextreme/localization';
import * as deMessages from 'devextreme/localization/messages/de.json';
import * as enMessages from 'devextreme/localization/messages/en.json';
import * as plMessages from 'devextreme/localization/messages/en.json';
import * as frMessages from 'devextreme/localization/messages/fr.json';
import * as moment from 'moment';
import {
  DefaultLocaleConfig,
  LOCALE_CONFIG,
  NgxDaterangepickerMd,
} from 'ngx-daterangepicker-material';
import { ToastrModule } from 'ngx-toastr';

import { APP_CONFIG, DplLiveConfiguration } from '../../config';
import { environment } from '../../environments/environment';
import { AuthGuard } from './auth.guard';
import {
  MSALGuardConfigProvider,
  MSALInstanceProvider,
  MSALInterceptorConfigProvider,
} from './msal.factory';
import {
  AppErrorHandlerService,
  LoggingService,
  NotificationService,
  TimezoneOffsetHeaderInterceptor,
} from './services';
import { ApplicationInsightsErrorHandlerService } from './services/application-insights-error-handler.service';
import { AuthenticationService } from './services/authentication.service';
import { OrderManagementApiService } from './services/backend-api.service';
import { DevExtremeLocalizationService } from './services/dev-extreme-localization.service';
import { LocalizationService } from './services/localization.service';
import { SessionService } from './services/session.service';
import { getLanguageFromLocale } from './utils';

//import localizationEnGb from 'moment/locale/en-gb';

//import { AppErrorHandlerService } from './services/app-error-handler.service';
declare const require; // this is neccessary to dynamically load translations

const DEV_EXTREME_LOCALIZATION_MESSAGES = new InjectionToken<{
  [key: string]: string;
}>('DEV_EXTREME_LOCALIZATION_MESSAGES');

// change for local debugging
const definedLanguage = 'de';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DplLibModule,
    HttpClientModule,
    MsalModule,
    NgxDaterangepickerMd.forRoot(),
    // Funktioniert so nicht
    // NgxDaterangepickerMd.forRoot(
    //   {
    //     daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    //     monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //     firstDay: 1,
    //   }
    // ),
    // NgxDaterangepickerMd.forRoot({
    //   daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    //   monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //   firstDay: 1,
    // }),
    ToastrModule.forRoot({
      timeOut: 0,
      extendedTimeOut: 0,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    // only load when in production
    environment.production ? ApplicationInsightsModule : [],
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    DplLibModule,
    NgxDaterangepickerMd,
    MsalModule,
  ],
  providers: [
    {
      provide: LOCALE_CONFIG, // ngx-daterangepicker-material config ist teilweise noch auf Deutsch
      useFactory: (locale: string) => {
        const localeConfig = Object.assign({}, DefaultLocaleConfig);

        const momentLocal = moment.localeData(locale);

        localeConfig.daysOfWeek = momentLocal.weekdaysMin();
        localeConfig.monthNames = momentLocal.monthsShort();
        localeConfig.firstDay = momentLocal.firstDayOfWeek();
        //localeConfig.format= 'MM/DD/YYYY'; // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'

        return localeConfig;
      },
      deps: [LOCALE_ID],
    },
    {
      provide: AppInsightsConfig,
      useFactory: (config: DplLiveConfiguration) => config.applicationInsights,
      deps: [APP_CONFIG],
    },
    // this provider injects auth information into any request made via HttpClient
    MSALInstanceProvider,
    MSALGuardConfigProvider,
    MSALInterceptorConfigProvider,
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneOffsetHeaderInterceptor,
      multi: true,
    },
    MsalInterceptor,

    // only load when in production
    environment.production
      ? [
          AppInsightsService,
          // send all errors to application insights when in production
          {
            provide: ErrorHandler,
            useClass: environment.production
              ? ApplicationInsightsErrorHandlerService
              : ErrorHandler,
          },
        ]
      : [],

    // Global App Error Handler -> for details/api/other notifications an logging
    { provide: ErrorHandler, useClass: AppErrorHandlerService },
    // start - localization
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
    environment.production
      ? []
      : { provide: LOCALE_ID, useValue: definedLanguage },
    { provide: MAT_DATE_LOCALE, useExisting: LOCALE_ID },
    {
      provide: TRANSLATIONS,
      useFactory: (locale: string) => {
        let language = getLanguageFromLocale(locale || definedLanguage); // default to english if no locale provided

        // always use german language in development
        if (!environment.production) {
          language = definedLanguage;
          const localeData = language == 'de' ? localeDe : localeEn;
          registerLocaleData(localeData, language);
        }

        return require('raw-loader!../../locale/messages.' + language + '.xlf')
          .default;
      },
      deps: [LOCALE_ID],
    },
    {
      provide: DEV_EXTREME_LOCALIZATION_MESSAGES,
      useFactory: (locale: string) => {
        let language = getLanguageFromLocale(locale || definedLanguage); // default to english if no locale provided

        // always use german language in development
        if (!environment.production) {
          language = definedLanguage;
          const localeData = language == 'de' ? localeDe : localeEn;
          registerLocaleData(localeData, language);
        }

        switch (language) {
          case 'de':
            return (deMessages as any).default;
          case 'en':
            return (enMessages as any).default;
          case 'fr':
            return (frMessages as any).default;
          case 'pl':
            return (plMessages as any).default;
          default:
            throw new Error(
              $localize`:@@ArgumentOutOfRange:Argument out of range` +
                `: ${language}`
            );
        }
      },
      deps: [LOCALE_ID],
    },
    I18n,
    LocalizationService,
    DevExtremeLocalizationService,
    LoggingService,
    NotificationService,
    AppErrorHandlerService,
    // end- localization
  ],
})
export class CoreModule {
  constructor(
    @Inject(DEV_EXTREME_LOCALIZATION_MESSAGES) messages: {},
    @Inject(LOCALE_ID) localeString: string
  ) {
    const language = getLanguageFromLocale(localeString || definedLanguage);

    console.debug('CoreModule.constructor', { localeString, language });

    // Define global language ->
    // ->devextreme
    loadMessages(messages);
    locale(language);

    //#region "->moment"

    // TODO Muss bei Zeiten überarbeitet werden
    // CoreModule wird kontinuierlich instanziiert. Das ist für globale konfigurationen der falsche ort.
    // Auch das die LocalData händisch zugewiesen werden kann sicherlich besser gelöst werden
    // Habe aber für den Moment keine bessere Idee.

    // ->moment
    if (localeString == 'de') {
      moment.updateLocale(localeString, {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
    } else if (localeString == 'en') {
      // en->en-US existieren nicht als LocaleSpecification da sie bereits per default geladen sind
      // daher nur update des Standard Wochenanfangs da Teile des Codes fix auf Montag ausgelegt sind.
      moment.updateLocale('en', {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      } as any);
    } else {
      moment.updateLocale(localeString, {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      } as any);
    }
    //#endregion "->moment"
  }
}
