import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from '../../../user/services/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompleteSingleProcessUploadFileInfoType, CreateMultiProcessUploadPresetsResponseProcessType, UploadsApiService } from '../../../core/services/dpl-api-services';
import { AzureGatewayService } from '../../../shared/components/single-upload-form/azure-gateway.service';
import { UploadedFilesQuery } from '../../../process-confirmation/state/uploaded-files.query';
import { AttachmentDto, ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';
import { ProcessesQuery } from '../../../process-multi/state/processes.query';
import { SupportingDocumentsQuery } from '../../../process-multi/state/supporting-documents.query';
import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { ProcessesStore } from '../../../process-multi/state/processes.store';
import { SupportingDocumentsStore } from '../../../process-multi/state/supporting-documents.store';
import _ from 'lodash';
import { IUser } from '../../../user/state/user.model';
import { CustomersService } from '../../../customers/services/customers.service';
import { UploadedFile, UploadedFilesStore } from '../../../process-confirmation/state/uploaded-files.store';


export type FileUploadDialogData = {
  channel: ChannelDto;
  uploadedFiles: UploadedFile[];
};

type ViewData = {
  uploadProcessCode: string;
  filesUploaded: UploadedFile[];
  user: IUser<number, number>;
  canSubmit: boolean
};
@Component({
  selector: 'dpl-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
  providers: [
    AzureGatewayService,
    ProcessesQuery,
    ProcessesStore,
  ],
})
export class FileUploadDialogComponent implements OnInit {
  viewData$: Observable<ViewData>;
  panelOpenState = true;
  completeSingleProcessUploadFileInfoType = CompleteSingleProcessUploadFileInfoType;
  activeCustomerId: number = null;

  constructor(
    private uploadsApiService: UploadsApiService,
    private userService: UserService,
    private customersService: CustomersService,
    private uploadedFilesQuery: UploadedFilesQuery,
    private uploadedFilesStore: UploadedFilesStore,
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: FileUploadDialogData
  ) { }

  ngOnInit(): void {

    const uploadProcessCode$ = this.uploadsApiService
      .createUploadProcessDirect({})
      .pipe(
        map((uploadProcess) => {
          console.log(uploadProcess)
          return uploadProcess.uploadProcessCode;
        })
      );

    const filesUploaded$ =
      this.uploadedFilesQuery.selectAll().pipe(
        map((processes) => {
          return processes
        })
      );

    const canSubmit$ =
      this.uploadedFilesQuery.selectAll().pipe(
        map((processes) => {
          //check submit conditions
          return 4 > processes.length && processes.length > 0
        })
      );

    const user$ = this.userService.getCurrentUser();

    const activeCustomer$ = this.customersService.getActiveCustomer();

    this.viewData$ = combineLatest([
      uploadProcessCode$,
      filesUploaded$,
      user$,
      activeCustomer$,
      canSubmit$
    ]).pipe(
      map(
        ([
          uploadProcessCode,
          filesUploaded,
          user,
          activeCustomer,
          canSubmit
        ]) => {
          return {
            uploadProcessCode,
            filesUploaded,
            user,
            canSubmit
          };
        }
      )
    );
  }

  onClearUploadStore(): void {
    this.uploadedFilesStore.set({})
  }

  onSave(uploadProcessCode): void {
    this.dialogRef.close();
  }

  uploadCompleted(e) {
    console.log(e)
  }

  uploadStarted(e) {
    console.log(e)
  }

  removeItem(item: UploadedFile) {
    const index = this.dialogData.uploadedFiles.findIndex(object => object.fileId === item.fileId);
    if (index > -1) {
      this.dialogData.uploadedFiles.splice(index, 1);
    }
  }


}
