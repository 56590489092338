import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService, LogType } from './logging.service';

import { NotificationService, NotificationType } from './notification.service';
import {
  ApiException,
  DplProblemDetails,
  ProblemDetails,
  StateItem,
} from './dpl-api-services';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import * as _ from 'lodash';
import { LocalizationService } from './localization.service';
import { AuthenticationService } from './authentication.service';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { localizeUrl } from '../../../utils';

export class WrappedError extends Error {
  /**
   * Safe cast to <T>
   * @param response
   */
  public static jsonCastSafe<T>(response: any): T | null {
    try {
      return JSON.parse(response) as T;
    } catch (e) {
      // response lässt sich nicht parsen
      return null;
    }
  }

  constructor(
    private error: Error | string,
    private problemDetails?: ProblemDetails | null
  ) {
    super(
      $localize`:DefaultWrappedErrorMessage|This is a wrapped error, more details can be found in the error property @@DefaultWrappedErrorMessag: This is a wrapped error, more details can be found in the error property`
    );
    if (typeof error === 'string') {
      this._error = new Error(error);
    } else {
      this._error = error;
    }

    this._problemDetails = problemDetails;
  }

  get errorType(): ErrorType {
    if (WrappedError.isDplProblemDetails(this.ProblemDetails)) {
      return 'dplProblemDetails';
    }
    if (this.Error instanceof ApiException) {
      return 'api';
    } else return 'undefined';
  }

  private readonly _error: any;

  get Error(): Error {
    return this._error;
  }

  private readonly _problemDetails: ProblemDetails;

  get ProblemDetails(): ProblemDetails {
    return this._problemDetails;
  }

  public static isDplProblemDetails(
    dplProblemDetails: DplProblemDetails
  ): boolean {
    return (
      _.isObject(dplProblemDetails) &&
      (_.isArray(dplProblemDetails.ruleStates) ||
        _.isArray(dplProblemDetails.serviceStates))
    );
  }

  public static parse(error: Error | string): WrappedError {
    // ErrorText is not wrapped inside Error
    if (typeof error === 'string') {
      return new WrappedError(error);
    }
    // Error is HttpErrorResponse - Concurrent Api request
    else if (error instanceof HttpErrorResponse) {
      return new WrappedError(error);
    }
    // Error is ApiException -  Comes from the Api
    else if (ApiException.isApiException(error)) {
      if (error.status === 400 || error.status === 500) {
        return new WrappedError(error, error.result as DplProblemDetails);
      } else return new WrappedError(error, error.result as ProblemDetails);
    }

    // If none of a defined Error occured
    return new WrappedError(error);
  }
}

export type ErrorType = 'dplProblemDetails' | 'api' | 'undefined';

class DefaultSettings {
  width = 300;
  shading = true;
}

@Injectable()
export class AppErrorHandlerService extends ErrorHandler {
  // Style override Default Values
  private override = {
    closeButton: true,
    tapToDismiss: true,
  } as Partial<IndividualConfig>;

  constructor(
    private logger: LoggingService,
    private notification: NotificationService,
    private injector: Injector,
    private router: Router,
    private authService: AuthenticationService,
    private localizationService: LocalizationService
  ) {
    super();
  }

  public defaultSettings = new DefaultSettings();

  handleError(error: Error): void {
    const parsedError = WrappedError.parse(error);

    switch (parsedError.errorType) {
      case 'dplProblemDetails':
        return this.handleErrorDplProblemDetails(parsedError);
      case 'api':
        return this.handleErrorApi(parsedError.Error as ApiException); // From ApiException without details
      case 'undefined':
        return this.handleErrorUndefined(parsedError.Error); // Default handling, just console message
      default:
        return super.handleError(parsedError);
    }
  }

  private handleErrorDplProblemDetails(error: WrappedError): void {
    // Argument error
    if (error == null) {
      throw new ReferenceError(
        $localize`:Errormessage@@NotAnError:Not an error`
      );
    }

    const dplProblemDetails = error.ProblemDetails as DplProblemDetails;

    // Do nothing if there are no Details or ruleStates
    if (!WrappedError.isDplProblemDetails(dplProblemDetails)) {
      return;
    }

    // Log message
    this.logger.message(
      LogType.error,
      error.message,
      error.message,
      dplProblemDetails
    );

    // Get All RuleStates
    const items: StateItem[] = [];
    if (dplProblemDetails.ruleStates) {
      dplProblemDetails.ruleStates.forEach((ruleState) =>
        items.push(...ruleState)
      );
    }
    if (dplProblemDetails.serviceStates) {
      dplProblemDetails.serviceStates.forEach((serviceState) =>
        items.push(...serviceState)
      );
    }

    // Show Message for each RuleState
    items.forEach((item) => {
      // i18n macht probleme in bezug auf das extrahieren wenn value zur laufzeit bekanntgemacht wird
      //const translateMessage = $localize`:StatusMeaning|Descr@@${item.messageId}:Server`

      const s = this.injector.get(LocalizationService);
      const translateMessage = s.getTranslationById(item.messageId);
      const type = item.type.toLowerCase() as NotificationType;

      this.notification.show(type, translateMessage, '', this.override);
    });
  }

  private handleErrorApi(error: ApiException) {
    if (!ApiException.isApiException(error)) {
      throw new Error('Not an ApiException');
    }

    this.logger.error('Api Error', error);

    const title = $localize`:ApiError|@@ApiError:Server Api Fehler`;

    const problemDetails = error.result as ProblemDetails;

    switch (error.status) {
      case 400: {
        const message = $localize`:BadRequest|BadRequest@@BadRequest:Die Anfrage-Nachricht war fehlerhaft aufgebaut.`;

        this.notification.showError(message, title, this.override);
        break;
      }
      case 401: {
        const message = $localize`:UnauthorizedError|@@UnauthorizedError:Die Anfrage kann nicht ohne gültige Authentifizierung gestellt werden`;

        this.notification.showError(message, title, this.override);
        break;
      }
      case 403: {
        const message = $localize`:ForbiddenError|ForbiddenError@@ForbiddenError:Die Anfrage wurde aufgrund fehlender Autorisierung durch den Kunden, z.B. weil der authentifizierte Benutzer nicht autorisiert ist oder eine als HTTPS konfigurierte URL nur mit HTTP aufgerufen wurde.`;

        this.notification.showError(message, title, this.override);

        break;
      }
      case 404: {
        const message = $localize`:NotFoundError|NotFoundError@@NotFoundError:Die angeforderte Ressource wurde nicht gefunden.`;

        this.notification.showError(message, title, this.override);

        break;
      }
      case 405: {
        const message = $localize`:MethodNotAllowedError|MethodNotAllowedError@@MethodNotAllowedError:Die Anforderung kann nur mit anderen HTTP-Methoden erfolgen.`;

        this.notification.showError(message, title, this.override);
        break;
      }
      case 408: {
        const message = $localize`:@@RequestTimeoutError:Innerhalb der vom Server erlaubten Zeitspanne wurde keine vollstaendige Anfrage des Clients empfangen.`;

        this.notification.showError(message, title, this.override);
        break;
      }

      case 500:
      default: {
        const message = $localize`:UnexpectedServerError|@@UnexpectedServerError:Ein unerwarteter Serverfehler ist aufgetreten.`;

        this.notification.showError(message, title, this.override);
        break;
      }
    }
  }

  private handleErrorUndefined(error: Error) {
    if (error == null) {
      throw new ReferenceError('Error cannot be null');
    }
    if (error.message.includes('Error: Loading chunk')) {
      this.notification.showError(
        $localize`:LoadingChunkErrorMessage|Start of the Error Message whit a Link to the Supportpage@@LoadingChunkErrorMessage:Eine neue Version ist jetzt vorhanden. Bitte laden sie die Seite neu oder rufen Sie die` +
          ` <a mat-raised-button
          color="primary"
              href="` +
          localizeUrl('/support') +
          `">` +
          $localize`:ErrorGoToSupportPageLinkText|Clickable Link in ErrorMessage Move to Support Page@@ErrorGoToSupportPageLinkText:Supportseite` +
          `</a>` +
          $localize`:ErrorSupportPageMessageEnd|text after the link in the error message@@ErrorSupportPageMessageEnd: auf.`,
        $localize`:ErrorSupportPageTitleNewVersion|Title of Version Error@@ErrorSupportPageTitleNewVersion:Neue Version vorhanden`,
        { ...this.override, enableHtml: true, tapToDismiss: false }
      );
      return;
    }
    const title = $localize`:UndefinedError|UndefinedError@@UndefinedError:Fehlermeldung`;
    const chatErrorTitle = $localize`:ChatError|ChatError@@ChatError:Chat Fehler`;
    let chatErrorMessage = $localize`:ChatErrorMessage|ChatErrorMessage@@ChatErrorMessage:Der Chat ist zurzeit nicht verfügbar.`;
    const defaultChatError = $localize`:ChatDefaultErrorMessage|ChatDefaultErrorMessage@@ChatDefaultErrorMessage:Der Chat ist zurzeit nicht verfügbar.`;

    if (
      error.message.includes('(in promise)') &&
      error.message.includes('dpl-chat-plugin')
    ) {
      // error chat update (throw on delete channels or new user)
      // chatErrorMessage = $localize`:ChatUnknownErrorMessage|ChatUnknownErrorMessage@@ChatUnknownErrorMessage:Ein nicht behandelter Chatfehler ist aufgetreten`;
      if (
        error.message.includes('(in promise)') &&
        error.message.includes('data') &&
        error.message.includes('dpl-chat-plugin') &&
        error.message.includes('Generator.forEach')
      ) {
        chatErrorMessage = $localize`:ChatUpdateErrorMessage|ChatUpdateErrorMessage@@ChatUpdateErrorMessage:Der Chat hat eventuell ein Änderung nicht mit bekommen. Vielleicht sollten Sie die Seite mit leerem Cache erneut laden`;
      }
      // hack for a chat error - maybe blocked cookies or urls - not tested
      if (
        error.message.includes('(in promise)') &&
        error.message.includes('me') &&
        error.message.includes('of undefined') &&
        error.message.includes('dpl-chat-plugin') &&
        error.message.includes('Object.onInvoke')
      ) {
        chatErrorMessage = $localize`:ChatMeErrorMessage|ChatMeErrorMessage@@ChatMeErrorMessage:Der Chat kann Sie nicht kennen lernen. Bitte überprüfen Sie Ihre Firewall- und Browsereinstellungen`;
      }
      // hack for a chat error - no api key
      if (
        error.message.includes('(in promise)') &&
        error.message.includes('Connect failed with error') &&
        error.message.includes('api_key') &&
        error.message.includes('not provided') &&
        error.message.includes('dpl-chat-plugin') &&
        error.message.includes('401')
      ) {
        chatErrorMessage = $localize`:ChatNoKeyErrorMessage|ChatNoKeyErrorMessage@@ChatNoKeyErrorMessage:Es wurde kein API-Key für den Chat gesetzt`;
      }
      // hack for a chat error - wrong api key
      if (
        error.message.includes('(in promise)') &&
        error.message.includes('Connect failed with error') &&
        error.message.includes('api_key') &&
        error.message.includes('not found') &&
        error.message.includes('dpl-chat-plugin') &&
        error.message.includes('401')
      ) {
        chatErrorMessage = $localize`:ChatWrongKeyErrorMessage|ChatWrongKeyErrorMessage@@ChatWrongKeyErrorMessage:Der API-Key für den Chat wurde nicht gefunden`;
      }

      this.logger.error('Chat Error', chatErrorMessage, error);
      // comment out after customer feedback
      // this.notification.showError(
      //   defaultChatError,
      //   chatErrorTitle,
      //   this.override
      // );
    }
    // hack for 'AADSTS50058%3a+A+silent+sign-in+request+was+sent+but+no'
    else if (error && (error as any).errorCode === 'login_required') {
      this.logger.error('login_required', error);
      localStorage.clear();
      window.location.reload();
    } else {
      this.logger.error('undefined error', error);
      this.notification.showError(error.message, title, this.override);
    }
  }

  buildRuleStateDetailMessage(wrappedError: WrappedError) {
    let details = '';

    if (wrappedError.ProblemDetails) {
      details = wrappedError.ProblemDetails.title;
      if (WrappedError.isDplProblemDetails(wrappedError.ProblemDetails)) {
        const dplProblemDetails =
          wrappedError.ProblemDetails as DplProblemDetails;

        // Get All RuleStates
        const items: StateItem[] = [];
        if (dplProblemDetails.ruleStates) {
          dplProblemDetails.ruleStates.forEach((ruleState) =>
            items.push(...ruleState)
          );
        }
        if (dplProblemDetails.serviceStates) {
          dplProblemDetails.serviceStates.forEach((serviceState) =>
            items.push(...serviceState)
          );
        }

        //const localizationService = this.injector.get(LocalizationService);

        // Erklärung:
        // const nested = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];
        // let flat = nested.reduce((acc, it) => [...acc, ...it], []);
        // flat is [1, 2, 3, 4, 5, 6, 7, 8, 9]
        const reducer = (accumulator, currentValue) => [
          ...accumulator,
          ...currentValue,
        ];

        const ruleStatesFlat:StateItem[] =[];
        if (dplProblemDetails.ruleStates) {
          ruleStatesFlat.push(...dplProblemDetails.ruleStates.reduce(reducer));
        } else {
          const generalErrorItem:StateItem = {messageId: 'GeneralError'}
          ruleStatesFlat.push(...dplProblemDetails.serviceStates.reduce(reducer));
        }


        // function onlyUnique(value, index, self) {
        //   return self.indexOf(value) === index;
        // }
        // const unique = ruleStatesFlat.filter(onlyUnique)

        //ES6 has a native object Set to store unique values.
        let unique = [...new Set(ruleStatesFlat)];

        details = unique
          .map((item) =>
            this.localizationService.getTranslationById(item.messageId)
          )
          .join('; ');
      }
      return details;
    }
  }

  showNotify(error: ApiException, options: any = null): void {
    const wrappedError = WrappedError.parse(error);
    const details = this.buildRuleStateDetailMessage(wrappedError);
    const message = wrappedError.Error.message + ' ' + details;

    const opt = {
      message,
      width: this.defaultSettings.width,
      shading: this.defaultSettings.shading,
      ...options,
    };
    notify(opt, 'error');
  }

  onAjaxError(args) {
    if (args.xhr.response) {
      const response = args?.xhr?.response;
      const obj = WrappedError.jsonCastSafe<DplProblemDetails>(response);
      const isDplProblemDetails = WrappedError.isDplProblemDetails(obj);
      if (isDplProblemDetails) {
        const { serviceStates, ruleStates } = obj;
        const localizationService = this.injector.get(LocalizationService);
        if (serviceStates && serviceStates.length > 0 && !ruleStates) {
          const { messageId } = serviceStates[0][0];
          const messageHead = localizationService.getTranslationById(messageId);
          args.error = obj?.errors?.errors?.join(', ') ?? messageHead;
        }
        if (ruleStates && ruleStates.length > 0 && !serviceStates) {
          const { messageId } = ruleStates[0][0];
          const messageHead = localizationService.getTranslationById(messageId);
          args.error = obj?.errors?.errors?.join(', ') ?? messageHead;
        }
        if (
          ruleStates &&
          ruleStates.length > 0 &&
          serviceStates &&
          serviceStates.length > 0
        ) {
          //TODO: What happend if both States are set?
          //TODO: Need something here
          this.logger.log('RuleAndServiceStateError', response);
          //Hack show RuleStates first Error Message
          const { messageId } = ruleStates[0][0];
          const messageHead = localizationService.getTranslationById(messageId);
          args.error = obj?.errors?.errors?.join(', ') ?? messageHead;
        }
      } else {
        //TODO: xhr.response error isn't a isDplProblemDetails
        // args.error = response; //old
        //overwrite args.error to show a undefined error /maybe response must be logged in a logFile
        this.logger.log('OnAjaxXHRUnlistedError', response);
        args.error = $localize`:DefaultGridOnAjaxXhrErrorMessage|undefined error  xhr noIsProblemDetails AspNetStore @@DefaultGridOnAjaxXhrErrorMessage:Ein undefinierter Fehler ist aufgetretten.`;
      }
    } else {
      //TODO: Log Errors that are not in XHR
      this.logger.log('OnAjaxNoXHRUnlistedError', args);
      args.error = $localize`:DefaultGridOnAjaxNotXhrErrorMessage|undefined error  noXhr AspnetStore@@DefaultGridOnAjaxNotXhrErrorMessage:Ein undefinierter Netzwerkfehler ist aufgetretten.`;
    }

    //Example response
    //response: "{"serviceStates":[[{"type":"Error","messageId":"Error|Common|GeneralError"}]],"errors":{},"title":"One or more validation errors occurred.","status":500,"extensions":{}}"
  }

  convertError(error: ApiException): Error {
    const wrappedError = WrappedError.parse(error);
    const details = this.buildRuleStateDetailMessage(wrappedError);
    const message = wrappedError.Error.message + ' ' + details;

    return new Error(message);
  }

  convertGridError(error: ApiException): Error {
    //ErrorHandling for Custom Grid DataSources
    //Problems on buttons in a grid in a grid
    //Todo: what happend if a ServiceState fails
    const wrappedError = WrappedError.parse(error);
    const details = this.buildRuleStateDetailMessage(wrappedError);
    // console.log('details', details);
    if (!!details) {
      if (wrappedError.Error.message) {
        this.logger.log(wrappedError.Error.message, details);
        // return new Error(wrappedError.Error.message + ' ' + details);
      }
      //Todo: Maybe Hidde Message ID
      const message = details.replace(';', '');
      return new Error(message);
    }
    //TODO: log undefined errors
    this.logger.log('ConvertGridErrorUnlistedError', wrappedError);
    return new Error(
      $localize`:DefaultGridErrorMessage|undefined Grid Error out of a Promise@@DefaultGridErrorMessage:Ein undefinierter Fehler ist aufgetretten.`
    );
  }
}
