import { Injectable } from '@angular/core';
import { LoadCarrierQualityType, PostingAccountCondition } from '@app/api/dpl';
import { DplApiService } from '@app/core';
import { combineQueries } from '@datorama/akita';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadCarriersService } from '../../master-data/load-carriers/services/load-carriers.service';
import { TransferLimitationQuery } from '../../transfer/state/transfer-limitations.query';
import { UserService } from '../../user/services/user.service';
import { IAccount } from '../state/account.model';
import { AccountsQuery } from '../state/accounts.query';
import { AccountsStore } from '../state/accounts.store';
import { BalancesQuery } from '../state/balances.query';
import { BalancesStore } from '../state/balances.store';
import {
  AccountOverviewType,
  ActiveAccountLoadCarrierType,
  ILoadCarrierOrderOption,
  IOrderOptions,
  ISelfServiceData,
} from './accounts.service.types';

@Injectable({ providedIn: 'root' })
export class AccountsService {
  constructor(
    private accountsStore: AccountsStore,
    private accountsQuery: AccountsQuery,
    private balancesStore: BalancesStore,
    private balancesQuery: BalancesQuery,
    private loadCarrierService: LoadCarriersService,
    private transferLimitationsQuery: TransferLimitationQuery,
    private dpl: DplApiService,
    private user: UserService
  ) {}

  getAllAccounts() {
    return this.accountsQuery.accounts$;
  }

  getAllDepotAccounts() {
    return this.accountsQuery.selectAll({
      filterBy: (x) =>
        x.refLtmsAccountNumber?.includes('DEP') ||
        x.refLtmsAccountNumber?.includes('LAG') ||
        x.refLtmsAccountNumber?.includes('LDL'),
    });
  }

  setActiveAccount(id?: number) {
    const currentActiveId = this.accountsQuery.getActiveId();

    if (currentActiveId === id) {
      return;
    }

    this.accountsStore.setActive(id);

    // select first loadCarrierType from Account
    const active = this.accountsQuery.getActive();
    if (active && active.loadCarrierTypeIds.length > 0) {
      this.loadCarrierService.setActiveLoadCarrierType(
        active.loadCarrierTypeIds[0]
      );
    } else {
      this.loadCarrierService.setActiveLoadCarrierType(null);
    }
  }

  getActiveAccount() {
    return this.accountsQuery.activeAccount$;
  }

  setActiveBalance(generatedId?: string) {
    this.balancesStore.setActive(generatedId);
  }

  getActiveBalance() {
    return this.balancesQuery.activeBalance$;
  }

  getBalanceForLoadCarrierType(loadCarrierTypeId: number) {
    // HACK: Implement get balances from server
    return [];
  }

  getBalanceForLoadCarrier(loadCarrierId: number) {
    // HACK: Implement get balances from server
    return [];
  }

  getLoadCarrierOrderOptionsForAccounts(accounts: IAccount[]) {
    return this.loadCarrierService.getLoadCarriers().pipe(
      map((loadCarriers) => {
        const loadCarriersDict = _(loadCarriers)
          .keyBy((i) => i.id)
          .value();

        const supply = _(accounts)
          .map((account) => {
            const conditionsDict = _(account.dropoffConditions)
              .keyBy((i) => i.loadCarrierId)
              .value();
            return loadCarriers
              .filter((i) => !!conditionsDict[i.id])
              .map((loadCarrier) => {
                return {
                  loadCarrierId: loadCarrier.id,
                  account,
                  orderCondition: conditionsDict[loadCarrier.id],
                };
              });
          })
          .flatten()
          .groupBy((i) => i.loadCarrierId)
          .map((g, loadCarrierId) => {
            return {
              loadCarrier: loadCarriersDict[loadCarrierId],
              accountInfos: g.map(({ account, orderCondition }) => ({
                account,
                min: orderCondition.minQuantity
                  ? orderCondition.minQuantity
                  : 0,
                max: orderCondition.maxQuantity
                  ? orderCondition.maxQuantity
                  : 100000, // no max condition, for infinity 100000
              })),
            } as ILoadCarrierOrderOption;
          })
          .value();

        const demand = _(accounts)
          .map((account) => {
            let conditionsDict: _.Dictionary<PostingAccountCondition>;
            if (account.pickupSmallQuantityConditions?.length > 0) {
              //map loadCarrierIds check if no small exists take from pickupConditions

              const allConditions = _.uniq([
                ...account.pickupConditions.map((x) => x.loadCarrierId),
                ...account.pickupSmallQuantityConditions.map(
                  (x) => x.loadCarrierId
                ),
              ]);

              const specialPickupConditions: PostingAccountCondition[] = [];
              allConditions.forEach((loadCarrierId) => {
                const special = account.pickupConditions?.find(
                  (x) => x.loadCarrierId === loadCarrierId
                );
                if (special) {
                  specialPickupConditions.push(special);
                } else {
                  const normal = account.pickupSmallQuantityConditions?.find(
                    (x) => x.loadCarrierId === loadCarrierId
                  );
                  if (normal) {
                    specialPickupConditions.push(normal);
                  }
                }
              });

              conditionsDict = _(specialPickupConditions)
                .keyBy((i) => i.loadCarrierId)
                .value();
            } else {
              conditionsDict = _(account.pickupConditions)
                .keyBy((i) => i.loadCarrierId)
                .value();
            }
            
            return loadCarriers
              .filter((i) => !!conditionsDict[i.id])
              .map((loadCarrier) => {
                return {
                  loadCarrierId: loadCarrier.id,
                  account,
                  orderCondition: conditionsDict[loadCarrier.id],
                };
              });
          })
          .flatten()
          .groupBy((i) => i.loadCarrierId)
          .map((g, loadCarrierId) => {
            return {
              loadCarrier: loadCarriersDict[loadCarrierId],
              accountInfos: g
                .map(({ account, orderCondition }) => ({
                  account,
                  min: orderCondition.minQuantity
                    ? orderCondition.minQuantity
                    : 0,
                  max:
                    // Math.min(
                    orderCondition.maxQuantity
                      ? orderCondition.maxQuantity
                      : 100000, // no max condition, for infinity 100000
                  // balance.availableBalance
                  // ),
                }))
                // handle edge case when balance drives down max belwo min
                .filter((i) => i.min < i.max),
            } as ILoadCarrierOrderOption;
          })
          // handles edge case where balances causes initially orderable load carrier to become non orderable
          .filter((i) => i.accountInfos.length > 0)
          .value();

        return {
          supply,
          demand,
        } as IOrderOptions;
      })
    );
  }

  getAvailableLoadCarrierTypes() {
    return this.accountsQuery.activeAccount$.pipe(
      map((account) => {
        return account ? account.loadCarrierTypeIds : [];
      })
    );
  }

  getActiveAccountAndLoadCarrierType(): Observable<ActiveAccountLoadCarrierType> {
    return combineQueries([
      this.accountsQuery.activeAccount$,
      this.loadCarrierService.getActiveLoadCarrierType(),
      this.getAccountOverviewType(), // remove if not needed
    ]).pipe(
      map(([account, activeLoadCarrierType, accountOverviewType]) => {
        if (account && activeLoadCarrierType) {
          const active: ActiveAccountLoadCarrierType = {
            accountId: account.id,
            loadCarrierTypeId: activeLoadCarrierType.id,
          };
          return active;
        }
        return null;
      })
    );
  }

  getSelectorExpanded() {
    return this.accountsQuery.selectorExpanded$;
  }

  setSelectorExpanded(expanded: boolean) {
    return this.accountsStore.updateSelectorExpanded(expanded);
  }

  getAccountOverviewType() {
    return this.accountsQuery.accountOverviewType$;
  }

  setAccountOverviewType(accountOverviewType: AccountOverviewType) {
    return this.accountsStore.updateAccountOverviewType(accountOverviewType);
  }
}
