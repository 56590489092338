import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormRemapComponent } from 'ngx-sub-form';
import { Directive } from '@angular/core';

export interface WrappedControlForm<TControl> {
  innerControl: TControl;
}

@Directive()
export abstract class NgxSingleFieldSubFormComponent<
  ControlInterface,
  FormInterface = ControlInterface
> extends NgxSubFormRemapComponent<
  ControlInterface,
  WrappedControlForm<FormInterface>
> {
  constructor() {
    super();
  }

  protected getFormControl(): AbstractControl {
    return new UntypedFormControl(null);
  }

  protected getFormControls(): Controls<WrappedControlForm<FormInterface>> {
    return {
      innerControl: this.getFormControl(),
    };
  }

  protected transformToFormGroup(
    innerControl: ControlInterface
  ): WrappedControlForm<FormInterface> {
    return innerControl
      ? { innerControl: (innerControl as unknown) as FormInterface }
      : ((this.getDefaultValues() || {}) as WrappedControlForm<FormInterface>);
  }

  protected transformFromFormGroup(
    formValue: WrappedControlForm<FormInterface>
  ): ControlInterface {
    return formValue
      ? ((formValue.innerControl as unknown) as ControlInterface)
      : null;
  }
}
