<ng-container [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxLayout="column" *ngIf="formSync$ | async" fxFlex="1 1 auto">
      <ng-container *ngIf="presetSet">
        <mat-form-field>
          <mat-label
            i18n="@@PartnerAdministrationPartnerCompanyNameColumnCaption"
            >Firmenname</mat-label
          >
          <input matInput type="text" [value]="presetDisplayText" disabled />
        </mat-form-field>
      </ng-container>

      <dx-drop-down-box
        displayExpr="companyName"
        [placeholder]="placeholder"
        [showClearButton]="true"
        [dataSource]="partnersDs"
        [formControl]="formGroup.controls.partner"
        [value]="dropDownValue"
        [(opened)]="dropDownOpened"
        [dropDownOptions]="dropDownOptions"
        [deferRendering]="false"
        *ngIf="!presetSet"
        style="padding: 7px 0 1px"
      >
        <div *dxTemplate="let data of 'content'">
          <div fxLayout="column" style="height:100%;">
            <div
              fxLayout="row"
              fxLayoutGap="20px"
              fxLayoutAlign="space-between"
            >
              <div>
                <mat-form-field>
                  <mat-label i18n="Label Suchtext@@SearchText"
                    >Suchtext</mat-label
                  >

                  <input
                    matInput
                    type="text"
                    [formControl]="formGroup.controls.searchValue"
                    id="searchValue"
                  />
                  <button
                    matSuffix
                    mat-icon-button
                    i18n-aria-label="@@Clear"
                    aria-label="Clear"
                    (click)="clearSearchValue()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <button
                [disabled]="disableBtn$ | async"
                mat-raised-button
                (click)="onCreateNewPartner()"
                style="height: 40px"
                i18n="
                  PartnerCreateDialogTitle|Neuen Partner
                  erstellen@@PartnerCreateDialogTitle"
              >
                Neuer Partner
              </button>
            </div>
            <!-- [remoteOperations]="{
              paging: true,
              filtering:true,
              grouping:true
            }" -->
            <dx-scroll-view
    [scrollByContent]="true">

            <dx-data-grid
              [dataSource]="partnersDs"
              [hoverStateEnabled]="true"
              height="100%"
              *ngIf="
                (searchValue$ | async)?.length >=
                  config.app.ui.autoComplete.minLength;
                else minCharacters
              "
              (onSelectionChanged)="onSelectionPartnerDataGridChanged($event)"
              id="partnergrid"
              (onEditorPreparing)="onEditorPreparing($event)"
              (onRowPrepared)="onRowPrepared($event)"
              [showBorders]="true"
              [showColumnLines]="true"
            >
              <dxo-scrolling showScrollbar="always"></dxo-scrolling>
              <dxo-remote-operations
                [filtering]="true"
                [paging]="false"
                [sorting]="true"
                [summary]="false"
                [grouping]="true"
                [groupPaging]="false"
              >
              </dxo-remote-operations>
              <dxi-column
                dataField="companyName"
                caption="Firmenname"
                i18n-caption="
                  @@PartnerAdministrationPartnerCompanyNameColumnCaption"
                [width]="200"
                cellTemplate="cellTemplate"
                [allowSorting]="false"
              ></dxi-column>
              <dxi-column
                dataField="addressString"
                caption="Adresse"
                i18n-caption="@@PartnerLookUpAddressColumnCaption"
                cellTemplate="cellTemplate"
                [allowSorting]="false"
              ></dxi-column>

              <dxi-column
                dataField="directoryName"
                [groupIndex]="0"
                groupCellTemplate="group"
                [allowSorting]="false"
              >
                <div *dxTemplate="let data of 'group'">
                  <div
                    [class.header-pooling]="isPoolingHeader(data)"
                    [class.heafer-procurement]="isProcurementHeader(data)"
                  >
                    <span *ngIf="data.groupContinuedMessage as groupText"
                      >({{ groupText }})&nbsp;</span
                    >
                    <span i18n="Directory|Label Verzeichnis@@Directory"
                      >Verzeichnis</span
                    >:
                    {{ data.displayValue }}
                    <span *ngIf="data.groupContinuesMessage as groupText"
                      >&nbsp;({{ groupText }})</span
                    >
                  </div>
                </div>
              </dxi-column>
              <ng-container *dxTemplate="let data of 'cellTemplate'">
                <span
                  [ngStyle]="{
                    'font-weight':
                      data.data?.partnerDirectoryType == directoryType.Pooling
                        ? 'bold'
                        : 'normal'
                  }"
                  >{{ data.value }}</span
                >
              </ng-container>
              <dxo-selection mode="single"></dxo-selection>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager
                [showPageSizeSelector]="false"
                [allowedPageSizes]="[5, 10, 20]"
                [showInfo]="false"
                [showNavigationButtons]="true"
              ></dxo-pager>
              <dxo-grouping [autoExpandAll]="true"> </dxo-grouping>
            </dx-data-grid>
          </dx-scroll-view>
          </div>
        </div>
      </dx-drop-down-box>
      <ng-template #minCharacters>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          style="color: red; font-weight: bold"
        >
          <span
            i18n="
              PartnerLookupEmptySearchMin1|Mindestens@@PartnerLookupEmptySearchMin1"
            >Mindestens
          </span>
          &nbsp;{{ config.app.ui.autoComplete.minLength }}&nbsp;
          <span
            i18n="
              PartnerLookupEmptySearchMin2|Zeichen zur
              Suche@@PartnerLookupEmptySearchMin2"
          >
            Zeichen zur Suche eingeben</span
          >
        </div>
      </ng-template>
      <mat-error
        *ngIf="
          formGroup.controls.partner.touched &&
          formGroup.controls.partner.errors?.required
        "
      >
        <span i18n="@@PartnerLookupNoneSelectValidationMessage"
          >Bitte wählen Sie einen Wert aus der Liste oder legen Sie einen neuen
          Eintrag an</span
        >
      </mat-error>
    </div>
    <mat-form-field fxFlex="0 0 110px">
      <input
        matInput
        [formControl]="formGroup.controls.type"
        placeholder="Typ"
        i18n-placeholder="Type|Label Typ|@@Type"
      />
    </mat-form-field>
  </div>
  <app-address-form [subForm]="formGroup.controls.address"></app-address-form>
</ng-container>
