<div fxLayout="column" fxLayoutGap="20px" style="margin: 20px">
  <h3 i18n="@@SupportPageHeader">Willkommen auf der Support Seite</h3>

  <div>
    <h3 i18n="@@FireFox">FireFox</h3>
    <img src="assets/images/LivepoolingSupport.jpg" />
  </div>
  <div>
    <h3 i18n="@@GoogleChorme">Google Chrome</h3>
    <h3 i18n="@@GoogleChormeDescription">
      Chrome öffnen -> Taste "F12" drücken -> rechte Maustaste auf das markierte
      Symbol -> „cache leeren und vollständig aktualsieren“
    </h3>
    <img src="assets\images\RefreshCacheForChrome.jpg" />
  </div>
  <!-- Other Browsers -->
  <!-- More Selfhelp or Own Sites -->
</div>
