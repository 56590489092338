<ng-container *ngIf="viewData$ | async as data; else noTickets">
  <ng-container
    *ngIf="data.ticket.channelType !== 1000; else showAnnouncementLayout"
  >
    <ng-container *ngIf="hasActiveTicket$ | async; else noChannel">
      <div>
        <div mat-dailog-title class="message-list">
          <div class="message-list-header">
            <ng-container *ngIf="!showOwnIcons">
              <div class="channel-header">
                <div class="channel-header-area">
                  <div class="channel-name">
                    <ng-container
                      *ngIf="data.ticket.channelType !== 1000; else noLinkShown"
                    >
                      <a
                        (click)="openInNewTab(data.ticket)"
                        class="channel-link"
                      >
                        {{ data.ticket.channelTitle }}</a
                      ></ng-container
                    >
                    <ng-template #noLinkShown>{{
                      data.ticket.channelTitle
                    }}</ng-template>
                  </div>
                  <div class="channel-header-area-buttons">
                    <div class="language-select-div">
                      <mat-select
                        class="channellanguageselector"
                        [formControl]="selectedLanguageControl"
                        style="max-width: 100px; height: 100%"
                        title="Sortierung der Vorgänge"
                        i18n-title="@@MessagingSystemChannelListSortingHint"
                      >
                        <mat-select-trigger>
                          <div style="color: white">
                            {{
                              languageDict[
                                selectedLanguageControl.value$ | async
                              ]?.name
                            }}
                          </div>
                        </mat-select-trigger>
                        <mat-option
                          *ngFor="let language of languages"
                          [value]="language.value"
                        >
                          {{ language.name }}
                        </mat-option>
                      </mat-select>
                    </div>
                    <div class="channel-header-controls">
                      <!-- <dx-button
                    icon="link"
                    hint="Zum Vorgang springen"
                    i18n-hint="@@ChannelNavigationIconHint"
                    class="link-icon icon"
                    (click)="openInNewTab(data.ticket)"
                  >
                  </dx-button> -->
                      <ng-container
                        *ngIf="
                          data.currentUser &&
                          data.currentUser?.role === 'DplEmployee' &&
                          data.ticket.channelType !== 1000
                        "
                      >
                        <dx-button
                          class="open-responsive-member-dialog-button icon"
                          icon="card"
                          i18n-text="@@ChannelResponsiveMemberIconHint"
                          text="Bearbeiter ändern"
                          (click)="openResponsiveMembersDialog(data.ticket)"
                        >
                        </dx-button>
                        <dx-button
                          icon="isnotblank"
                          text="Vorgang schliessen"
                          i18n-text="@@ChannelChangeStateIconHint"
                          [class]="
                            data.ticket.channelState === channelState.Open
                              ? 'red-icon icon'
                              : data.ticket.channelState ===
                                channelState.InProgress
                              ? 'yellow-icon icon'
                              : data.ticket.channelState ===
                                channelState.Enclosed
                              ? 'green-icon icon'
                              : 'grey-icon icon'
                          "
                          (click)="openChannelStateDialog(data.ticket)"
                          [disabled]="
                            data.ticket.channelState === channelState.Enclosed
                          "
                        >
                        </dx-button>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <ng-container
                  *ngIf="
                    !showChannelInfo || data.ticket.channelType === 1000;
                    else channelInfoForAll
                  "
                >
                </ng-container>
              </div>
            </ng-container>
            <!-- ChannelInfo Template -->
            <ng-template #channelInfoForAll>
              <div class="channel-info">
                <div class="channel-info-left">
                  <div class="channel-header-state">
                    {{ translateChannelState(data.ticket.channelState) }}
                  </div>
                  <!-- <div>|</div> -->
                  <div class="responsive-member-row">
                    <div
                      class="responsive-member-info-text"
                      i18n="@@ChannelHeaderResponsiveUser"
                    >
                      Bearbeiter:
                    </div>
                    <span class="responsible-user-select">{{
                      data.ticket.responsibleUser?.userName
                    }}</span>
                  </div>
                  <!-- <div>|</div> -->
                  <div class="channel-header-members">
                    <span i18n="@@ChannelHeaderMembers">Mitglieder: </span>
                    <div
                      class="members-tooltip"
                      id="membersTooltipTarget"
                      (mouseenter)="popOverVisible = true"
                      (mouseleave)="popOverVisible = false"
                    >
                      {{ data.ticket.members?.length }}
                      <dx-popover
                        target="{{ '#membersTooltipTarget' }}"
                        position="bottom"
                        [(visible)]="popOverVisible"
                      >
                        <div
                          *dxTemplate="let templateData = model; of: 'content'"
                        >
                          <div *ngFor="let member of data.ticket?.members">
                            {{ member.firstName }} {{ member.lastName }}
                          </div>
                        </div>
                      </dx-popover>
                    </div>
                  </div>
                </div>
                <div class="channel-date">
                  <span i18n="@@ChannelInfoCreationDate">Erstelldatum: </span>
                  <span class="channel-creator-timestemp">
                    {{ data.ticket.creator?.timestamp | dateEx }}</span
                  >
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="showOwnIcons">
              <div
                cdkDragHandle
                cdkDrag
                cdkDragRootElement=".cdk-overlay-pane"
                class="channel-header"
              >
                <div class="channel-header-area">
                  <div class="channel-name">
                    {{ data.ticket.channelTitle }}
                  </div>
                  <div class="channel-header-controls">
                    <div class="language-select-div">
                      <mat-select
                        class="channellanguageselector"
                        [formControl]="selectedLanguageControl"
                        style="max-width: 100px; height: 100%"
                      >
                        <mat-select-trigger>
                          <div style="color: white">
                            {{
                              languageDict[
                                selectedLanguageControl.value$ | async
                              ]?.name
                            }}
                          </div>
                        </mat-select-trigger>
                        <mat-option
                          *ngFor="let language of languages"
                          [value]="language.value"
                        >
                          {{ language.name }}
                        </mat-option>
                      </mat-select>
                    </div>
                    <ng-container
                      *ngIf="!showOwnIcons; else contentIcons"
                    ></ng-container>
                  </div>
                </div>
                <ng-container *ngIf="!showChannelInfo; else channelInfoForAll">
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ng-container
            *ngIf="data.ticket?.messages?.length > 0; else noMessages"
          >
            <div id="chat-window">
              <ng-container *ngIf="!isDialog">
                <div class="messaging-system-extra-row"></div>
              </ng-container>
              <dpl-message-list
                [messages]="data.ticket.messages"
                [height]="
                  data.ticket.channelState === channelState.Enclosed
                    ? !isDialog
                      ? messageListHight - 19 - 36
                      : messageListHight - 19
                    : !isDialog
                    ? messageListHight - 36
                    : messageListHight
                "
                [currentUser]="data.currentUser"
                (copiedMessageText)="onCopyMessage($event)"
                [selectedLanguage]="selectedLanguageControl.value$ | async"
              ></dpl-message-list>
            </div>
          </ng-container>
        </div>
        <ng-container
          *ngIf="data.ticket.channelState === channelState.Enclosed"
        >
          <div class="closed-channel-banner">
            <span i18n="@@ChannelClosedTicketInfoText"
              >Der Vorgang wurde geschlossen. Zum Reaktivieren neue Nachricht
              schreiben.
            </span>
          </div>
        </ng-container>
        <div class="message-input">
          <dpl-message-input
            [isDialog]="isDialog"
            [selectedChannel]="data.ticket"
            (changeListHight)="onListHightChange($event)"
            [copiedText]="copyText"
            [disableSendButton]="data.ticket.channelType === 1000"
          >
            <!-- TODO: Maybe add to disableSendButton  "&& data.currentUser?.role !== 'DplEmployee'" -->
          </dpl-message-input>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #showAnnouncementLayout>
    <div mat-dailog-title class="message-list">
      <div class="message-list-header">
        <!-- ChannelInfo Template -->
        <div class="channel-header">
          <div class="channel-header-area">
            <div class="channel-name">
              {{ data.ticket.channelTitle }}
            </div>
            <div class="channel-header-controls">
              <ng-container
                *ngIf="checkIfTicketIsRead(data.ticket, data.currentUser)"
              >
                <dx-button
                  icon="bell"
                  i18n-hint="
                    Dialog Schließen und nicht mehr
                    Anzeigen@@ChannelReadHintShowAnnouncementOnLogin"
                  i18n-text="
                    Dialog Als gelesen
                    markieren@@ChannelReadTextShowAnnouncementOnLogin"
                  hint="Ankündigung als gelesen markieren"
                  (click)="closeAnnoncementForNextLogin(data.ticket)"
                  text="Lesen"
                >
                </dx-button
              ></ng-container>
              <div class="language-select-div">
                <mat-select
                  class="channellanguageselector"
                  [formControl]="selectedLanguageControl"
                  style="max-width: 100px; height: 100%"
                >
                  <mat-select-trigger>
                    <div style="color: white">
                      {{
                        languageDict[selectedLanguageControl.value$ | async]
                          ?.name
                      }}
                    </div>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let language of languages"
                    [value]="language.value"
                  >
                    {{ language.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          {{ data.ticket.creator?.timestamp | dateEx }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="data.ticket.messages?.length > 0; else noMessages">
      <div id="chat-window">
        <ng-container *ngIf="!isDialog">
          <div class="messaging-system-extra-row">
            <ng-container
              *ngIf="selectedLanguageControl.value$ | async as languageValue"
            >
              <div
                *ngIf="languageValue.length > 0"
                class="lanuage-changed-info"
                i18n="@@AnnouncementLanguageChangedInfo"
              >
                Hinweis: Sie haben eine andere Sprache als Original ausgewählt.
                Die angezeigte Übersetzung wurde automatisch generiert.
              </div>
            </ng-container>
          </div>
        </ng-container>
        <dx-list
          #dxList
          [dataSource]="data.ticket.messages"
          [height]="730 + 'px'"
          [activeStateEnabled]="false"
          [showScrollbar]="'always'"
          [focusStateEnabled]="false"
          [hoverStateEnabled]="false"
        >
          <div *dxTemplate="let listData of 'item'">
            <dpl-announcement-message-template
              style="width: -webkit-fill-available"
              [message]="listData"
              [selectedLanguage]="selectedLanguageControl.value$ | async"
            >
            </dpl-announcement-message-template>
          </div>
        </dx-list>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #noMessages>
  <div
    id="chat-window"
    [class]="messageListHight > 0 ? 'show-text' : 'expanded-input'"
  >
    <span i18n="@@NoMessagesForMessageListText">
      Keine Nachrichten vorhanden
    </span>
  </div>
</ng-template>
<ng-template #noTickets>
  <div>
    <div mat-dailog-title class="message-list">
      <div class="message-list-header">
        <div class="channel-header">
          <div class="channel-header-area">
            <div class="channel-name" i18n="@@WelcomeHeaderTextForChannel">
              Willkommen im Messaging-System
            </div>
            <div class="channel-header-controls">
              <div class="language-select-div"></div>
              <ng-container
                *ngIf="!showOwnIcons; else contentIcons"
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="chat-window"
      [class]="messageListHight > 0 ? 'show-text' : 'expanded-input'"
    >
      <ng-container *ngIf="!isDialog">
        <div class="messaging-system-extra-row"></div>
      </ng-container>
      <div>
        <!-- Maybe more Infos -->
        <!-- <div><h2>Information zum Messaging-System Vorgang erstellen.</h2></div>
      <div>
        <p>
          Sie können einen Vorgang für das Messaging-System über die
          Messaging-System Icons erstellen.
        </p>
      </div> -->
      </div>
    </div>
  </div>
  <div class="message-input">
    <dpl-message-input
      [isDialog]="isDialog"
      [disableSendButton]="true"
      (changeListHight)="onListHightChange($event)"
    >
    </dpl-message-input>
  </div>
</ng-template>
<ng-template #contentIcons>
  <ng-content select="[headerIcons]" class="icon"></ng-content>
</ng-template>
<ng-template #noChannel>
  <div
    cdkDragHandle
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    class="channel-header"
    *ngIf="isDialog"
  >
    <div class="channel-header-area">
      <div class="channel-name">
        <!-- Maybe show a HeaderText -->
      </div>
      <div class="channel-header-controls">
        <div class="language-select-div"></div>
        <ng-container *ngIf="!showOwnIcons; else contentIcons"></ng-container>
      </div>
    </div>
  </div>
  <div class="no-channel-selected">
    <ng-container *ngIf="isDialog; else noChannelSelectedNotDialogInfo">
      <h4 i18n="@@NoChannelSelectedDialogText">
        Der Vorgang konnte nicht ausgewählt werden, bitte versuchen Sie es
        erneut.
      </h4>
    </ng-container>
    <ng-template #noChannelSelectedNotDialogInfo></ng-template>
    <h4 i18n="@@NoChannelSelectedText">Kein Vorgang ausgewählt</h4>
  </div>
</ng-template>
