<!-- order transport type !== self -->
<!-- loading location ownershipType == self -->
<ng-container
  *ngIf="loadingLocationOwnership === loadingLocationOwnershipType.Self"
>
  <ng-container
    *ngIf="!isSelfTransport && status === orderStatus.TransportPlanned"
  >
    <button
      i18n-title="Buttontitel Beleg erstellen@@LoadCarrierCreateReceiptButton"
      mat-icon-button
      (click)="onCreateReceipt()"
      title="Beleg erstellen"
    >
      <mat-icon>receipt</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="refLmsAvail2DeliId && status === orderStatus.Fulfilled">
    <button
      i18n-title="Buttontitel Beleg öffnen@@LoadCarrierOpenDocumentButton"
      mat-icon-button
      (click)="openDocument()"
      title="Beleg öffnen"
    >
      <mat-icon>remove_red_eye</mat-icon>
    </button>
  </ng-container>
</ng-container>
