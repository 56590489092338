import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserService } from '../user/services/user.service';
import { CustomerDivisionSelectorComponent } from './components/customer-division-selector/customer-division-selector.component';
import { CustomersRoutingModule } from './customers-routing.module';
import { CoreModule } from '../core';

@NgModule({
  imports: [CommonModule, CustomersRoutingModule,CoreModule],
  providers: [UserService],
  declarations: [CustomerDivisionSelectorComponent],
  exports: [CustomerDivisionSelectorComponent],
})
export class CustomersModule {}
