// extracted from devextreme pivot grid remote store code
//https://github.com/DevExpress/DevExtreme/blob/c6a11a3c27afde0bd2b44ef65c6f1fb4304b0490/js/ui/pivot_grid/remote_store.js

export function mergeFilters(filter1, filter2) {
  let mergedFilter;
  const notEmpty = function (filter) {
    return filter && filter.length;
  };

  if (notEmpty(filter1) && notEmpty(filter2)) {
    mergedFilter = [filter1, 'and', filter2];
  } else {
    mergedFilter = notEmpty(filter1) ? filter1 : filter2;
  }

  return mergedFilter;
}

export function createFilterExpressions(fields) {
  let filterExpressions: unknown[] = [];

  fields.forEach(function (field) {
    const fieldExpressions = createFieldFilterExpressions(field);

    if (!fieldExpressions.length) {
      return [];
    }

    if (filterExpressions.length) {
      filterExpressions.push('and');
    }

    filterExpressions.push(fieldExpressions);
  });

  if (filterExpressions.length === 1) {
    filterExpressions = filterExpressions[0] as unknown[];
  }

  return filterExpressions;
}

function getFieldFilterSelector(field) {
  let selector = field.dataField;
  let groupInterval = field.groupInterval;

  if (field.dataType === 'date' && typeof groupInterval === 'string') {
    if (groupInterval.toLowerCase() === 'quarter') {
      groupInterval = 'Month';
    }
    selector = selector + '.' + capitalizeFirstLetter(groupInterval);
  }

  return selector;
}

function getIntervalFilterExpression(
  selector,
  numericInterval,
  numericValue,
  isExcludedFilterType
) {
  const startFilterValue = [
    selector,
    isExcludedFilterType ? '<' : '>=',
    numericValue,
  ];
  const endFilterValue = [
    selector,
    isExcludedFilterType ? '>=' : '<',
    numericValue + numericInterval,
  ];

  return [
    startFilterValue,
    isExcludedFilterType ? 'or' : 'and',
    endFilterValue,
  ];
}

function getFilterExpressionForFilterValue(field, filterValue) {
  const selector = getFieldFilterSelector(field);
  const isExcludedFilterType = field.filterType === 'exclude';
  let expression = [selector, isExcludedFilterType ? '<>' : '=', filterValue];

  if (isDefined(field.groupInterval)) {
    if (
      typeof field.groupInterval === 'string' &&
      field.groupInterval.toLowerCase() === 'quarter'
    ) {
      expression = getIntervalFilterExpression(
        selector,
        3,
        (filterValue - 1) * 3 + 1,
        isExcludedFilterType
      );
    } else if (
      typeof field.groupInterval === 'number' &&
      field.dataType !== 'date'
    ) {
      expression = getIntervalFilterExpression(
        selector,
        field.groupInterval,
        filterValue,
        isExcludedFilterType
      );
    }
  }

  return expression;
}

function createFieldFilterExpressions(field, operation?) {
  const fieldFilterExpressions: unknown[] = [];

  if (field.searchValue) {
    return [field.dataField, 'contains', field.searchValue];
  }

  if (field.filterType === 'exclude') {
    operation = operation || 'and';
  } else {
    operation = operation || 'or';
  }

  field.filterValues.forEach(function (filterValue, index) {
    let currentExpression = [];

    if (Array.isArray(filterValue)) {
      const parseLevelsRecursive = field.levels && field.levels.length;

      if (parseLevelsRecursive) {
        currentExpression = createFieldFilterExpressions(
          {
            filterValues: filterValue,
            filterType: field.filterType,
            levels: field.levels,
          },
          'and'
        );
      }
    } else {
      const currentField = field.levels ? field.levels[index] : field;

      currentExpression = getFilterExpressionForFilterValue(
        currentField,
        filterValue
      );
    }

    if (!currentExpression.length) {
      return;
    }

    if (fieldFilterExpressions.length) {
      fieldFilterExpressions.push(operation);
    }

    fieldFilterExpressions.push(currentExpression);
  });

  return fieldFilterExpressions;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isDefined(object) {
  return object !== null && object !== undefined;
}

// Custom code, not extracted from devextreme
export function createFilterExpressionAnd(clause1: unknown[], clause2: unknown[]) {
  if (!clause1?.length && !clause2?.length) {
    return [];
  }

  if (!clause1.length) {
    return clause2;
  }

  if (!clause2.length) {
    return clause1;
  }

  return [clause1, 'and', clause2];
}
