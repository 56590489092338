import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  OrderStatus,
  OrderTransportType,
  OrderType,
  ResourceAction,
} from '../../../core/services/dpl-api-services';
import { Router } from '@angular/router';
import { CustomerDivisionsStore } from '../../../customers/state/customer-divisions.store';
import { LoadingLocationsStore } from '../../../loading-locations/state/loading-locations.store';

export interface EditOrderRouteParams {}
export interface EditOrderQueryParams {
  orderId: number;
}

@Component({
  selector: 'dpl-edit-order-button',
  templateUrl: './edit-order-button.component.html',
  styleUrls: ['./edit-order-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditOrderButtonComponent implements OnInit {
  @Input() orderId: number;
  @Input() status: OrderStatus;
  @Input() type: OrderType;
  @Input() transportType: OrderTransportType;

  resourceAction = ResourceAction;

  constructor(
    private router: Router,
    private loadingLocationsStore: LoadingLocationsStore
  ) {}

  ngOnInit(): void {}

  canEdit() {
    switch (this.status) {
      case OrderStatus.Pending:
      case OrderStatus.InCoordination:
        return true;

      default:
        return false;
    }
  }

  edit() {
    switch (this.type) {
      case OrderType.Supply:
        this.router.navigate(['availabilities'], {
          queryParams: { orderId: this.orderId },
        });
        break;
      case OrderType.Demand:
        if (this.transportType === OrderTransportType.Self) {
          this.router.navigate(['offers/request'], {
            queryParams: { orderId: this.orderId },
          });
        } else {
          this.router.navigate(['needs'], {
            queryParams: { orderId: this.orderId },
          });
        }
        break;

      default:
        break;
    }
  }
}
