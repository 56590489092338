import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipeEnumBase } from './translate-pipe.base';
import { LocalizationService } from '@app/core';
import { VoucherStatus } from '@app/api/dpl';

@Pipe({
  name: 'LoadCarrierReceiptState',
})
export class LoadCarrierReceiptStatusPipe extends TranslatePipeEnumBase {
  name: string = 'LoadCarrierReceiptState';

  constructor(private ls: LocalizationService) {
    super(ls);
  }
}
