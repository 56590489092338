import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@ngneat/reactive-forms';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface LoadingLocationBusinessHoursSchedulerAddExceptionDialogData {
  minTime: Date;
  dayMinTime: Date;
  maxTime: Date;
  dayMaxTime: Date;
  canAllDay: boolean;
}

export interface LoadingLocationBusinessHoursSchedulerAddExceptionDialogResult {
  allDay: boolean;
  fromTime?: Date;
  toTime?: Date;
}

@Component({
  selector: 'dpl-loading-location-business-hours-scheduler-add-exception',
  templateUrl:
    './loading-location-business-hours-scheduler-add-exception.component.html',
  styleUrls: [
    './loading-location-business-hours-scheduler-add-exception.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLocationBusinessHoursSchedulerAddExceptionComponent
  implements OnInit
{
  form: FormGroup<LoadingLocationBusinessHoursSchedulerAddExceptionDialogResult>;
  data: LoadingLocationBusinessHoursSchedulerAddExceptionDialogData;
  toTimeMin$: Observable<Date>;
  constructor(
    public dialogRef: MatDialogRef<
      LoadingLocationBusinessHoursSchedulerAddExceptionComponent,
      LoadingLocationBusinessHoursSchedulerAddExceptionDialogResult
    >,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    data: LoadingLocationBusinessHoursSchedulerAddExceptionDialogData
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.fb.group(
      {
        allDay: new FormControl({
          value: false,
          disabled: !this.data.canAllDay,
        }),
        fromTime: new FormControl(this.data.minTime),
        toTime: new FormControl(this.data.maxTime),
      },
      { validators: FromGtToValidator() }
    );
    //ToDo validator min> max
    this.toTimeMin$ = this.form.controls.fromTime.valueChanges.pipe(
      startWith(this.data.minTime),
      map((fromTime) => {
        return moment(fromTime).add(30, 'minute').toDate();
      })
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    console.log(this.form.value);
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}

export const FromGtToValidator = (): ValidatorFn => {
  return (
    control: FormGroup<LoadingLocationBusinessHoursSchedulerAddExceptionDialogResult>
  ): ValidationErrors | null => {
    if (
      control.value.fromTime &&
      control.value.toTime &&
      control.value.fromTime.getTime() < control.value.toTime.getTime()
    ) {
      return null;
    }
    return { ToTimeGtFromTime: true };
  };
};
