import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@ngneat/reactive-forms';
import notify from 'devextreme/ui/notify';
import { fromEvent, of, Subscription } from 'rxjs';
import { exhaustMap, first, switchMap } from 'rxjs/operators';

import { LoadingService } from '../../../../../../../libs/dpl-lib/src';
import { DplApiService } from '../../../core/services';
import { CustomerDivisionsService } from '../../../customers/services/customer-divisions.service';

export interface SendEmailDialogData {
  orderId: number;
  email: string;
  digitalCode: string;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class ErrorStateMatcherEx implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'dpl-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
})
export class SendEmailDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    public dialogRef: MatDialogRef<SendEmailDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: SendEmailDialogData,
    private division: CustomerDivisionsService,
    private loadingService: LoadingService,
    private dpl: DplApiService,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  @ViewChild('submitButton', { read: ElementRef, static: true })
  button: ElementRef;

  formGroup = this.fb.group({
    email: [this.data?.email, [Validators.required, Validators.email]],
  });

  submitClickSubscription: Subscription;

  public get emailControl() {
    //return this.form.controls["email"];
    return this.formGroup.get('email');
  }

  public get sendEmailDisabled() {
    return !this.emailControl.valid;
  }

  matcher = new ErrorStateMatcherEx();

  onCancel(): void {
    this.dialogRef.close();
  }

  onSendEmail(email: string) {
    console.debug('onSendEmail', { email });

    const digitalCode = this.data.digitalCode;

    return this.division.getActiveDivision().pipe(
      first(),
      switchMap((division) => {
        if (!division) {
          throw $localize`:@@ActiveDivisionNeedTobeSetCreatingVoucher:Active division needs to be set when creating voucher`;
        }
        return this.dpl.transportVouchersService
          .sendEmail(division.id, digitalCode, {
            email,
            printDateTimeOffset: new Date().getTimezoneOffset(),
            localeId: this.localeId,
          })
          .pipe(
            this.loadingService.showLoadingWhile(),
            switchMap((transportVoucher) => {
              return of(transportVoucher);
            })
          );
      })
    );
  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.submitClickSubscription = fromEvent(this.button.nativeElement, 'click')
      .pipe(
        exhaustMap((event: MouseEvent) =>
          this.onSendEmail(this.emailControl.value)
        )
      )
      .subscribe((res) => {
        console.log(res);
        this.dialogRef.close(res);

        notify(
          {
            message:
              $localize`:@@NotifyTicketDigitalCodePartOne:Auftragsticket für Digitalcode ` +
              `'${res.key.item2}'` +
              $localize`:@@NotifyTicketDigitalCodePartTwo: wird versendet an ` +
              `${res.values.email}`,
            position: {
              my: 'center',
              at: 'center',
              of: 'Window',
            },
          },
          'success',
          3000
        );
      });
  }

  ngOnDestroy() {
    this.submitClickSubscription.unsubscribe();
  }
}
