import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * The validateEmails function takes a string of emails separated by semicolons and returns true if all the
 * emails are valid. It does this by splitting the string into an array of email strings, trimming each one,
 * and then checking that every email is valid using Angular's built-in Validators.email function. If any
 * email is invalid, it will return false immediately without checking any more emails in the list. Otherwise,
 * it will return true after checking all of them (or if there are no emails at all). This function can be used as a custom validator for reactive forms in Angular 2+. See https
 *
 * @param emails: string Pass in the list of emails
 *
 * @return A boolean value
 *
 * @docauthor Trelent
 */
function validateEmails(emails: string) {
  const emailList = emails.split(/[;]+/).map(email => email.trim());
  return emailList.every(email => Validators.email(<AbstractControl>{ value: email }) === null);
}

export /**
        * The multiEmailsValidator function is a validator function that checks if the value of an input field
        * contains multiple email addresses separated by commas. If it does, then the function returns null to indicate
        * that there are no errors. Otherwise, it returns an object with a key named emails and a value of true to indicate
        * that there is an error in the input field's value. The multiEmailsValidator function uses another custom validator
        * called validateEmails which we will create next:
        *
        *
        * @return A validatorfn that takes an abstractcontrol and returns a validationerrors object or null
        *
        * @docauthor Trelent
        */
       function multiEmailsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || validateEmails(control.value)) {
      return null;
    }
    return { 'emails': true };
  };
}

export function validateEmail(e:validatorParam){
  const emailList = e.value.split(/[;]+/).map(email => email.trim());
  return emailList.every(email => Validators.email(<AbstractControl>{ value: email }) === null);
}

export type validatorParam = {
  column:Object
  data:	Object
  formItem:	Object
  rule:	Object
  validator:	Object
  value:	String
}
