import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'dpl-info-loading-direction',
  templateUrl: './info-loading-direction.component.html',
  styleUrls: ['./info-loading-direction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoLoadingDirectionComponent implements OnInit {
  popOverVisible=false;

  constructor() { }

  ngOnInit(): void {
  }

}
