import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelDto } from '../../../generated/Dpl.B2b.Contracts.Chat.Shared';

@Component({
  selector: 'dpl-messaging-system-dialog',
  templateUrl: './messaging-system-dialog.component.html',
  styleUrls: ['./messaging-system-dialog.component.scss'],
})
export class MessagingSystemDialogComponent implements OnInit {
  dialogIsOpen: boolean;

  constructor(
    public dialogRef: MatDialogRef<MessagingSystemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public channel: ChannelDto
  ) { }

  ngOnInit(): void {
    this.dialogIsOpen = true
  }

  onCancel(): void {
    this.dialogIsOpen = false
    this.dialogRef.close();
  }

}
