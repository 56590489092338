import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'dpl-info-suffix',
  templateUrl: './info-suffix.component.html',
  styleUrls: ['./info-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoSuffixComponent implements OnInit {
  @Input() fieldName: string;
  @Input() infoText:string;
  popOverVisible=false;
  

  constructor() { }

  ngOnInit(): void {
    
  }

}
