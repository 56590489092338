<ng-container *ngIf="viewData$ | async as data; else loading">
  <form [formGroup]="formGroup">
    <!--Mat Form-->
    <mat-dialog-content fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <mat-form-field fxFlex="33">
            <mat-label
              i18n="
                DisplayName|Label Anzeigename der Ladestelle
                @@LoadingLocationCreateFormDisplayName"
              >Anzeigename
            </mat-label>
            <input
              matInput
              trim
              [formControl]="formGroup?.controls?.displayName"
            />
            <mat-error
              *ngIf="formGroup?.controls?.displayName?.errors?.maxlength"
            >
              <strong i18n="MaxLength|Label Pflichtfeld@@MaxLength"
                >Die Eingabe ist zu lang</strong
              >
            </mat-error>
            <mat-error
              *ngIf="formGroup?.controls?.displayName?.errors?.required"
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="33">
            <mat-label
              i18n="
                LoadingLocationType|Label Typ der Ladestelle wählen
                @@LoadingLocationType"
              >Typ der Be-/Entladestelle
            </mat-label>
            <mat-select multiple [formControl]="formGroup?.controls?.types">
              <mat-option
                *ngFor="let loadingLocationType of data.loadingLocationTypes"
                [value]="loadingLocationType ? loadingLocationType : null"
                [disabled]="getLoadingLocationTypeDisabled(loadingLocationType)"
              >
                {{ loadingLocationType | LoadingLocationType }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="formGroup?.controls?.loadingLocationType?.errors?.required"
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="33">
            <mat-label
              i18n="
                LoadingLocationOwnershipType|Label Ladestellenverwaltung wählen
                @@LoadingLocationOwnershipType"
              >Verwaltung
            </mat-label>
            <mat-select [formControl]="formGroup?.controls?.ownershipType">
              <mat-option
                *ngFor="
                  let loadingLocationOwnershipType of data.loadingLocationOwnershipTypes
                "
                [value]="
                  loadingLocationOwnershipType
                    ? loadingLocationOwnershipType
                    : null
                "
              >
                {{
                  loadingLocationOwnershipType | LoadingLocationOwnershipType
                }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="formGroup?.controls?.ownershipType?.errors?.required"
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <mat-form-field fxFlex="100">
            <mat-label
              i18n="
                LoadingLocationType|Label Beschreibung
                @@LoadingLocationCreateFormDescription"
              >Beschreibung
            </mat-label>
            <input matInput [formControl]="formGroup?.controls?.description" />
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="50">
            <mat-label
              i18n="
                CompanyName|Label Firmenname der Ladestelle
                @@LoadingLocationCreateFormCompanyName"
              >Firmenname der Be-/Entladestelle
            </mat-label>
            <input
              matInput
              trim
              [formControl]="formGroup?.controls?.companyName"
            />
            <mat-error
              *ngIf="formGroup?.controls?.companyName?.errors?.maxlength"
            >
              <strong i18n="MaxLength|Label Pflichtfeld@@MaxLength"
                >Die Eingabe ist zu lang</strong
              >
            </mat-error>
            <mat-error
              *ngIf="formGroup?.controls?.companyName?.errors?.required"
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <mat-label
              i18n="
                Adresszusatz|Label
                Adresszusatz@@LoadingLocationCreateFormStreet2"
              >Adresszusatz
            </mat-label>
            <input
              matInput
              [formControl]="formGroup?.controls?.address?.controls?.street2"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="40">
            <mat-label
              i18n="Adresse|Label Adresse@@LoadingLocationCreateFormAddress"
              >Straße und Hausnummer
            </mat-label>
            <input
              matInput
              [formControl]="formGroup?.controls?.address?.controls?.street1"
            />
            <mat-error
              *ngIf="
                formGroup?.controls?.address?.controls?.street1?.errors
                  ?.required
              "
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="20">
            <mat-label i18n="Country|Label Land@@Country">Land </mat-label>
            <mat-select
              [formControl]="
                formGroup?.controls?.address?.controls?.countryIso2Code
              "
            >
              <mat-option
                *ngFor="let country of data.countries"
                [value]="country?.iso2Code"
              >

              {{ country.id | country : 'long' }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                formGroup?.controls?.address?.controls?.countryIso2Code?.errors
                  ?.required
              "
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="20">
            <mat-label i18n="PostalCode|Label PLZ@@PostalCode"
              >Postleitzahl
            </mat-label>
            <input
              matInput
              [formControl]="formGroup?.controls?.address?.controls?.postalCode"
            />
            <mat-error
              *ngIf="
                formGroup?.controls?.address?.controls?.postalCode?.errors
                  ?.required
              "
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="30">
            <mat-label i18n="Location|Label Ort@@Location">Ort </mat-label>
            <input
              matInput
              [formControl]="formGroup?.controls?.address?.controls?.city"
            />
            <mat-error
              *ngIf="
                formGroup?.controls?.address?.controls?.city?.errors?.required
              "
            >
              <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                >Pflichfeld</span
              >
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="30px">
        <div
          fxLayout="column"
          fxLayoutGap="10px"
          fxFlex="33"
          [formGroup]="formGroup.controls.loadingOptions"
        >
          <div fxLayout="row" fxLayoutGap="4px" style="font-weight: bold">
            <span
              i18n="
                LoadingVariantsNeutral|Label Verladevarianten
                Neutral@@LoadingVariantsNeutral"
              >Be-/Entladevarianten</span
            ><span> *</span>
          </div>
          <mat-slide-toggle
            color="primary"
            formControlName="supportsSideLoading"
            class="postCreateCheck"
          >
            <span i18n="LoadingSideways|Label Seitenbeladung@@LoadingSideways"
              >Seitenbeladung</span
            >
          </mat-slide-toggle>
          <mat-slide-toggle
            color="primary"
            formControlName="supportsRearLoading"
            class="postCreateCheck"
          >
            <span i18n="LoadingFromBehind|Label Heckbeladung@@LoadingFromBehind"
              >Heckbeladung</span
            >
          </mat-slide-toggle>

          <mat-slide-toggle
            color="primary"
            formControlName="supportsJumboVehicles"
            class="postCreateCheck"
          >
            <span
              i18n="
                LoadingJumboLoading|Label Jumbobeladung@@LoadingJumboLoading"
              >Jumbobeladung</span
            >
          </mat-slide-toggle>
          <!--Todo add a Validation Rule One must be Selected-->
        </div>
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="27">
          <div fxLayout="row" fxLayoutGap="4px" style="font-weight: bold">
            <span
              i18n-title="
                StackHightHeaderTitle|Title (in Stück EUR-Paletten zu je 14,4
                cm) @@LoadingLocationCreateFormStackHightHeaderTitle"
              title="(in Stück EUR-Paletten zu je 14,4cm Höhe)"
              i18n="
                StackHightHeader|Label Stapelhöhe Ladungsträger
                @@LoadingLocationCreateFormStackHightHeader"
              >Stapelhöhe Ladungsträger</span
            >
          </div>
          <div fxLayout="row" fxLayoutGap="30px">
            <mat-form-field fxFlex="35">
              <mat-label
                i18n="
                  StackHightMin|Label Von
                  @@LoadingLocationCreateFormStackHightMin"
                >Von
              </mat-label>
              <input
                matInput
                [formControl]="formGroup?.controls?.stackHeightMin"
              />
              <mat-error
                *ngIf="formGroup?.controls?.stackHeightMin?.errors?.required"
              >
                <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                  >Pflichfeld</span
                >
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="35">
              <mat-label
                i18n="
                  StackHightMax|Label Bis
                  @@LoadingLocationCreateFormStackHightMax"
                >Bis
              </mat-label>
              <input
                matInput
                [formControl]="formGroup?.controls?.stackHeightMax"
              />
              <mat-error
                *ngIf="formGroup?.controls?.stackHeightMax?.errors?.required"
              >
                <span i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                  >Pflichfeld</span
                >
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <div style="font-size: 12px">
              <span
                i18n="
                  HeightEURInfo|Text in Stück EUR-Paletten zu
                  je@@LoadingLocationCreateFormHeightEURInfo"
                >in Stück EUR-Paletten zu je</span
              ><br /><span
                i18n="
                  HeightInfo|Text 14,4cm
                  Höhe@@LoadingLocationCreateFormHeightInfo"
                >14,4cm Höhe</span
              >
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="40">
          <div fxLayout="row" fxLayoutGap="4px" style="font-weight: bold">
            <span i18n="@@Contact">Kontakt</span>
          </div>
          <mat-form-field>
            <mat-label>
              <span i18n="Email|Label E-Mail @@LoadingLocationCreateEmail"
                >E-Mail</span
              >
            </mat-label>
            <input matInput [formControl]="formGroup?.controls?.email" />
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
              <mat-label>
                <span i18n="Phone|Label Telefon @@LoadingLocationCreatePhone"
                  >Telefon
                </span>
              </mat-label>
              <input matInput [formControl]="formGroup?.controls?.phone" />
            </mat-form-field>

            <mat-form-field fxFlex="45">
              <mat-label>
                <span i18n="Fax|Label Telefax @@LoadingLocationCreateFax"
                  >Telefax</span
                >
              </mat-label>
              <input matInput [formControl]="formGroup?.controls?.fax" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div
        fxLayout="column"
        *ngIf="
          useCase == locationCreateUseCase.Admin ||
          (useCase == locationCreateUseCase.Customer &&
            mode == locationCreateMode.Create)
        "
      >
        <dx-tabs
          [(selectedIndex)]="tabIndex"
          (onItemClick)="onSelectTab($event)"
        >
          <dxi-item>
            <div *dxTemplate>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
              >
                <div
                  i18n="
                    AdditionalInfoLoadingLocation|Sonstige
                    Angaben@@AdditionalInfoLoadingLocation:Sonstige Angaben"
                >
                  Sonstige Angaben
                </div>
                <span
                  *ngIf="
                    this.formGroup.controls.fulfillmentPreRegistrationHours
                      .errors ||
                    this.formGroup.controls.fulfillmentPreRegistrationHours
                      .errors ||
                    this.formGroup.controls.mainContactPerson.controls.lastName
                      .errors ||
                    this.formGroup.controls.mainContactPerson.controls.email
                      .errors
                  "
                  style="color: red"
                >
                  <mat-icon>warning_amber</mat-icon></span
                >
              </div>
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
              >
                <div
                  i18n="
                    OpeningHours|Öffnungszeiten@@OpeningHours:Öffnungszeiten"
                >
                  Öffnungszeiten
                </div>
                <span
                  *ngIf="this.formGroup.controls.businessHours.errors?.required"
                  style="color: red"
                >
                  <mat-icon>warning_amber</mat-icon></span
                >
              </div>
            </div>
          </dxi-item>
        </dx-tabs>
        <ng-container [ngSwitch]="tabIndex">
          <ng-container *ngSwitchCase="0">
            <div fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex="100">
                <mat-label
                  i18n="
                    Note|Label Be-/Entladeinformationen
                    @@LoadingLocationCreateNoteHeader"
                  >Abhol-/Anlieferanweisungen
                </mat-label>
                <input
                  matInput
                  [formControl]="formGroup?.controls?.deliveryInstructions"
                />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
              <div fxLayout="column" fxFlex="25">
                <div fxLayout="row" fxLayoutGap="4px" style="font-weight: bold">
                  <span i18n="@@availabilitiesAvisTitle">Avisierung</span>
                </div>
                <div
                  style="height: 64px"
                  fxLayout="Column"
                  fxLayoutAlign="start center"
                >
                  <mat-slide-toggle
                    [formControl]="
                      formGroup?.controls?.fulfillmentPreRegistrationRequired
                    "
                    i18n="@@AvailabilitiesNotificationRequired"
                  >
                    Avis erforderlich?</mat-slide-toggle
                  >
                </div>
                <div fxLayout="row">
                  <mat-form-field fxFlex="80">
                    <mat-label
                      i18n="
                        PreRegistrationHours|Label Vorlaufzeit (Stunden) this
                        need a change
                        @@LoadingLocationCreatePreRegistrationHours"
                      >Vorlaufzeit
                    </mat-label>

                    <input
                      type="number"
                      matInput
                      [formControl]="
                        formGroup?.controls?.fulfillmentPreRegistrationHours
                      "
                    />

                    <mat-error
                      *ngIf="
                        formGroup?.controls?.fulfillmentPreRegistrationHours
                          ?.errors?.required
                      "
                    >
                      <span
                        i18n="Pflichfeld|Error Pflichfeld@@RequiredFieldError"
                        >Pflichfeld</span
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="column" fxFlex="75">
                <div fxLayout="row" fxLayoutGap="4px" style="font-weight: bold">
                  <span i18n="@@LoadingLocationFormContactHeader"
                    >Kontakt bei Be-/Entladestelle</span
                  >
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                  <mat-form-field fxFlex="50">
                    <mat-label
                      i18n="
                        Contacts|Label Kontakt bei Verladestelle (Abteilung ggf.
                        Person) @@LoadingLocationCreateContacts"
                    >
                      Abteilung ggf. Person
                    </mat-label>
                    <input
                      matInput
                      [formControl]="
                        formGroup?.controls?.mainContactPerson.controls.lastName
                      "
                    />
                  </mat-form-field>
                  <mat-form-field fxFlex="50">
                    <mat-label
                      i18n="
                        Email|Label E-Mail bei Verladestelle
                        @@LoadingLocationCreateEmail"
                      >E-Mail
                    </mat-label>
                    <input
                      matInput
                      [formControl]="
                        formGroup?.controls?.mainContactPerson.controls.email
                      "
                    />
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                  <mat-form-field fxFlex="50">
                    <mat-label
                      i18n="
                        Phone|Label Telefon bei Verladestelle
                        @@LoadingLocationCreatePhone"
                      >Telefon
                    </mat-label>
                    <input
                      matInput
                      [formControl]="
                        formGroup?.controls?.mainContactPerson.controls
                          .phoneNumber
                      "
                    />
                  </mat-form-field>
                  <mat-form-field fxFlex="50">
                    <mat-label
                      i18n="
                        Fax|Label Telefonfax bei Verladestelle
                        @@LoadingLocationCreateFax"
                      >Telefax
                    </mat-label>
                    <input
                      matInput
                      [formControl]="
                        formGroup?.controls?.mainContactPerson.controls.fax
                      "
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-conainer *ngSwitchCase="1">
            <dpl-loading-location-business-hours
              [dataSource]="dataInput?.id ? undefined : data.businessHours"
              [loadingLocationId]="dataInput?.id ? dataInput.id : undefined"
            ></dpl-loading-location-business-hours>
          </ng-conainer>
          <!-- <dx-data-grid
            [dataSource]="data.businessHours"
            [showBorders]="true"
            id="businesshoursgrid"
          >
            <dxo-editing
              mode="row"
              [allowUpdating]="true"
              [allowAdding]="true"
              [allowDeleting]="true"
            >
              <dxo-form colCount="3">
                <dxi-item dataField="dayOfWeek"></dxi-item>
                <dxi-item dataField="fromTime"></dxi-item>
                <dxi-item dataField="toTime"></dxi-item>
              </dxo-form>
            </dxo-editing>
            <dxi-column
              i18n-caption="@@IDCaption"
              caption="ID"
              dataField="id"
              [visible]="false"
              [allowEditing]="false"
              [formItem]="{ visible: false }"
            >
            </dxi-column>
            <dxi-column
              caption="Wochentag"
              i18n-caption="
                @@CustomerAdministrationLoadingLocationBusinessHoursDayOfWeekColumnTitel"
              dataField="dayOfWeek"
              [allowSorting]="true"
              [calculateSortValue]="getDayOfWeekSortValues"
              cellTemplate="cellTemplateDayOfWeek"
              editCellTemplate="editCellTemplateDayOfWeek"
            >
            </dxi-column>
            <dxi-column
              caption="Öffungszeiten (von)"
              i18n-caption="
                @@CustomerAdministrationLoadingLocationBusinessHoursFromTimeColumnTitel"
              dataField="fromTime"
              dataType="datetime"
              format="shortTime"
              pickerType="rollers"
              [editorOptions]="{ type: 'time' }"
            >
            </dxi-column>
            <dxi-column
              caption="Öffungszeiten (bis)"
              i18n-caption="
                @@CustomerAdministrationLoadingLocationBusinessHoursToTimeColumnTitel"
              dataField="toTime"
              dataType="datetime"
              format="shortTime"
              pickerType="rollers"
              [editorOptions]="{ type: 'time' }"
            >
            </dxi-column>

            <div
              *dxTemplate="let templateDataDayOfWeek of 'cellTemplateDayOfWeek'"
            >
              {{ templateDataDayOfWeek.value | dayOfWeek }}
            </div>

            <div
              *dxTemplate="
                let templateDataDayOfWeek of 'editCellTemplateDayOfWeek'
              "
            >

              <dx-select-box
                id="duplicate-row-business-hours-day-of-week"
                [dataSource]="data.dayOfWeeksTypeData"
                displayExpr="display"
                valueExpr="value"
                [value]="templateDataDayOfWeek.value"
                (onValueChanged)="templateDataDayOfWeek.setValue($event.value)"
              >
              </dx-select-box>
            </div>
          </dx-data-grid> -->
          <dx-data-grid
            *ngSwitchCase="2"
            [dataSource]="data.businessHourExceptions"
            [showBorders]="true"
            id="businesshoursgrid"
            (onEditorPreparing)="businessExceptionsOnEditorPreparing($event)"
          >
            <dxo-editing
              mode="form"
              [allowUpdating]="true"
              [allowAdding]="true"
              [allowDeleting]="true"
              mode="form"
            >
              <dxo-form colCount="3">
                <dxi-item dataField="type"></dxi-item>
                <dxi-item dataField="fromDateTime"></dxi-item>
                <dxi-item dataField="toDateTime"></dxi-item>
              </dxo-form>
              >
            </dxo-editing>
            <dxi-column
              caption="ID"
              dataField="id"
              [visible]="false"
              [allowEditing]="false"
              [formItem]="{ visible: false }"
            ></dxi-column>
            <dxi-column
              i18n-caption="
                Spaltenname für
                Öffnungszustand@@LoadingLocationCreateFormBusinessHoursExceptionsTypeColumnTitel"
              caption="Öffnungszustand"
              dataField="type"
              editCellTemplate="typeEditTemplate"
              [calculateDisplayValue]="getTypeName"
            >
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column
              i18n-caption="
                Spaltenname für
                Von@@LoadingLocationCreateFormBusinessHoursExceptionsFromDateTimeColumnTitel"
              caption="Von"
              dataField="fromDateTime"
              dataType="datetime"
              format="shortDateShortTime"
            >
              <dxi-validation-rule type="required"></dxi-validation-rule>
              <!-- <dxi-validation-rule
                i18n-message="
                  Fehlernachricht für Von Datum Das Datum (von) muss kleiner
                  sein als das Datum
                  (bis)@@LoadingLocationCreateFormBusinessHoursExceptionsFromDateTimeCustomValidationMessage"
                type="custom"
                message="Das Datum (von) muss kleiner sein als das Datum (bis)"
                [validationCallback]="
                  customValidationCallbackBusinessHourExceptions
                "
                [reevaluate]="true"
                [ignoreEmptyValue]="true"
              >
              </dxi-validation-rule> -->
            </dxi-column>
            <dxi-column
              i18n-caption="
                Spaltenname für
                Bis@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsToDateTimeColumnTitel"
              caption="Bis"
              dataField="toDateTime"
              dataType="datetime"
              format="shortDateShortTime"
              ><dxi-validation-rule type="required"></dxi-validation-rule>
              <!-- <dxi-validation-rule
                i18n-message="
                  Fehlernachricht für Bis Datum Das Datum (bis) muss größer sein
                  als das Datum
                  (von)@@CustomerAdministrationLoadingLocationBusinessHoursExceptionsToDateTimeCustomValidationMessage"
                type="custom"
                message="Das Datum (bis) muss größer sein als das Datum (von)"
                [validationCallback]="
                  customValidationCallbackBusinessHourExceptions
                "
                [reevaluate]="true"
                [ignoreEmptyValue]="true"
              >
              </dxi-validation-rule> -->
            </dxi-column>
            <div *dxTemplate="let dataType of 'typeEditTemplate'">
              <dx-select-box
                [dataSource]="data.dropdownExecptionType"
                displayExpr="display"
                valueExpr="value"
                [(value)]="dataType.value"
                (onValueChanged)="dataType.setValue($event.value)"
              ></dx-select-box>
            </div>
          </dx-data-grid>
        </ng-container>
      </div>
    </mat-dialog-content>
  </form>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin: auto"></mat-spinner>
</ng-template>
